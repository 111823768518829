import { useRecoilValue } from "recoil";
import { selectedFacilityIdState } from "../../../state";
import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";
import { facilityEmployeesKey } from "../../query-keys";

export const useFacilityEmployees = () => {
  const {
    employees: { employeeControllerGetFacilityEmployees },
  } = new Api();
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const employees = useGetById({
    id: Number(selectedFacilityId),
    query: employeeControllerGetFacilityEmployees,
    queryKey: facilityEmployeesKey(selectedFacilityId),
  });

  return {
    employees,
  };
};
