import {
  add,
  endOfMonth,
  endOfWeek,
  getDaysInMonth,
  isToday,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { Dispatch, SetStateAction } from "react";
import { useTime } from "react-timer-hook";
import tw from "twin.macro";
import { WorkingHoursScheduleWithShifts } from "../../api/api";
import { getDaysArray } from "../../fields/form/utils";
import { Maybe, TwinStyle } from "../../types";
import { Typography } from "../../ui/Typograhy";
import { CalendarHeader } from "./CalendarHeader";
import { MonthCell } from "./components/MonthCell";
/** @jsxImportSource @emotion/react */

interface IMonthCalendarProps {
  selectedDay: Date;
  setSelectedDay: Dispatch<SetStateAction<Date>>;
  activeTab: number;
  schedule: WorkingHoursScheduleWithShifts[];
  containerCss?: Maybe<TwinStyle>;
}

export const MonthCalendar = ({
  selectedDay,
  setSelectedDay,
  activeTab,
  schedule,
  containerCss = [],
}: IMonthCalendarProps) => {
  const numberOfDaysInMonth = 42;
  const start = startOfMonth(selectedDay);
  const end = endOfMonth(selectedDay);
  const daysInMonth = getDaysInMonth(selectedDay);
  const missingNumberOfMonthDays = numberOfDaysInMonth - daysInMonth;
  const prevDaysToFetch = -((start.getDay() === 0 ? 7 : start.getDay()) - 1);
  const nextDayToFetch = missingNumberOfMonthDays - Math.abs(prevDaysToFetch);
  const daysOfMonth = getDaysArray(
    add(start, { days: prevDaysToFetch }),
    add(end, { days: nextDayToFetch })
  );

  const startWeek = startOfWeek(selectedDay, { weekStartsOn: 1 });
  const endWeek = endOfWeek(selectedDay, { weekStartsOn: 1 });
  const daysOfWeek = getDaysArray(startWeek, endWeek);

  return (
    <div css={containerCss}>
      <CalendarHeader
        setSelectedDay={setSelectedDay}
        selectedDay={selectedDay}
        type={"month"}
      />
      <div
        css={[
          tw`flex`,
          tw`max-w-[inherit] w-full rounded-t-md z-50`,
          tw`border-1 border-gray-100 rounded-b-md`,
        ]}
      >
        {daysOfWeek.map((dow, i, allDow) => (
          <div css={[tw`w-full`]} key={dow.getTime()}>
            <div
              css={[
                tw`flex flex-1 justify-center items-center py-2.5 px-4`,
                i === allDow.length - 1 && tw`rounded-tr-md`,
                i === 0 && tw`rounded-tl-md`,
                i !== allDow.length - 1 && tw`border-r-1 border-r-gray-100`,
                tw`bg-white`,
              ]}
            >
              <Typography.BodySmall
                containerCss={[
                  tw`leading-4 font-medium tracking-wider uppercase text-gray-600`,
                ]}
              >
                {new Date(dow).toLocaleString("en-hr", {
                  weekday: "short",
                })}
              </Typography.BodySmall>
            </div>
            <div css={[tw`max-h-[calc(100vh - 16rem)] w-full`]}>
              {daysOfMonth
                .filter((dom) => dom.getDay() === dow.getDay())
                .map((dom, index, allDom) => {
                  return (
                    <MonthCell
                      key={dom.getTime()}
                      day={dom}
                      schedule={schedule}
                      index={index}
                      isLast={i === allDow.length - 1}
                    />
                  );
                })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
