import { Api } from "../../api";
import { useGetPaginated } from "../../api-hooks/useGetPaginated";

export const usePatientAppointments = ({ id }: { id?: number }) => {
  const {
    appointments: {
      appointmentControllerGetPatientAppointmentsPaginated,
      appointmentControllerGetPatientHistoryAppointmentsPaginated,
      appointmentControllerGetPatientOngoingAppointmentsPaginated,
    },
  } = new Api();

  const patientAppointments = useGetPaginated({
    id: id!,
    query: appointmentControllerGetPatientAppointmentsPaginated,
    queryKey: ["patient", id!, "appointments"],
  });

  const patientOngoingAppointments = useGetPaginated({
    id: id!,
    query: appointmentControllerGetPatientOngoingAppointmentsPaginated,
    queryKey: ["patient", id!, "appointments", "ongoing"],
  });

  const patientHistoryAppointments = useGetPaginated({
    id: id!,
    query: appointmentControllerGetPatientHistoryAppointmentsPaginated,
    queryKey: ["patient", id!, "appointments", "history"],
  });

  return {
    patientAppointments,
    patientOngoingAppointments,
    patientHistoryAppointments,
  };
};
