import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();
export const filterState = atom({
  key: "filterStateAtom", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

export const searchState = atom({
  key: "searchStateAtom", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});
//testing
export const isLoggedInState = atom<boolean>({
  key: "isLoggedInAtom", // unique ID (with respect to other atoms/selectors)
  default: false,
  effects_UNSTABLE: [persistAtom],
});

//testing
export const hasFacilityState = selector({
  key: "hasFacilityAtom", // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const selectedFacilityId = get(selectedFacilityIdState);
    return !!selectedFacilityId;
  },
});

export const selectedFacilityIdState = atom<string | null>({
  key: "selectedFacilityIdAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const selectedOrganisationIdState = atom<string | number | null>({
  key: "selectedOrganisationIdAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

//testing
export const hasSeenMessageState = atom({
  key: "hasSeenMessageAtom", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
