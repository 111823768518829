/** @jsxImportSource @emotion/react */

import { IFieldComponentBaseProps, IOption } from "../../types";

import tw from "twin.macro";
import { useId } from "react";
import { Typography } from "../../ui/Typograhy";

export type IRadioGroupProps = Omit<
  IFieldComponentBaseProps<IOption<string>>,
  "placeholder"
> & {
  options: (IOption<string> & { description?: string })[];
  shouldOnlySelectValue?: boolean;
  dataParser?(data: any): any;
};
export const RadioGroup = (props: IRadioGroupProps) => {
  const id = useId();
  const { dataParser = (data) => data } = props;
  const option = props.shouldOnlySelectValue
    ? //@ts-ignore
      props.options.find((op) => dataParser(op.value) === props.value)
    : props.value;
  return (
    <div css={[tw`flex flex-col`, props.containerCss]}>
      {props.options.map((o, i, all) => {
        const isSelected = o.value === option?.value;
        return (
          <div
            key={i}
            css={[
              tw`flex flex-row gap-x-3 items-center p-4 cursor-pointer`,
              isSelected && tw`bg-primary-50`,
              isSelected && i === 0 && tw`rounded-t-md`,
              isSelected && i === all.length - 1 && tw`rounded-b-md`,
              i !== all.length - 1 && tw`border-b-1 border-b-gray-200`,
            ]}
            onClick={() => {
              if (option?.value === o.value) {
                props.onChange(null);
              } else {
                props.onChange(o);
              }
            }}
          >
            <input
              id={id}
              value={o.value}
              type="radio"
              checked={isSelected}
              onChange={(_) => {
                if (option?.value === o.value) {
                  props.onChange(null);
                } else {
                  props.onChange(o);
                }
              }}
              disabled={props.disabled ?? false}
              css={[
                tw`appearance-none h-0 w-0 border-3 border-transparent rounded-full cursor-pointer ring-[5px] ring-gray-200`,
                tw`checked:(bg-primary border-white ring-primary)`,
                tw`hover:(ring-primary checked:bg-primary)`,
                tw`disabled:(ring-gray-200 border-white cursor-not-allowed)`,
                tw`disabled:checked:(bg-gray-200)`,
                o.description && tw`-mt-5`,
                props.error && tw`ring-error`,
              ]}
            />
            <div>
              <Typography.BodySmall
                containerCss={[isSelected && tw`text-primary-900`]}
              >
                {o.label}
              </Typography.BodySmall>
              {o?.description && (
                <Typography.BodySmall
                  containerCss={[
                    tw`text-gray-500`,
                    isSelected && tw`text-primary-700`,
                  ]}
                >
                  {o.description}
                </Typography.BodySmall>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
