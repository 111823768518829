import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import tw from "twin.macro";
import { Api } from "../../../api/api";
import { MonthCalendar } from "../../../components/calendar/MonthCalendar";
/** @jsxImportSource @emotion/react */

export const FacilityScheduleTab = ({ activeTab, workTimes }) => {
  const { id } = useParams();

  const [selectedDay, setSelectedDay] = useState(new Date());
  const {
    facilities: { facilityControllerGetFacilityExceptionWorkingHours },
  } = new Api();

  const { data: facilityExceptionWorkingHours } = useQuery(
    ["facility", id, "working-hours", "exception"],
    async () => {
      const { data } = await facilityControllerGetFacilityExceptionWorkingHours(
        Number(id),
        { date: selectedDay.toISOString() }
      );

      return {
        ...data,
        payload: data.payload?.exceptionWorkingHoursScheduleWithShifts,
      };
    },
    {
      enabled: !!id,
    }
  );

  return (
    <div css={[tw`flex h-full justify-center`]}>
      <div css={[tw`w-full max-w-screen-xl`]}>
        <MonthCalendar
          schedule={[
            ...(workTimes || []),
            ...(facilityExceptionWorkingHours?.payload || []),
          ]}
          activeTab={activeTab}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
        />
      </div>
    </div>
  );
};
