import { IComponentBaseProps } from "../../types";
import { Typography } from "../../ui/Typograhy";
import tw from "twin.macro";
import { useFormError } from "../form/hooks";
import { useTranslation } from "react-i18next";

/** @jsxImportSource @emotion/react */

interface IFieldErrorProps extends IComponentBaseProps {
  name: string;
  disabled?: boolean;
}

export const FieldError = (props: IFieldErrorProps) => {
  const error = useFormError(props.name);
  const { t } = useTranslation();
  return (
    <div
      className="scrollable-error"
      css={[tw`min-h-5.25 mt-1`, props.containerCss]}
    >
      {!props.disabled && (
        <Typography.Caption containerCss={[tw`text-error`]}>
          {t(error || "", { count: 1 })}
        </Typography.Caption>
      )}
    </div>
  );
};
