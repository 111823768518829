/** @jsxImportSource @emotion/react */
import {
  HiCheckCircle,
  HiExclamation,
  HiExclamationCircle,
  HiInformationCircle,
  HiX,
} from "react-icons/hi";

import { IconType } from "react-icons";
import { toast as reactToast } from "react-toastify";
import tw from "twin.macro";
import { onKeyDownA11Y } from "../../utils";
import { Typography } from "../Typograhy";

interface IBaseToastProps {
  text: string;
  icon: IconType;
  // STATEMENT: Why are these 2 properties defined if we don't pass them through props.
  // This is a common pattern in React. To the end user are only exposed public properties
  // and the rest is injected by using React.cloneElement in library code.
  // Look here for examples: https://blog.logrocket.com/using-react-cloneelement-function/
  closeToast?: () => void;
  toastProps?: any;
}
const BaseToast = (props: IBaseToastProps) => {
  const Icon = props.icon;
  return (
    <>
      <div css={[tw`m-3 flex flex-row items-center gap-3`]}>
        <Icon css={[tw`h-6 w-6 text-white`]} />
        <Typography.BodyMedium containerCss={[tw`text-white`]}>
          {props.text}
        </Typography.BodyMedium>
      </div>
      <HiX
        role={"button"}
        tabIndex={0}
        onKeyDown={onKeyDownA11Y({ open: props.closeToast })}
        onClick={props.closeToast}
        css={[
          tw`absolute top-1.5 right-1.5 h-5 w-5`,
          tw`text-white cursor-pointer`,
        ]}
      />
    </>
  );
};

const bodyStyle = tw`m-0 p-0 relative`;
// STATEMENT: This isn't going to cut it. I need more options to achieve specific behaviour.
// Look at the App.tsx file. There is ToastContainer component which has many properties(duration, position, etc.)
export const toast = {
  error: (text?: string) =>
    reactToast(
      <BaseToast
        text={text ?? "Something went wrong."}
        icon={HiExclamationCircle}
      />,
      {
        style: tw`bg-error`,
        bodyStyle,
      }
    ),
  warning: (text: string) =>
    reactToast(<BaseToast text={text} icon={HiExclamation} />, {
      style: tw`bg-warning h-0`,
      bodyStyle,
    }),
  info: (text: string) =>
    reactToast(<BaseToast text={text} icon={HiInformationCircle} />, {
      style: tw`bg-info h-0`,
      bodyStyle,
    }),
  success: (text: string) =>
    reactToast(<BaseToast text={text} icon={HiCheckCircle} />, {
      style: tw`bg-success h-0`,
      bodyStyle,
    }),
};
