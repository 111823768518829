import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import * as yup from "yup";
import {
  CreatePatientWebRequestDto,
  GetPatientByIdResponseDto,
  UpdatePatientInfoRequestDto,
} from "../../../api/api";
import { usePatient } from "../../../api/hooks/patients/usePatient";
import { usePatients } from "../../../api/hooks/patients/usePatients";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { selectedFacilityIdState } from "../../../state";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { IModalProps, Modal } from "../../../ui/popups/Modal";
import { errorMessages } from "../../../utils";
/** @jsxImportSource @emotion/react */
import locale from "../../../utils/locale/hr.json";

const schema: yup.SchemaOf<CreatePatientWebRequestDto> = yup.object().shape({
  firstName: yup.string().required(errorMessages.required),
  lastName: yup.string().required(errorMessages.required),
  email: yup.string().required(errorMessages.required),
  phone: yup.string().required(errorMessages.required),
  birthDate: yup.string().required(errorMessages.required),
  sex: yup.string().required(errorMessages.required),
  country: yup.string().required(errorMessages.required),
  address: yup.string().required(errorMessages.required),
  city: yup.string().required(errorMessages.required),
  note: yup.string(),
  preferredFacilityId: yup.number().typeError(errorMessages.number),
  password: yup.string(),
});

type IForm = yup.InferType<typeof schema>;

const genderOptions = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
  { value: "OTHER", label: "Other" },
];

export const ManagePatientModal = ({
  open,
  onClose,
  patient,
}: Omit<IModalProps, "label" | "footerChildren"> & {
  patient?: GetPatientByIdResponseDto;
}) => {
  const isEditPatient = !!patient?.id;
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const { updatePatient } = usePatient({ id: patient?.id! });
  const { createPatient } = usePatients();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      ...patient,
      //@ts-ignore
      birthDate: new Date(patient?.birthDate || new Date()),
      //@ts-ignore
      note: patient?.note || "",
      preferredFacilityId: Number(selectedFacilityId),
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const allStates = Object.keys(locale.translation.countries).map((key) => ({
    value: key,
    label: t(`countries.${key}`),
  }));

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      if (isEditPatient) {
        await updatePatient({
          //@ts-ignore
          requestData: {
            ...values,
            birthDate: new Date(values.birthDate).toISOString(),
          } as UpdatePatientInfoRequestDto,
        });
      } else {
        await createPatient({
          requestData: {
            ...values,
            birthDate: new Date(values.birthDate).toISOString(),
          },
        });
      }
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  const onSubmitContinueToAddService = methods.handleSubmit(async (values) => {
    try {
      const patientData = await createPatient({
        requestData: {
          ...values,
          birthDate: new Date(values.birthDate).toISOString(),
        } as CreatePatientWebRequestDto,
      });
      if (patientData?.id) {
        navigate("/appointments", { state: { patientId: patientData.id } });
      }
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={
          (isEditPatient
            ? t("editPersonalInfo")
            : t("enterNewPatientInfo")) as string
        }
        footerChildren={
          <div css={[tw`flex justify-between gap-3`]}>
            {!isEditPatient && (
              <Button.Outlined
                containerCss={[tw`flex-1`]}
                // onClick={isEditPatient ? onClose : onSubmit}
                onClick={onSubmit}
              >
                {/* {isEditPatient ? t("archive") : t("addPatient")} */}
                {t("addPatient")}
              </Button.Outlined>
            )}
            <Button.Contained
              containerCss={[tw`flex-5`]}
              onClick={isEditPatient ? onSubmit : onSubmitContinueToAddService}
            >
              {isEditPatient ? t("save") : t("addPatientAndArrangeService")}
            </Button.Contained>
          </div>
        }
      >
        <div>
          <div css={[tw`flex gap-4`]}>
            <Form.TextInput.Outlined
              required
              label={t("name") as string}
              placeholder={t("enterName") as string}
              name="firstName"
            />
            <Form.TextInput.Outlined
              required
              label={t("surname") as string}
              placeholder={t("enterSurname") as string}
              name="lastName"
            />
          </div>
          <div css={[tw`flex gap-4`]}>
            <Form.TextInput.Outlined
              required
              label={t("email") as string}
              placeholder={t("emailPlaceholder") as string}
              name="email"
            />
            <Form.TextInput.Outlined
              required
              label={t("contactPhone") as string}
              placeholder={t("phonePlaceholder") as string}
              name="phone"
            />
          </div>
          <div css={[tw`flex gap-4`]}>
            <Form.DatePicker
              required
              label={t("birthDate") as string}
              placeholder={t("enterBirthDateDate") as string}
              name="birthDate"
            />
            <Form.Select
              options={genderOptions}
              required
              shouldOnlySelectValue
              label={t("gender") as string}
              placeholder={t("selectGender") as string}
              name="sex"
            />
          </div>
          <div css={[tw`flex gap-4`]}>
            <Form.Select
              options={allStates}
              shouldOnlySelectValue
              name="country"
              required
              label={t("state") as string}
              placeholder={t("selectState") as string}
            />
            <Form.TextInput.Outlined
              required
              label={t("address") as string}
              placeholder={t("enterAddress") as string}
              name="address"
            />
            <Form.TextInput.Outlined
              required
              label={t("city") as string}
              placeholder={t("enterCity") as string}
              name="city"
            />
          </div>
          {!isEditPatient && (
            <Form.TextArea.Outlined
              name="note"
              rows={5}
              label={t("remark") as string}
              placeholder={t("enterRemark") as string}
            />
          )}
        </div>
      </Modal>
    </FormProvider>
  );
};
