import { IFieldComponentBaseProps, IOption, ISelectOption } from "../../types";
import { addMinutes, endOfDay, isBefore, startOfDay } from "date-fns";

import { Select } from "./Select";
import { formatDate } from "../../utils";
import { useMemo } from "react";

/** @jsxImportSource @emotion/react */

interface ITimePickerProps extends IFieldComponentBaseProps<IOption<Date>> {
  timeIntervals?: number;
  autofocus?: boolean;
  shouldOnlySelectValue?: boolean;
  dataParser?(data: any): any;
  customOptions?: ISelectOption<Date>[];
}

export const createTimeOptions = (
  timeInterval: number,
  selectedDate?: Date,
  format?: string
): IOption<Date>[] => {
  const options: IOption<Date>[] = [];
  const start = startOfDay(new Date(selectedDate || new Date()));
  const end = endOfDay(new Date(selectedDate || new Date()));
  let current = start;
  while (isBefore(current, end)) {
    options.push({
      label: formatDate(current, { dateFormat: format || "HH:mm" }),
      value: current,
    });
    current = addMinutes(current, timeInterval);
  }

  return options;
};

export const TimePicker = (props: ITimePickerProps) => {
  const options = useMemo(
    () => props.customOptions || createTimeOptions(props.timeIntervals ?? 15),
    [props.customOptions, props.timeIntervals]
  );

  return (
    <Select
      disabled={props.disabled}
      value={props.value}
      autofocus={props.autofocus}
      onChange={props.onChange}
      label={props.label}
      error={props.error}
      dataParser={props.dataParser}
      required={props.required}
      placeholder={props.placeholder}
      options={options}
      containerCss={props.containerCss}
      shouldOnlySelectValue={props.shouldOnlySelectValue}
    />
  );
};
