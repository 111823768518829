import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";

import { usePatientAppointments } from "../../../../api/hooks/patients/usePatientAppointments";
import { searchState } from "../../../../state";
import { Maybe } from "../../../../types";
import { AsyncDataTable } from "../../../../ui/layout/data-table/async/AsyncDataTable";
import { TableCell } from "../../../../ui/layout/data-table/cell";
import { AppointmentDetailsModal } from "../../../appointments/modals/AppointmentDetailsModal";
import { PatientInfoSection } from "../../components/PatientInfoSection";

/** @jsxImportSource @emotion/react */

export const PatientArrangedAppointmentsTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [activeRowIndex, setActiveRowIndex] = useState<Maybe<number>>(null);
  const [isEnterAppointmentInfoModalOpen, setIsEnterAppointmentInfoModalOpen] =
    useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] =
    useState<Maybe<number>>(null);
  const search = useRecoilValue(searchState);

  const {
    patientAppointments: {
      data: patientAppointments,
      isFetching,
      filters,
      setFilter,
      page,
      setPage,
      size,
      setOrderField,
      setOrder,
      order,
      orderField,
    },
  } = usePatientAppointments({ id: Number(id) });

  useEffect(() => {
    if (filters.term !== search) {
      setFilter("term", search);
    }
  }, [filters.term, search, setFilter]);

  const records = patientAppointments?.items ?? [];
  const total = patientAppointments?.totalItems ?? 0;
  return (
    <div css={[tw`flex h-full justify-center`]}>
      <PatientInfoSection />
      <AsyncDataTable
        activeRowIndex={activeRowIndex}
        loading={isFetching}
        data={records}
        onRowClick={(u, i) => {
          setActiveRowIndex(u.id);
          setSelectedAppointmentId(u.id);
          setIsEnterAppointmentInfoModalOpen(true);
        }}
        containerCss={[tw`max-w-[965px] w-full flex flex-col justify-between`]}
        columns={[
          {
            Header: t("serviceName"),
            accessor: "serviceName",
            disableSortBy: true,

            cell: (s) => <TableCell.Text value={`${s.serviceName}`} />,
          },
          {
            Header: t("zu"),
            accessor: "facilityName",
            align: "right",
            disableSortBy: true,
            cell: (s) => <TableCell.Text value={`${s.facilityName}`} />,
          },
          {
            Header: t("date"),
            accessor: "recordCreatedAt",
            disableSortBy: true,
            align: "right",
            cell: (s) => <TableCell.Date value={new Date(s.scheduledFor)} />,
          },
          {
            Header: t("time"),
            accessor: "scheduledFor",
            disableSortBy: true,
            align: "right",
            cell: (s) => (
              <TableCell.Date
                value={new Date(s.scheduledFor)}
                dateFormat="HH:mm"
              />
            ),
          },
          {
            Header: t("price"),
            accessor: "price",
            disableSortBy: true,
            align: "right",
            cell: (s) => (
              <TableCell.Text
                value={`€ ${s.price} (${(s.price * 7.435).toFixed(2)} kn)`}
              />
            ),
          },
          {
            Header: "",
            align: "right",
            disableSortBy: true,
            accessor: "arrivedAt",
            cell: (s) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const [isModalOpen, setIsModalOpen] = useState(false);
              return (
                <>
                  <TableCell.Button
                    lead={RiCheckboxCircleLine}
                    onClick={(e) => {
                      // e.stopPropagation();
                      // e.preventDefault();
                      // setIsModalOpen(true);
                    }}
                    variant="small"
                    containerCss={[tw`pl-2 pr-2 min-w-2`]}
                  >
                    {t("reportArrival")}
                  </TableCell.Button>
                  <AppointmentDetailsModal
                    onClose={() => setIsModalOpen(false)}
                    open={isModalOpen}
                    appointmentId={s.id}
                  />
                </>
              );
            },
          },
        ]}
        currentPage={page}
        setPage={setPage}
        pageSize={size}
        maxPage={patientAppointments?.totalPages || 0}
        totalCount={total}
        setSortKey={setOrderField}
        setSortOrder={setOrder}
        sortKey={orderField}
        sortOrder={order}
      />
      {!!selectedAppointmentId && (
        <AppointmentDetailsModal
          appointmentId={selectedAppointmentId}
          onClose={() => setIsEnterAppointmentInfoModalOpen(false)}
          open={isEnterAppointmentInfoModalOpen}
        />
      )}
    </div>
  );
};
