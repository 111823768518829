import {
  isWeekend,
  add,
  sub,
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
} from "date-fns";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RiArrowLeftSLine,
  RiCalendarTodoLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import tw from "twin.macro";
import { Button } from "../../ui/buttons/Button";
import { Typography } from "../../ui/Typograhy";
import { CalendarModal } from "../modals/CalendarModal";
/** @jsxImportSource @emotion/react */

export const CalendarHeader = ({
  setSelectedDay,
  selectedDay,
  type,
}: {
  setSelectedDay: Dispatch<SetStateAction<Date>>;
  selectedDay: Date;
  type: "day" | "month" | "week";
}) => {
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleAdd = () => {
    if (type === "day") {
      setSelectedDay((currDay) => {
        if (!isWeekend(add(currDay, { days: 1 }))) {
          return add(currDay, { days: 1 });
        } else if (!isWeekend(add(currDay, { days: 2 }))) {
          return add(currDay, { days: 2 });
        }
        return add(currDay, { days: 3 });
      });
    } else if (type === "month") {
      setSelectedDay((currDay) => {
        const startOfNextMonth = startOfMonth(add(currDay, { months: 1 }));
        startOfNextMonth.setHours(2);

        return startOfNextMonth;
      });
    } else if (type === "week") {
      setSelectedDay((currDay) => {
        const startOfNextWeek = startOfWeek(add(currDay, { weeks: 1 }), {
          weekStartsOn: 1,
        });
        startOfNextWeek.setHours(2);
        return startOfNextWeek;
      });
    }
  };

  const handleSubtract = () => {
    if (type === "day") {
      setSelectedDay((currDay) => {
        if (!isWeekend(sub(currDay, { days: 1 }))) {
          return sub(currDay, { days: 1 });
        } else if (!isWeekend(sub(currDay, { days: 2 }))) {
          return sub(currDay, { days: 2 });
        }
        return sub(currDay, { days: 3 });
      });
    } else if (type === "month") {
      setSelectedDay((currDay) => {
        const startOfPrevMonth = startOfMonth(sub(currDay, { months: 1 }));
        startOfPrevMonth.setHours(2);

        return startOfPrevMonth;
      });
    } else if (type === "week") {
      setSelectedDay((currDay) => {
        const startOfPrevWeek = startOfWeek(sub(currDay, { weeks: 1 }), {
          weekStartsOn: 1,
        });
        startOfPrevWeek.setHours(2);

        return startOfPrevWeek;
      });
    }
  };

  return (
    <>
      <div
        css={[
          tw`flex items-center w-full justify-center py-6`,
          ["month", "week"].includes(type) && tw`justify-between`,
        ]}
      >
        {type === "week" && (
          <div css={[tw`flex`]}>
            <Typography.BodyLarge>
              {format(
                startOfWeek(selectedDay, { weekStartsOn: 1 }),
                "dd. MMMM yyyy."
              )}
            </Typography.BodyLarge>
            <Typography.BodyLarge containerCss={[tw`mx-2`]}>
              -
            </Typography.BodyLarge>
            <Typography.BodyLarge>
              {format(
                endOfWeek(selectedDay, { weekStartsOn: 1 }),
                "dd. MMMM yyyy."
              )}
            </Typography.BodyLarge>
          </div>
        )}
        {type === "month" && (
          <div css={[tw`flex`]}>
            <Typography.BodyLarge>
              {format(selectedDay, "MMMM yyyy.")}
            </Typography.BodyLarge>
          </div>
        )}
        <div css={[tw`flex items-center gap-3`]}>
          <Button.Outlined
            trail={RiArrowLeftSLine}
            trailCss={[tw`ml-0`]}
            variant="small"
            containerCss={[tw`min-w-10`]}
            onClick={handleSubtract}
          />
          {type === "day" && (
            <div
              css={[
                tw`flex items-center gap-2.5 py-0.5 border-2 border-gray-200 rounded max-w-64 w-full justify-center hover:(cursor-pointer)`,
              ]}
              onClick={() => setIsCalendarModalOpen(true)}
            >
              <Typography.BodySmall>
                {format(selectedDay, "eeee dd. MMM yyyy")}
              </Typography.BodySmall>
              <RiCalendarTodoLine />
            </div>
          )}
          <Button.Outlined
            trail={RiArrowRightSLine}
            variant="small"
            onClick={handleAdd}
            containerCss={[tw`min-w-30`]}
          >
            {type === "day"
              ? t("nextWorkDay")
              : type === "month"
              ? t("nextMonth")
              : t("nextWeek")}
          </Button.Outlined>
          <Button.Outlined
            variant="small"
            containerCss={[tw`min-w-10`]}
            onClick={() => setSelectedDay(new Date())}
          >
            {type === "day"
              ? t("today")
              : type === "month"
              ? t("showThisMonth")
              : t("showThisWeek")}
          </Button.Outlined>
        </div>
      </div>
      <CalendarModal
        selectedDay={selectedDay}
        open={isCalendarModalOpen}
        onClose={() => setIsCalendarModalOpen(false)}
        onDayClick={(value) => setSelectedDay(value)}
      />
    </>
  );
};
