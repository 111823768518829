import { IComponentBaseProps } from "../../types";
import { PropsWithChildren } from "react";
import { Typography } from "../../ui/Typograhy";
import tw from "twin.macro";

/** @jsxImportSource @emotion/react */

interface IFieldLabelProps extends IComponentBaseProps {
  disabled?: boolean;
  isInErrorState?: boolean;
}

export const FieldLabel = (props: PropsWithChildren<IFieldLabelProps>) => {
  return (
    <Typography.BodyXSmall
      containerCss={[
        tw`leading-4 font-semibold tracking-wide uppercase`,
        props.disabled && tw`text-gray-300`,
        props.isInErrorState && tw`text-error`,
        props.containerCss,
      ]}
      element="label"
    >
      {props.children}
    </Typography.BodyXSmall>
  );
};
