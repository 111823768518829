import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";
import { useUpdate } from "../../api-hooks/useUpdate";

export const useFacility = ({ id }: { id: number }) => {
  const {
    facilities: {
      facilityControllerGetFacility,
      facilityControllerUpdateFacilityInfo,
      facilityControllerUpdateFacilityDescription,
    },
  } = new Api();

  const {
    data: facility,
    isLoading: isLoadingFacility,
    refetch: refetchFacility,
  } = useGetById({
    id,
    query: facilityControllerGetFacility,
    queryKey: ["facility", id],
  });

  const { mutateAsync: updateFacility, isLoading: isUpdatingFacility } =
    useUpdate(id, facilityControllerUpdateFacilityInfo, ["facility", id]);

  const { mutateAsync: updateFacilityDesc, isLoading: isUpdatingFacilityDesc } =
    useUpdate(id, facilityControllerUpdateFacilityDescription, [
      "facility",
      id,
    ]);

  return {
    facility,
    updateFacility,
    isUpdatingFacility,
    updateFacilityDesc,
    isUpdatingFacilityDesc,
    refetchFacility,
    isLoadingFacility,
  };
};
