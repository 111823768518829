import { yupResolver } from "@hookform/resolvers/yup";
import { getHours, getMinutes } from "date-fns";
import { addMinutes } from "date-fns/esm";

import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import * as yup from "yup";
import {
  CreateNewsNewsImage,
  RequestNewsImageLinksResponseDto,
} from "../../../api/api";
import { useFileUpload } from "../../../api/hooks/file-upload/useFileUpload";
import { useNews } from "../../../api/hooks/news/useNews";

import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Button } from "../../../ui/buttons/Button";
import { Dropzone } from "../../../ui/Dropzone";
import { toast } from "../../../ui/indicators/Toast";
import { Modal } from "../../../ui/popups/Modal";
import { Typography } from "../../../ui/Typograhy";
import { errorMessages } from "../../../utils";
import { uploadFile } from "../../../utils/upload-file";

/** @jsxImportSource @emotion/react */

const schema = yup
  .object()
  .shape({
    superTitle: yup.string().required(errorMessages.required),
    title: yup.string().required(errorMessages.required),
    schedulePublishingForDate: yup.string(),
    schedulePublishingForTime: yup.string(),
    introText: yup.string().required(errorMessages.required),
    image: yup.object(),
    asPushNotification: yup.boolean().default(false).required(),
    content: yup.string().required(errorMessages.required),
  })
  .required(errorMessages.required);

interface ITextInputModalProps {
  open: boolean;
  onClose: () => void;
}

type IForm = yup.InferType<typeof schema>;

export const CreateNewsModal = ({ open, onClose }: ITextInputModalProps) => {
  const { createNews } = useNews();
  const { t } = useTranslation();
  const { requestNewsImageLinks } = useFileUpload();
  const [imageForUpload, useImageForUpload] = useState<File>();

  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      schedulePublishingForTime: 0,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      let newsImageWithLinks: CreateNewsNewsImage | undefined = undefined;
      if (imageForUpload) {
        const uploadData = (await requestNewsImageLinks({
          fileName: { fileName: imageForUpload.name },
        })) as RequestNewsImageLinksResponseDto;
        await uploadFile(uploadData.uploadUrl, imageForUpload);
        newsImageWithLinks = {
          name: imageForUpload.name,
          downloadUrl: uploadData.downloadUrl,
          fileUUID: uploadData.fileUUID,
        };
      }
      await createNews({
        requestData: {
          ...values,
          isDraft: false,
          schedulePublishingForDateAndTime: values?.schedulePublishingForDate
            ? addMinutes(
                new Date(values.schedulePublishingForDate),
                values.schedulePublishingForTime
              ).toISOString()
            : undefined,
          image: newsImageWithLinks ? newsImageWithLinks : undefined,
        },
      });
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  const onSaveAsDraft = methods.handleSubmit(async (values) => {
    try {
      await createNews({
        requestData: {
          ...values,
          isDraft: true,
          schedulePublishingForDateAndTime: values?.schedulePublishingForDate
            ? addMinutes(
                new Date(values.schedulePublishingForDate),
                values.schedulePublishingForTime
              ).toISOString()
            : undefined,
        },
      });
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  const RequestLinksForNewsImage = async (files: File[]) => {
    useImageForUpload(files[0]);
  };

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={t("addNews") as string}
        footerChildren={
          <div
            css={[
              tw`flex bg-gray-100 pr-4 pl-4 pt-2 pb-2 gap-3 -ml-6 -mb-6 -mr-6 rounded-br-md`,
            ]}
          >
            <div css={[tw`flex w-full gap-3`]}>
              <Button.Outlined
                containerCss={[tw`w-full`]}
                onClick={() => onSaveAsDraft()}
              >
                {t("saveAsDraft")}
              </Button.Outlined>
              <Button.Outlined containerCss={[tw`w-full`]}>
                {t("previewBeforePublishing")}
              </Button.Outlined>
            </div>
            <Button.Contained
              containerCss={[tw`w-full`]}
              onClick={() => onSubmit()}
            >
              {t("addNews")}
            </Button.Contained>
          </div>
        }
      >
        <div css={[tw`flex flex-col`]}>
          <div css={[tw`flex gap-6`]}>
            <div css={[tw`w-full`]}>
              <Form.TextInput.Outlined
                label={t("superTitle") as string}
                name="superTitle"
                placeholder={t("enterSuperTitle") as string}
                required={true}
              />
              <Form.TextInput.Outlined
                label={t("headline") as string}
                name="title"
                placeholder={t("enterHeadline") as string}
                required={true}
              />
              <div css={[tw`flex gap-6`]}>
                <Form.DatePicker
                  label={t("schedulePublishing") as string}
                  placeholder={t("selectDate") as string}
                  name="schedulePublishingForDate"
                  minDate={new Date(Date.now())}
                />
                <Form.TimePicker
                  containerCss={[tw`flex-3`]}
                  shouldOnlySelectValue
                  dataParser={(data) => getMinutes(data) + getHours(data) * 60}
                  label={t("time") as string}
                  placeholder={t("selectTime") as string}
                  name="schedulePublishingForTime"
                />
              </div>
              <Form.TextArea.Outlined
                name="introText"
                rows={3}
                label={t("introText") as string}
                placeholder={t("introTextPlaceholder") as string}
                required={true}
              />
              <Dropzone
                multiple={false}
                title={t("addOrDragDropPhoto") as string}
                subtitle={t("pngOrJpgOrPdf10Mb") as string}
                maxFileSize={10}
                containerCss={[tw`p-1 mb-6`]}
                accept={{
                  "image/png": [".png"],
                  "image/jpg": [".jpg"],
                }}
                onUpload={(files) => RequestLinksForNewsImage(files)}
              />
              <div css={[tw`flex items-center justify-between w-full mb-6`]}>
                <div>
                  <Typography.BodyXSmall>
                    {t("sendNotification")}
                  </Typography.BodyXSmall>
                  <Typography.BodySmall>
                    {t("notificationWithPush")}
                  </Typography.BodySmall>
                </div>
                <Form.Switch
                  name="asPushNotification"
                  containerCss={[tw`flex-none w-fit flex items-center`]}
                />
              </div>
            </div>
            <div css={[tw`w-full`]}>
              <Form.TextArea.Outlined
                name="content"
                rows={26}
                label={t("newsText") as string}
                placeholder={t("enterText") as string}
                required={true}
              />
            </div>
          </div>
        </div>
      </Modal>
    </FormProvider>
  );
};
