import { useFormError, useFormField } from "./hooks";

import { DatePicker } from "../controlled/DatePicker";
import { FieldError } from "../components/FieldError";
import { IFormFieldComponentBaseProps } from "../../types";
import tw from "twin.macro";

/** @jsxImportSource @emotion/react */

export interface IFormDatePickerProps extends IFormFieldComponentBaseProps {
  minDate?: Date | null;
}

export const FormDatePicker = (props: IFormDatePickerProps) => {
  const field = useFormField(props.name);
  const error = useFormError(props.name);

  return (
    <div css={[tw`flex-1 w-full`, props.containerCss]}>
      <DatePicker
        disabled={props.disabled}
        value={field.value}
        onChange={field.onChange}
        label={props.label}
        error={error}
        required={props.required}
        placeholder={props.placeholder}
        minDate={props.minDate}
      />
      <FieldError name={props.name} containerCss={[tw`ml-4`]} />
    </div>
  );
};
