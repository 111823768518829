export enum UserSex {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export enum FacilityType {
  POLYCLINIC = "POLYCLINIC",
  SPECIAL_HOSPITAL = "SPECIAL HOSPITAL",
  REST = "REST",
}

export enum PermissionType {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export enum AwardType {
  REFERRAL_AWARD = "REFERRAL_AWARD",
  USE_SERVICE = "USE_SERVICE",
  ADMIN_AWARD = "ADMIN_AWARD",
  DEFAULT_AWARD = "DEFAULT_AWARD",
}

export enum FeatureType {
  PATIENTS = "PATIENTS",
  EMPLOYEES = "EMPLOYEES",
  DEPARTMENTS = "DEPARTMENTS",
  COUPONS = "COUPONS",
  NEWS = "NEWS",
  FACILITIES = "FACILITIES",
  APPOINTMENTS = "APPOINTMENTS",
  MY_SCHEDULE = "MY_SCHEDULE",
  ROLES_AND_PERMISSIONS = "ROLES_AND_PERMISSIONS",
  CATEGORIES = "CATEGORIES",
  ROOMS = "ROOMS",
  SERVICES = "SERVICES",
  AWARDS = "AWARDS",
  ORGANISATION = "ORGANISATION",
}

export enum CustomRouting {
  LOGIN = "LOGIN",
  HOME = "HOME",
}
