import { useRecoilValue } from "recoil";
import { selectedFacilityIdState } from "../../../state";
import { Api } from "../../api";
import { useGetPaginated } from "../../api-hooks/useGetPaginated";

export const useFacilityNews = () => {
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const selectedFacilityIdNumber = Number(selectedFacilityId);
  const {
    news: {
      newsControllerGetActiveFacilityNewsPaginated,
      newsControllerGetUpcomingFacilityNewsPaginated,
      newsControllerGetDraftFacilityNewsPaginated,
      newsControllerGetPastFacilityNewsPaginated,
    },
  } = new Api();

  const activeFacilityNews = useGetPaginated({
    id: selectedFacilityIdNumber,
    query: newsControllerGetActiveFacilityNewsPaginated,
    queryKey: ["facility", selectedFacilityIdNumber, "news", "active"],
  });

  const upcomingFacilityNews = useGetPaginated({
    id: selectedFacilityIdNumber,
    query: newsControllerGetUpcomingFacilityNewsPaginated,
    queryKey: ["facility", selectedFacilityIdNumber, "news", "upcoming"],
  });

  const draftFacilityNews = useGetPaginated({
    id: selectedFacilityIdNumber,
    query: newsControllerGetDraftFacilityNewsPaginated,
    queryKey: ["facility", selectedFacilityIdNumber, "news", "draft"],
  });

  const pastFacilityNews = useGetPaginated({
    id: selectedFacilityIdNumber,
    query: newsControllerGetPastFacilityNewsPaginated,
    queryKey: ["facility", selectedFacilityIdNumber, "news", "past"],
  });

  return {
    activeFacilityNews,
    upcomingFacilityNews,
    draftFacilityNews,
    pastFacilityNews,
  };
};
