import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CustomDepartmentCategoriesWithServices } from "../../../api-types";
import { Api, GetServiceResponseDto } from "../../api";
import { useDelete } from "../../api-hooks/useDelete";
import { useGetById } from "../../api-hooks/useGetById";
import { useGetPaginated } from "../../api-hooks/useGetPaginated";
import { useUpdate } from "../../api-hooks/useUpdate";
import { serviceRooms } from "../../query-keys";

export const useService = ({ id }: { id?: number }) => {
  const {
    services: {
      serviceControllerDeleteService,
      serviceControllerGetService,
      serviceControllerUpdateService,
      serviceControllerGetServiceRooms,
    },
    appointments: {
      appointmentControllerGetServiceAppointmentsPaginated,
      appointmentControllerGetServiceHistoryAppointmentsPaginated,
    },
  } = new Api();
  const client = useQueryClient();
  const {
    data: service,
    isLoading: isServiceLoading,
    refetch: refetchService,
  } = useGetById({
    id,
    query: serviceControllerGetService,
    queryKey: ["service", id!],
  });

  const { mutateAsync: deleteService, isLoading: isDeletingService } =
    useDelete(id!, serviceControllerDeleteService, ["services"]);

  const { mutateAsync: updateService, isLoading: isUpdatingService } =
    useUpdate(
      id!,
      serviceControllerUpdateService,
      ["service", id!],
      (data, variables) => {
        const queryData = client.getQueryData([
          "department",
          //@ts-ignore
          Number(variables.requestData.departmentId),
          "categories",
          "services",
        ]) as CustomDepartmentCategoriesWithServices;

        const queryServiceData = client.getQueryData([
          "service",
          id,
        ]) as GetServiceResponseDto;

        const tempService = { ...queryServiceData, ...data };
        client.setQueryData(["service", id], tempService);

        client.setQueryData(
          [
            "department",
            //@ts-ignore
            Number(variables.requestData.departmentId),
            "categories",
            "services",
          ],
          queryData.map((c) => {
            return {
              ...c,
              services: c.services.map((s) =>
                s.id === tempService.id
                  ? {
                      id: tempService.id,
                      name: tempService.name,
                      isHidden: false,
                      categoryId: c.id,
                      departmentId: c.departmentId,
                    }
                  : s
              ),
            };
          })
        );
      }
    );

  const serviceAppointments = useGetPaginated({
    id: id!,
    query: appointmentControllerGetServiceAppointmentsPaginated,
    queryKey: ["service", id!, "appointments"],
  });

  const serviceHistoryAppointments = useGetPaginated({
    id: id!,
    query: appointmentControllerGetServiceHistoryAppointmentsPaginated,
    queryKey: ["service", id!, "appointments", "history"],
  });

  const rooms = useQuery(
    serviceRooms(id),
    async () => {
      const { data } = await serviceControllerGetServiceRooms(id!);

      if (data.isSuccessful) {
        return data.payload?.rooms.map((c) => ({
          ...c,
          serviceId: data.payload?.id,
        }));
      }
      return [];
    },
    {
      enabled: !!id,
    }
  );

  return {
    service,
    updateService,
    rooms,
    isUpdatingService,
    isServiceLoading,
    refetchService,
    deleteService,
    isDeletingService,
    serviceAppointments,
    serviceHistoryAppointments,
  };
};
