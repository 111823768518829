import { forwardRef, MouseEventHandler, useState } from "react";
import { ITextInputProps, TextInput } from "./TextInput";

import ReactDatePicker from "react-datepicker";
import { RiCalendarTodoLine } from "react-icons/ri";
import { IFieldComponentBaseProps } from "../../types";
import { formatDate } from "../../utils";
import tw from "twin.macro";

/** @jsxImportSource @emotion/react */

export interface IDatePickerProps extends IFieldComponentBaseProps<Date> {
  minDate?: Date | null;
}

type IDatePickerInputProps = Omit<ITextInputProps, "onChange"> & {
  onClick: MouseEventHandler<HTMLDivElement>;
  onClear(): void;
  input:
    | typeof TextInput["Contained"]
    | typeof TextInput["Underlined"]
    | typeof TextInput["Outlined"];
};

export const DatePicker = (props: IDatePickerProps) => {
  const [isMonthPicked, setIsMonthPicked] = useState(false);
  const [isYearPicked, setIsYearPicked] = useState(false);
  const CustomInput = forwardRef<any, IDatePickerInputProps>((props, ref) => {
    const Input = props.input;
    return (
      <div ref={ref}>
        <Input
          disabled={props.disabled}
          value={props.value}
          placeholder={props.placeholder}
          error={props.error}
          trail={RiCalendarTodoLine}
          trailCss={[tw`text-gray-600`]}
          required={props.required}
          // STATEMENT: Why is onChange empty function?
          // ReactDatePicker handles date change internally and injects current value in this component.
          // Therefore, here we provide "dummy" implementation of this property to satisfy typings
          // @ts-ignore
          onChange={() => {}}
          disabledCss={[tw`bg-white`]}
          onClick={props.onClick}
          onKeyDown={(e) => {
            const isDelete = ["Backspace", "Delete"].includes(e.key);
            if (isDelete) {
              props.onClear();
            }
          }}
          label={props.label}
        />
      </div>
    );
  });

  const value = props.value ? formatDate(props.value) : "";

  return (
    <div css={props.containerCss}>
      <ReactDatePicker
        value={value}
        onChange={(date) => {
          if (!isYearPicked) {
            setIsYearPicked(true);
          } else if (!isMonthPicked) {
            setIsMonthPicked(true);
          }
          props.onChange(date);
        }}
        minDate={props.minDate}
        shouldCloseOnSelect={isYearPicked && isMonthPicked}
        forceShowMonthNavigation
        disabled={props.disabled}
        placeholderText={props.placeholder}
        required={props.required}
        showYearPicker={!isYearPicked}
        showMonthYearPicker={isYearPicked && !isMonthPicked}
        allowSameDay
        selected={props.value}
        customInput={
          <CustomInput
            label={props.label}
            input={TextInput.Outlined}
            error={props.error}
            // STATEMENT: Why is onClick empty function?
            // ReactDatePicker component injects placeholder, value and onClick into the
            // CustomInput component. Therefore, here we provide "dummy" implementation of
            // these properties to satisfy typings
            value={value}
            onClick={() => {}}
            onClear={() => props.onChange(null)}
          />
        }
      />
    </div>
  );
};
