import { yupResolver } from "@hookform/resolvers/yup";
import { getHours, getMinutes, startOfDay } from "date-fns";
import { addMinutes } from "date-fns/esm";

import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import * as yup from "yup";

import { useEditNews } from "../../../api/hooks/news/useEditNews";

import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { Modal } from "../../../ui/popups/Modal";
import { errorMessages } from "../../../utils";

/** @jsxImportSource @emotion/react */

const schema = yup
  .object()
  .shape({
    superTitle: yup.string().required(errorMessages.required),
    title: yup.string().required(errorMessages.required),
    schedulePublishingForDate: yup.string(),
    schedulePublishingForTime: yup.string(),
    introText: yup.string().required(errorMessages.required),
    image: yup.object(),
    asPushNotification: yup.boolean().default(false).required(),
    content: yup.string().required(errorMessages.required),
  })
  .required(errorMessages.required);

interface IEditNewsModalProps {
  open: boolean;
  onClose: () => void;
  newsId: number;
}

type IForm = yup.InferType<typeof schema>;

export const EditCurrentNewsModal = ({
  open,
  onClose,
  newsId,
}: IEditNewsModalProps) => {
  const { t } = useTranslation();
  const {
    news: { data: news },
    updateNews,
    deleteNews,
  } = useEditNews({ id: Number(newsId) });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (news) {
      const schedulePublishingForDate = startOfDay(
        new Date(news.availableFrom)
      );
      let schedulePublishingForTime =
        new Date(news.availableFrom).getMinutes() +
        new Date(news.availableFrom).getHours() * 60;
      if (schedulePublishingForTime % 15 !== 0) {
        schedulePublishingForTime =
          schedulePublishingForTime - (schedulePublishingForTime % 15);
      }
      methods.reset({
        superTitle: news?.superTitle,
        title: news?.title,
        introText: news?.introText,
        asPushNotification: news?.asPushNotification,
        content: news?.content,
        schedulePublishingForDate: schedulePublishingForDate,
        schedulePublishingForTime: schedulePublishingForTime,
      });
    }
  }, [methods, news]);

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      await updateNews({
        requestData: {
          ...values,
          isDraft: false,
          schedulePublishingForDateAndTime: values?.schedulePublishingForDate
            ? addMinutes(
                new Date(values.schedulePublishingForDate),
                values.schedulePublishingForTime
              ).toISOString()
            : undefined,
        },
      });
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={t("editNews") as string}
        footerChildren={
          <div
            css={[
              tw`flex bg-gray-100 pr-4 pl-4 pt-2 pb-2 gap-3 -ml-6 -mb-6 -mr-6 rounded-br-md`,
            ]}
          >
            <div css={[tw`flex w-full gap-3`]}>
              <Button.Outlined
                containerCss={[tw`w-full`]}
                onClick={() => onClose()}
              >
                {t("cancel")}
              </Button.Outlined>

              <Button.Outlined
                containerCss={[tw`w-full`]}
                onClick={async () => {
                  await deleteNews();
                  onClose();
                }}
              >
                {t("deleteNews")}
              </Button.Outlined>
            </div>
            <Button.Contained
              containerCss={[tw`w-full`]}
              onClick={() => onSubmit()}
            >
              {t("editNews")}
            </Button.Contained>
          </div>
        }
      >
        <div css={[tw`flex flex-col`]}>
          <div css={[tw`flex gap-6`]}>
            <div css={[tw`w-full`]}>
              <Form.TextInput.Outlined
                label={t("superTitle") as string}
                name="superTitle"
                placeholder={t("enterSuperTitle") as string}
                required={true}
              />
              <Form.TextInput.Outlined
                label={t("headline") as string}
                name="title"
                placeholder={t("enterHeadline") as string}
                required={true}
              />
              <div css={[tw`flex gap-6`]}>
                <Form.DatePicker
                  label={t("schedulePublishing") as string}
                  placeholder={t("selectDate") as string}
                  name="schedulePublishingForDate"
                  disabled
                />
                <Form.TimePicker
                  containerCss={[tw`flex-3`]}
                  shouldOnlySelectValue
                  dataParser={(data) => getMinutes(data) + getHours(data) * 60}
                  label={t("time") as string}
                  placeholder={t("selectTime") as string}
                  name="schedulePublishingForTime"
                  disabled
                />
              </div>
              <Form.TextArea.Outlined
                name="introText"
                rows={3}
                label={t("introText") as string}
                placeholder={t("introTextPlaceholder") as string}
                required={true}
              />
            </div>
            <div css={[tw`w-full`]}>
              <Form.TextArea.Outlined
                name="content"
                rows={21}
                label={t("newsText") as string}
                placeholder={t("enterText") as string}
                required={true}
              />
            </div>
          </div>
        </div>
      </Modal>
    </FormProvider>
  );
};
