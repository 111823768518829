import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiAddCircleFill, RiSettings3Line } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import tw from "twin.macro";
import { useOrganisation } from "../../api/hooks/organisations/useOrganisation";
import { useLogout } from "../../api/hooks/user/useLogout";
import { useMe } from "../../api/hooks/user/useMe";
import { Assets } from "../../assets";
import { Select } from "../../fields/controlled/Select";
import { routes } from "../../navigation/routing";
import { NewFacilityModal } from "../../pages/facilities/modals/NewFacilityModal";
import {
  hasSeenMessageState,
  isLoggedInState,
  searchState,
  selectedFacilityIdState,
  selectedOrganisationIdState,
} from "../../state";
import { IComponentBaseProps } from "../../types";
import { Avatar } from "../../ui/Avatar";
import { Button } from "../../ui/buttons/Button";
import { ActivityIndicator } from "../../ui/indicators/ActivityIndicator";
import { IMenuItem, Menu } from "../../ui/popups/Menu";
import { Typography } from "../../ui/Typograhy";
import { MessageModal } from "../modals/MessageModal";
import { QRCodeScannerModal } from "../modals/QRCodeScannerModal";

/** @jsxImportSource @emotion/react */
interface IHeaderProps extends IComponentBaseProps {}

export const Header: React.FC = (props: IHeaderProps) => {
  const { pathname } = useLocation();
  const { employeeLogout } = useLogout();
  const { me } = useMe();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openQrCodeModal, setOpenQrCodeModal] = useState(false);
  const navigate = useNavigate();
  const [selectedFacilityId, setSelectedFacilityId] = useRecoilState(
    selectedFacilityIdState
  );
  const [, setHasSeenMessage] = useRecoilState(hasSeenMessageState);
  const availableRoutes = routes({ permissions: me?.role?.permissions });
  const [, setOrganisationId] = useRecoilState(selectedOrganisationIdState);
  const organisationId = useRecoilValue(selectedOrganisationIdState);
  const hasPermissions = Object.keys(availableRoutes || {}).length > 2;

  const {
    organisationFacilities: { data: organisationFacilities, isInitialLoading },
  } = useOrganisation();

  const headerRoutes = [
    { url: availableRoutes?.["PATIENTS"], label: t("patients") },
    { url: availableRoutes?.["DEPARTMENTS"], label: t("departments") },
    { url: availableRoutes?.["COUPONS"], label: t("coupons") },
    { url: availableRoutes?.["NEWS"], label: t("news") },
    { url: availableRoutes?.["EMPLOYEES"], label: t("employees") },
    { url: availableRoutes?.["MY_SCHEDULE"], label: t("mySchedule") },
  ].filter((r) => !!r.url);

  const menuRoutes = [
    {
      url: availableRoutes?.["ROLES_AND_PERMISSIONS"]?.byId(organisationId),
      label: t("organisationSettings"),
    },
    {
      url: selectedFacilityId
        ? availableRoutes?.["FACILITIES"]?.byId(selectedFacilityId!)
        : undefined,
      label: t("facilitySettings"),
    },
  ].filter((r) => !!r.url);

  const isDoctor = me.role?.permissions?.MY_SCHEDULE?.READ;

  useEffect(() => {
    if (
      isDoctor &&
      me?.facilityId &&
      selectedFacilityId !== me?.facilityId.toString() &&
      !selectedFacilityId
    ) {
      setSelectedFacilityId(me?.facilityId.toString());
    } else if (
      !selectedFacilityId &&
      (organisationFacilities || [])?.length > 0
    ) {
      setSelectedFacilityId((organisationFacilities || [])?.[0]?.id.toString());
    }
  }, [
    isDoctor,
    me?.facilityId,
    organisationFacilities,
    selectedFacilityId,
    setSelectedFacilityId,
  ]);

  const [, setIsLoggedIn] = useRecoilState(isLoggedInState);

  const firstFacility = organisationFacilities?.[0];
  const selectedTempFacility = useMemo(() => {
    const f =
      organisationFacilities?.find(
        (f) => Number(f.id) === Number(selectedFacilityId)
      ) || firstFacility;

    return {
      label: f?.name || "",
      value: f?.id.toString() || "1",
      belongsTo: f?.city || "",
      rightIcon: {
        icon: RiSettings3Line,
        onClick: () => navigate(`/facilities/${f?.id || 1}`),
      },
    };
  }, [firstFacility, navigate, organisationFacilities, selectedFacilityId]);

  const [search, setSearch] = useRecoilState(searchState);
  const hasFacility = (organisationFacilities || []).length > 0;

  const path = pathname.endsWith("/") ? pathname : `${pathname}/`;
  const isInOrganisation = path.includes("roles_and_permissions");
  const logoStyles = tw`h-9 mr-6`;

  return (
    <>
      <nav
        css={[
          tw`bg-primary py-3.5`,
          tw`flex flex-row items-center justify-center`,
          tw`px-4`,
          tw`xs:(px-5)`,
          tw`sm:(px-6)`,
          tw`md:(px-8)`,
        ]}
      >
        <div
          css={[
            tw`flex flex-row items-center justify-between max-w-screen-xl w-full`,
          ]}
        >
          <div css={[tw`flex flex-row items-center justify-between`]}>
            <Assets.LogoColored
              css={[logoStyles, isInOrganisation && tw`mr-0`]}
            />
            {!isInOrganisation && (
              <>
                {isInitialLoading ? (
                  <div css={[tw`w-32 justify-center items-center flex`]}>
                    <ActivityIndicator containerCss={[tw`w-4 h-4`]} />
                  </div>
                ) : hasFacility &&
                  hasPermissions &&
                  (organisationFacilities || [])?.length > 0 ? (
                  <Select
                    options={(organisationFacilities || []).map((f) => ({
                      label: f?.name,
                      value: f?.id.toString(),
                      belongsTo: f?.city,
                      rightIcon: me.role?.permissions?.FACILITIES?.READ
                        ? {
                            icon: RiSettings3Line,
                            onClick: () => navigate(`/facilities/${f.id}`),
                          }
                        : undefined,
                    }))}
                    footer={
                      me.role?.permissions?.FACILITIES?.CREATE ? (
                        <div css={[tw`bg-gray-100 -mb-1 rounded-b-md p-3`]}>
                          <Button.Outlined
                            containerCss={[tw`w-full`]}
                            lead={RiAddCircleFill}
                            onClick={() => setOpenModal(true)}
                          >
                            {t("addNewFacility")}
                          </Button.Outlined>
                        </div>
                      ) : undefined
                    }
                    value={selectedTempFacility}
                    //@ts-ignore
                    onChange={(option) => {
                      if (option) {
                        setSelectedFacilityId(option?.value);
                        navigate("/");
                      }
                    }}
                    controlCss={[
                      tw`bg-primary border-primary hover:(bg-primary-700 border-white)`,
                    ]}
                    indicatorCss={[tw`text-white`]}
                    singleValueCss={[tw`text-white`]}
                    containerCss={[
                      tw`mr-6 duration-300`,
                      search.length > 0
                        ? tw`opacity-0 w-0 h-0`
                        : tw`opacity-100 w-56 h-12`,
                    ]}
                  />
                ) : (
                  me.role?.permissions?.FACILITIES?.CREATE && (
                    <Button.Outlined
                      lead={RiAddCircleFill}
                      containerCss={[tw`min-w-44 mr-6`]}
                      onClick={() => setOpenModal(true)}
                    >
                      {t("addNewFacility")}
                    </Button.Outlined>
                  )
                )}
              </>
            )}
            {!isInOrganisation && (
              <div
                css={[
                  tw`hidden flex-row items-center gap-x-6 md:flex duration-300`,
                  search.length > 0
                    ? tw`opacity-0 w-0 h-0`
                    : tw`opacity-100 w-fit h-12`,
                ]}
              >
                {headerRoutes.map((i) => {
                  const isSelected = new RegExp(`${i?.url?.all}/.*`).test(path);
                  return (
                    <Link
                      key={i?.url?.all}
                      to={{ pathname: i?.url?.all }}
                      style={{ pointerEvents: hasFacility ? "auto" : "none" }}
                    >
                      <Typography.Link
                        containerCss={[
                          tw`no-underline text-white border-transparent pt-2 pb-2 pl-3 pr-3 rounded-md`,
                          tw`hover:(bg-primary-700)`,
                          isSelected &&
                            tw`bg-primary-700 hover:(bg-primary-800)`,
                          !hasFacility &&
                            tw`text-primary-500 bg-primary hover:(bg-inherit) cursor-not-allowed`,
                        ]}
                      >
                        {i.label}
                      </Typography.Link>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
          {isInOrganisation && (
            <Link key={"settings"} to={{ pathname: path }}>
              <Typography.Link
                containerCss={[
                  tw`no-underline text-white border-transparent pt-2 pb-2 pl-3 pr-3 rounded-md`,
                  tw`hover:(bg-primary-700)`,
                  tw`bg-primary-700 hover:(bg-primary-800)`,
                  !hasFacility &&
                    tw`text-primary-500 bg-primary hover:(bg-inherit) cursor-not-allowed`,
                ]}
              >
                {t("organisationSettings")}
              </Typography.Link>
            </Link>
          )}
          {/* <SearchBar
            onChange={setSearch}
            value={search}
            disabled={!hasFacility}
            onClear={() => setSearch("")}
            trail={RiQrScan2Line}
            trailCss={[tw`text-white cursor-pointer`]}
            onTrailClick={() => {
              if (hasFacility) {
                setOpenQrCodeModal(true);
              }
            }}
            placeholder={t("search") || ""}
            containerCss={[
              tw`pl-6 pr-6 duration-500`,
              search.length > 0 ? tw`w-full max-w-4xl` : tw`w-2/3`,
            ]}
          /> */}

          <Menu
            header={
              <div css={[tw`px-4 py-3`, tw`border-b-1 border-b-gray-200`]}>
                <Typography.BodySmall>
                  {me?.firstName} {me?.lastName}
                </Typography.BodySmall>
                <Typography.BodySmall>
                  {t(me?.role?.name || "")}
                </Typography.BodySmall>
              </div>
            }
            extraXMovement={-50}
            extraYMovement={-2}
            items={
              [
                ...menuRoutes.map((r) => ({
                  label: r.label,
                  onClick: () => navigate(r.url),
                })),
                {
                  label: t("logOut"),
                  onClick: () => setOpenLogoutModal(true),
                },
              ].filter((p) => !!p) as IMenuItem[]
            }
          >
            <Avatar
              name={`${me?.firstName} ${me?.lastName}`}
              containerCss={[tw`hidden md:flex cursor-pointer relative`]}
              imageUrl={me?.imageUrl}
            />
          </Menu>

          {/* <IconButton.Contained
            icon={HiMenu}
            iconCss={[tw`h-6 w-6`]}
            onClick={() => setOpenDrawer(true)}
            containerCss={[tw`md:hidden`]}
          /> */}
        </div>
      </nav>
      {/* <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <div css={[tw`flex flex-col gap-y-3`]}>
          {headerItems.map((i) => {
            const isSelected = i.url === selectedRoute?.url;
            return (
              <Typography.Link
                href={i.url.all}
                key={i.url.all}
                containerCss={[
                  tw`no-underline border-b-3 border-transparent`,
                  tw`hover:(border-white)`,
                  isSelected && tw` border-white`,
                ]}
              >
                {i.label}
              </Typography.Link>
            );
          })}
        </div>
      </Drawer> */}
      <NewFacilityModal open={openModal} onClose={() => setOpenModal(false)} />
      <QRCodeScannerModal
        open={openQrCodeModal}
        onClose={() => setOpenQrCodeModal(false)}
      />
      <MessageModal
        open={openLogoutModal}
        onClose={() => setOpenLogoutModal(false)}
        buttonTitle={t("logMeOut")}
        handleSubmit={async () => {
          setIsLoggedIn(false);
          setSelectedFacilityId(null);
          setHasSeenMessage(false);
          setOrganisationId(null);
          await employeeLogout();
          navigate("/login");
        }}
        label={t("logoutReassurance")}
      />
    </>
  );
};
