/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RiAddFill,
  RiArrowLeftLine,
  RiCloseLine,
  RiEdit2Line,
} from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import tw from "twin.macro";
import { useDepartment } from "../../api/hooks/departments/useDepartment";
import { PageContainer } from "../../components/layout/PageContainer";
import { MessageModal } from "../../components/modals/MessageModal";
import { Button } from "../../ui/buttons/Button";
import { IconButton } from "../../ui/buttons/IconButton";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { Typography } from "../../ui/Typograhy";
import { ManageCategoryModal } from "./modals/ManageCategoryModal";
import { NewDepartmentModal } from "./modals/NewDepartmentModal";
import { NewRoomModal } from "./modals/NewRoomModal";
import { NewServiceModal } from "./modals/NewServiceModal";
import { RoomsSchedulesTab } from "./tabs/RoomsSchedulesTab";
import { ListOfServicesTab } from "./tabs/ListOfServicesTab";
import { useMe } from "../../api/hooks/user/useMe";

export const DepartmentPage = () => {
  const { id } = useParams();
  const {
    categoriesWithServices: { data: categoriesWithServices, isFetched },
    department,
  } = useDepartment({
    id: Number(id),
  });
  const { me } = useMe();

  const [isNewDepartmentModalOpen, setIsNewDepartmentModalOpen] =
    useState(false);
  const [isNewRoomModalOpen, setIsNewRoomModalOpen] = useState(false);
  const [isNewServiceModalOpen, setIsNewServiceModalOpen] = useState(false);
  const [isNewCategoryModalOpen, setIsNewCategoryModalOpen] = useState(false);
  const [isEditDepartmentNameModalOpen, setIsEditDepartmentNameModalOpen] =
    useState(false);
  const [editingServices, setIsEditingServices] = useState(false);
  const [isConfirmSaveModalOpen, setIsConfirmSaveModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const categories = categoriesWithServices || [];

  return (
    <PageContainer>
      {!editingServices && (
        <Tabs
          onTabChange={(val) => setActiveTab(val)}
          defaultTab={0}
          leftComponent={
            <div css={[tw`flex gap-3 items-center flex-1`]}>
              <Button.Text
                lead={RiArrowLeftLine}
                leadCss={[tw`text-gray-900`]}
                textCss={[tw`text-gray-900`]}
                containerCss={[tw`px-2`]}
                onClick={() => navigate("/departments")}
              >
                {t("backToDepartments")}
              </Button.Text>
              <Typography.BodyMedium containerCss={[tw`font-bold`]}>
                {department?.data?.name || ""}
              </Typography.BodyMedium>
            </div>
          }
          rightComponent={
            <div css={[tw`flex justify-end gap-3 flex-1`]}>
              {categories.length > 0 && activeTab === 0 && (
                <Button.Outlined
                  lead={RiEdit2Line}
                  onClick={() => setIsEditingServices(true)}
                >
                  {t("editSchedule")}
                </Button.Outlined>
              )}
              {((activeTab === 1 && me.role?.permissions?.ROOMS?.CREATE) ||
                (activeTab === 0 &&
                  me.role?.permissions?.SERVICES?.CREATE)) && (
                <Button.Contained
                  lead={RiAddFill}
                  onClick={() =>
                    activeTab === 1
                      ? setIsNewRoomModalOpen(true)
                      : setIsNewServiceModalOpen(true)
                  }
                >
                  {activeTab === 1 ? t("addNewRoom") : t("addNewService")}
                </Button.Contained>
              )}
            </div>
          }
          containerCss={[tw`m-auto`]}
        >
          <Tabs.Item text={t("listOfServices")}>
            <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
            <ListOfServicesTab
              departmentId={Number(id)}
              categories={categories}
              isFetched={isFetched}
            />
          </Tabs.Item>
          <Tabs.Item text={t("scheduleByRooms")}>
            <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
            <RoomsSchedulesTab
              departmentId={Number(id)}
              // rooms={rooms}
              activeTab={activeTab}
            />
          </Tabs.Item>
        </Tabs>
      )}
      {editingServices && (
        <div>
          <div
            css={[
              tw`py-2 flex justify-center border-b-1 border-b-gray-100 bg-gray-900`,
            ]}
          >
            <div css={[tw`max-w-screen-xl w-full pl-2 flex justify-between`]}>
              <div css={[tw`flex gap-5 items-center`]}>
                <IconButton.Text
                  icon={RiCloseLine}
                  onClick={() => setIsEditingServices(false)}
                />
                <Typography.BodyMedium
                  containerCss={[tw`font-bold text-white`]}
                >
                  {department.data?.name || ""}
                </Typography.BodyMedium>
                {me.role?.permissions?.DEPARTMENTS?.UPDATE && (
                  <Button.Outlined
                    lead={RiEdit2Line}
                    onClick={() => setIsEditDepartmentNameModalOpen(true)}
                  >
                    {t("editDepartment")}
                  </Button.Outlined>
                )}
              </div>
              <div css={[tw`flex gap-3 items-center`]}>
                {me.role?.permissions.CATEGORIES.CREATE && (
                  <Button.Outlined
                    lead={RiAddFill}
                    onClick={() => setIsNewCategoryModalOpen(true)}
                  >
                    {t("addCategory")}
                  </Button.Outlined>
                )}
                {/* <Button.Contained
                  onClick={() => setIsConfirmSaveModalOpen(true)}
                >
                  {t("saveSchedule")}
                </Button.Contained> */}
              </div>
            </div>
          </div>
          <ListOfServicesTab
            departmentId={Number(id)}
            categories={categories}
            isEditing={true}
          />
        </div>
      )}
      <NewDepartmentModal
        open={isNewDepartmentModalOpen}
        onClose={() => setIsNewDepartmentModalOpen(false)}
      />

      <NewRoomModal
        onClose={() => setIsNewRoomModalOpen(false)}
        open={isNewRoomModalOpen}
        departmentId={Number(id)}
      />
      <NewServiceModal
        onClose={() => setIsNewServiceModalOpen(false)}
        open={isNewServiceModalOpen}
        departmentId={Number(id)}
      />
      <ManageCategoryModal
        departmentId={Number(id)}
        onClose={() => setIsNewCategoryModalOpen(false)}
        open={isNewCategoryModalOpen}
      />
      {department.isFetched && (
        <NewDepartmentModal
          onClose={() => setIsEditDepartmentNameModalOpen(false)}
          open={isEditDepartmentNameModalOpen}
          department={department.data}
        />
      )}
      {/* <TextInputModal
        label={t("editDepartmentName")}
        buttonTitle={t("edit")}
        value={department?.data?.name || ""}
        placeholderText={t("departmentPlaceholder")}
        handleSubmit={handleEditDepartmentName}
        open={isEditDepartmentNameModalOpen}
        onClose={() => setIsEditDepartmentNameModalOpen(false)}
        name={"departmentName"}
      /> */}
      <MessageModal
        buttonTitle={t("yesApply")}
        handleSubmit={() => {
          setIsConfirmSaveModalOpen(false);
          setIsEditingServices(false);
        }}
        open={isConfirmSaveModalOpen}
        label={t("newScheduleReassurance")}
        onClose={() => setIsConfirmSaveModalOpen(false)}
      />
    </PageContainer>
  );
};
