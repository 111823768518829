import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RiEdit2Line } from "react-icons/ri";
import tw from "twin.macro";
import { useArray } from "../../../hooks/use-array";
import { Button } from "../../../ui/buttons/Button";
import { Accordion } from "../../../ui/layout/Accordion";
import { Typography } from "../../../ui/Typograhy";
/** @jsxImportSource @emotion/react */
import { yupResolver } from "@hookform/resolvers/yup";
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { usePermissions } from "../../../api/hooks/roles-permissions/permissions/usePermissions";
import { useRole } from "../../../api/hooks/roles-permissions/roles/useRole";
import { useMe } from "../../../api/hooks/user/useMe";
import { TwoButtonMessageModal } from "../../../components/modals/TwoButtonMessageModal";
import { Checkbox } from "../../../fields/controlled/Checkbox";
import { Switch } from "../../../fields/controlled/Switch";
import { scrollToError } from "../../../fields/form/utils";
import { errorMessages } from "../../../utils";
import { ManageRoleModal } from "../modals/ManageRoleModal";
import { UnsavedRoleChangesModal } from "./UnsavedRoleChangesModal";

const schema = yup.object().shape({
  permissionIds: yup
    .array()
    .of(yup.number().required(errorMessages.required))
    .required(errorMessages.required),
});

type IForm = {
  permissionIds: number[];
};

export const RoleDetails = ({
  roleId,
  setIsDirty,
  setIsUnsavedChangesModalOpen,
  isUnsavedChangesModalOpen,
}: {
  roleId: number;
  setIsDirty: any;
  setIsUnsavedChangesModalOpen: ({
    state,
    onNext,
  }: {
    state: boolean;
    onNext: (() => void) | undefined;
  }) => void;
  isUnsavedChangesModalOpen: {
    state: boolean;
    onNext: (() => void) | undefined;
  };
}) => {
  const [
    isRoleDeleteReassuranceModalOpen,
    setIsRoleDeleteReassuranceModalOpen,
  ] = useState(false);
  const [isManageRoleModalOpen, setIsManageRoleModalOpen] = useState(false);
  const { t } = useTranslation();
  const [keys, { replace, push }] = useArray<string>([]);
  const {
    role: { data: role },
    updateRolePermissions,
  } = useRole({ id: roleId });

  const {
    permissions: { data: permissions },
  } = usePermissions();

  const { me, isLoadingMe } = useMe();

  const methods = useForm<IForm>({
    defaultValues: {
      permissionIds: undefined,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const isAdminRole = useMemo(
    () =>
      !!role?.permissions.find((p) => p.feature === "ROLES_AND_PERMISSIONS"),
    [role?.permissions]
  );

  const availableFeatures = [
    ...new Set(permissions?.permissions.map((p) => p.feature)),
  ];

  const parsedPermissionsByFeature = availableFeatures
    .map((f, i) => ({
      id: i,
      name: f,
      permissions: permissions?.permissions.filter((p) => p.feature === f),
    }))
    .filter(
      (f) =>
        (f.name === "ROLES_AND_PERMISSIONS" && isAdminRole) ||
        (f.name !== "ROLES_AND_PERMISSIONS" && true)
    );

  const currentlySelectedPermissionIds = methods.watch("permissionIds");

  useEffect(() => {
    methods.reset({
      permissionIds: undefined,
    });
  }, [methods, roleId]);

  useEffect(() => {
    if (
      (currentlySelectedPermissionIds || [])?.length !==
        role?.permissions.length &&
      !currentlySelectedPermissionIds &&
      (role?.permissions || []).length > 0
    ) {
      methods.setValue(
        "permissionIds",
        (role?.permissions || []).map((p) => p.id)
      );
    }
  }, [currentlySelectedPermissionIds, methods, role, role?.permissions]);

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      await updateRolePermissions({ requestData: values });
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e.response.data.message);
    }
  }, scrollToError);

  const isDirty =
    (difference(
      (role?.permissions || []).map((p) => p.id),
      currentlySelectedPermissionIds || []
    ).length > 0 ||
      difference(
        currentlySelectedPermissionIds || [],
        (role?.permissions || []).map((p) => p.id)
      ).length > 0) &&
    !!currentlySelectedPermissionIds;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  return (
    <div css={[tw`w-full flex-6 overflow-scroll`]}>
      <div css={[tw`flex justify-between w-full items-center mb-9`]}>
        <Typography.H4>{t(role?.name as string)}</Typography.H4>
        <div css={[tw`flex gap-2`]}>
          {/* {me?.role?.permissions?.ROLES_AND_PERMISSIONS?.DELETE && (
            <Button.Outlined
              variant="small"
              lead={RiIndeterminateCircleLine}
              onClick={() => setIsRoleDeleteReassuranceModalOpen(true)}
            >
              {t("delete")}
            </Button.Outlined>
          )} */}
          {me?.role?.permissions?.ROLES_AND_PERMISSIONS?.UPDATE && (
            <Button.Outlined
              lead={RiEdit2Line}
              variant="small"
              onClick={() => setIsManageRoleModalOpen(true)}
            >
              {t("editNameAndDescription")}
            </Button.Outlined>
          )}
          <Button.Contained
            lead={RiEdit2Line}
            variant="small"
            onClick={onSubmit}
            disabled={!isDirty}
          >
            {t("save")}
          </Button.Contained>
        </div>
      </div>
      <Accordion
        activeKeys={keys}
        setActiveKeys={replace}
        containerCss={[tw`mb-4`]}
      >
        {parsedPermissionsByFeature.map((feature) => {
          const permissionsWoutRead = (feature?.permissions || []).filter(
            (p) => p.type !== "READ"
          );
          const permissionIdsWoutRead = permissionsWoutRead.map((p) => p.id);
          const readPermission = (feature?.permissions || []).find(
            (p) => p.type === "READ"
          );
          const intersectionIds = intersection(
            currentlySelectedPermissionIds || [],
            permissionIdsWoutRead
          );
          const diffFromPermissionIds = difference(
            permissionIdsWoutRead,
            currentlySelectedPermissionIds || []
          );
          const diffFromCurrSelected = difference(
            currentlySelectedPermissionIds || [],
            permissionIdsWoutRead
          );
          const isAll =
            (currentlySelectedPermissionIds || [])
              .map((cspid) => permissionsWoutRead.find((p) => p.id === cspid))
              .filter((p) => !!p).length === 3;
          const adminPermission = feature.permissions?.find((p) => p.id === 30);

          return (
            <Accordion.Item
              key={feature.id}
              itemKey={feature.name}
              title={`${t(feature?.name.toLowerCase())} ${
                adminPermission?.id ? `(${t("admin")})` : ""
              }`}
              middleComponent={() => {
                return isLoadingMe ? (
                  <></>
                ) : (
                  <Switch
                    disabled={
                      !me?.role?.permissions?.ROLES_AND_PERMISSIONS?.UPDATE ||
                      !!isAdminRole
                    }
                    onChange={() => {
                      if (
                        (currentlySelectedPermissionIds || [])?.includes(
                          readPermission?.id!
                        )
                      ) {
                        methods.setValue(
                          "permissionIds",
                          diffFromCurrSelected.filter(
                            (p) => p !== readPermission?.id
                          )
                        );
                      } else {
                        methods.setValue("permissionIds", [
                          ...(currentlySelectedPermissionIds || []),
                          readPermission?.id!,
                        ]);
                        if (!keys.includes(feature.name)) {
                          push(feature.name);
                        }
                      }
                    }}
                    value={(currentlySelectedPermissionIds || [])?.includes(
                      readPermission?.id!
                    )}
                  />
                );
              }}
            >
              <div css={[tw`p-4`]}>
                <Typography.BodyXSmall
                  containerCss={[tw`mb-4 leading-4 font-semibold uppercase`]}
                >
                  {t("permissions")}
                </Typography.BodyXSmall>
                <div css={[tw`flex gap-3 items-center justify-start`]}>
                  <Checkbox
                    value={isAll}
                    disabled={
                      !(currentlySelectedPermissionIds || [])?.includes(
                        readPermission?.id!
                      ) ||
                      !me?.role?.permissions?.ROLES_AND_PERMISSIONS?.UPDATE ||
                      isAdminRole
                    }
                    onChange={() => {
                      if (intersectionIds.length === 3) {
                        methods.setValue("permissionIds", diffFromCurrSelected);
                      } else {
                        methods.setValue("permissionIds", [
                          ...(currentlySelectedPermissionIds || []),
                          ...diffFromPermissionIds,
                        ]);
                      }
                    }}
                    label={t("enableAll")}
                  />
                  <div css={[tw`bg-gray-200 w-0.25 h-5`]} />
                  {permissionsWoutRead.map((p) => (
                    <Checkbox
                      disabled={
                        !(currentlySelectedPermissionIds || [])?.includes(
                          readPermission?.id!
                        ) || isAdminRole
                      }
                      key={p.id}
                      label={t(p.type.toLowerCase()) as string}
                      containerCss={[tw`flex-none w-min`]}
                      value={(currentlySelectedPermissionIds || [])?.includes(
                        p.id
                      )}
                      onChange={() => {
                        if (
                          (currentlySelectedPermissionIds || [])?.includes(p.id)
                        ) {
                          const tempArray = (
                            currentlySelectedPermissionIds || []
                          )?.filter((id) => id !== p.id);
                          methods.setValue("permissionIds", tempArray);
                        } else {
                          methods.setValue("permissionIds", [
                            ...(currentlySelectedPermissionIds || []),
                            p.id,
                          ]);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            </Accordion.Item>
          );
        })}
      </Accordion>

      <TwoButtonMessageModal
        label={t("roleDeleteReassurance")}
        open={isRoleDeleteReassuranceModalOpen}
        onClose={() => setIsRoleDeleteReassuranceModalOpen(false)}
        buttonLeft={{
          title: t("quit"),
          onClick: () => setIsRoleDeleteReassuranceModalOpen(false),
        }}
        buttonRight={{
          title: t("delete"),
          onClick: async () => {
            setIsRoleDeleteReassuranceModalOpen(false);
          },
        }}
      />
      {role?.name && (
        <ManageRoleModal
          open={isManageRoleModalOpen}
          role={{
            id: role?.id,
            name: role?.name,
            description: role?.description,
          }}
          onClose={() => setIsManageRoleModalOpen(false)}
        />
      )}
      <UnsavedRoleChangesModal
        onClose={() =>
          setIsUnsavedChangesModalOpen({ onNext: undefined, state: false })
        }
        onNext={async () => {
          if (isUnsavedChangesModalOpen.onNext) {
            await onSubmit();
            isUnsavedChangesModalOpen.onNext();
            replace([]);
            setIsUnsavedChangesModalOpen({ state: false, onNext: undefined });
          }
        }}
        open={isUnsavedChangesModalOpen.state}
        roleName={role?.name || ""}
      />
    </div>
  );
};
