import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";

export const useCategories = () => {
  const {
    categories: { categoryControllerCreateCategory },
  } = new Api();
  const { mutateAsync: createCategory, isLoading: isCreatingCategory } =
    useCreate(categoryControllerCreateCategory, ["categories"]);

  return {
    createCategory,
    isCreatingCategory,
  };
};
