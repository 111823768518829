import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";

export const useNews = () => {
  const {
    news: { newsControllerCreateNews },
  } = new Api();

  const { mutateAsync: createNews, isLoading: isCreatingNews } = useCreate(
    newsControllerCreateNews,
    ["news"]
  );

  return {
    createNews,
    isCreatingNews,
  };
};
