import { yupResolver } from "@hookform/resolvers/yup";
import format from "date-fns/format";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import * as yup from "yup";
import {
  GetAwardsForFacilityResponseDto,
  UpdateAwardRequestDto,
} from "../../../api/api";
import { useAward } from "../../../api/hooks/awards/useAward";
import { useFacilityServices } from "../../../api/hooks/facilities/useFacilityServices";
import { useMe } from "../../../api/hooks/user/useMe";
import { TwoButtonMessageModal } from "../../../components/modals/TwoButtonMessageModal";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { selectedFacilityIdState } from "../../../state";
import { Typography } from "../../../ui/Typograhy";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { IModalProps, Modal } from "../../../ui/popups/Modal";
import { errorMessages } from "../../../utils";
/** @jsxImportSource @emotion/react */

const schema: yup.SchemaOf<UpdateAwardRequestDto> = yup.object().shape({
  facilityId: yup.number().required(errorMessages.required),
  serviceId: yup.number().required(errorMessages.required),
  name: yup.string().required(errorMessages.required),
  type: yup.string().required(errorMessages.required),
  maxNoOfUsage: yup.number().required(errorMessages.required),
  awardPoints: yup
    .number()
    .typeError(errorMessages.number)
    .required(errorMessages.required),
  availableFrom: yup.string().required(errorMessages.required),
  expirationDate: yup.string().required(errorMessages.required),
  description: yup.string().required(errorMessages.required),
});

type IForm = yup.InferType<typeof schema>;

export const PointsCollectionDetailsModal = ({
  open,
  onClose,
  collectionMethod,
}: Omit<IModalProps, "label" | "footerChildren"> & {
  collectionMethod: GetAwardsForFacilityResponseDto;
}) => {
  const { deleteAward, updateAward } = useAward({ id: collectionMethod.id });
  const { me } = useMe();
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const [isEdit, setIsEdit] = useState(false);
  const [
    isDeleteCollectionMethodModalOpen,
    setIsDeleteCollectionMethodModalOpen,
  ] = useState(false);
  const { t } = useTranslation();
  const { facilityServices } = useFacilityServices();
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      ...collectionMethod,
      facilityId: Number(selectedFacilityId),
      serviceId: collectionMethod.service.id,
      //@ts-ignore
      availableFrom: collectionMethod?.availableFrom
        ? new Date(collectionMethod?.availableFrom)
        : undefined,
      //@ts-ignore
      expirationDate: collectionMethod?.expirationDate
        ? new Date(collectionMethod?.expirationDate)
        : undefined,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      await updateAward({
        requestData: {
          ...values,
          availableFrom: new Date(values.availableFrom).toISOString(),
          expirationDate: values?.expirationDate
            ? new Date(values.expirationDate).toISOString()
            : undefined,
        },
      });
      setIsEdit(false);
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  const watchAll = methods.watch();

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={t("collectionMethod") as string}
        containerCss={[tw`bg-white`]}
        footerChildren={
          <div css={[tw`flex justify-between gap-3`]}>
            {(me?.role?.permissions?.AWARDS?.DELETE ||
              (!me?.role?.permissions?.AWARDS?.DELETE && isEdit)) && (
              <Button.Outlined
                containerCss={[tw`flex-3`]}
                onClick={
                  isEdit
                    ? () => {
                        methods.reset({
                          ...collectionMethod,
                          serviceId: collectionMethod.service.id,
                          //@ts-ignore
                          availableFrom: collectionMethod?.availableFrom
                            ? new Date(collectionMethod?.availableFrom)
                            : undefined,
                          //@ts-ignore
                          expirationDate: collectionMethod?.expirationDate
                            ? new Date(collectionMethod?.expirationDate)
                            : undefined,
                        });
                        setIsEdit(false);
                      }
                    : () => setIsDeleteCollectionMethodModalOpen(true)
                }
              >
                {isEdit ? t("quit") : t("deleteCollectionMethod")}
              </Button.Outlined>
            )}
            {me?.role?.permissions?.AWARDS?.UPDATE && (
              <Button.Contained
                containerCss={[tw`flex-5`]}
                onClick={isEdit ? onSubmit : () => setIsEdit(true)}
              >
                {isEdit ? t("save") : t("editCollectionMethodDetails")}
              </Button.Contained>
            )}
          </div>
        }
      >
        {isEdit ? (
          <div>
            <Form.TextInput.Outlined
              name="name"
              required
              label={t("name") as string}
              placeholder={t("enterCouponName") as string}
            />
            <Form.Select
              options={facilityServices || []}
              name="serviceId"
              required
              shouldOnlySelectValue
              label={t("connectedServices") as string}
              placeholder={t("chooseServices") as string}
            />
            <div css={[tw`flex gap-4`]}>
              <Form.Select
                options={[
                  { label: t("unlimited"), value: null },
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                  { label: "4", value: 4 },
                  { label: "5", value: 5 },
                ]}
                required
                shouldOnlySelectValue
                label={t("numberOfUsageTimes") as string}
                placeholder={t("enterNumberOfUsages") as string}
                name="maxNoOfUsage"
              />
              <Form.TextInput.Outlined
                disabled
                label={t("additionOfPoints") as string}
                placeholder={t("enterNumberOfPoints") as string}
                name="awardPoints"
              />
            </div>
            <div css={[tw`flex gap-4`]}>
              <Form.DatePicker
                name="availableFrom"
                required
                label={t("validFrom") as string}
                placeholder={t("chooseDate") as string}
              />
              <Form.DatePicker
                name="expirationDate"
                required
                label={t("validTo") as string}
                placeholder={t("chooseDate") as string}
              />
            </div>
            <Form.TextArea.Outlined
              name="description"
              required
              rows={5}
              label={t("collectingPointsDescription") as string}
              placeholder={t("enterDescription") as string}
            />
          </div>
        ) : (
          <div>
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("name")}
            </Typography.BodyXSmall>
            <Typography.BodySmall
              containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
            >
              {watchAll?.name}
            </Typography.BodySmall>
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("connectedService")}
            </Typography.BodyXSmall>
            <Typography.BodySmall
              containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
            >
              {(facilityServices || [])?.find(
                (fs) => fs.value === Number(watchAll.serviceId)
              )?.label || "-"}
            </Typography.BodySmall>
            <div css={[tw`flex gap-6`]}>
              <div
                css={[
                  tw`flex flex-1 flex-col border-b-1 border-b-gray-200 mb-3 pb-3 `,
                ]}
              >
                <Typography.BodyXSmall
                  containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
                >
                  {t("usage")}
                </Typography.BodyXSmall>
                <Typography.BodySmall>
                  {watchAll?.maxNoOfUsage !== null
                    ? watchAll?.maxNoOfUsage
                    : t("unlimited")}
                </Typography.BodySmall>
              </div>
              <div
                css={[
                  tw`flex flex-1 flex-col border-b-1 border-b-gray-200 mb-3 pb-3 `,
                ]}
              >
                <Typography.BodyXSmall
                  containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
                >
                  {t("points")}
                </Typography.BodyXSmall>
                <Typography.BodySmall>
                  +{watchAll?.awardPoints}
                </Typography.BodySmall>
              </div>
            </div>
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("duration")}
            </Typography.BodyXSmall>
            <Typography.BodySmall
              containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
            >
              {format(
                //@ts-ignore
                new Date(watchAll?.availableFrom),
                "dd. MMMM yyyy."
              )}{" "}
              -{" "}
              {watchAll?.expirationDate
                ? format(new Date(watchAll.expirationDate), "dd. MMMM yyyy.")
                : " / "}
            </Typography.BodySmall>
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("description")}
            </Typography.BodyXSmall>
            <Typography.BodySmall
              containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
            >
              {watchAll.description || "-"}
            </Typography.BodySmall>
          </div>
        )}
        <TwoButtonMessageModal
          label={t("collectionMethodReassurance")}
          open={isDeleteCollectionMethodModalOpen}
          onClose={() => setIsDeleteCollectionMethodModalOpen(false)}
          buttonLeft={{
            title: t("quit"),
            onClick: () => {
              setIsDeleteCollectionMethodModalOpen(false);
              onClose();
            },
          }}
          buttonRight={{
            title: t("delete"),
            onClick: async () => {
              await deleteAward();
              setIsDeleteCollectionMethodModalOpen(false);
            },
          }}
        />
      </Modal>
    </FormProvider>
  );
};
