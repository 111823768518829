/** @jsxImportSource @emotion/react */

import "twin.macro";

import {
  FocusEventHandler,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  PropsWithChildren,
} from "react";
import {
  IFieldComponentBaseProps,
  Maybe,
  ReactComponent,
  TwinStyle,
} from "../../types";

import { MouseEventHandler } from "react";
import tw from "twin.macro";
import { addAsterisk } from "../../utils";
import { FieldLabel } from "../components/FieldLabel";

interface IBaseTextInputProps extends IFieldComponentBaseProps<string> {
  lead?: ReactComponent<{}>;
  trail?: ReactComponent<{}>;
  type?: Omit<HTMLInputTypeAttribute, "number"> | undefined;
  inputCss?: Maybe<TwinStyle>;
  textCss?: Maybe<TwinStyle>;
  errorCss?: Maybe<TwinStyle>;
  leadCss?: Maybe<TwinStyle>;
  trailCss?: Maybe<TwinStyle>;
  disabledCss?: Maybe<TwinStyle>;
  autofocus?: boolean;
  autocomplete?: Maybe<string>;
  onFocus?: FocusEventHandler<any> | undefined;
  onBlur?: FocusEventHandler<any> | undefined;
  onKeyDown?: KeyboardEventHandler<any> | undefined;
  onKeyUp?: KeyboardEventHandler<any> | undefined;
  onClick?: MouseEventHandler<any> | undefined;
  onLeadClick?: MouseEventHandler<any> | undefined;
  onTrailClick?: MouseEventHandler<any> | undefined;
}
const BaseTextInput = (props: PropsWithChildren<IBaseTextInputProps>) => {
  const Lead = props.lead;
  const Trail = props.trail;
  const leadCss = [tw`left-2 w-4.75 h-4.75 absolute`, props.leadCss];
  const trailCss = [tw`right-2 w-4.75 h-4.75 absolute`, props.trailCss];

  return (
    <div css={[props.containerCss]}>
      <FieldLabel isInErrorState={!!props.error} containerCss={[tw`mb-1`]}>
        {addAsterisk(props.label, props.required)}
      </FieldLabel>
      <div
        css={[
          tw`flex flex-row items-center relative`,
          props.inputCss,
          props.error && props.errorCss,
          props.disabled && tw`cursor-not-allowed`,
          props.disabled && props.disabledCss,
        ]}
        onClick={props.onClick}
      >
        {Lead && (
          <Lead
            css={leadCss}
            containerCss={leadCss}
            onClick={props.onLeadClick}
          />
        )}

        <input
          value={props.value ?? ""}
          type={(props.type ?? "text") as any}
          autoFocus={props.autofocus}
          placeholder={props.placeholder}
          disabled={props.disabled}
          autoComplete={props.autocomplete as any}
          onChange={(e) => props.onChange(e.target.value)}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          onKeyUp={props.onKeyUp}
          css={[
            tw`ring-0 outline-none flex-1 bg-inherit py-2.5 px-4 rounded-md shadow-sm`,
            Lead && tw`pl-8`,
            Trail && tw`pr-8`,
            props.textCss,
            props.disabled && tw`cursor-not-allowed`,
          ]}
        />
        {Trail && (
          <Trail
            css={trailCss}
            containerCss={trailCss}
            onClick={props.onTrailClick}
          />
        )}
      </div>
    </div>
  );
};

export type IDisabledButtonProps = {
  disabledLeadCss?: Maybe<TwinStyle>;
  disabledTrailCss?: Maybe<TwinStyle>;
  disabledTextCss?: Maybe<TwinStyle>;
  disabledInputCss?: Maybe<TwinStyle>;
  disabledCss?: Maybe<TwinStyle>;
};

export type ITextInputProps = Omit<
  IBaseTextInputProps,
  | "inputCss"
  | "focusedCss"
  | "bluredCss"
  | "errorCss"
  | "textCss"
  | "disabledCss"
>;

export const TextInput = {
  Contained: (
    props: PropsWithChildren<ITextInputProps & IDisabledButtonProps>
  ) => {
    const {
      leadCss,
      trailCss,
      error,
      disabledCss,
      disabledTextCss,
      disabledInputCss,
      disabledLeadCss,
      disabledTrailCss,
      ...rest
    } = props;
    return (
      <BaseTextInput
        {...rest}
        error={error}
        inputCss={[
          tw`rounded-md bg-[#658BE2] border-1`,
          tw`border-transparent`,
          tw`focus-within:(border-1 border-primary-100 ring-2 ring-primary-400)`,
          props.error && tw`focus-within:ring-error-light`,
          props.disabled && disabledInputCss,
        ]}
        errorCss={[tw`border-error`]}
        leadCss={[
          tw`text-gray-100`,
          leadCss,
          props.disabled && tw`text-gray-300`,
          props.disabled && disabledLeadCss,
        ]}
        trailCss={[
          tw`text-gray-100`,
          trailCss,
          props.disabled && tw`text-gray-300`,
          props.disabled && disabledTrailCss,
        ]}
        textCss={[
          tw`text-body-small font-400 text-white placeholder:(text-white)`,
          props.disabled && tw`text-gray-300 placeholder:text-gray-300`,
          props.disabled && disabledTextCss,
        ]}
        disabledCss={[tw`bg-white`, props.disabled && disabledCss]}
      >
        {props.children}
      </BaseTextInput>
    );
  },
  Underlined: (props: PropsWithChildren<ITextInputProps>) => {
    const { leadCss, trailCss, error, value, ...rest } = props;

    const hasValue = (value ?? "").trim().length > 0;

    return (
      <BaseTextInput
        {...rest}
        value={value}
        error={error}
        inputCss={[
          tw`rounded-t-md bg-gray-400 border-b-1 border-b-gray-300`,
          hasValue && tw`border-b-1 border-b-primary-100`,
          !error &&
            !hasValue &&
            tw`hover:(bg-primary-500 border-b-primary-300 border-b-1)`,
          tw`focus-within:(border-b-2 bg-gray-400 border-b-primary-100)`,
          props.error
            ? tw`focus-within:(border-b-error)`
            : tw`hover:(focus-within:(border-b-primary-100 border-b-2))`,
        ]}
        errorCss={[tw`border-b-error border-b-2`]}
        leadCss={[
          tw`text-gray-100`,
          props.disabled && tw`text-gray-300`,
          leadCss,
        ]}
        trailCss={[
          tw`text-gray-100`,
          props.disabled && tw`text-gray-300`,
          trailCss,
        ]}
        textCss={[
          tw`text-body-small font-400 text-gray placeholder:(text-gray-200)`,
          props.disabled && tw`text-gray-300 placeholder:text-gray-300`,
        ]}
        disabledCss={[tw`bg-gray-400 border-b-gray-300 hover:(bg-gray-400)`]}
      >
        {props.children}
      </BaseTextInput>
    );
  },
  Outlined: (props: PropsWithChildren<ITextInputProps>) => {
    const { leadCss, trailCss, error, ...rest } = props;
    return (
      <BaseTextInput
        {...rest}
        error={error}
        inputCss={[
          tw`transition-all rounded-md bg-white border-1 border-gray-300`,
          tw`hover:(border-gray-600)`,
          tw`focus-within:(border-white ring-1 ring-primary hover:(text-gray-900 ring-2))`,
          props.error && tw`focus-within:(ring-error-light)`,
        ]}
        errorCss={[tw`border-error`]}
        leadCss={[
          tw`text-gray-100`,
          leadCss,
          props.disabled && tw`text-gray-100`,
        ]}
        trailCss={[
          tw`text-gray-100`,
          trailCss,
          props.disabled && tw`text-gray-100`,
        ]}
        textCss={[
          tw`text-body-small font-400 text-gray placeholder:(text-gray-200)`,
          props.disabled && tw`text-gray-100 placeholder:text-gray-100`,
        ]}
        disabledCss={[tw`border-gray-100 hover:(border-gray-100 ring-0)`]}
      >
        {props.children}
      </BaseTextInput>
    );
  },
};
