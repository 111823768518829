/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { Button } from "../../../ui/buttons/Button";
import { Modal } from "../../../ui/popups/Modal";

interface ITextInputModalProps {
  open: boolean;
  onClose: () => void;
  onNext: () => void;
  roleName: string;
}

export const UnsavedRoleChangesModal = ({
  open,
  roleName,
  onClose,
  onNext,
}: ITextInputModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      label={`${t("unsavedChanges")} ${roleName}` as string}
      footerChildren={
        <div css={[tw`flex gap-4`]}>
          <Button.Outlined containerCss={[tw`flex-1`]} onClick={onClose}>
            {t("quit")}
          </Button.Outlined>
          <Button.Contained containerCss={[tw`flex-1`]} onClick={onNext}>
            {t("saveAndContinue")}
          </Button.Contained>
        </div>
      }
    />
  );
};
