import "twin.macro";

import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";

import { TableInstance } from "react-table";
import { Typography } from "../../../Typograhy";
import { css } from "@emotion/react";
import tw from "twin.macro";

/** @jsxImportSource @emotion/react */

interface IAsyncDataTableHeaderProps {
  tableInstance: TableInstance;
  sortKey: any;
  sortOrder?: "ASC" | "DESC";
  setSortKey: (value: string) => void;
}

export const AsyncDataTableHeader = (props: IAsyncDataTableHeaderProps) => {
  const { tableInstance, sortKey, sortOrder, setSortKey } = props;
  const { headerGroups } = tableInstance;
  const iconStyle = tw`h-5 w-5 text-gray-100`;

  return (
    <thead
      css={[tw`bg-gray-50 border-b-gray-200 border-b`, tw`flex justify-center`]}
    >
      {headerGroups.map((headerGroup) => (
        <tr
          {...headerGroup.getHeaderGroupProps()}
          css={css([tw`max-w-screen-xl bg-gray-50`])}
        >
          {headerGroup.headers.map((column) => {
            const disabledSorting = column.disableSortBy ?? false;
            const isSorted = column.id === sortKey;
            const isSortedDesc = sortOrder === "DESC";
            return (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                onClick={() => {
                  if (!disabledSorting) {
                    setSortKey(column.id);
                  }
                }}
                css={[tw`bg-gray-50 px-4 py-4 font-normal`]}
              >
                <div
                  tw="flex items-center gap-x-1"
                  css={[
                    //@ts-ignore
                    column.align === "left" && tw`justify-start`,
                    //@ts-ignore
                    column.align === "right" && tw`justify-end`,
                  ]}
                >
                  <Typography.BodyXSmall containerCss={[tw`tracking-wider`]}>
                    {column.render("Header")?.toString().toUpperCase()}
                  </Typography.BodyXSmall>
                  <div>
                    {!disabledSorting && (
                      <span>
                        {isSorted ? (
                          isSortedDesc ? (
                            <TiArrowSortedDown
                              css={[iconStyle, tw`text-primary-100`]}
                            />
                          ) : (
                            <TiArrowSortedUp
                              css={[iconStyle, tw`text-primary-100`]}
                            />
                          )
                        ) : (
                          <TiArrowUnsorted
                            css={[iconStyle, tw`text-gray-100`]}
                          />
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};
