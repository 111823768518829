import { useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { selectedFacilityIdState } from "../../../state";
import { Api, GetFacilityDepartmentsResponseDto } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";

export const useDepartments = () => {
  const {
    departments: { departmentControllerCreateDepartment },
  } = new Api();
  const client = useQueryClient();
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const { mutateAsync: createDepartment, isLoading: isCreatingDepartment } =
    useCreate(
      departmentControllerCreateDepartment,
      ["departments"]
      //  (data) => {
      //   const queryData = (client.getQueryData([
      //     "facility",
      //     Number(selectedFacilityId),
      //     "departments",
      //   ]) || []) as GetFacilityDepartmentsResponseDto;

      //   const tempData = [...queryData.departments, data];

      //   client.setQueryData(
      //     ["facility", Number(selectedFacilityId), "departments"],
      //     { ...queryData, departments: tempData }
      //   );
      // }
    );

  return {
    createDepartment,
    isCreatingDepartment,
  };
};
