import { useQueryClient } from "@tanstack/react-query";
import { refetchAllContainingQueryKey } from "../../../fields/form/utils";
import { Api } from "../../api";
import { useDelete } from "../../api-hooks/useDelete";
import { useUpdate } from "../../api-hooks/useUpdate";

export const useCategory = ({ id }: { id: number }) => {
  const {
    categories: {
      categoryControllerDeleteCategory,
      categoryControllerUpdateCategory,
    },
  } = new Api();
  const client = useQueryClient();
  const { mutateAsync: updateCategory } = useUpdate(
    id,
    categoryControllerUpdateCategory,
    ["category", id],
    async () => {
      client.refetchQueries(["category", id]);
      refetchAllContainingQueryKey({ client, queryKey: ["categories"] });
    }
  );

  const { mutateAsync: deleteCategory } = useDelete(
    id,
    categoryControllerDeleteCategory,
    ["categories"]
  );

  return {
    updateCategory,
    deleteCategory,
  };
};
