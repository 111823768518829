import { useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { selectedFacilityIdState } from "../../../state";
import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";
import { departmentCategoriesServicesKey } from "../../query-keys";

export const useFacilityDepartments = () => {
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const selectedFacilityIdNumber = Number(selectedFacilityId);
  const {
    facilities: { facilityControllerGetFacilityDepartments },
    departments: { departmentControllerGetDepartmentCategoriesWithServices },
  } = new Api();
  const queryClient = useQueryClient();
  const facilityDepartments = useGetById({
    id: selectedFacilityIdNumber,
    query: facilityControllerGetFacilityDepartments,
    queryKey: ["facility", selectedFacilityIdNumber, "departments"],
    // onSuccess: (data) => {
    //   data?.departments.map((d) =>
    //     queryClient.prefetchQuery(
    //       departmentCategoriesServicesKey(d.id),
    //       async () => {
    //         const f =
    //           await departmentControllerGetDepartmentCategoriesWithServices(
    //             Number(d.id)
    //           );
    //         return f.data.isSuccessful
    //           ? f.data.payload?.categories.map((c) => ({
    //               ...c,
    //               departmentId: d?.id,
    //               services: c.services.map((s) => ({
    //                 ...s,
    //                 categoryId: c.id,
    //                 departmentId: d?.id,
    //               })),
    //             }))
    //           : null;
    //       }
    //     )
    //   );
    // },
  });

  return {
    facilityDepartments,
  };
};
