/** @jsxImportSource @emotion/react */
import { PropsWithChildren, memo } from "react";

import { IComponentBaseProps } from "../types";

export interface IFreeze extends IComponentBaseProps {
  value: boolean;
}

export const Freeze = memo(
  (props: PropsWithChildren<IFreeze>) => {
    return <>{props.children}</>;
  },
  (prevProps, nextProps) => !prevProps.value && nextProps.value
);
