import "twin.macro";

import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { IComponentBaseProps, Maybe } from "../../../../types";
import { ReactNode, useEffect } from "react";

import { AsyncDataTableBody } from "./AsyncDataTableBody";
import { AsyncDataTableHeader } from "./AsyncDataTableHeader";
import { AsyncDataTableLoader } from "./AsyncDataTableLoader";
import { AsyncDataTablePagination } from "./AsyncDataTablePagination";
import { Freeze } from "../../../../components/Freeze";
import { Typography } from "../../../Typograhy";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import tw from "twin.macro";
import { useSticky } from "react-table-sticky";
import { useTranslation } from "react-i18next";

const customTableContainerStyle = css`
  .table {
    /* tr:hover > td {
      ${tw`bg-gray-300`}
    } */
    /* tr > td:first-of-type {
      ${tw`border-l`}
    }
    tr > td:last-of-type {
      ${tw`border-r`}
    } */

    &.sticky {
      border: none;
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 20;
        width: fit-content;
      }
      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        border-left: none;
        border-right: 1px solid #e5e9ee;
      }
      th[data-sticky-last-left-td] {
        border-left: none;
        border-right: 1px solid #ddd;
        z-index: 30;
      }

      [data-sticky-first-right-td] {
        border-right: none;
        border-left: 1px solid #e5e9ee;
      }
      th[data-sticky-first-right-td] {
        border-right: none;
        border-left: 1px solid #ddd;
        z-index: 30;
      }
    }
  }
`;
export type ICustomColumnProps<T extends object> = Omit<Column<T>, "Cell"> & {
  cell(data: T): ReactNode;
  accessor: keyof T | T[keyof T];
  align?: "left" | "right" | "center";
  disableSortBy?: boolean;
  sticky?: "left" | "right";
};

interface IAsyncDataTableProps<T extends object> extends IComponentBaseProps {
  columns: ICustomColumnProps<T>[];
  data: T[];
  noDataPlaceholder?: {
    title: string;
    subtitle?: string;
  };
  totalCount: number;
  maxPage: number;
  currentPage: number;
  pageSize: number;
  loading: boolean;
  hidePagination?: boolean;
  activeRowIndex?: Maybe<number>;
  setPage(value: number): void;
  onRowClick?: (t: T, index: number) => void;
  sortKey?: keyof T;
  setSortKey: (value: keyof T) => void;
  sortOrder?: "ASC" | "DESC";
  setSortOrder: (value: "ASC" | "DESC") => void;
}

export const AsyncDataTable = <T extends object>(
  props: IAsyncDataTableProps<T>
) => {
  const { t } = useTranslation();
  const {
    columns,
    totalCount,
    hidePagination,
    data,
    currentPage,
    setPage,
    pageSize,
    maxPage,
    loading,
    sortKey,
    sortOrder,
    setSortKey,
  } = props;

  const table = useTable(
    {
      columns: columns as Column[],
      data: !props.loading ? data : new Array(pageSize).fill({}),
      manualPagination: true,
      initialState: {
        // @ts-ignore
        pageIndex: currentPage - 1,
        pageSize,
      },
      pageCount: maxPage,
      manualSortBy: true,
      disableMultiSort: true,
    },
    useSortBy,
    useSticky,
    useFlexLayout,
    usePagination
  );

  useEffect(() => {
    table.gotoPage(currentPage - 1);
  }, [table, currentPage]);

  const showEmptyState = !props.loading && props.totalCount === 0;
  return (
    <div css={[props.containerCss]}>
      <div
        tw="relative -mr-4 sm:-mr-6 md:-mr-0"
        css={[
          customTableContainerStyle,
          showEmptyState
            ? tw`overflow-x-hidden h-full`
            : tw`overflow-x-scroll scrollbar-hide`,
        ]}
      >
        {/* {showEmptyState && (
          <div tw="absolute top-1/3 left-0 right-0 z-20">
            <div tw="flex items-center flex-col my-8">
              <Typography.H4 tw="text-gray mb-6">No results</Typography.H4>
              <Typography.BodyLarge tw="text-gray-100">
                Try adjusting your search
              </Typography.BodyLarge>
            </div>
          </div>
        )} */}
        <table
          {...table.getTableProps()}
          css={tw`w-full h-[95%]`}
          className="table sticky"
        >
          <AsyncDataTableHeader
            tableInstance={table}
            sortKey={sortKey}
            sortOrder={sortOrder}
            setSortKey={setSortKey as any}
          />
          {props.loading ? (
            <AsyncDataTableLoader tableInstance={table} />
          ) : props.totalCount !== 0 ? (
            <AsyncDataTableBody
              tableInstance={table}
              onRowClick={props.onRowClick}
              activeRowIndex={props.activeRowIndex}
            />
          ) : (
            <div tw="flex items-center flex-col my-8 h-[90%] justify-center">
              <Typography.H4 tw="text-gray mb-6">
                {props?.noDataPlaceholder?.title || t("noResults")}
              </Typography.H4>
              <Typography.BodyLarge tw="text-gray-100">
                {props?.noDataPlaceholder?.subtitle || t("tryAdjustingSearch")}
              </Typography.BodyLarge>
            </div>
          )}
        </table>
      </div>
      {!hidePagination && (
        <Freeze value={loading}>
          <AsyncDataTablePagination
            // @ts-ignore
            canPreviousPage={table.canPreviousPage}
            // @ts-ignore
            canNextPage={table.canNextPage}
            totalOnPage={data.length}
            totalCount={totalCount}
            pageSize={pageSize}
            maxPage={props.maxPage}
            currentPage={currentPage}
            setCurrentPage={setPage}
          />
        </Freeze>
      )}
    </div>
  );
};
