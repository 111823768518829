import { differenceInMinutes, format } from "date-fns";
import { CSSProperties, useState } from "react";
import tw from "twin.macro";
import { AppointmentDetailsModal } from "../pages/appointments/modals/AppointmentDetailsModal";
import { Typography } from "../ui/Typograhy";
/** @jsxImportSource @emotion/react */

interface ISlot {
  startDate: Date;
  endDate: Date;
  patientName: string;
  serviceName: string;
  doctorName: string;
  id: number;
}

export const TimeItem = ({
  slot,
  offset,
  vertical,
  currentTimeInMinutes,
}: {
  slot: ISlot;
  offset: number;
  vertical?: boolean;
  currentTimeInMinutes: number;
}) => {
  const width =
    (differenceInMinutes(slot.endDate, slot.startDate) / 30) *
    (vertical ? 78.2 : 128);
  const startTimeInMinutes =
    slot.startDate.getMinutes() + slot.startDate.getHours() * 60;
  const endTimeInMinutes =
    slot.endDate.getMinutes() + slot.endDate.getHours() * 60;
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const dayDiff = slot.startDate.getDate() - new Date().getDate();

  const marginOffset = offset * 1.6 + 3;
  const isWarning = false;
  const isFailed = false;
  const isDone =
    (dayDiff <= 0 && endTimeInMinutes < currentTimeInMinutes) || dayDiff < 0;
  const isActive =
    dayDiff === 0 &&
    startTimeInMinutes < currentTimeInMinutes &&
    endTimeInMinutes > currentTimeInMinutes;

  const style = vertical
    ? ({
        top: marginOffset,
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
        height: width,
        overflow: "scroll",
      } as CSSProperties)
    : ({
        width,
        left: marginOffset,
      } as CSSProperties);

  return (
    <>
      <div
        css={[
          tw`absolute z-10 bg-primary-50 rounded p-1.5 border-1 border-primary-50 focus:(bg-primary-100) cursor-pointer`,
          tw`scrollbar-hide`,
          isWarning && tw`bg-error-light border-error-light`,
          isFailed && tw`bg-white border-gray-100 focus:(bg-gray-100)`,
          isDone && tw`bg-gray-100 border-gray-100 focus:(bg-gray-200)`,
          isActive &&
            tw`bg-primary-100  border-primary-100 focus:(bg-primary-200)`,
          vertical && tw`w-[calc(100% - 1rem)] left-2`,
          !vertical && tw`min-w-32`,
        ]}
        style={style}
        onClick={() => setIsAppointmentModalOpen(true)}
      >
        <div>
          <Typography.BodySmall
            containerCss={[
              tw`truncate`,
              isWarning && tw`text-error`,
              isFailed && tw`text-gray-600`,
              isDone && tw`text-gray-900`,
              isDone && tw`text-gray-900`,
            ]}
          >
            {slot.patientName}
          </Typography.BodySmall>
          <Typography.BodySmall
            containerCss={[
              tw`truncate`,
              isWarning && tw`text-error`,
              isFailed && tw`text-gray-600`,
              isDone && tw`text-gray-900`,
              isDone && tw`text-gray-900`,
            ]}
          >
            {slot.serviceName}
          </Typography.BodySmall>
        </div>
        <div>
          <Typography.BodyXSmall
            containerCss={[
              tw`text-[10px] leading-[12px] truncate text-gray-700`,
              isWarning && tw`text-error`,
              isFailed && tw`text-gray-600`,
              isDone && tw`text-gray-900`,
              isDone && tw`text-gray-700`,
            ]}
          >
            {slot.doctorName}
          </Typography.BodyXSmall>
          <p css={[tw`flex items-center truncate h-3`]}>
            <Typography.BodyXSmall
              containerCss={[
                tw`text-[10px] leading-[12px] text-gray-700`,
                isWarning && tw`text-error`,
                isFailed && tw`text-gray-600`,
                isDone && tw`text-gray-900`,
                isDone && tw`text-gray-700`,
              ]}
            >
              {format(slot.startDate, "HH:mm")} -
            </Typography.BodyXSmall>
            <Typography.BodyXSmall
              containerCss={[
                tw`text-[10px] leading-[12px] text-gray-700 ml-0.5`,
                isWarning && tw`text-error`,
                isFailed && tw`text-gray-600`,
                isDone && tw`text-gray-900`,
                isDone && tw`text-gray-700`,
              ]}
            >
              {format(slot.endDate, "HH:mm")}
            </Typography.BodyXSmall>
          </p>
        </div>
      </div>
      <AppointmentDetailsModal
        appointmentId={slot.id}
        onClose={() => setIsAppointmentModalOpen(false)}
        open={isAppointmentModalOpen}
        hideReportArrival
      />
    </>
  );
};
