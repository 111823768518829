import { FieldLabel } from "../components/FieldLabel";
import { IFieldComponentBaseProps } from "../../types";
import tw from "twin.macro";
import { useCallback } from "react";

/** @jsxImportSource @emotion/react */

type ISwitchProps = Omit<IFieldComponentBaseProps<boolean>, "placeholder">;

export const Switch = (props: ISwitchProps) => {
  const { disabled, value, onChange } = props;
  const onSwitch = useCallback(() => {
    if (disabled) {
      return;
    } else {
      onChange(!value);
    }
  }, [disabled, value, onChange]);
  return (
    <div
      css={[tw`flex flex-row items-center gap-x-2 mb-0.5`, props.containerCss]}
    >
      <div
        css={[
          tw`w-12 h-6 flex items-center rounded-full p-0.5 cursor-pointer`,
          tw`transition-all duration-200`,
          value ? tw`bg-primary` : tw`bg-gray-400`,
          disabled && tw`bg-gray-100 cursor-not-allowed`,
          disabled && value && tw`bg-primary opacity-50`,
        ]}
        onClick={(e) => {
          e.stopPropagation();
          onSwitch();
        }}
      >
        <div
          css={[
            tw`bg-white h-5 w-5 rounded-full shadow-md`,
            tw`duration-200 ease-in-out`,
            value && tw`transform translate-x-6`,
          ]}
        />
      </div>
      <FieldLabel isInErrorState={!!props.error} disabled={props.disabled}>
        {props.label}
      </FieldLabel>
    </div>
  );
};
