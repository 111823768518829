/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface HttpResponse {
  code: number;
  message: string;
  messageCode: string;
  isSuccessful: boolean;
}

export interface GetOrganisationPatientService {
  id: number;
  name: string;
}

export interface GetOrganisationPatientsAppointment {
  id: number;
  service: GetOrganisationPatientService;
}

export interface GetAwardsForFacilityService {
  id: number;
  name: string;
}

export interface GetAwardsForFacilityResponseDto {
  id: number;
  type: string;
  name?: string;
  awardPoints: number;
  maxNoOfUsage?: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  description: string;
  /** @format date-time */
  createdAt: string;
  service: GetAwardsForFacilityService;
}

export interface GetCouponResponseServiceDto {
  id: number;
  name: string;
}

export interface GetCouponResponseDto {
  id: number;
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  asNews: boolean;
  description: string;
  imageUrl?: string;
  service: GetCouponResponseServiceDto;
}

export interface GetPatientsUsedCouponPaginatedPatient {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetPatientsUsedCouponPaginatedResponseDto {
  patient: GetPatientsUsedCouponPaginatedPatient;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  usedAt: string;
}

export interface GetPatientUsedCouponsPaginatedService {
  id: number;
  name: string;
}

export interface GetPatientUsedCouponsPaginatedResponseDto {
  id: number;
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  service: GetPatientUsedCouponsPaginatedService;
}

export interface GetPatientActivatedCouponsPaginatedResponseDto {
  id: number;
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  asNews: boolean;
  description: string;
  imageUrl?: string;
  service: GetPatientUsedCouponsPaginatedService;
}

export interface GetPatientsActivatedCouponPaginatedPatient {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetPatientsActivatedCouponPaginatedResponseDto {
  patient: GetPatientsActivatedCouponPaginatedPatient;
  /** @format date-time */
  createdAt: string;
}

export interface CreateOrganisationResponseDto {
  id: number;
  name: string;
  description: string;
  /** @format date-time */
  createdAt: string;
}

export interface GetPatientMePreferredFacility {
  id: number;
  name: string;
  type: string;
  abbreviation: string;
  country: string;
  city: string;
  address: string;
  lng?: number;
  lat?: number;
  phone1?: string;
  phone2?: string;
  email?: string;
  description?: string;
  imageUrl?: string;
}

export interface CreatePatientMobileRequestDto {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  address: string;
  /** @format date-time */
  birthDate: string;
  sex: string;
  phone: string;
  note?: string;
  password: string;
  referralCode?: string;
  preferredFacilityId?: number;
  organisationId: number;
}

export interface CreatePatientWebRequestDto {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  address: string;
  /** @format date-time */
  birthDate: string;
  sex: string;
  phone: string;
  note?: string;
  preferredFacilityId?: number;
}

export interface CreateOrganisationRequestDto {
  name: string;
  description?: string;
}

export interface GetOrganisationPatientsPatient {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  address: string;
  /** @format date-time */
  birthDate: string;
  sex: string;
  phone: string;
  note?: string;
  appointments: GetOrganisationPatientsAppointment[];
}

export interface GetOrganisationPatientsResponseDto {
  patients: GetOrganisationPatientsPatient[];
}

export interface UpdatePatientPreferredFacilityPreferredFacility {
  id: number;
  name: string;
}

export interface UpdatePatientPreferredFacilityResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  address: string;
  /** @format date-time */
  birthDate: string;
  sex: string;
  phone: string;
  note?: string;
  preferredFacility: UpdatePatientPreferredFacilityPreferredFacility;
}

export interface UpdatePatientPreferredFacilityRequestDto {
  preferredFacilityId: number;
}

export interface GetFacilityDepartmentsPaginatedDepartment {
  id: number;
  name: string;
  iconUrl: string;
}

export interface GetServiceAppointmentsPaginatedItem {
  id: number;
  patientFirstName: string;
  patientLastName: string;
  employeeFirstName: string;
  employeeLastName: string;
  /** @format date-time */
  scheduledFor: string;
  price: number;
}

export interface GetServiceHistoryAppointmentsPaginatedItem {
  id: number;
  patientFirstName: string;
  patientLastName: string;
  employeeFirstName: string;
  employeeLastName: string;
  /** @format date-time */
  scheduledFor: string;
  price: number;
}

export interface GetPatientAppointmentsPaginatedItem {
  id: number;
  serviceName: string;
  serviceId: number;
  facilityName: string;
  /** @format date-time */
  scheduledFor: string;
  price: number;
  /** @format date-time */
  arrivedAt: string;
  /** @format date-time */
  recordCreatedAt: string;
}

export interface GetPatientHistoryAppointmentsPaginatedItem {
  id: number;
  serviceName: string;
  serviceId: number;
  facilityName: string;
  /** @format date-time */
  scheduledFor: string;
  price: number;
  employeeFirstName: string;
  employeeLastName: string;
}

export interface GetEmployeeDefaultWorkingHoursForFacilityResponseDto {
  defaultWorkingHoursScheduleWithShifts: WorkingHoursScheduleWithShifts[];
}

export interface GetEmployeeExceptionWorkingHoursForFacilityResponseDto {
  exceptionWorkingHoursScheduleWithShifts: WorkingHoursScheduleWithShifts[];
}

export interface PutEmployeeDefaultWorkingHoursForFacilityResponseDto {
  defaultWorkingHoursScheduleWithShifts: WorkingHoursScheduleWithShifts[];
}

export interface PutEmployeeDefaultWorkingHoursForFacilityBodyRequestDto {
  workingHours: WorkingHoursSchedule[];
}

export interface PutEmployeeExceptionWorkingHoursForFacilityResponseDto {
  exceptionWorkingHoursScheduleWithShifts: WorkingHoursScheduleWithShifts[];
}

export interface PutEmployeeExceptionWorkingHoursForFacilityBodyRequestDto {
  /** @format date-time */
  date: string;
  workingHours: WorkingHoursExceptionSchedule[];
}

export interface GetFacilityEmployeesEmployee {
  id: number;
  firstName: string;
  lastName: string;
  /** @format date-time */
  createdAt: string;
  role: GetEmployeeByIdRole;
}

export interface GetEmployeeByIdResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  /** @format date-time */
  birthDate: string;
  imageUrl: string;
  /** @format date-time */
  createdAt: string;
  facilityId: number;
  facilityName: string;
  departmentIds: string[];
  role: GetEmployeeByIdRole;
}

export interface GetFacilityDefaultWorkingHoursResponseDto {
  defaultWorkingHoursScheduleWithShifts: WorkingHoursScheduleWithShifts[];
}

export interface GetPatientCouponsAwardsResponseCoupon {
  id: number;
  name: string;
  requiredPoints: number;
}

export interface GetPatientCouponsAwardsResponsePatientCoupon {
  id: number;
  /** @format date-time */
  createdAt: string;
  coupon: GetPatientCouponsAwardsResponseCoupon;
}

export interface GetPatientCouponsAwardsResponseAward {
  id: number;
  name: string;
  awardPoints: number;
}

export interface GetPatientCouponsAwardsResponsePatientAward {
  id: number;
  /** @format date-time */
  createdAt: string;
  award: GetPatientCouponsAwardsResponseAward;
}

export interface GetPatientCouponsAwardsResponseDto {
  patientCoupons: GetPatientCouponsAwardsResponsePatientCoupon[];
  patientAwards: GetPatientCouponsAwardsResponsePatientAward[];
}

export interface GetEmployeeAppointmentsItem {
  serviceName: string;
  facilityName: string;
  /** @format date-time */
  scheduledFor: string;
  price: number;
  /** @format date-time */
  arrivedAt: string;
  /** @format date-time */
  recordCreatedAt: string;
}

export interface GetEmployeeWeekAppointmentsPatient {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetEmployeeWeekAppointmentsEmployee {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetEmployeeWeekAppointmentsService {
  id: number;
  name: string;
}

export interface GetEmployeeWeekAppointmentsAppointment {
  id: number;
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  endTime: string;
  dayofTheWeek: number;
  patient: GetEmployeeWeekAppointmentsPatient;
  employee: GetEmployeeWeekAppointmentsEmployee;
  service: GetEmployeeWeekAppointmentsService;
}

export interface GetEmployeeWeekAppointmentsResponseDto {
  appointments: GetEmployeeWeekAppointmentsAppointment[];
}

export interface GetOranisationFacilitiesFacility {
  id: number;
  type: string;
  name: string;
  abbreviation: string;
  country: string;
  city: string;
  address: string;
  lng?: number;
  lat?: number;
  phone1?: string;
  phone2?: string;
  email?: string;
  description?: string;
  imageUrl?: string;
}

export interface GetOrganisationRolesRole {
  id: number;
  name: string;
  description: string;
}

export interface GetOrganisationRolesResponseDto {
  id: number;
  roles: GetOrganisationRolesRole[];
}
export interface GetOrganisationRolesRoleWithPermissions {
  id: number;
  name: string;
  description: string;
  permissions: number[];
}

export interface GetOrganisationRolesWithPermissionsResponseDto {
  id: number;
  roles: GetOrganisationRolesRoleWithPermissions[];
}

export interface GetOrganisationFacilitiesResponseDto {
  id: number;
  facilities: GetOranisationFacilitiesFacility[];
}

export interface CreatePatientResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  address: string;
  /** @format date-time */
  birthDate: string;
  sex: string;
  phone: string;
  note?: string;
  /** @format date-time */
  createdAt: string;
}

export interface LoginPatientResponseDto {
  token: string;
}

export interface LoginPatientRequestDto {
  email: string;
  password: string;
}

export enum UserSex {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export interface IOrganisationPatientItems {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  address: string;
  birthDate: Date;
  sex: UserSex;
  phone: string;
  note?: string;
  appointments: { id: number; service: { id: number; name: string } }[];
}

export interface UpdateFacilityDescriptionResponseDto {
  id: number;
  type: string;
  name: string;
  abbreviation: string;
  country: string;
  city: string;
  address: string;
  lng: number;
  lat: number;
  phone1: string;
  phone2: string;
  email: string;
  description: string;
  imageUrl: string;
  /** @format date-time */
  createdAt: string;
}

export interface UpdateFacilityDescriptionRequestDto {
  description?: string;
}

export interface GetEmptyAppointmentSlotsWebResponseTimeSlot {
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  endTime: string;
}

export interface GetEmptyAppointmentSlotsWebResponseDto {
  timeSlots: GetEmptyAppointmentSlotsWebResponseTimeSlot[];
}

export interface GetPossibleRoomsForEmployeeAndServiceResponseDto {
  id: number;
  name: string;
  position: string;
}

export interface CreateAppointmentResponseDtoPatient {
  id: number;
  firstName: string;
  lastName: string;
  /** @format date-time */
  birthDate: string;
}

export interface CreateAppointmentResponseDtoEmployee {
  id: number;
  firstName: string;
  lastName: string;
}

export interface CreateAppointmentResponseDtoService {
  id: number;
  name: string;
}

export interface CreateAppointmentResponseDtoRoom {
  id: number;
  name: string;
  position: string;
}

export interface CreateAppointmentResponseDto {
  id: number;
  /** @format date-time */
  scheduledFor: string;
  /** @format date-time */
  arrivedAt?: string;
  /** @format date-time */
  recordCreatedAt?: string;
  remark?: string;
  attachment?: string;
  patient: CreateAppointmentResponseDtoPatient;
  employee: CreateAppointmentResponseDtoEmployee;
  service: CreateAppointmentResponseDtoService;
  room: CreateAppointmentResponseDtoRoom;
}

export interface CreateAppointmentRequestDto {
  /** @format date-time */
  scheduledFor: string;
  remark?: string;
  attachment?: string;
  serviceId: number;
  employeeId: number;
  patientId: number;
  roomId: number;
}

export interface GetEmptyAppointmentSlotsMobileResponseTimeSlot {
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  endTime: string;
}

export interface GetEmptyAppointmentSlotsMobileResponseDto {
  employeeId: number | null;
  roomId: number | null;
  timeSlots: GetEmptyAppointmentSlotsMobileResponseTimeSlot[];
}

export interface UpdateEmployeeResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  /** @format date-time */
  birthDate: string;
  role: GetEmployeeByIdRole;
  imageUrl: string;
  /** @format date-time */
  updatedAt: string;
}

export interface UpdateEmployeeRequestDto {
  firstName: string;
  lastName: string;
  email: string;
  /** @format date-time */
  birthDate: string;
  imageUrl?: string;
  departmentIds: number[];
  roleId: number;
}

export interface RequestEmployeeImageLinksResponseDto {
  uploadUrl: string;
  downloadUrl: string;
  fileUUID: string;
}

export interface RequestEmployeeImageLinksRequestBodyDto {
  fileName: string;
}

export interface CreateRoleResponseDto {
  id: number;
  name: string;
  description: string;
  /** @format date-time */
  createdAt: string;
}

export interface CreateRoleRequestDto {
  name: string;
  description?: string;
}

export interface GetRoleResponseDtoPermission {
  id: number;
  name: string;
  description: string;
  feature: string;
  type: string;
}

export interface GetRoleResponseDto {
  id: number;
  name: string;
  description: string;
  permissions: GetRoleResponseDtoPermission[];
}

export type Role = object;

export interface UpdateRoleResponseDto {
  role: Role;
}

export interface UpdateRoleBodyRequestDto {
  name?: string;
  description?: string;
}

export interface UpdateRolePermissionsDtoPermission {
  id: number;
  name: string;
  description: string;
  feature: string;
  type: string;
}

export interface UpdateRolePermissionsResponseDto {
  id: number;
  name: string;
  description: string;
  permissions: UpdateRolePermissionsDtoPermission[];
}

export interface UpdateRolePermissionsBodyRequestDto {
  permissionIds: number[];
}

export interface CreatePermissionResponseDto {
  id: number;
  name: string;
  description: string;
  /** @format date-time */
  createdAt: string;
  type: string;
  feature: string;
}

export interface CreatePermissionRequestDto {
  name: string;
  description?: string;
  type: string;
  feature: string;
}

export interface GetAllPermissionsResponseDtoPermission {
  id: number;
  name: string;
  description: string;
  type: string;
  feature: string;
}

export interface GetAllPermissionsResponseDto {
  permissions: GetAllPermissionsResponseDtoPermission[];
}

export interface GetPermissionResponseDtoRole {
  id: number;
  name: string;
  description: string;
}

export interface GetPermissionResponseDto {
  id: number;
  name: string;
  description: string;
  type: string;
  feature: string;
  roles: GetPermissionResponseDtoRole[];
}

export interface GetRoomResponseDtoService {
  id: number;
  name: string;
}

export interface GetRoomResponseDtoEmployee {
  id: number;
  firstName: string;
  lastName: string;
}

export interface ReportArrivalAppointmentResponseDto {
  id: number;
  /** @format date-time */
  arrivedAt: string;
}

export interface GetRoomResponseDto {
  id: number;
  name: string;
  position: string;
  services: GetRoomResponseDtoService[];
  employees: GetRoomResponseDtoEmployee[];
}

export interface GetRoomEmployeesEmployee {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetRoomEmployeesResponseDto {
  id: number;
  employees: GetRoomEmployeesEmployee[];
}

export interface GetMultipleRoomsEmployeesEmployee {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetMultipleRoomsEmployeesResponseDto {
  roomIds: number[];
  employees: GetMultipleRoomsEmployeesEmployee[];
}

export interface GetMultipleRoomsEmployeesRequestDto {
  roomIds: number[];
}

export type LogoutPatientResponseDto = object;

export interface UpdatePatientInfoResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  address: string;
  /** @format date-time */
  birthDate: string;
  sex: string;
  phone: string;
  note?: string;
  /** @format date-time */
  createdAt: string;
}

export interface UpdatePatientInfoRequestDto {
  firstName?: string;
  lastName?: string;
  email?: string;
  country?: string;
  city?: string;
  address?: string;
  /** @format date-time */
  birthDate?: Date;
  sex?: string;
  phone?: string;
  note?: string;
}

export interface UpdatePatientPointsResponseDto {
  id: number;
  points: number;
}

export interface GetPatientByIdResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  address: string;
  /** @format date-time */
  birthDate: string;
  sex: string;
  points: number;
  phone: string;
  note?: string;
}

export interface GetDepartmentCategoriesCategory {
  id: number;
  name: string;
}

export interface GetDepartmentCategoriesResponseDto {
  id: number;
  name: string;
  categories: GetDepartmentCategoriesCategory[];
}

export interface UpdatePatientPointsRequestDto {
  points: number;
}

export interface GetPatientUsedCouponsCoupon {
  id: number;
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate: string;
}

export interface GetPatientUsedCouponsPatientCoupon {
  createdAt: string;
  coupon: GetPatientUsedCouponsCoupon[];
}

export type WorkingHours = object;

export interface CreateFacilityImage {
  id: number;
  name: string;
  path: string;
}

export interface CreateFacilityResponseDto {
  id: number;
  type: string;
  name: string;
  abbreviation: string;
  country: string;
  city: string;
  address: string;
  lng: number;
  lat: number;
  phone1: string;
  phone2: string;
  email: string;
  description: string;
  workingHours: WorkingHours[];
  image?: CreateFacilityImage;
  /** @format date-time */
  createdAt: string;
}

export interface CreateFacilityFacilityImage {
  name: string;
  downloadUrl: string;
  fileUUID: string;
}

export interface CreateFacilityRequestDto {
  type: string;
  name: string;
  abbreviation: string;
  country: string;
  city: string;
  address: string;
  lng: number;
  lat: number;
  phone1?: string;
  phone2?: string;
  email: string;
  description?: string;
  workingHours: WorkingHoursSchedule[];
  image?: CreateFacilityFacilityImage;
}

export interface GetFacilityExceptionWorkingHoursResponseDto {
  exceptionWorkingHoursScheduleWithShifts: WorkingHoursScheduleWithShifts[];
}

export interface PutFacilityDefaultWorkingHoursResponseDto {
  defaultWorkingHoursScheduleWithShifts: WorkingHoursScheduleWithShifts[];
}

export interface PutFacilityDefaultWorkingHoursBodyRequestDto {
  workingHours: WorkingHoursSchedule[];
}

export interface PutFacilityExceptionWorkingHoursResponseDto {
  exceptionWorkingHoursScheduleWithShifts: WorkingHoursScheduleWithShifts[];
}

export interface RequestFacilityImageLinksResponseDto {
  uploadUrl: string;
  downloadUrl: string;
  fileUUID: string;
}

export interface RequestFacilityImageLinksRequestBodyDto {
  fileName: string;
}

export interface WorkingHoursExceptionSchedule {
  openingTime: number;
  closingTime: number;
}

export interface PutFacilityExceptionWorkingHoursBodyRequestDto {
  /** @format date-time */
  date: string;
  workingHours: WorkingHoursExceptionSchedule[];
}

export interface UpdateFacilityInfoResponseDto {
  id: number;
  type: string;
  name: string;
  abbreviation: string;
  country: string;
  city: string;
  address: string;
  lng?: number;
  lat?: number;
  phone1?: string;
  phone2?: string;
  email?: string;
  description?: string;
  imageUrl?: string;
  /** @format date-time */
  createdAt: string;
}

export interface UpdateFacilityInfoRequestDto {
  type?: string;
  name?: string;
  abbreviation?: string;
  country?: string;
  city?: string;
  address?: string;
  phone1?: string;
  phone2?: string;
  email?: string;
}

export interface GetEmployeeLoginResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  organisation: GetEmployeeOrganisation;
}

export interface LoginEmployeeResponseDto {
  employee: GetEmployeeLoginResponseDto;
}

export type Map = object;

export interface WorkingHoursScheduleWithShifts {
  shiftId: number;
  openingTime: number;
  closingTime: number;
  daysOfTheWeek: DaysOfTheWeek;
}

export interface GetFacilityResponseDto {
  id: number;
  type: string;
  name: string;
  country: string;
  city: string;
  address: string;
  lng?: number;
  lat?: number;
  phone1?: string;
  phone2?: string;
  email?: string;
  description?: string;
  imageUrl?: string;
  workingHoursScheduleWithShifts: WorkingHoursScheduleWithShifts[];
}

export interface GetFacilityDepartmentsDepartment {
  id: number;
  name: string;
  iconUrl: string;
}

export interface GetFacilityDepartmentsResponseDto {
  id: number;
  departments: GetFacilityDepartmentsDepartment[];
}

export interface GetServiceEmployeesResponseDto {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetCurrentFacilityCouponsCoupon {
  id: number;
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate: string;
  asNews: boolean;
  timesActivated: number;
  timesUsed: number;
}

export interface CreateDepartmentResponseDto {
  id: number;
  name: string;
  iconUrl: string;
  /** @format date-time */
  createdAt: string;
}

export interface CreateDepartmentRequestDto {
  name: string;
  iconUrl: string;
  facilityId: number;
}

export interface UpdateDepartmentResponseDto {
  id: number;
  name: string;
  iconUrl: string;
}

export interface UpdateDepartmentRequestDto {
  name?: string;
  iconUrl?: string;
}

export interface DeleteDepartmentResponseDto {
  affected: number[];
}

export interface GetDepartmentCategoriesWithServicesService {
  id: number;
  name: string;
  duration: number;
  price: number;
}

export interface PaginatedPayload {
  items: string[];
  itemsPerPage: number;
  totalPages: number;
  totalItems: number;
  currentPage: number;
}

export interface GetDepartmentCategoriesWithServicesCategory {
  id: number;
  name: string;
  services: GetDepartmentCategoriesWithServicesService[];
}

export interface GetDepartmentCategoriesWithServicesResponseDto {
  id: number;
  name: string;
  iconUrl: string;
  categories: GetDepartmentCategoriesWithServicesCategory[];
}

export interface CreateEmployeeResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  /** @format date-time */
  birthDate: string;
  role: GetEmployeeByIdRole;
  imageUrl: string;
  /** @format date-time */
  createdAt: string;
}

export interface CreateEmployeeEmployeeImage {
  name: string;
  downloadUrl: string;
  fileUUID: string;
}

export interface CreateEmployeeRequestDto {
  firstName: string;
  lastName: string;
  email: string;
  /** @format date-time */
  birthDate: string;
  password: string;
  departmentIds: number[];
  roleId: number;
  image?: CreateEmployeeEmployeeImage;
}

export interface GetAppointmentDoneDetailsResponseDtoMedicalRecordDocument {
  name: string;
  path: string;
}

export interface GetAppointmentDoneDetailsResponseDtoMedicalRecord {
  diagnosis: string;
  treatment: string;
  remark?: string;
  medicalRecordDocuments?: GetAppointmentDoneDetailsResponseDtoMedicalRecordDocument[];
}

export interface GetAppointmentDoneDetailsResponseDto {
  id: number;
  /** @format date-time */
  arrivedAt?: string;
  employee: GetAppointmentDoneDetailsResponseDtoEmployee;
  service: GetAppointmentDoneDetailsResponseDtoService;
  medicalRecord: GetAppointmentDoneDetailsResponseDtoMedicalRecord;
}

export interface GetAppointmentDoneDetailsResponseDtoEmployee {
  firstName: string;
  lastName: string;
}

export interface GetAppointmentDoneDetailsResponseDtoService {
  name: string;
  price: number;
  duration: number;
}

export interface GetAppointmentScheduledDetailsResponseDtoEmployee {
  firstName: string;
  lastName: string;
}

export interface GetAppointmentScheduledDetailsResponseDtoService {
  name: string;
  price: number;
  duration: number;
  description: string;
}

export interface GetAppointmentScheduledDetailsResponseDtoAppointmentDocument {
  name: string;
  path: string;
}

export interface GetAppointmentScheduledDetailsResponseDto {
  id: number;
  /** @format date-time */
  arrivedAt?: string;
  employee: GetAppointmentScheduledDetailsResponseDtoEmployee;
  service: GetAppointmentScheduledDetailsResponseDtoService;
  appointmentDocuments?: GetAppointmentScheduledDetailsResponseDtoAppointmentDocument[];
}

export interface RequestAppointmentDocumentLinksResponseDto {
  uploadUrl: string;
  downloadUrl: string;
  fileUUID: string;
}

export interface RequestAppointmentDocumentLinksRequestBodyDto {
  fileName: string;
}

export interface GetEmployeeRole {
  id: number;
  name: string;
  description: string;
  permissions: Record<FeatureType, Record<PermissionType, boolean>>;
}

export interface LoginEmployeeRequestDto {
  email: string;
  password: string;
}

export type LogoutEmployeeResponseDto = object;

export interface CreateCategoryResponseDto {
  id: number;
  name: string;
  /** @format date-time */
  createdAt: string;
}

export interface GetServiceRoomsRoom {
  id: number;
  name: string;
  position: string;
}

export interface GetServiceRoomsResponseDto {
  id: number;
  rooms: GetServiceRoomsRoom[];
}

export interface CreateCategoryRequestDto {
  name: string;
  departmentId: number;
}

export interface UpdateCategoryResponseDto {
  id: number;
  name: string;
}

export interface GetCurrentCouponsForPatientByFacilityIdPatientCoupon {
  /** @format date-time */
  createdAt: string;
}

export interface GetCurrentCouponsForPatientByFacilityIdCoupon {
  id: number;
  name: string;
  description: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  patientCoupon?: GetCurrentCouponsForPatientByFacilityIdPatientCoupon | null;
}

export interface GetCurrentCouponsForPatientByFacilityIdResponseDto {
  coupons: GetCurrentCouponsForPatientByFacilityIdCoupon;
}

export interface UpdateCategoryRequestDto {
  name: string;
}

export interface DeleteCategoryResponseDto {
  affected: number[];
}

export interface CreateRoomResponseDto {
  id: number;
  name: string;
  position: string;
  /** @format date-time */
  createdAt: string;
}

export interface GetAppointmentInfoResponseDtoAppointmentDocument {
  path: string;
  name: string;
}

export interface GetAppointmentInfoResponseDtoPatient {
  id: number;
  firstName: string;
  lastName: string;
  /** @format date-time */
  birthDate: string;
  sex: string;
  phone: string;
  email: string;
  address: string;
  country: string;
  city: string;
}

export interface GetAppointmentInfoResponseDtoEmployee {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetAppointmentInfoResponseDtoService {
  id: number;
  name: string;
}

export interface GetAppointmentInfoResponseDtoRoom {
  id: number;
  name: string;
  position: string;
}

export interface GetAppointmentInfoResponseDtoMedicalRecord {
  id: number;
  /** @format date-time */
  createdAt: string;
}

export interface GetAppointmentInfoResponseDto {
  id: number;
  /** @format date-time */
  scheduledFor: string;
  /** @format date-time */
  arrivedAt?: string;
  remark?: string;
  facilityId: number;
  facilityName: string;
  appointmentDocuments?: GetAppointmentInfoResponseDtoAppointmentDocument[];
  patient: GetAppointmentInfoResponseDtoPatient;
  employee: GetAppointmentInfoResponseDtoEmployee;
  service: GetAppointmentInfoResponseDtoService;
  room: GetAppointmentInfoResponseDtoRoom;
  medicalRecord?: GetAppointmentInfoResponseDtoMedicalRecord;
}

export interface UpdateServiceResponseDto {
  id: number;
  name: string;
  price: number;
  duration: number;
  description: string;
  points: number;
}

export interface UpdateServiceRequestDto {
  name?: string;
  price?: number;
  duration?: number;
  description?: string;
  points?: number;
  categoryId?: number;
  employeeIds?: number[];
  roomIds?: number[];
}

export interface CreateRoomRequestDto {
  name: string;
  position: string;
  departmentId: number;
  employeeIds: number[];
}

export interface CreateServiceResponseDto {
  id: number;
  name: string;
  price: number;
  duration: number;
  description: string;
  points: number;
  /** @format date-time */
  createdAt: string;
}

export interface CreateServiceRequestDto {
  name: string;
  price: number;
  duration: number;
  description?: string;
  points: number;
  categoryId: number;
  employeeIds: number[];
  roomIds: number[];
}

export interface DeleteServiceResponseDto {
  affected: number[];
}

export interface GetServiceResponseDto {
  id: number;
  name: string;
  price: number;
  duration: number;
  description?: string;
  points: number;
}

export interface CreateCouponImage {
  id: number;
  name: string;
  path: string;
}

export interface CreateCouponCouponImage {
  name: string;
  downloadUrl: string;
  fileUUID: string;
}

export interface CreateCouponResponseDto {
  id: number;
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate: string;
  asNews: boolean;
  description: string;
  image?: CreateCouponImage;
  /** @format date-time */
  createdAt: string;
}

export interface CreateCouponRequestDto {
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  asNews: boolean;
  description: string;
  image?: CreateCouponCouponImage;
  serviceId: number;
  facilityId: number;
}

export interface GetPatientMeResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  address: string;
  /** @format date-time */
  birthDate: string;
  sex: string;
  phone: string;
  note?: string;
  referralCode?: string;
  preferredFacility?: GetPatientMePreferredFacility;
}

export interface GetDepartmentRoomsWithAppointmentsByDatePatient {
  id: number;
  firstName: string;
  lastName: string;
}

export interface WorkingHoursSchedule {
  openingTime: number;
  closingTime: number;
  daysOfTheWeek: Record<string, boolean>;
}

export interface GetDepartmentRoomsWithAppointmentsByDateEmployee {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetDepartmentRoomsWithAppointmentsByDateService {
  id: number;
  name: string;
}

export interface GetDepartmentRoomsWithAppointmentsByDateAppointment {
  id: number;
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  endTime: string;
  patient: GetDepartmentRoomsWithAppointmentsByDatePatient;
  employee: GetDepartmentRoomsWithAppointmentsByDateEmployee;
  service: GetDepartmentRoomsWithAppointmentsByDateService;
}

export interface GetDepartmentRoomsWithAppointmentsByDateRoom {
  id: number;
  name: string;
  appointments: GetDepartmentRoomsWithAppointmentsByDateAppointment[];
}

export interface GetDepartmentRoomsWithAppointmentsByDateResponseDto {
  id: number;
  rooms: GetDepartmentRoomsWithAppointmentsByDateRoom[];
}

export interface GetDepartmentRoomsRoom {
  id: number;
  name: string;
}

export interface GetDepartmentRoomsResponseDto {
  id: number;
  rooms: GetDepartmentRoomsRoom[];
}

export interface GetDepartmentEmployeesEmployee {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetDepartmentEmployeesResponseDto {
  id: number;
  employees: GetDepartmentEmployeesEmployee[];
}

export interface GetFacilityEmployeesResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  /** @format date-time */
  birthDate: string;
  role: GetEmployeeByIdRole;
  imageUrl: string;
}

export interface GetFacilityServicesResponseDto {
  id: number;
  name: string;
  price: number;
  duration: number;
  description?: string;
  points: number;
}

export interface GetEmployeeOrganisation {
  id: number;
  name: string;
}

export interface GetEmployeeMeResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  /** @format date-time */
  birthDate: string;
  organisation: GetEmployeeOrganisation;
  role: GetEmployeeRole;
  imageUrl: string;
}

export interface GetEmployeeByIdRole {
  id: number;
  name: string;
}

export interface GetCurrentCouponsForFacilityResponseDto {
  id: number;
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate: string;
  asNews: boolean;
  timesActivated: number;
  timesUsed: number;
}

export interface GetUpcomingCouponsForFacilityResponseDto {
  id: number;
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate: string;
  asNews: boolean;
  timesActivated: number;
  timesUsed: number;
}

export interface GetPastCouponsForFacilityResponseDto {
  id: number;
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate: string;
  asNews: boolean;
  timesActivated: number;
  timesUsed: number;
}

export interface DeleteCouponResponseDto {
  affected: number[];
}

export interface UpdateCouponResponseDto {
  id: number;
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate: string;
  asNews: boolean;
  description: string;
  imageUrl: string;
}

export interface UpdateCouponRequestDto {
  name: string;
  requiredPoints: number;
  discountPercentage: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  asNews: boolean;
  imageUrl?: string;
  description: string;
  serviceId: number;
  facilityId: number;
}

export interface UseCouponResponseDto {
  patientId: number;
  couponId: number;
}

export interface UseCouponRequestDto {
  patientId: number;
  couponId: number;
}

export interface UpdateAwardResponseDto {
  id: number;
  name: string;
}

export interface UpdateAwardRequestDto {
  type: string;
  name?: string;
  awardPoints: number;
  maxNoOfUsage?: number | null;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  description: string;
  serviceId?: number;
  facilityId: number;
}

export interface CreateAwardResponseDto {
  id: number;
  type: string;
  name?: string;
  awardPoints: number;
  maxNoOfUsage?: number;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  description: string;
  /** @format date-time */
  createdAt: string;
}

export interface CreateAwardRequestDto {
  type: string;
  name?: string;
  awardPoints: number;
  maxNoOfUsage?: number | null;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  description: string;
  serviceId?: number;
  facilityId: number;
}

export interface DeleteAwardResponseDto {
  affected: number[];
}

export interface CreateMedicalRecordMedicalRecordDocument {
  name: string;
  path: string;
}

export interface CreateMedicalRecordResponseDto {
  id: number;
  /** @format date-time */
  createdAt: string;
  diagnosis: string;
  treatment: string;
  remark?: string;
  medicalRecordDocuments: CreateMedicalRecordMedicalRecordDocument[];
}

export interface MedicalRecordAttachment {
  name: string;
  downloadUrl: string;
  fileUUID: string;
}

export interface CreateMedicalRecordRequestDto {
  diagnosis: string;
  treatment: string;
  remark?: string;
  appointmentId: number;
  attachments?: MedicalRecordAttachment[];
}

export interface GetMedicalRecordMedicalRecordDocument {
  name: string;
  path: string;
}

export interface GetMedicalRecordResponseDto {
  id: number;
  /** @format date-time */
  createdAt: string;
  diagnosis: string;
  treatment: string;
  remark?: string;
  medicalRecordDocuments: GetMedicalRecordMedicalRecordDocument[];
}

export interface RequestMedicalRecordDocumentLinksResponseDto {
  uploadUrl: string;
  downloadUrl: string;
  fileUUID: string;
}

export interface RequestMedicalRecordDocumentLinksRequestBodyDto {
  fileName: string;
}

export interface CreateNewsImage {
  id: number;
  name: string;
  path: string;
}

export interface CreateNewsResponseDto {
  id: number;
  superTitle: string;
  title: string;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  introText: string;
  asPushNotification: boolean;
  isDraft: boolean;
  content: string;
  image?: CreateNewsImage;
  /** @format date-time */
  createdAt: string;
}

export interface CreateNewsNewsImage {
  name: string;
  downloadUrl: string;
  fileUUID: string;
}

export interface CreateNewsRequestDto {
  superTitle: string;
  title: string;
  /** @format date-time */
  schedulePublishingForDateAndTime?: string;
  introText: string;
  image?: CreateNewsNewsImage;
  asPushNotification: boolean;
  isDraft: boolean;
  content: string;
}

export interface GetNewsCoupon {
  id: number;
}

export interface GetNewsImage {
  id: number;
  name: string;
  path: string;
}

export interface GetNewsResponseDto {
  id: number;
  superTitle: string;
  title: string;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  introText: string;
  asPushNotification: boolean;
  isDraft: boolean;
  content: string;
  coupon?: GetNewsCoupon;
  image?: GetNewsImage;
  /** @format date-time */
  createdAt: string;
}

export interface GetOrganisationMobilePreviewNewsImage {
  id: number;
  name: string;
  path: string;
}

export interface GetOrganisationMobilePreviewNewsResponseDtoNews {
  id: number;
  superTitle: string;
  title: string;
  introText: string;
  content: string;
  image?: GetOrganisationMobilePreviewNewsImage;
}

export interface GetOrganisationMobilePreviewNewsResponseDto {
  news: GetOrganisationMobilePreviewNewsResponseDtoNews[];
}

export interface GetOrganisationMobileAllNewsPaginatedImage {
  id: number;
  name: string;
  path: string;
}

export interface GetOrganisationMobileAllNewsPaginatedResponseDto {
  id: number;
  superTitle: string;
  title: string;
  introText: string;
  content: string;
  /** @format date-time */
  availableFrom: string;
  image?: GetOrganisationMobileAllNewsPaginatedImage;
}

export interface GetActiveFacilityNewsPaginatedCoupon {
  id: number;
}

export interface GetActiveFacilityNewsPaginatedAuthor {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetActiveFacilityNewsPaginatedResponseDto {
  id: number;
  superTitle: string;
  title: string;
  /** @format date-time */
  availableFrom: string;
  coupon?: GetActiveFacilityNewsPaginatedCoupon;
  author: GetActiveFacilityNewsPaginatedAuthor;
}

export interface GetUpcomingFacilityNewsPaginatedCoupon {
  id: number;
}

export interface GetUpcomingFacilityNewsPaginatedAuthor {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetUpcomingFacilityNewsPaginatedResponseDto {
  id: number;
  superTitle: string;
  title: string;
  /** @format date-time */
  availableFrom: string;
  coupon?: GetUpcomingFacilityNewsPaginatedCoupon;
  author: GetUpcomingFacilityNewsPaginatedAuthor;
}

export interface GetDraftFacilityNewsPaginatedCoupon {
  id: number;
}

export interface GetDraftFacilityNewsPaginatedAuthor {
  id: number;
  firstName: string;
  lastName: string;
}

export type DeactivateCouponResponseDto = object;

export interface GetDraftFacilityNewsPaginatedResponseDto {
  id: number;
  superTitle: string;
  title: string;
  coupon?: GetDraftFacilityNewsPaginatedCoupon;
  author: GetDraftFacilityNewsPaginatedAuthor;
}

export interface GetPastFacilityNewsPaginatedCoupon {
  id: number;
}

export interface GetPastFacilityNewsPaginatedAuthor {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetPastFacilityNewsPaginatedResponseDto {
  id: number;
  superTitle: string;
  title: string;
  /** @format date-time */
  availableFrom: string;
  coupon?: GetPastFacilityNewsPaginatedCoupon;
  author: GetPastFacilityNewsPaginatedAuthor;
}

export interface RequestNewsImageLinksRequestBodyDto {
  fileName: string;
}

export interface RequestNewsImageLinksResponseDto {
  uploadUrl: string;
  downloadUrl: string;
  fileUUID: string;
}

export interface RequestCouponImageLinksRequestBodyDto {
  fileName: string;
}

export interface EditNewsImage {
  id: number;
  name: string;
  path: string;
}

export interface EditNewsResponseDto {
  id: number;
  superTitle: string;
  title: string;
  /** @format date-time */
  availableFrom: string;
  /** @format date-time */
  expirationDate?: string;
  introText: string;
  asPushNotification: boolean;
  isDraft: boolean;
  content: string;
  image?: EditNewsImage;
  /** @format date-time */
  createdAt: string;
}

export interface EditNewsRequestBodyDto {
  superTitle: string;
  title: string;
  /** @format date-time */
  schedulePublishingForDateAndTime?: string;
  introText: string;
  image?: CreateNewsNewsImage;
  asPushNotification: boolean;
  isDraft: boolean;
  content: string;
}

export interface DeleteNewsResponseDto {
  affected: number[];
}

export type PublishDraftNewsResponseDto = object;

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from "axios";
import { FeatureType, PermissionType } from "../enums";

import { DaysOfTheWeek } from "../types";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      withCredentials: true,
      baseURL: process.env.REACT_APP_BACKEND_URL || "",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
    this.instance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          const persist = JSON.parse(
            localStorage.getItem("recoil-persist") || ""
          );
          if (persist?.isLoggedInAtom) {
            persist.isLoggedInAtom = false;
            localStorage.setItem("recoil-persist", JSON.stringify(persist));
            location.reload();
          }
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === "object"
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== "string"
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { "Content-Type": type }
          : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title CliniCorePlus API
 * @version 0.0.2
 * @contact CoreLine <contact@coreline.agency> (https://coreline.agency)
 *
 * CliniCorePlus API documentation. If you need a .json version of this documentation find it at: BASE_URL/documentation-json
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  health = {
    /**
     * No description
     *
     * @tags Health Check
     * @name HealthControllerCheck
     * @request GET:/health
     */
    healthControllerCheck: (params: RequestParams = {}) =>
      this.request<
        {
          /** @example "ok" */
          status?: string;
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {} */
          error?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {"database":{"status":"up"}} */
          details?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
        },
        {
          /** @example "error" */
          status?: string;
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {"redis":{"status":"down","message":"Could not connect"}} */
          error?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {"database":{"status":"up"},"redis":{"status":"down","message":"Could not connect"}} */
          details?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
        }
      >({
        path: `/health`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  organisations = {
    /**
     * @description Used for the creation of the organisations.
     *
     * @tags Organisations
     * @name OrganisationControllerCreateOrganisation
     * @summary Stores the given organisation to the database.
     * @request POST:/organisations
     * @secure
     */
    organisationControllerCreateOrganisation: (
      data: CreateOrganisationRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateOrganisationResponseDto;
        },
        any
      >({
        path: `/organisations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching organisation facilities data.
     *
     * @tags Organisations
     * @name OrganisationControllerGetOrganisationFacilities
     * @summary Fetches the given organisation facilities from the database.
     * @request GET:/organisations/{id}/facilities
     * @secure
     */
    organisationControllerGetOrganisationFacilities: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetOrganisationFacilitiesResponseDto;
        },
        any
      >({
        path: `/organisations/${id}/facilities`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching organisation roles data.
     *
     * @tags Organisations
     * @name OrganisationControllerGetOrganisationRoles
     * @summary Fetches the given organisation roles from the database.
     * @request GET:/organisations/{id}/roles
     * @secure
     */
    organisationControllerGetOrganisationRoles: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetOrganisationRolesResponseDto;
        },
        any
      >({
        path: `/organisations/${id}/roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching organisation roles with permissions data.
     *
     * @tags Organisations
     * @name OrganisationControllerGetOrganisationRolesWithPermissions
     * @summary Fetches the given organisation roles with permissions from the database.
     * @request GET:/organisations/{id}/roles/permissions
     * @secure
     */
    organisationControllerGetOrganisationRolesWithPermissions: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetOrganisationRolesWithPermissionsResponseDto;
        },
        any
      >({
        path: `/organisations/${id}/roles/permissions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  patients = {
    /**
     * @description Used for the creation of the patients from web aplication.
     *
     * @tags Patients
     * @name PatientControllerCreatePatientWeb
     * @summary Stores the given patient to the database from web application.
     * @request POST:/patients/web
     * @secure
     */
    patientControllerCreatePatientWeb: (
      data: CreatePatientWebRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreatePatientResponseDto;
        },
        any
      >({
        path: `/patients/web`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the creation of the patients from mobile device.
     *
     * @tags Patients
     * @name PatientControllerCreatePatientMobile
     * @summary Stores the given patient to the database from mobile device.
     * @request POST:/patients/mobile
     * @secure
     */
    patientControllerCreatePatientMobile: (
      data: CreatePatientMobileRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreatePatientResponseDto;
        },
        any
      >({
        path: `/patients/mobile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for login of the given patient.
     *
     * @tags Patients
     * @name PatientControllerLoginPatient
     * @summary Checks patient credentials.
     * @request POST:/patients/login
     * @secure
     */
    patientControllerLoginPatient: (
      data: LoginPatientRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: LoginPatientResponseDto;
        },
        any
      >({
        path: `/patients/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Clears patient http only cookies
     *
     * @tags Patients
     * @name PatientControllerLogoutPatient
     * @summary Logout for the patient.
     * @request POST:/patients/logout
     * @secure
     */
    patientControllerLogoutPatient: (params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: LogoutPatientResponseDto;
        },
        any
      >({
        path: `/patients/logout`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the update of the patient info.
     *
     * @tags Patients
     * @name PatientControllerUpdatePatientInfo
     * @summary Updates information on the given patient in the database.
     * @request PUT:/patients/{id}/info
     * @secure
     */
    patientControllerUpdatePatientInfo: (
      id: number,
      data: UpdatePatientInfoRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdatePatientInfoResponseDto;
        },
        any
      >({
        path: `/patients/${id}/info`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching patient coupons and patient awards of provided patient.
     *
     * @tags Patients
     * @name PatientControllerGetPatientCouponsAwards
     * @summary Fetches the patient coupons and patient awards of provided patient.
     * @request GET:/patients/{id}/coupons/awards
     * @secure
     */
    patientControllerGetPatientCouponsAwards: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetPatientCouponsAwardsResponseDto;
        },
        any
      >({
        path: `/patients/${id}/coupons/awards`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the update of the patient points.
     *
     * @tags Patients
     * @name PatientControllerUpdatePatientPoints
     * @summary Updates points of the given patient in the database.
     * @request PUT:/patients/{id}/points/add
     * @secure
     */
    patientControllerUpdatePatientPoints: (
      id: number,
      data: UpdatePatientPointsRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdatePatientPointsResponseDto;
        },
        any
      >({
        path: `/patients/${id}/points/add`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching the used coupons of the provided patient.
     *
     * @tags Patients
     * @name PatientControllerGetPatientUsedCouponsPaginated
     * @summary Fetches the used coupons of provided patient
     * @request GET:/patients/{id}/coupons/used/paginated
     * @secure
     */
    patientControllerGetPatientUsedCouponsPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetPatientUsedCouponsPaginatedResponseDto[];
          };
        },
        any
      >({
        path: `/patients/${id}/coupons/used/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching the activated coupons of the provided patient.
     *
     * @tags Patients
     * @name PatientControllerGetPatientActivatedCouponsPaginated
     * @summary Fetches the activated coupons of provided patient
     * @request GET:/patients/{id}/coupons/activated/paginated
     * @secure
     */
    patientControllerGetPatientActivatedCouponsPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetPatientActivatedCouponsPaginatedResponseDto[];
          };
        },
        any
      >({
        path: `/patients/${id}/coupons/activated/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the data for the patient that is logged in.
     *
     * @tags Patients
     * @name PatientControllerGetPatientMe
     * @summary Fetches the data for currently logged in patient
     * @request GET:/patients/me
     * @secure
     */
    patientControllerGetPatientMe: (params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: GetPatientMeResponseDto;
        },
        any
      >({
        path: `/patients/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * @description Used for the retrieval of the data for the patient whode id is provided.
     *
     * @tags Patients
     * @name PatientControllerGetPatientbyId
     * @summary Fetches the data of patient whose id is provided
     * @request GET:/patients/{id}
     * @secure
     */
    patientControllerGetPatientbyId: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: GetPatientByIdResponseDto;
        },
        any
      >({
        path: `/patients/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching organisation patients paginated data.
     *
     * @tags Patients
     * @name patientControllerGetOrganisationPatientsPaginated
     * @summary Fetches the given organisation patients paginated from the database.
     * @request GET:/patients/organisation/{id}
     * @secure
     */
    patientControllerGetOrganisationPatientsPaginated: (
      id: number,
      query: {
        /** Current page */
        page: number;
        /** Items per page */
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetOrganisationPatientsPatient[];
          };
        },
        any
      >({
        path: `/patients/organisation/${id}/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching organisation patients data.
     *
     * @tags Patients
     * @name PatientControllerGetOrganisationPatients
     * @summary Fetches the given organisation patients from the database.
     * @request GET:/patients/organisation/{id}
     * @secure
     */
    patientControllerGetOrganisationPatients: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetOrganisationPatientsResponseDto;
        },
        any
      >({
        path: `/patients/organisation/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the update of the patient preferred facility.
     *
     * @tags Patients
     * @name PatientControllerAddPatientPreferredFacility
     * @summary Updates preffered facility for the given patient in the database.
     * @request PUT:/patients/{id}/facility/preferred
     * @secure
     */
    patientControllerAddPatientPreferredFacility: (
      id: number,
      data: UpdatePatientPreferredFacilityRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdatePatientPreferredFacilityResponseDto;
        },
        any
      >({
        path: `/patients/${id}/facility/preferred`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  facilities = {
    /**
     * @description Used for the creation of the facilities.
     *
     * @tags Facilities
     * @name FacilityControllerCreateFacility
     * @summary Stores the given facility to the database.
     * @request POST:/facilities
     * @secure
     */
    facilityControllerCreateFacility: (
      data: CreateFacilityRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateFacilityResponseDto;
        },
        any
      >({
        path: `/facilities`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching facility departments data paginated.
     *
     * @tags Facilities
     * @name FacilityControllerGetFacilityDepartmentsPaginated
     * @summary Fetches the given facility departments from the database with pagination.
     * @request GET:/facilities/{id}/departments/paginated
     * @secure
     */
    facilityControllerGetFacilityDepartmentsPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetFacilityDepartmentsPaginatedDepartment[];
          };
        },
        any
      >({
        path: `/facilities/${id}/departments/paginated`,
        method: "GET",
        secure: true,
        format: "json",
        query,
        ...params,
      }),

    /**
     * @description Used for the update of the facility info.
     *
     * @tags Facilities
     * @name FacilityControllerUpdateFacilityInfo
     * @summary Updates information on the given facility in the database.
     * @request PUT:/facilities/{id}/info
     * @secure
     */
    facilityControllerUpdateFacilityInfo: (
      id: number,
      data: UpdateFacilityInfoRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdateFacilityInfoResponseDto;
        },
        any
      >({
        path: `/facilities/${id}/info`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching facility data.
     *
     * @tags Facilities
     * @name FacilityControllerGetFacility
     * @summary Fetches the given facility from the database.
     * @request GET:/facilities/{id}
     * @secure
     */
    facilityControllerGetFacility: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: GetFacilityResponseDto;
        },
        any
      >({
        path: `/facilities/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching facility default working hours.
     *
     * @tags Facilities
     * @name FacilityControllerGetFacilityDefaultWorkingHours
     * @summary Fetches the given facility default working hours.
     * @request GET:/facilities/{id}/working-hours/default
     * @secure
     */
    facilityControllerGetFacilityDefaultWorkingHours: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetFacilityDefaultWorkingHoursResponseDto;
        },
        any
      >({
        path: `/facilities/${id}/working-hours/default`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for updating facility default working hours.
     *
     * @tags Facilities
     * @name FacilityControllerPutFacilityDefaultWorkingHours
     * @summary Updates the given facility default working hours.
     * @request PUT:/facilities/{id}/working-hours/default
     * @secure
     */
    facilityControllerPutFacilityDefaultWorkingHours: (
      id: number,
      data: PutFacilityDefaultWorkingHoursBodyRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PutFacilityDefaultWorkingHoursResponseDto;
        },
        any
      >({
        path: `/facilities/${id}/working-hours/default`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching facility exception working hours.
     *
     * @tags Facilities
     * @name FacilityControllerGetFacilityExceptionWorkingHours
     * @summary Fetches the given facility exception working hours.
     * @request GET:/facilities/{id}/working-hours/exception
     * @secure
     */
    facilityControllerGetFacilityExceptionWorkingHours: (
      id: number,
      query: {
        /** @format date-time */
        date: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetFacilityExceptionWorkingHoursResponseDto;
        },
        any
      >({
        path: `/facilities/${id}/working-hours/exception`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for updating facility exception working hours.
     *
     * @tags Facilities
     * @name FacilityControllerPutFacilityExceptionWorkingHours
     * @summary Updates the given facility exception working hours.
     * @request PUT:/facilities/{id}/working-hours/exception
     * @secure
     */
    facilityControllerPutFacilityExceptionWorkingHours: (
      id: number,
      data: PutFacilityExceptionWorkingHoursBodyRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PutFacilityExceptionWorkingHoursResponseDto;
        },
        any
      >({
        path: `/facilities/${id}/working-hours/exception`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching facility departments data.
     *
     * @tags Facilities
     * @name FacilityControllerGetFacilityDepartments
     * @summary Fetches the given facility departments from the database.
     * @request GET:/facilities/{id}/departments
     * @secure
     */
    facilityControllerGetFacilityDepartments: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetFacilityDepartmentsResponseDto;
        },
        any
      >({
        path: `/facilities/${id}/departments`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching current facility coupons data.
     *
     * @tags Facilities
     * @name FacilityControllerGetCurrentFacilityCoupons
     * @summary Fetches the given facility current coupons from the database.
     * @request GET:/facilities/{id}/coupons/current
     * @secure
     */

    facilityControllerUpdateFacilityDescription: (
      id: number,
      data: UpdateFacilityDescriptionRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdateFacilityDescriptionResponseDto;
        },
        any
      >({
        path: `/facilities/${id}/desecription`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for generating facility image links.
     *
     * @tags Facilities
     * @name FacilityControllerRequestFacilityImageLinks
     * @summary Generates download and upload link for a facility image to S3.
     * @request POST:/facilities/file/links
     * @secure
     */
    facilityControllerRequestFacilityImageLinks: (
      data: RequestFacilityImageLinksRequestBodyDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: RequestFacilityImageLinksResponseDto;
        },
        any
      >({
        path: `/facilities/file/links`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  departments = {
    /**
     * @description Used for the creation of the departments.
     *
     * @tags Departments
     * @name DepartmentControllerCreateDepartment
     * @summary Stores the given department to the database.
     * @request POST:/departments
     * @secure
     */
    departmentControllerCreateDepartment: (
      data: CreateDepartmentRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateDepartmentResponseDto;
        },
        any
      >({
        path: `/departments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the update of the departments.
     *
     * @tags Departments
     * @name DepartmentControllerUpdateDepartment
     * @summary Updates the given department.
     * @request PUT:/departments/{id}
     * @secure
     */
    departmentControllerUpdateDepartment: (
      id: number,
      data: UpdateDepartmentRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdateDepartmentResponseDto;
        },
        any
      >({
        path: `/departments/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the deletion of the departments.
     *
     * @tags Departments
     * @name DepartmentControllerDeleteDepartment
     * @summary Deletes the given department.
     * @request DELETE:/departments/{id}
     * @secure
     */
    departmentControllerDeleteDepartment: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: DeleteDepartmentResponseDto;
        },
        any
      >({
        path: `/departments/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching the categories with services of the departments.
     *
     * @tags Departments
     * @name DepartmentControllerGetDepartmentCategoriesWithServices
     * @summary Fetches the categories with services for provided department
     * @request GET:/departments/{id}/categories/services
     * @secure
     */
    departmentControllerGetDepartmentCategoriesWithServices: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetDepartmentCategoriesWithServicesResponseDto;
        },
        any
      >({
        path: `/departments/${id}/categories/services`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of appoitments by date and rooms.
     *
     * @tags Departments
     * @name DepartmentControllerGetDepartmentRoomsWithAppointmentsByDate
     * @summary Fetches the rooms for given department with appointments.
     * @request GET:/departments/{id}/rooms/appointments
     * @secure
     */
    departmentControllerGetDepartmentRoomsWithAppointmentsByDate: (
      id: number,
      query: {
        /**
         * Date of the appointments
         * @format date-time
         */
        date: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetDepartmentRoomsWithAppointmentsByDateResponseDto;
        },
        any
      >({
        path: `/departments/${id}/rooms/appointments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the rooms of provided department.
     *
     * @tags Departments
     * @name DepartmentControllerGetDepartmentRooms
     * @summary Fetches department rooms.
     * @request GET:/departments/{id}/rooms
     * @secure
     */
    departmentControllerGetDepartmentRooms: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetDepartmentRoomsResponseDto;
        },
        any
      >({
        path: `/departments/${id}/rooms`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the employees of provided department.
     *
     * @tags Departments
     * @name DepartmentControllerGetDepartmentEmployees
     * @summary Fetches department employees.
     * @request GET:/departments/{id}/employees
     * @secure
     */
    departmentControllerGetDepartmentEmployees: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetDepartmentEmployeesResponseDto;
        },
        any
      >({
        path: `/departments/${id}/employees`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the categories of provided department.
     *
     * @tags Departments
     * @name DepartmentControllerGetDepartmentCategories
     * @summary Fetches department categories.
     * @request GET:/departments/{id}/categories
     * @secure
     */
    departmentControllerGetDepartmentCategories: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetDepartmentCategoriesResponseDto;
        },
        any
      >({
        path: `/departments/${id}/categories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  employees = {
    /**
     * @description Used for the creation of the employees.
     *
     * @tags Employees
     * @name EmployeeControllerCreateEmployee
     * @summary Stores the given employee to the database.
     * @request POST:/employees
     * @secure
     */
    employeeControllerCreateEmployee: (
      data: CreateEmployeeRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateEmployeeResponseDto;
        },
        any
      >({
        path: `/employees`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the data for the employee that is logged in.
     *
     * @tags Employees
     * @name EmployeeControllerGetEmployeeMe
     * @summary Fetches the data for currently logged in employee
     * @request GET:/employees/me
     * @secure
     */
    employeeControllerGetEmployeeMe: (params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: GetEmployeeMeResponseDto;
        },
        any
      >({
        path: `/employees/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for login of the given employee.
     *
     * @tags Employees
     * @name EmployeeControllerLoginEmployee
     * @summary Checks employee credentials.
     * @request POST:/employees/login
     * @secure
     */
    employeeControllerLoginEmployee: (
      data: LoginEmployeeRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: LoginEmployeeResponseDto;
        },
        any
      >({
        path: `/employees/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Clears employee http only cookies
     *
     * @tags Employees
     * @name EmployeeControllerLogoutEmployee
     * @summary Logout for the employee.
     * @request POST:/employees/logout
     * @secure
     */
    employeeControllerLogoutEmployee: (params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: LogoutEmployeeResponseDto;
        },
        any
      >({
        path: `/employees/logout`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * @description Used for the retrieval of the data for the facility employees.
     *
     * @tags Employees
     * @name EmployeeControllerGetFacilityEmployees
     * @summary Fetches data for all facility employees.
     * @request GET:/employees/facility/{id}
     * @secure
     */
    employeeControllerGetFacilityEmployees: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetFacilityEmployeesResponseDto[];
        },
        any
      >({
        path: `/employees/facility/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching facility employees paginated data.
     *
     * @tags Employees
     * @name EmployeeControllerGetFacilityEmployeesPaginated
     * @summary Fetches the given facility employees paginated from the database.
     * @request GET:/employees/facility/{id}/paginated
     * @secure
     */
    employeeControllerGetFacilityEmployeesPaginated: (
      id: number,
      query: {
        /** Current page */
        page: number;
        /** Items per page */
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetFacilityEmployeesEmployee[];
          };
        },
        any
      >({
        path: `/employees/facility/${id}/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the data for the employee with given id.
     *
     * @tags Employees
     * @name EmployeeControllerGetEmployeeById
     * @summary Fetches data for employee with given id.
     * @request GET:/employees/{id}
     * @secure
     */
    employeeControllerGetEmployeeById: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetEmployeeByIdResponseDto;
        },
        any
      >({
        path: `/employees/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the editing of the employees.
     *
     * @tags Employees
     * @name EmployeeControllerUpdateEmployee
     * @summary Edit the given employee.
     * @request PUT:/employees/{id}
     * @secure
     */
    employeeControllerUpdateEmployee: (
      id: number,
      data: UpdateEmployeeRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdateEmployeeResponseDto;
        },
        any
      >({
        path: `/employees/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching employee default working hours.
     *
     * @tags Employees
     * @name EmployeeControllerGetEmployeeDefaultWorkingHoursForFacility
     * @summary Fetches the given employee default working hours.
     * @request GET:/employees/{employeeId}/working-hours/{facilityId}/default
     * @secure
     */
    employeeControllerGetEmployeeDefaultWorkingHoursForFacility: (
      employeeId: number,
      facilityId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetEmployeeDefaultWorkingHoursForFacilityResponseDto;
        },
        any
      >({
        path: `/employees/${employeeId}/working-hours/${facilityId}/default`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for updating employee default working hours.
     *
     * @tags Employees
     * @name EmployeeControllerPutEmployeeDefaultWorkingHoursForFacility
     * @summary Updates the given employee default working hours.
     * @request PUT:/employees/{employeeId}/working-hours/{facilityId}/default
     * @secure
     */
    employeeControllerPutEmployeeDefaultWorkingHoursForFacility: (
      employeeId: number,
      facilityId: number,
      data: PutEmployeeDefaultWorkingHoursForFacilityBodyRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PutEmployeeDefaultWorkingHoursForFacilityResponseDto;
        },
        any
      >({
        path: `/employees/${employeeId}/working-hours/${facilityId}/default`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching employee exception working hours.
     *
     * @tags Employees
     * @name EmployeeControllerGetEmployeeExceptionWorkingHoursForFacility
     * @summary Fetches the given employee exception working hours.
     * @request GET:/employees/{employeeId}/working-hours/{facilityId}/exception
     * @secure
     */
    employeeControllerGetEmployeeExceptionWorkingHoursForFacility: (
      employeeId: number,
      facilityId: number,
      query: {
        /** @format date-time */
        date: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetEmployeeExceptionWorkingHoursForFacilityResponseDto;
        },
        any
      >({
        path: `/employees/${employeeId}/working-hours/${facilityId}/exception`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for updating employee exception working hours.
     *
     * @tags Employees
     * @name EmployeeControllerPutEmployeeExceptionWorkingHoursForFacility
     * @summary Updates the given employee exception working hours.
     * @request PUT:/employees/{employeeId}/working-hours/{facilityId}/exception
     * @secure
     */
    employeeControllerPutEmployeeExceptionWorkingHoursForFacility: (
      employeeId: number,
      facilityId: number,
      data: PutEmployeeExceptionWorkingHoursForFacilityBodyRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PutEmployeeExceptionWorkingHoursForFacilityResponseDto;
        },
        any
      >({
        path: `/employees/${employeeId}/working-hours/${facilityId}/exception`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for generating employee image links.
     *
     * @tags Employees
     * @name EmployeeControllerRequestEmployeeImageLinks
     * @summary Generates download and upload link for an employee image to S3.
     * @request POST:/employees/file/links
     * @secure
     */
    employeeControllerRequestEmployeeImageLinks: (
      data: RequestEmployeeImageLinksRequestBodyDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: RequestEmployeeImageLinksResponseDto;
        },
        any
      >({
        path: `/employees/file/links`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  categories = {
    /**
     * @description Used for the creation of the categories.
     *
     * @tags Categories
     * @name CategoryControllerCreateCategory
     * @summary Stores the given category to the database.
     * @request POST:/categories
     * @secure
     */
    categoryControllerCreateCategory: (
      data: CreateCategoryRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateCategoryResponseDto;
        },
        any
      >({
        path: `/categories`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the update of the categories.
     *
     * @tags Categories
     * @name CategoryControllerUpdateCategory
     * @summary Updates the given category.
     * @request PUT:/categories/{id}
     * @secure
     */
    categoryControllerUpdateCategory: (
      id: number,
      data: UpdateCategoryRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdateCategoryResponseDto;
        },
        any
      >({
        path: `/categories/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the deletion of the categories.
     *
     * @tags Categories
     * @name CategoryControllerDeleteCategory
     * @summary Deletes the given category.
     * @request DELETE:/categories/{id}
     * @secure
     */
    categoryControllerDeleteCategory: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: DeleteCategoryResponseDto;
        },
        any
      >({
        path: `/categories/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  roles = {
    /**
     * @description Used for the creation of the roles.
     *
     * @tags Roles
     * @name RoleControllerCreateRole
     * @summary Stores the given role to the database.
     * @request POST:/roles
     * @secure
     */
    roleControllerCreateRole: (
      data: CreateRoleRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateRoleResponseDto;
        },
        any
      >({
        path: `/roles`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching role data.
     *
     * @tags Roles
     * @name RoleControllerGetRole
     * @summary Fetches the given role from the database.
     * @request GET:/roles/{id}
     * @secure
     */
    roleControllerGetRole: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: GetRoleResponseDto;
        },
        any
      >({
        path: `/roles/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for updating role data.
     *
     * @tags Roles
     * @name RoleControllerUpdateRole
     * @summary Updates the given role from the database.
     * @request PUT:/roles/{id}
     * @secure
     */
    roleControllerUpdateRole: (
      id: number,
      data: UpdateRoleBodyRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdateRoleResponseDto;
        },
        any
      >({
        path: `/roles/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for updating role permissions data.
     *
     * @tags Roles
     * @name RoleControllerUpdateRolePermissions
     * @summary Updates the given role permissions from the database.
     * @request PUT:/roles/{id}/permissions
     * @secure
     */
    roleControllerUpdateRolePermissions: (
      id: number,
      data: UpdateRolePermissionsBodyRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdateRolePermissionsResponseDto;
        },
        any
      >({
        path: `/roles/${id}/permissions`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  permissions = {
    /**
     * @description Used for the creation of permissions.
     *
     * @tags Permissions
     * @name PermissionControllerCreatePermission
     * @summary Stores the given permission to the database.
     * @request POST:/permissions
     * @secure
     */
    permissionControllerCreatePermission: (
      data: CreatePermissionRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreatePermissionResponseDto;
        },
        any
      >({
        path: `/permissions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching all permissions data.
     *
     * @tags Permissions
     * @name PermissionControllerGetAllPermissions
     * @summary Fetches the all permissions from the database.
     * @request GET:/permissions
     * @secure
     */
    permissionControllerGetAllPermissions: (params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: GetAllPermissionsResponseDto;
        },
        any
      >({
        path: `/permissions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching permission data.
     *
     * @tags Permissions
     * @name PermissionControllerGetPermission
     * @summary Fetches the given permission from the database.
     * @request GET:/permissions/{id}
     * @secure
     */
    permissionControllerGetPermission: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetPermissionResponseDto;
        },
        any
      >({
        path: `/permissions/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  rooms = {
    /**
     * @description Used for the creation of the rooms.
     *
     * @tags Rooms
     * @name RoomControllerCreateRoom
     * @summary Stores the given room to the database.
     * @request POST:/rooms
     * @secure
     */
    roomControllerCreateRoom: (
      data: CreateRoomRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateRoomResponseDto;
        },
        any
      >({
        path: `/rooms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching room data.
     *
     * @tags Rooms
     * @name RoomControllerGetRoom
     * @summary Fetches the given room from the database.
     * @request GET:/rooms/{id}
     * @secure
     */
    roomControllerGetRoom: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: GetRoomResponseDto;
        },
        any
      >({
        path: `/rooms/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the employees of provided room.
     *
     * @tags Rooms
     * @name RoomControllerGetRoomEmployees
     * @summary Fetches room employees.
     * @request GET:/rooms/{id}/employees
     * @secure
     */
    roomControllerGetRoomEmployees: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: GetRoomEmployeesResponseDto;
        },
        any
      >({
        path: `/rooms/${id}/employees`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the distinct employees of provided rooms.
     *
     * @tags Rooms
     * @name RoomControllerGetMultipleRoomsEmployees
     * @summary Fetches multiple rooms distinct employees.
     * @request POST:/rooms/multiple/employees
     * @secure
     */
    roomControllerGetMultipleRoomsEmployees: (
      data: GetMultipleRoomsEmployeesRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetMultipleRoomsEmployeesResponseDto;
        },
        any
      >({
        path: `/rooms/multiple/employees`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  services = {
    /**
     * @description Used for the creation of the services.
     *
     * @tags Services
     * @name ServiceControllerCreateService
     * @summary Stores the given service to the database.
     * @request POST:/services
     * @secure
     */
    serviceControllerCreateService: (
      data: CreateServiceRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateServiceResponseDto;
        },
        any
      >({
        path: `/services`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the deletion of the services.
     *
     * @tags Services
     * @name ServiceControllerDeleteService
     * @summary Deletes the given service.
     * @request DELETE:/services/{id}
     * @secure
     */
    serviceControllerDeleteService: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: DeleteServiceResponseDto;
        },
        any
      >({
        path: `/services/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the update of the services.
     *
     * @tags Services
     * @name ServiceControllerUpdateService
     * @summary Updates the given service.
     * @request PUT:/services/{id}
     * @secure
     */
    serviceControllerUpdateService: (
      id: number,
      data: UpdateServiceRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdateServiceResponseDto;
        },
        any
      >({
        path: `/services/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching service data.
     *
     * @tags Services
     * @name ServiceControllerGetService
     * @summary Fetches the given service from the database.
     * @request GET:/services/{id}
     * @secure
     */
    serviceControllerGetService: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: GetServiceResponseDto;
        },
        any
      >({
        path: `/services/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching service data related to a facility.
     *
     * @tags Services
     * @name ServiceControllerGetFacilityServices
     * @summary Fetches all services for a given facility id
     * @request GET:/services/facility/{id}
     * @secure
     */
    serviceControllerGetFacilityServices: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetFacilityServicesResponseDto[];
        },
        any
      >({
        path: `/services/facility/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the data for the service employees.
     *
     * @tags Services
     * @name ServiceControllerGetServiceEmployees
     * @summary Fetches data for all service employees.
     * @request GET:/services/{id}/employees
     * @secure
     */
    serviceControllerGetServiceEmployees: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetServiceEmployeesResponseDto[];
        },
        any
      >({
        path: `/services/${id}/employees`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching rooms data related to a service.
     *
     * @tags Services
     * @name ServiceControllerGetServiceRooms
     * @summary Fetches all rooms for a given service
     * @request GET:/services/{id}/rooms
     * @secure
     */
    serviceControllerGetServiceRooms: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetServiceRoomsResponseDto;
        },
        any
      >({
        path: `/services/${id}/rooms`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  coupons = {
    /**
     * @description Used for the creation of the coupon.
     *
     * @tags Coupons
     * @name CouponControllerCreateCoupon
     * @summary Stores the given coupon to the database.
     * @request POST:/coupons
     * @secure
     */
    couponControllerCreateCoupon: (
      data: CreateCouponRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateCouponResponseDto;
        },
        any
      >({
        path: `/coupons`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the deletion of the coupons.
     *
     * @tags Coupons
     * @name CouponControllerDeleteCoupon
     * @summary Deletes the given coupon.
     * @request DELETE:/coupons/{id}
     * @secure
     */
    couponControllerDeleteCoupon: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: DeleteCouponResponseDto;
        },
        any
      >({
        path: `/coupons/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the update of the coupons.
     *
     * @tags Coupons
     * @name CouponControllerUpdateCoupon
     * @summary Updates the given coupon.
     * @request PUT:/coupons/{id}
     * @secure
     */
    couponControllerUpdateCoupon: (
      id: number,
      data: UpdateCouponRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdateCouponResponseDto;
        },
        any
      >({
        path: `/coupons/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching the coupon from the database.
     *
     * @tags Coupons
     * @name CouponControllerGetCoupon
     * @summary Fetches the coupon from the database.
     * @request GET:/coupons/{id}
     * @secure
     */
    couponControllerGetCoupon: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: GetCouponResponseDto;
        },
        any
      >({
        path: `/coupons/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching the patients that used provided coupon.
     *
     * @tags Coupons
     * @name CouponControllerGetPatientsUsedCouponByCouponId
     * @summary Fetches the patients that used provided coupon.
     * @request GET:/coupons/{id}/used/patients
     * @secure
     */
    couponControllerGetPatientsUsedCouponByCouponId: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetPatientsUsedCouponPaginatedResponseDto[];
          };
        },
        any
      >({
        path: `/coupons/${id}/used/patients`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching the patients activated used provided coupon.
     *
     * @tags Coupons
     * @name CouponControllerGetPatientsActivatedCouponByCouponId
     * @summary Fetches the patients that activated provided coupon.
     * @request GET:/coupons/{id}/activated/patients
     * @secure
     */
    couponControllerGetPatientsActivatedCouponByCouponId: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetPatientsActivatedCouponPaginatedResponseDto[];
          };
        },
        any
      >({
        path: `/coupons/${id}/activated/patients`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the creation of coupons used by patients.
     *
     * @tags Coupons
     * @name CouponControllerUseCoupon
     * @summary Stores the coupon that is used by patient to the database.
     * @request POST:/coupons/use
     * @secure
     */
    couponControllerUseCoupon: (
      data: UseCouponRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UseCouponResponseDto;
        },
        any
      >({
        path: `/coupons/use`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching current facility coupons data.
     *
     * @tags Coupons
     * @name CouponControllerGetCurrentCouponsForFacilityPaginated
     * @summary Fetches the given facility current coupons from the database.
     * @request GET:/coupons/current/facilities/{id}/paginated
     * @secure
     */
    couponControllerGetCurrentCouponsForFacilityPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetCurrentCouponsForFacilityResponseDto[];
          };
        },
        any
      >({
        path: `/coupons/current/facilities/${id}/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching upcoming facility coupons data.
     *
     * @tags Coupons
     * @name CouponControllerGetUpcomingCouponsForFacilityPaginated
     * @summary Fetches the given facility upcoming coupons from the database.
     * @request GET:/coupons/upcoming/facilities/{id}/paginated
     * @secure
     */
    couponControllerGetUpcomingCouponsForFacilityPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetUpcomingCouponsForFacilityResponseDto[];
          };
        },
        any
      >({
        path: `/coupons/upcoming/facilities/${id}/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching current facility coupons data with information about activation by current patient.
     *
     * @tags Coupons
     * @name CouponControllerGetCurrentCouponsForPatientByFacilityId
     * @summary Fetches the given facility current coupons with information about activation by current patient from the database.
     * @request GET:/coupons/current/facilities/{id}/mobile
     * @secure
     */
    couponControllerGetCurrentCouponsForPatientByFacilityId: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetCurrentCouponsForPatientByFacilityIdResponseDto;
        },
        any
      >({
        path: `/coupons/current/facilities/${id}/mobile`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching past facility coupons data.
     *
     * @tags Coupons
     * @name CouponControllerGetPastCouponsForFacilityPaginated
     * @summary Fetches the given facility past coupons from the database.
     * @request GET:/coupons/past/facilities/{id}/paginated
     * @secure
     */
    couponControllerGetPastCouponsForFacilityPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetPastCouponsForFacilityResponseDto[];
          };
        },
        any
      >({
        path: `/coupons/past/facilities/${id}/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for generating coupon image links.
     *
     * @tags Coupons
     * @name CouponControllerRequestCouponImageLinks
     * @summary Generates download and upload link for a coupon image to S3.
     * @request POST:/coupons/file/links
     * @secure
     */
    couponControllerRequestCouponImageLinks: (
      data: RequestCouponImageLinksRequestBodyDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: RequestNewsImageLinksResponseDto;
        },
        any
      >({
        path: `/coupons/file/links`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the deactivation of the coupons.
     *
     * @tags Coupons
     * @name CouponControllerDeactivateCoupon
     * @summary Deactivates the given coupon.
     * @request PUT:/coupons/deactivate/{id}
     * @secure
     */
    couponControllerDeactivateCoupon: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: DeactivateCouponResponseDto;
        },
        any
      >({
        path: `/coupons/deactivate/${id}`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  awards = {
    /**
     * @description Used for the creation of the award.
     *
     * @tags Awards
     * @name AwardControllerCreateAward
     * @summary Stores the given award to the database.
     * @request POST:/awards
     * @secure
     */
    awardControllerCreateAward: (
      data: CreateAwardRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateAwardResponseDto;
        },
        any
      >({
        path: `/awards`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the update of the awards.
     *
     * @tags Awards
     * @name AwardControllerUpdateAward
     * @summary Updates the given award.
     * @request PUT:/awards/{id}
     * @secure
     */
    awardControllerUpdateAward: (
      id: number,
      data: UpdateAwardRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: UpdateAwardResponseDto;
        },
        any
      >({
        path: `/awards/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the deletion of the awards.
     *
     * @tags Awards
     * @name AwardControllerDeleteAward
     * @summary Deletes the given award.
     * @request DELETE:/awards/{id}
     * @secure
     */
    awardControllerDeleteAward: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: DeleteAwardResponseDto;
        },
        any
      >({
        path: `/awards/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching facility awards data.
     *
     * @tags Awards
     * @name AwardControllerGetCurrentCouponsForFacilityPaginated
     * @summary Fetches the given facility awards from the database.
     * @request GET:/awards/facilities/{id}/paginated
     * @secure
     */
    awardControllerGetCurrentCouponsForFacilityPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetAwardsForFacilityResponseDto[];
          };
        },
        any
      >({
        path: `/awards/facilities/${id}/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  appointments = {
    /**
     * @description Used for the retrieval of the appointment info by id.
     *
     * @tags Appointments
     * @name AppointmentControllerGetAppointmentInfo
     * @summary Fetches appointment info.
     * @request GET:/appointments/{id}
     * @secure
     */
    appointmentControllerGetAppointmentInfo: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetAppointmentInfoResponseDto;
        },
        any
      >({
        path: `/appointments/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    appointmentControllerGetAppointmentDoneDetails: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetAppointmentDoneDetailsResponseDto;
        },
        any
      >({
        path: `/appointments/done/${id}/details`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the scheduled appointment details by id.
     *
     * @tags Appointments
     * @name AppointmentControllerGetAppointmentScheduledDetails
     * @summary Fetches scheduled appointment details.
     * @request GET:/appointments/scheduled/{id}/details
     * @secure
     */

    appointmentControllerGetAppointmentScheduledDetails: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetAppointmentScheduledDetailsResponseDto;
        },
        any
      >({
        path: `/appointments/scheduled/${id}/details`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the service appointments with pagination.
     *
     * @tags Appointments
     * @name AppointmentControllerGetServiceAppointmentsPaginated
     * @summary Fetches paginated service appointments.
     * @request GET:/appointments/service/{id}/paginated
     * @secure
     */
    appointmentControllerGetServiceAppointmentsPaginated: (
      id: number,
      query: {
        /** Current page */
        page: number;
        /** Items per page */
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetServiceAppointmentsPaginatedItem[];
          };
        },
        any
      >({
        path: `/appointments/service/${id}/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the service history appointments with pagination.
     *
     * @tags Appointments
     * @name AppointmentControllerGetServiceHistoryAppointmentsPaginated
     * @summary Fetches paginated service history appointments.
     * @request GET:/appointments/service/{id}/history/paginated
     * @secure
     */
    appointmentControllerGetServiceHistoryAppointmentsPaginated: (
      id: number,
      query: {
        /** Current page */
        page: number;
        /** Items per page */
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetServiceHistoryAppointmentsPaginatedItem[];
          };
        },
        any
      >({
        path: `/appointments/service/${id}/history/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the patient appointments with pagination.
     *
     * @tags Appointments
     * @name AppointmentControllerGetPatientAppointmentsPaginated
     * @summary Fetches paginated patient appointments.
     * @request GET:/appointments/patient/{id}/paginated
     * @secure
     */
    appointmentControllerGetPatientAppointmentsPaginated: (
      id: number,
      query: {
        /** Current page */
        page: number;
        /** Items per page */
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetPatientAppointmentsPaginatedItem[];
          };
        },
        any
      >({
        path: `/appointments/patient/${id}/arranged/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the patient appointments with pagination.
     *
     * @tags Appointments
     * @name AppointmentControllerGetPatientOngoingAppointmentsPaginated
     * @summary Fetches paginated patient appointments.
     * @request GET:/appointments/patient/{id}/ongoing/paginated
     * @secure
     */
    appointmentControllerGetPatientOngoingAppointmentsPaginated: (
      id: number,
      query: {
        /** Current page */
        page: number;
        /** Items per page */
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetPatientAppointmentsPaginatedItem[];
          };
        },
        any
      >({
        path: `/appointments/patient/${id}/ongoing/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the patient history appointments with pagination.
     *
     * @tags Appointments
     * @name AppointmentControllerGetPatientHistoryAppointmentsPaginated
     * @summary Fetches paginated patient history appointments.
     * @request GET:/appointments/patient/{id}/history/paginated
     * @secure
     */
    appointmentControllerGetPatientHistoryAppointmentsPaginated: (
      id: number,
      query: {
        /** Current page */
        page: number;
        /** Items per page */
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetPatientHistoryAppointmentsPaginatedItem[];
          };
        },
        any
      >({
        path: `/appointments/patient/${id}/history/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the calculation fo empty appointment slots for provided constraints.
     *
     * @tags Appointments
     * @name AppointmentControllerGetEmptyAppointmentSlotsWeb
     * @summary Fetches empty appointment slots.
     * @request GET:/appointments/slots/empty/web
     * @secure
     */
    appointmentControllerGetEmptyAppointmentSlotsWeb: (
      query: {
        /** Employee id */
        employeeId: number;
        /** Service id */
        serviceId: number;
        /** Room id */
        roomId: number;
        /**
         * Appointment date
         * @format date-time
         */
        date: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetEmptyAppointmentSlotsWebResponseDto;
        },
        any
      >({
        path: `/appointments/slots/empty/web`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of rooms used by service and employee.
     *
     * @tags Appointments
     * @name AppointmentControllerGetPossibleRoomsForEmployeeAndService
     * @summary Fetches rooms that are used both for service and by employee provided.
     * @request GET:/appointments/rooms/possible
     * @secure
     */
    appointmentControllerGetPossibleRoomsForEmployeeAndService: (
      query: {
        /** Employee id */
        employeeId: number;
        /** Service id */
        serviceId: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetPossibleRoomsForEmployeeAndServiceResponseDto[];
        },
        any
      >({
        path: `/appointments/rooms/possible`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the creation of the appointments.
     *
     * @tags Appointments
     * @name AppointmentControllerCreateAppointment
     * @summary Stores the given appointment to the database.
     * @request POST:/appointments
     * @secure
     */
    appointmentControllerCreateAppointment: (
      data: CreateAppointmentRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateAppointmentResponseDto;
        },
        any
      >({
        path: `/appointments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the calculation fo empty appointment slots for provided constraints in mobile app.
     *
     * @tags Appointments
     * @name AppointmentControllerGetEmptyAppointmentSlotsMobile
     * @summary Fetches empty appointment slots.
     * @request GET:/appointments/slots/empty/mobile
     * @secure
     */
    appointmentControllerGetEmptyAppointmentSlotsMobile: (
      query: {
        /** Service id */
        serviceId: number;
        /**
         * Appointment date
         * @format date-time
         */
        date: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetEmptyAppointmentSlotsMobileResponseDto;
        },
        any
      >({
        path: `/appointments/slots/empty/mobile`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the retrieval of the employee appointments.
     *
     * @tags Appointments
     * @name AppointmentControllerGetEmployeeAppointments
     * @summary Fetches employee appointments.
     * @request GET:/appointments/employees/{id}
     * @secure
     */
    appointmentControllerGetEmployeeAppointments: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetEmployeeAppointmentsItem;
        },
        any
      >({
        path: `/appointments/employees/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for reporting the time a patient arrived to an appointment.
     *
     * @tags Appointments
     * @name AppointmentControllerReportArrivalAppointment
     * @summary Updates appointment arrived at date.
     * @request PUT:/appointments/{id}/report-arrival
     * @secure
     */
    appointmentControllerReportArrivalAppointment: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: ReportArrivalAppointmentResponseDto;
        },
        any
      >({
        path: `/appointments/${id}/report-arrival`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching the appointments for the provided employee and week.
     *
     * @tags Appointments
     * @name AppointmentControllerGetDepartmentRoomsWithAppointmentsByDate
     * @summary Fetches employee appointments for the provided week.
     * @request GET:/appointments/{id}/employee/appointments/week
     * @secure
     */
    appointmentControllerGetDepartmentRoomsWithAppointmentsByDate: (
      id: number,
      query: {
        /**
         * Week start date
         * @format date-time
         */
        weekStartDate: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetEmployeeWeekAppointmentsResponseDto;
        },
        any
      >({
        path: `/appointments/${id}/employee/appointments/week`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for generating appointment document links.
     *
     * @tags Appointments
     * @name AppointmentControllerRequestAppointmentDocumentLinks
     * @summary Generates download and upload link for an appointment document to S3.
     * @request POST:/appointments/file/links
     * @secure
     */
    appointmentControllerRequestAppointmentDocumentLinks: (
      data: RequestAppointmentDocumentLinksRequestBodyDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: RequestAppointmentDocumentLinksResponseDto;
        },
        any
      >({
        path: `/appointments/file/links`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  medicalRecords = {
    /**
     * @description Used for the creation of the medical records.
     *
     * @tags MedicalRecords
     * @name MedicalRecordControllerCreateMedicalRecord
     * @summary Stores the given medical record to the database.
     * @request POST:/medical-records
     * @secure
     */
    medicalRecordControllerCreateMedicalRecord: (
      data: CreateMedicalRecordRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateMedicalRecordResponseDto;
        },
        any
      >({
        path: `/medical-records`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching medical record data.
     *
     * @tags MedicalRecords
     * @name MedicalRecordControllerGetMedicalRecord
     * @summary Fetches the given medical record from the database.
     * @request GET:/medical-records/{id}
     * @secure
     */
    medicalRecordControllerGetMedicalRecord: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetMedicalRecordResponseDto;
        },
        any
      >({
        path: `/medical-records/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    medicalRecordControllerRequestMedicalRecordDocumentLinks: (
      data: RequestMedicalRecordDocumentLinksRequestBodyDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: RequestMedicalRecordDocumentLinksResponseDto;
        },
        any
      >({
        path: `/medical-records/file/links`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };

  news = {
    /**
     * @description Used for the creation of the news.
     *
     * @tags News
     * @name NewsControllerCreateNews
     * @summary Stores the given news to the database.
     * @request POST:/news
     * @secure
     */
    newsControllerCreateNews: (
      data: CreateNewsRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: CreateNewsResponseDto;
        },
        any
      >({
        path: `/news`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching news data.
     *
     * @tags News
     * @name NewsControllerGetNews
     * @summary Fetches the given news from the database.
     * @request GET:/news/{id}
     * @secure
     */
    newsControllerGetNews: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: GetNewsResponseDto;
        },
        any
      >({
        path: `/news/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the update of the news.
     *
     * @tags News
     * @name NewsControllerEditNews
     * @summary Updates the given news in the database.
     * @request PUT:/news/{id}
     * @secure
     */
    newsControllerEditNews: (
      id: number,
      data: EditNewsRequestBodyDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: EditNewsResponseDto;
        },
        any
      >({
        path: `/news/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for the deletion of the news.
     *
     * @tags News
     * @name NewsControllerDeleteNews
     * @summary Deletes the given news.
     * @request DELETE:/news/{id}
     * @secure
     */
    newsControllerDeleteNews: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: DeleteNewsResponseDto;
        },
        any
      >({
        path: `/news/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching recent news data for given organisation for mobile preview.
     *
     * @tags News
     * @name NewsControllerGetOrganisationMobilePreviewNews
     * @summary Fetches the recent news for given organisation.
     * @request GET:/news/organisation/{id}/mobile/preview
     * @secure
     */
    newsControllerGetOrganisationMobilePreviewNews: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: GetOrganisationMobilePreviewNewsResponseDto;
        },
        any
      >({
        path: `/news/organisation/${id}/mobile/preview`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching all active news data for given organisation for mobile.
     *
     * @tags News
     * @name NewsControllerGetOrganisationMobileAllNewsPaginated
     * @summary Fetches all active news for given organisation.
     * @request GET:/news/organisation/{id}/mobile/all/paginated
     * @secure
     */
    newsControllerGetOrganisationMobileAllNewsPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetOrganisationMobileAllNewsPaginatedResponseDto[];
          };
        },
        any
      >({
        path: `/news/organisation/${id}/mobile/all/paginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching all active news data for given facility paginated.
     *
     * @tags News
     * @name NewsControllerGetActiveFacilityNewsPaginated
     * @summary Fetches all active news for given facility.
     * @request GET:/news/facility/{id}/active
     * @secure
     */
    newsControllerGetActiveFacilityNewsPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetActiveFacilityNewsPaginatedResponseDto[];
          };
        },
        any
      >({
        path: `/news/facility/${id}/active`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching all upcoming news data for given facility paginated.
     *
     * @tags News
     * @name NewsControllerGetUpcomingFacilityNewsPaginated
     * @summary Fetches all upcoming news for given facility.
     * @request GET:/news/facility/{id}/upcoming
     * @secure
     */
    newsControllerGetUpcomingFacilityNewsPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetUpcomingFacilityNewsPaginatedResponseDto[];
          };
        },
        any
      >({
        path: `/news/facility/${id}/upcoming`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching all draft news data for given facility paginated.
     *
     * @tags News
     * @name NewsControllerGetDraftFacilityNewsPaginated
     * @summary Fetches all draft news for given facility.
     * @request GET:/news/facility/{id}/draft
     * @secure
     */
    newsControllerGetDraftFacilityNewsPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetDraftFacilityNewsPaginatedResponseDto[];
          };
        },
        any
      >({
        path: `/news/facility/${id}/draft`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for fetching all past news data for given facility paginated.
     *
     * @tags News
     * @name NewsControllerGetPastFacilityNewsPaginated
     * @summary Fetches all past news for given facility.
     * @request GET:/news/facility/{id}/past
     * @secure
     */
    newsControllerGetPastFacilityNewsPaginated: (
      id: number,
      query: {
        page: number;
        itemsPerPage: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: PaginatedPayload & {
            items?: GetPastFacilityNewsPaginatedResponseDto[];
          };
        },
        any
      >({
        path: `/news/facility/${id}/past`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Used for generating news image links.
     *
     * @tags News
     * @name NewsControllerRequestNewsImageLinks
     * @summary Generates download and upload link for a news image to S3.
     * @request POST:/news/file/links
     * @secure
     */
    newsControllerRequestNewsImageLinks: (
      data: RequestNewsImageLinksRequestBodyDto,
      params: RequestParams = {}
    ) =>
      this.request<
        HttpResponse & {
          payload?: RequestNewsImageLinksResponseDto;
        },
        any
      >({
        path: `/news/file/links`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Sets news as not draft and enables publishing.
     *
     * @tags News
     * @name NewsControllerPublishDraftNews
     * @summary Publishes draft news.
     * @request PUT:/news/{id}/draft/publish
     * @secure
     */
    newsControllerPublishDraftNews: (id: number, params: RequestParams = {}) =>
      this.request<
        HttpResponse & {
          payload?: PublishDraftNewsResponseDto;
        },
        any
      >({
        path: `/news/${id}/draft/publish`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
