import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";

export const useEmployees = () => {
  const {
    employees: { employeeControllerCreateEmployee },
  } = new Api();
  const { mutateAsync: createEmployee, isLoading: isCreatingEmployee } =
    useCreate(employeeControllerCreateEmployee, ["employees"]);

  return {
    createEmployee,
    isCreatingEmployee,
  };
};
