import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { RiAddFill } from "react-icons/ri";
import tw from "twin.macro";
import { useFacilityDepartments } from "../../../api/hooks/facilities/useFacilityDepartments";
import { useMe } from "../../../api/hooks/user/useMe";
import { EmptyState } from "../../../components/empty/EmptyState";
import { Typography } from "../../../ui/Typograhy";
import { DepartmentCard } from "../DepartmentCard";
/** @jsxImportSource @emotion/react */

export const DepartmentsTab = ({
  setIsNewDepartmentModalOpen,
}: {
  setIsNewDepartmentModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    facilityDepartments: { data: facilityDepartments, isFetched },
  } = useFacilityDepartments();
  const { t } = useTranslation();
  const { me } = useMe();
  const isDoctor = false;

  const tempDepartments =
    facilityDepartments?.departments.filter((de) =>
      isDoctor ? (me?.departmentIds || []).includes(de.id.toString()) : true
    ) || [];

  const rest =
    tempDepartments.length > 3
      ? tempDepartments.length % 3 === 1
        ? 2
        : tempDepartments.length % 3 === 0
        ? 0
        : 1
      : 3 - tempDepartments.length;

  if ((facilityDepartments?.departments || []).length === 0 && isFetched) {
    return (
      <EmptyState
        title={t("noDepartments")}
        buttonText={t("addNewDepartment")}
        subtitle={t("addNewDepartment")}
        buttonIcon={RiAddFill}
        onButtonClick={() => setIsNewDepartmentModalOpen(true)}
        containerCss={[tw`min-h-[calc(100vh - 10rem)]`]}
        hasButton={me.role?.permissions?.DEPARTMENTS?.CREATE}
      />
    );
  }

  return (
    <div css={[tw`mt-8 max-w-screen-xl mx-auto`]}>
      {/* <Typography.BodyXSmall
    containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
  >
    {t("mostUsedServices")}
  </Typography.BodyXSmall>
  <div css={[tw`gap-2 flex mb-8`]}>
    {services.map((s) => {
      return <Chip value={s} type="table" />;
    })}
  </div> */}
      <Typography.BodyXSmall
        containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
      >
        {t("departments")}
      </Typography.BodyXSmall>
      <div css={[tw`flex flex-wrap gap-4 justify-between`]}>
        {[...tempDepartments, ...Array(rest).fill({ name: "" })].map((d) => (
          <DepartmentCard key={d.id} {...d} />
        ))}
      </div>
    </div>
  );
};
