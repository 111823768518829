import { useQuery } from "@tanstack/react-query";
import { Api } from "../../api";
import { serviceRooms } from "../../query-keys";

export const useServiceRooms = ({ id }: { id?: number }) => {
  const {
    services: { serviceControllerGetServiceRooms },
  } = new Api();

  const rooms = useQuery(
    serviceRooms(id),
    async () => {
      const { data } = await serviceControllerGetServiceRooms(id!);

      if (data.isSuccessful) {
        return data.payload?.rooms.map((c) => ({
          ...c,
          serviceId: data.payload?.id,
        }));
      }
      return [];
    },
    {
      enabled: !!id,
    }
  );

  return {
    rooms,
  };
};
