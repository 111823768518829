import "twin.macro";

import { Maybe } from "../../../../types";
import { TableInstance } from "react-table";
import tw from "twin.macro";

/** @jsxImportSource @emotion/react */

interface IAsyncDataTableBodyProps<T> {
  tableInstance: TableInstance;
  onRowClick?: (data: T, index: number) => void;
  activeRowIndex: Maybe<number>;
}

export const AsyncDataTableBody = (props: IAsyncDataTableBodyProps<any>) => {
  const { tableInstance, activeRowIndex, onRowClick } = props;
  const { getTableBodyProps, prepareRow, page } = tableInstance;
  return (
    <tbody {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row);
        const isSelected =
          //@ts-ignore
          activeRowIndex === row.original?.id!;
        return (
          <tr
            {...row.getRowProps()}
            css={[
              tw`rounded-none cursor-pointer min-w-0 border-b border-b-gray-100`,
              tw`flex justify-center font-500`,
              tw`hover:(bg-primary-50) bg-white`,
              isSelected && tw`bg-primary-50 hover:(bg-primary-50)`,
            ]}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRowClick && onRowClick(row.original, row.index);
            }}
          >
            <div
              css={[
                tw`max-w-screen-xl w-full`,
                tw`flex justify-between`,
                tw`hover:(bg-primary-50) bg-white`,
                isSelected && tw`bg-primary-50 font-500 hover:(bg-primary-50)`,
              ]}
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    css={[
                      tw`bg-inherit flex pl-4 pr-4 py-4 text-left font-normal items-center`,
                      //@ts-ignore
                      cell.column.align === "left" && tw`justify-start`,
                      //@ts-ignore
                      cell.column.align === "right" && tw`justify-end`,
                    ]}
                  >
                    {/* @ts-ignore */}
                    {cell.column.cell(cell.row.original)}
                  </td>
                );
              })}
            </div>
          </tr>
        );
      })}
    </tbody>
  );
};
