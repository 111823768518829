import { ReactComponent as LogoFull } from "./assets/logo_full.svg";
import { ReactComponent as LogoSmall } from "./assets/logo_small.svg";
import { ReactComponent as LogoColor } from "./assets/logo_color.svg";
import { ReactComponent as LogoWhite } from "./assets/logo_white.svg";
import { ReactComponent as LogoMonotone } from "./assets/logo-monotone.svg";
import { ReactComponent as LogoMonotoneNegative } from "./assets/logo-monotone-negative.svg";
import { ReactComponent as LogoColored } from "./assets/logo-colored.svg";
import { ReactComponent as LogoColoredNegative } from "./assets/logo-colored-negative.svg";
import { ReactComponent as LoginImage } from "./assets/login_image.svg";
import { ReactComponent as GeneralErrorImage } from "./assets/general_error.svg";
import { ReactComponent as WifiErrorImage } from "./assets/wifi_error.svg";
import { ReactComponent as EmptyStateImage } from "./assets/no-users.svg";
import { ReactComponent as FacilityTestImage } from "./assets/facility-test-image.svg";
import { ReactComponent as DepartmentPlaceholder } from "./assets/department-placeholder.svg";
import { ReactComponent as BacteriaIcon } from "./assets/department-icons/bacteria-icon.svg";
import { ReactComponent as CartonIcon } from "./assets/department-icons/carton-icon.svg";
import { ReactComponent as CaughIcon } from "./assets/department-icons/caugh-icon.svg";
import { ReactComponent as GinecologyIcon } from "./assets/department-icons/ginecology-icon.svg";
import { ReactComponent as HeartIcon } from "./assets/department-icons/heart-icon.svg";
import { ReactComponent as PillIcon } from "./assets/department-icons/pill-icon.svg";
import { ReactComponent as PillRoundIcon } from "./assets/department-icons/pill-round-icon.svg";
import { ReactComponent as ReportIcon } from "./assets/department-icons/report-icon.svg";
import { ReactComponent as StetoscopeIcon } from "./assets/department-icons/stetoscope-icon.svg";
import { ReactComponent as SurgeryIcon } from "./assets/department-icons/surgery-icon.svg";
import { ReactComponent as DragMoveLine } from "./assets/drag-move-line.svg";
import { ReactComponent as Paperclip } from "./assets/paperclip.svg";
import { ReactComponent as ScannerBoundary } from "./assets/scanner-boundary.svg";
import { ReactComponent as NoPermissionsImage } from "./assets/no-permission-image.svg";

export const Assets = {
  ScannerBoundary,
  LogoFull,
  LogoSmall,
  LogoColor,
  LoginImage,
  NoPermissionsImage,
  GeneralErrorImage,
  WifiErrorImage,
  LogoWhite,
  LogoColored,
  LogoColoredNegative,
  LogoMonotone,
  LogoMonotoneNegative,
  EmptyStateImage,
  FacilityTestImage,
  DepartmentPlaceholder,
  BacteriaIcon,
  CartonIcon,
  CaughIcon,
  GinecologyIcon,
  HeartIcon,
  PillIcon,
  PillRoundIcon,
  ReportIcon,
  StetoscopeIcon,
  SurgeryIcon,
  DragMoveLine,
  Paperclip,
};
