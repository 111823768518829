import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdEuroSymbol } from "react-icons/md";
import { RiAddCircleFill } from "react-icons/ri";
import tw from "twin.macro";
import * as yup from "yup";
import {
  CreateServiceRequestDto,
  GetServiceResponseDto,
} from "../../../api/api";
import { useCategories } from "../../../api/hooks/categories/useCategories";
import { useDepartment } from "../../../api/hooks/departments/useDepartment";
import { useMultipleRooms } from "../../../api/hooks/rooms/useMultipleRooms";
import { useService } from "../../../api/hooks/services/useService";
import { useServices } from "../../../api/hooks/services/useServices";
import { TextInputModal } from "../../../components/modals/TextInputModal";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { IModalProps, Modal } from "../../../ui/popups/Modal";
import { Typography } from "../../../ui/Typograhy";
import { errorMessages } from "../../../utils";
/** @jsxImportSource @emotion/react */

const schema: yup.SchemaOf<
  CreateServiceRequestDto & { showInMobile: boolean }
> = yup.object().shape({
  categoryId: yup
    .number()
    .typeError(errorMessages.number)
    .required(errorMessages.required),
  name: yup.string().required(errorMessages.required),
  price: yup
    .number()
    .required(errorMessages.required)
    .min(1, errorMessages.numberMin)
    .typeError(errorMessages.number),
  duration: yup
    .number()
    .typeError(errorMessages.number)
    .min(1, errorMessages.numberMin)
    .required(errorMessages.required),
  roomIds: yup
    .array()
    .required(errorMessages.required)
    .min(1, errorMessages.min),
  employeeIds: yup
    .array()
    .required(errorMessages.required)
    .min(1, errorMessages.min),
  description: yup.string().nullable().required(errorMessages.required),
  points: yup
    .number()
    .typeError(errorMessages.number)
    .required(errorMessages.required),
  showInMobile: yup.boolean().required(errorMessages.required),
  availableInFacilities: yup
    .array()
    .of(yup.object().required(errorMessages.required)),
});

const stepDescriptions = ["enterServiceData", "enterServiceData"];

const triggerByStep = [
  [
    "name",
    "price",
    "duration",
    "roomIds",
    "employeeIds",
    "description",
    "categoryId",
  ],
  ["points", "availableInFacilities", "showInMobile"],
];

type IForm = yup.InferType<typeof schema>;

export const NewServiceModal = ({
  open,
  onClose,
  departmentId,
  categoryId,
  service,
}: Omit<IModalProps, "label" | "footerChildren"> & {
  departmentId: number;
  categoryId?: number;
  service?:
    | (GetServiceResponseDto & { rooms: number[]; employees: number[] })
    | null;
}) => {
  const [isNewCategoryModalOpen, setIsNewCategoryModalOpen] = useState(false);

  const { createService } = useServices();
  const { updateService } = useService({ id: service?.id });
  const { createCategory } = useCategories();
  const { categories, rooms } = useDepartment({
    id: departmentId,
  });

  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      name: service?.name,
      price: service?.price,
      duration: service?.duration,
      roomIds: service?.rooms || [],
      description: service?.description,
      employeeIds: service?.employees || [],
      categoryId,
      points: service?.points || 100,
      showInMobile: true,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const roomIdsSelected = methods.watch("roomIds");

  const { roomsEmployees } = useMultipleRooms(roomIdsSelected || []);

  const [step, setStep] = useState(0);
  const { t } = useTranslation();
  const isEdit = !!service?.id;
  const parsedCategories = (categories.data?.categories || []).map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const parsedEmployees = (roomsEmployees.data || []).map((e) => ({
    value: e.id,
    label: `${e.firstName} ${e.lastName}`,
  }));
  const parsedRooms = (rooms.data?.rooms || []).map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const incrementStep = useCallback(async () => {
    //@ts-ignore
    const isCorrect = await methods.trigger(triggerByStep[step]);

    if (isCorrect) {
      setStep((prevStep) =>
        Math.min(prevStep + 1, stepDescriptions.length - 1)
      );
    }
  }, [methods, step]);

  const decrementStep = useCallback(() => {
    setStep((prevStep) => Math.max(prevStep - 1, 0));
  }, []);

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      if (!isEdit) {
        await createService({
          requestData: {
            ...values,
            employeeIds: values.employeeIds || [],
            roomIds: values.roomIds || [],
            //@ts-ignore
            departmentId,
          },
        });
      } else {
        await updateService({
          requestData: {
            ...values,
            employeeIds: values.employeeIds || [],
            roomIds: values.roomIds || [],
            //@ts-ignore
            departmentId,
          },
        });
      }
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  const handleAddCategory: (values: { categoryName: string }) => void = async (
    values
  ) => {
    if (values.categoryName) {
      await createCategory({
        requestData: {
          name: values.categoryName,
          departmentId: Number(departmentId)!,
        },
      });
    }
  };

  const isLastStep = step === stepDescriptions.length - 1;

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={
          isEdit ? (t("editService") as string) : (t("addNewService") as string)
        }
        footerChildren={
          <div css={[tw`flex justify-between gap-3`]}>
            <Button.Outlined
              containerCss={[tw`flex-1`]}
              onClick={isLastStep ? decrementStep : onClose}
            >
              {isLastStep ? t("back") : t("quit")}
            </Button.Outlined>
            <Button.Contained
              containerCss={[tw`flex-5`]}
              onClick={isLastStep ? onSubmit : incrementStep}
            >
              {isLastStep
                ? isEdit
                  ? t("updateService")
                  : t("addService")
                : t("next")}
            </Button.Contained>
          </div>
        }
      >
        <Typography.BodyMedium containerCss={[tw`mb-6`]}>
          {t("step")} {step + 1}.{" "}
          <Typography.BodyMedium containerCss={[tw`font-700 inline`]}>
            {t(stepDescriptions[step])}
          </Typography.BodyMedium>
        </Typography.BodyMedium>
        {step === 0 && (
          <div>
            <Form.TextInput.Outlined
              name="name"
              required
              label={t("serviceName") as string}
              placeholder={t("serviceNamePlaceholder") as string}
            />
            <Form.Select
              options={parsedCategories}
              name="categoryId"
              required
              shouldOnlySelectValue
              footer={
                <div css={[tw`bg-gray-100 -mb-1 rounded-b-md p-3`]}>
                  <Button.Outlined
                    containerCss={[tw`w-full`]}
                    lead={RiAddCircleFill}
                    onClick={() => setIsNewCategoryModalOpen(true)}
                  >
                    {t("addNewCategory")}
                  </Button.Outlined>
                </div>
              }
              label={t("category") as string}
              placeholder={t("selectCategoryOrCreateNewOne") as string}
            />
            <div css={[tw`flex gap-4`]}>
              <Form.TextInput.Outlined
                required
                lead={MdEuroSymbol}
                type="number"
                label={t("servicePrice") as string}
                placeholder={t("servicePricePlaceholder") as string}
                name="price"
                trail={() => (
                  <Typography.BodySmall containerCss={[tw`mr-2 text-gray-200`]}>
                    EUR
                  </Typography.BodySmall>
                )}
              />
              <Form.TextInput.Outlined
                required
                type="number"
                label={t("duration") as string}
                placeholder={t("durationPlaceholder") as string}
                name="duration"
                trail={() => (
                  <Typography.BodySmall containerCss={[tw`mr-2 text-gray-200`]}>
                    min
                  </Typography.BodySmall>
                )}
              />
            </div>
            <div css={[tw`flex gap-4`]}>
              <Form.MultiSelect
                options={parsedRooms || []}
                name="roomIds"
                required
                shouldOnlySelectValue
                label={t("room") as string}
                placeholder={t("roomPlaceholder") as string}
              />
              {roomIdsSelected && roomIdsSelected?.length > 0 && (
                <Form.MultiSelect
                  name="employeeIds"
                  options={parsedEmployees || []}
                  required
                  shouldOnlySelectValue
                  label={t("doctor") as string}
                  placeholder={t("doctorPlaceholder") as string}
                />
              )}
            </div>
            <Form.TextArea.Outlined
              name="description"
              required
              rows={5}
              label={t("serviceDescription") as string}
              placeholder={t("serviceDescriptionPlaceholder") as string}
            />
          </div>
        )}
        {step === 1 && (
          <div>
            <div css={[tw`flex items-center justify-between w-full mb-6`]}>
              <div>
                <Typography.BodyXSmall>
                  {t("showServiceInMobileApp")}
                </Typography.BodyXSmall>
                <Typography.BodySmall>
                  {t("showServiceInMobileAppDescription")}
                </Typography.BodySmall>
              </div>
              <Form.Switch
                name="showInMobile"
                containerCss={[tw`flex-none w-fit flex items-center`]}
              />
            </div>
          </div>
        )}
        <TextInputModal
          label={t("addNewCategory")}
          buttonTitle={t("addCategory")}
          placeholderText={t("categoryPlaceholder")}
          handleSubmit={handleAddCategory}
          open={isNewCategoryModalOpen}
          onClose={() => setIsNewCategoryModalOpen(false)}
          name={"categoryName"}
        />
      </Modal>
    </FormProvider>
  );
};
