import { Accept, useDropzone } from "react-dropzone";

import { useCallback, useState } from "react";
import { RiImageAddLine } from "react-icons/ri";
import tw from "twin.macro";
import { IComponentBaseProps } from "../types";
import { Typography } from "./Typograhy";

/** @jsxImportSource @emotion/react */

interface IDropzoneProps extends IComponentBaseProps {
  accept?: Accept;
  multiple?: boolean;
  onUpload: (files: File[]) => void;
  title?: string;
  subtitle?: string;
  maxFileSize?: number;
}

export const Dropzone = (props: IDropzoneProps) => {
  const [files, setFiles] = useState<File[] | null>(null);
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(acceptedFiles);
      props.onUpload(acceptedFiles);
    },
    [props]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.accept,
    multiple: props.multiple,
  });

  const filenames = (files ?? []).map((f) => f.name).join(", ");

  return (
    <div
      {...getRootProps()}
      css={[
        tw`cursor-pointer border-2 border-gray-300 border-dashed rounded-md bg-transparent p-20`,
        tw`hover:(text-primary-100 border-primary-100)`,
        tw`focus-visible:(text-primary-100 border-primary-100 border-dashed outline-none)`,
        isDragActive && tw`border-primary-100 text-primary-100`,
        props.containerCss,
      ]}
    >
      <input {...getInputProps()} />
      <div css={[tw`flex flex-col items-center gap-y-2`]}>
        <RiImageAddLine css={[tw`h-12 w-12 text-gray-400`]} />
        <Typography.BodySmall containerCss={[tw`text-gray-600`]}>
          {props.title || "Click or drag file in this area to upload"}
        </Typography.BodySmall>
        <Typography.BodyXSmall containerCss={[tw`text-gray-500`]}>
          {props.subtitle || "All files"}
        </Typography.BodyXSmall>
        <Typography.BodySmall containerCss={[tw`text-primary text-center`]}>
          {filenames}
        </Typography.BodySmall>
      </div>
    </div>
  );
};
