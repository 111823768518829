import { useQueryClient } from "@tanstack/react-query";
import { Api, GetPatientByIdResponseDto } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";
import { useGetPaginated } from "../../api-hooks/useGetPaginated";
import { useUpdate } from "../../api-hooks/useUpdate";

export const usePatient = ({ id }: { id: number }) => {
  const {
    patients: {
      patientControllerUpdatePatientInfo,
      patientControllerUpdatePatientPoints,
      patientControllerGetPatientbyId,
    },
    appointments: {
      appointmentControllerGetPatientAppointmentsPaginated,
      appointmentControllerGetPatientHistoryAppointmentsPaginated,
    },
  } = new Api();
  const client = useQueryClient();

  const { mutateAsync: updatePatient, isLoading: isUpdatingPatient } =
    useUpdate(
      id,
      patientControllerUpdatePatientInfo,
      ["patient", id],
      (data) => {
        const queryData = client.getQueryData([
          "patient",
          Number(id),
        ]) as GetPatientByIdResponseDto;

        const tempData = { ...queryData, ...data };

        client.setQueryData(["patient", Number(id)], tempData);
      }
    );

  const {
    mutateAsync: updatePatientPoints,
    isLoading: isUpdatingPatientPoints,
  } = useUpdate(id, patientControllerUpdatePatientPoints, [
    "patient",
    id,
    "points",
  ]);

  const patient = useGetById({
    id,
    query: patientControllerGetPatientbyId,
    queryKey: ["patient", id],
  });

  const patientAppointments = useGetPaginated({
    id,
    query: appointmentControllerGetPatientAppointmentsPaginated,
    queryKey: ["patient", id, "appointments"],
  });
  const patientHistoryAppointments = useGetPaginated({
    id,
    query: appointmentControllerGetPatientHistoryAppointmentsPaginated,
    queryKey: ["patient", id, "appointments", "history"],
  });

  return {
    patient,
    patientAppointments,
    patientHistoryAppointments,
    updatePatient,
    updatePatientPoints,
    isUpdatingPatient,
    isUpdatingPatientPoints,
  };
};
