import Collapse, { Panel } from "rc-collapse";
import React, { PropsWithChildren, useEffect, useMemo } from "react";
import {
  IComponentBaseProps,
  Maybe,
  ReactComponent,
  TwinStyle,
} from "../../types";

import { css } from "@emotion/react";
import invariant from "invariant";
import { HiChevronDown } from "react-icons/hi";
import tw from "twin.macro";
import { useArray } from "../../hooks/use-array";
import { Typography } from "../Typograhy";

/** @jsxImportSource @emotion/react */

interface IAccordionItemProps extends IComponentBaseProps {
  itemKey: string;
  onChange: (key: string) => void;
  panelCss?: Maybe<TwinStyle>;
  selectedPanelCss?: Maybe<TwinStyle>;
  textCss?: Maybe<TwinStyle>;
  icon?: ReactComponent<{}>;
  middleComponent?: ReactComponent<{}>;
  iconCss?: Maybe<TwinStyle>;
  title: string;
  isSelected: boolean;
  isLast?: boolean;
  isFirst?: boolean;
}

export type IAccordionItemPropsPublic = Omit<
  IAccordionItemProps,
  "isSelected" | "isLast" | "isFirst" | "onChange"
>;

const AccordionItem = (props: PropsWithChildren<IAccordionItemProps>) => {
  const Icon = props.icon;
  const MiddleComponent = props.middleComponent;

  const iconCss = useMemo(
    () => [tw`w-5 h-5 text-inherit mr-3 `, props.iconCss],
    [props.iconCss]
  );

  const header = useMemo(
    () => (
      <div
        className="accordion-header"
        css={[
          tw`px-2.5 py-3 rounded-t-md`,
          tw`flex flex-row items-center justify-between`,
          tw`hover:(bg-primary-400 text-primary)`,
          props.panelCss,
          props.isSelected && props.selectedPanelCss,
        ]}
      >
        <div css={[tw`flex flex-row items-center`]}>
          {Icon && <Icon css={iconCss} containerCss={iconCss} />}
          <Typography.BodySmall
            containerCss={[tw`text-inherit`, MiddleComponent && tw`mr-6`]}
          >
            {props.title}
          </Typography.BodySmall>
          {MiddleComponent && <MiddleComponent />}
        </div>

        <HiChevronDown
          css={[
            tw`h-6 w-6 text-inherit rounded-full bg-gray-50`,
            props.isSelected && tw`rotate-180`,
          ]}
        />
      </div>
    ),
    [
      Icon,
      MiddleComponent,
      iconCss,
      props.isSelected,
      props.panelCss,
      props.selectedPanelCss,
      props.title,
    ]
  );
  return (
    <Panel
      css={[
        props.containerCss,
        css`
          .accordion-header-container {
            outline: none;
            &:not(:focus-within:focus-visible) {
              .accordion-header {
                ${tw`bg-white text-gray rounded-md`}
                ${props.isSelected && tw`rounded-b-none`}
              }
            }
          }
        `,
        tw`bg-gray-50 rounded-md mb-4 border-1 border-gray-200`,
      ]}
      headerClass="accordion-header-container"
      panelKey={props.itemKey}
      onItemClick={(key) => props.onChange(key as string)}
      header={header}
      isActive={props.isSelected}
    >
      {props.children}
    </Panel>
  );
};

interface IAccordionProps extends IComponentBaseProps {
  defaultActiveKey?: string;
  activeKeys?: string[];
  setActiveKeys?(keys: string[]): void;
}

const Accordion: React.FC<PropsWithChildren<IAccordionProps>> & {
  Item: React.FC<PropsWithChildren<IAccordionItemPropsPublic>>;
} = (props: PropsWithChildren<IAccordionProps>) => {
  invariant(
    !(props.defaultActiveKey && (props.activeKeys || props.setActiveKeys)),
    "Accordion can't be controlled and uncontrolled at the same time"
  );
  const [activeKeys, { replace, remove, push }] = useArray<string>(
    props.defaultActiveKey ? [props.defaultActiveKey] : []
  );

  useEffect(() => {
    if (props.activeKeys) {
      replace(props.activeKeys);
    }
  }, [replace, props.activeKeys]);
  const children = React.Children.toArray(props.children);

  const items =
    React.Children.map(children, (child: any, index) => {
      const isSelected = activeKeys.some((k) => k === child.props.itemKey);
      return React.cloneElement(child, {
        isSelected: isSelected,
        isLast: index + 1 === children.length,
        isFirst: index === 0,
        onChange: (key: string) => {
          let newKeys = isSelected ? remove(key) : push(key);
          if (props.setActiveKeys) {
            props.setActiveKeys(newKeys);
          }
        },
      });
    }) ?? [];
  return (
    <Collapse
      css={[props.containerCss]}
      activeKey={activeKeys}
      destroyInactivePanel
    >
      {items}
    </Collapse>
  );
};

// @ts-ignore
Accordion.Item = AccordionItem;
export { Accordion };
