import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { refetchAllContainingQueryKey } from "../../fields/form/utils";
import { RequestParams, HttpResponse } from "../api";

export function useDelete(
  id: number,
  mutation: (
    id: number,
    params?: RequestParams
  ) => Promise<
    AxiosResponse<HttpResponse & { payload?: { affected: Array<number> } }, any>
  >,
  queryKey: Array<string | number>
) {
  const client = useQueryClient();

  const mutationResp = useMutation(
    async () => {
      const { data } = await mutation(id);

      if (data.isSuccessful) {
        return data.payload;
      } else {
        toast.error(data.message);
        return null;
      }
    },
    {
      onSuccess: () => refetchAllContainingQueryKey({ client, queryKey }),
    }
  );
  return mutationResp;
}
