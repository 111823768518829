import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import * as yup from "yup";
import {
  GetFacilityResponseDto,
  UpdateFacilityInfoRequestDto,
} from "../../../api/api";
import { useFacility } from "../../../api/hooks/facilities/useFacility";
import { FacilityType } from "../../../enums";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { IModalProps, Modal } from "../../../ui/popups/Modal";
import { errorMessages } from "../../../utils";
/** @jsxImportSource @emotion/react */
import locale from "../../../utils/locale/hr.json";

const schemaDetails: yup.SchemaOf<UpdateFacilityInfoRequestDto> = yup
  .object()
  .shape({
    type: yup.string(),
    name: yup.string(),
    abbreviation: yup.string(),
    country: yup.string(),
    address: yup.string(),
    city: yup.string(),
    phone1: yup.string(),
    email: yup.string().email(errorMessages.emailBadFormat),
  })
  .required(errorMessages.required);

const schemaDescription = yup
  .object()
  .shape({
    description: yup.string().required(errorMessages.required),
  })
  .required(errorMessages.required);

type IDetailsForm = yup.InferType<typeof schemaDetails>;
type IDescriptionForm = yup.InferType<typeof schemaDescription>;

export const EditFacilityModal = ({
  open,
  onClose,
  type,
  facility,
}: Omit<IModalProps, "label" | "footerChildren"> & {
  type: "details" | "description";
  facility: GetFacilityResponseDto;
}) => {
  const { updateFacility, updateFacilityDesc } = useFacility({
    id: facility?.id!,
  });
  const isDetails = type === "details";

  const { t } = useTranslation();
  const defaultValues =
    type === "details" ? facility : { description: facility.description };
  const methods = useForm<IDetailsForm | IDescriptionForm>({
    defaultValues,
    resolver: yupResolver(isDetails ? schemaDetails : schemaDescription),
    mode: "onSubmit",
  });

  const allStates = Object.keys(locale.translation.countries).map((key) => ({
    value: key,
    label: t(`countries.${key}`),
  }));

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      if (type === "details") {
        await updateFacility({ requestData: { ...values, phone2: "" } });
      } else {
        await updateFacilityDesc({ requestData: { ...values, phone2: "" } });
      }
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={t("editFacilityInfo") as string}
        footerChildren={
          <Button.Contained containerCss={[tw`w-full`]} onClick={onSubmit}>
            {t("save")}
          </Button.Contained>
        }
      >
        {isDetails ? (
          <div>
            <Form.Select
              options={[
                { value: FacilityType.POLYCLINIC, label: t("POLYCLINIC") },
                {
                  value: FacilityType.SPECIAL_HOSPITAL,
                  label: t("SPECIAL_HOSPITAL"),
                },
                { value: FacilityType.REST, label: t("REST") },
              ]}
              name="type"
              required
              shouldOnlySelectValue
              label={t("typeOfMedicalFacility") as string}
              placeholder={t("typeOfMedicalFacilityPlaceholder") as string}
            />
            <div css={[tw`flex gap-4`]}>
              <Form.TextInput.Outlined
                required
                containerCss={[tw`flex-3`]}
                label={t("nameOfMedicalFacility") as string}
                placeholder={t("nameOfMedicalFacilityPlaceholder") as string}
                name="name"
              />
              <Form.TextInput.Outlined
                containerCss={[tw`flex-1`]}
                label={t("abbreviation") as string}
                placeholder={t("abbreviationPlaceholder") as string}
                name="abbreviation"
              />
            </div>
            <div css={[tw`flex gap-4`]}>
              <Form.Select
                options={allStates}
                shouldOnlySelectValue
                name="country"
                required
                label={t("state") as string}
                placeholder={t("selectState") as string}
              />
              <Form.TextInput.Outlined
                name="address"
                required
                label={t("address") as string}
                placeholder={t("addressPlaceholder") as string}
              />
              <Form.TextInput.Outlined
                name="city"
                required
                label={t("city") as string}
                placeholder={t("cityPlaceholder") as string}
              />
            </div>
            <div css={[tw`flex gap-4`]}>
              <Form.TextInput.Outlined
                name="phone1"
                required
                label={t("phone") as string}
                placeholder={t("phonePlaceholder") as string}
              />
              <Form.TextInput.Outlined
                name="email"
                required
                label={t("email") as string}
                placeholder={t("emailPlaceholder") as string}
              />
            </div>
          </div>
        ) : (
          <div>
            <Form.TextArea.Outlined
              name="description"
              required
              rows={10}
              label={t("medicalFacultyDescription") as string}
              placeholder={t("medicalFacultyDescriptionPlaceholder") as string}
            />
          </div>
        )}
      </Modal>
    </FormProvider>
  );
};
