import { useQuery } from "@tanstack/react-query";
import { CategoriesWithServicesWithParentIds } from "../../../types";
import { Api } from "../../api";
import { useDelete } from "../../api-hooks/useDelete";
import { useGetById } from "../../api-hooks/useGetById";
import { useUpdate } from "../../api-hooks/useUpdate";
import { departmentCategoriesServicesKey } from "../../query-keys";

export const useDepartment = ({ id }: { id: number }) => {
  const {
    departments: {
      departmentControllerDeleteDepartment,
      departmentControllerGetDepartmentCategoriesWithServices,
      departmentControllerUpdateDepartment,
      departmentControllerGetDepartmentEmployees,
      departmentControllerGetDepartmentRooms,
      departmentControllerGetDepartmentCategories,
      departmentControllerGetDepartmentRoomsWithAppointmentsByDate,
    },
  } = new Api();

  const department = useQuery(
    ["department", id],
    async () => {
      const { data } =
        await departmentControllerGetDepartmentCategoriesWithServices(id);

      if (data.code === 200) {
        return {
          id: data.payload?.id,
          name: data.payload?.name,
          iconUrl: data.payload?.iconUrl,
        };
      }
    },
    {
      enabled: !!id,
    }
  );

  const categoriesWithServices = useQuery(
    departmentCategoriesServicesKey(id),
    async () => {
      const { data } =
        await departmentControllerGetDepartmentCategoriesWithServices(id);

      if (data.isSuccessful) {
        return data.payload?.categories.map((c) => ({
          ...c,
          departmentId: data.payload?.id,
          services: c.services.map((s) => ({
            ...s,
            categoryId: c.id,
            departmentId: data.payload?.id,
          })),
        })) as CategoriesWithServicesWithParentIds;
      }
      return [];
    },
    {
      enabled: !!id,
    }
  );

  const employees = useGetById({
    id,
    query: departmentControllerGetDepartmentEmployees,
    queryKey: ["department", id, "employees"],
  });

  const rooms = useGetById({
    id,
    query: departmentControllerGetDepartmentRooms,
    queryKey: ["department", id, "rooms"],
  });
  const categories = useGetById({
    id,
    query: departmentControllerGetDepartmentCategories,
    queryKey: ["department", id, "categories"],
  });

  const roomsWithAppointments = useGetById({
    id,
    query: departmentControllerGetDepartmentRoomsWithAppointmentsByDate,
    queryKey: ["department", id, "rooms", "appointments"],
    queryParams: { date: new Date() },
  });

  const { mutateAsync: updateDepartment, isLoading: isUpdatingDepartment } =
    useUpdate(
      id,
      departmentControllerUpdateDepartment,
      ["department", id]
      // (data, variables) => {
      //   const tempDepartment = client.getQueryData(departmentCategoriesServicesKey(id));
      // }
    );

  const { mutateAsync: deleteDepartment, isLoading: isDeletingDepartment } =
    useDelete(id, departmentControllerDeleteDepartment, ["departments"]);

  return {
    rooms,
    employees,
    roomsWithAppointments,
    categories,
    department,
    categoriesWithServices,
    updateDepartment,
    deleteDepartment,
    isUpdatingDepartment,
    isDeletingDepartment,
  };
};
