import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Api } from "../../api";

export const useMultipleRooms = (roomIds: number[]) => {
  const {
    rooms: { roomControllerGetMultipleRoomsEmployees },
  } = new Api();

  const roomsEmployees = useQuery(
    ["multipleRoomsEmployees", roomIds],
    async () => {
      const { data } = await roomControllerGetMultipleRoomsEmployees({
        roomIds: roomIds,
      });
      if (data.isSuccessful) {
        const employees = data.payload?.employees;
        return employees || [];
      } else {
        toast.error(data.message);
        return null;
      }
    },
    {
      enabled: !!roomIds,
    }
  );

  return {
    roomsEmployees,
  };
};
