import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiAddCircleFill } from "react-icons/ri";
import { useMe } from "../../api/hooks/user/useMe";
import { NewFacilityModal } from "../../pages/facilities/modals/NewFacilityModal";
import { EmptyState } from "./EmptyState";
/** @jsxImportSource @emotion/react */

export const NoFacilities = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const { me } = useMe();
  return (
    <>
      <EmptyState
        title={t("noFacilities")}
        subtitle={t("enterFacilityToUseCliniCore")}
        buttonText={t("addNewFacility")}
        buttonIcon={RiAddCircleFill}
        onButtonClick={() => setIsModalOpen(true)}
        hasButton={me.role?.permissions?.FACILITIES?.CREATE}
      />
      {me.role?.permissions?.FACILITIES?.CREATE && (
        <NewFacilityModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};
