import { useMutation } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { isLoggedInState, selectedOrganisationIdState } from "../../../state";
import { toast } from "../../../ui/indicators/Toast";
import {
  Api,
  LoginEmployeeRequestDto,
  LoginPatientRequestDto,
} from "../../api";

export const useLogin = () => {
  const {
    employees: { employeeControllerLoginEmployee },
    patients: { patientControllerLoginPatient },
  } = new Api();
  const [, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [, setOrganisationId] = useRecoilState(selectedOrganisationIdState);

  const { mutateAsync: employeeLogin, isLoading: isEmployeeLoggingIn } =
    useMutation(
      async ({
        employeeLoginData,
      }: {
        employeeLoginData: LoginEmployeeRequestDto;
      }) => {
        const { data } = await employeeControllerLoginEmployee(
          employeeLoginData
        );
        if (data.isSuccessful) {
          return data.payload;
        } else {
          toast.error(data.message);
          return null;
        }
      },
      {
        onSuccess: (data) => {
          if (data) {
            setOrganisationId(data?.employee.organisation.id!);
          }
          setIsLoggedIn(true);
        },
      }
    );

  const { mutateAsync: patientLogin, isLoading: isPatientLoggingIn } =
    useMutation(
      async ({
        patientLoginData,
      }: {
        patientLoginData: LoginPatientRequestDto;
      }) => {
        const { data } = await patientControllerLoginPatient(patientLoginData);
        if (data.isSuccessful) {
          return data.payload;
        } else {
          toast.error(data.message);
          return null;
        }
      },
      {
        onSuccess: () => {
          setIsLoggedIn(true);
        },
      }
    );

  return {
    employeeLogin,
    isEmployeeLoggingIn,
    patientLogin,
    isPatientLoggingIn,
  };
};
