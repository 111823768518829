/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import { Button } from "../../ui/buttons/Button";
import { Modal } from "../../ui/popups/Modal";

interface IMessageModalProps {
  label: string;
  buttonTitle: string;
  handleSubmit: (values: any) => void;
  open: boolean;
  onClose: () => void;
}

export const MessageModal = ({
  label,
  buttonTitle,
  handleSubmit,
  open,
  onClose,
}: IMessageModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      label={label}
      footerChildren={
        <Button.Contained containerCss={[tw`w-full`]} onClick={handleSubmit}>
          {buttonTitle}
        </Button.Contained>
      }
    />
  );
};
