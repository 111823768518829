import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";
import { useDelete } from "../../api-hooks/useDelete";
import { useUpdate } from "../../api-hooks/useUpdate";

export const useMedicalRecords = () => {
  const {
    medicalRecords: { medicalRecordControllerCreateMedicalRecord },
  } = new Api();

  const { mutateAsync: createMedicalRecord } = useCreate(
    medicalRecordControllerCreateMedicalRecord,
    ["medical-records"]
  );

  // const { mutateAsync: updateAward } = useUpdate(
  //   id,
  //   awardControllerUpdateAward,
  //   ["award", id]
  // );

  // const { mutateAsync: deleteAward } = useDelete(
  //   id,
  //   awardControllerDeleteAward,
  //   ["awards"]
  // );

  // return {
  //   deleteAward,
  //   updateAward,
  // };

  return { createMedicalRecord };
};
