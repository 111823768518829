import { yupResolver } from "@hookform/resolvers/yup";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import { useCallback, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RiAddFill, RiIndeterminateCircleLine } from "react-icons/ri";
import tw from "twin.macro";
import * as yup from "yup";
import {
  CreateFacilityFacilityImage,
  CreateFacilityRequestDto,
  RequestFacilityImageLinksResponseDto,
} from "../../../api/api";
import { useFacilities } from "../../../api/hooks/facilities/useFacilities";
import { useFileUpload } from "../../../api/hooks/file-upload/useFileUpload";
import { useMe } from "../../../api/hooks/user/useMe";
import { FacilityType } from "../../../enums";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Button } from "../../../ui/buttons/Button";
import { Dropzone } from "../../../ui/Dropzone";
import { toast } from "../../../ui/indicators/Toast";
import { IModalProps, Modal } from "../../../ui/popups/Modal";
import { Typography } from "../../../ui/Typograhy";
import { errorMessages } from "../../../utils";
import locale from "../../../utils/locale/hr.json";
import { uploadFile } from "../../../utils/upload-file";
/** @jsxImportSource @emotion/react */

const schema: yup.SchemaOf<CreateFacilityRequestDto> = yup
  .object()
  .shape({
    type: yup.string().required(errorMessages.required),
    name: yup.string().required(errorMessages.required),
    abbreviation: yup.string().required(errorMessages.required),
    country: yup.string().required(errorMessages.required),
    address: yup.string().required(errorMessages.required),
    city: yup.string().required(errorMessages.required),
    phone1: yup.string(),
    lat: yup
      .number()
      .typeError(errorMessages.number)
      .required(errorMessages.required),
    lng: yup
      .number()
      .typeError(errorMessages.number)
      .required(errorMessages.required),
    email: yup
      .string()
      .required(errorMessages.required)
      .email(errorMessages.emailBadFormat),
    description: yup.string(),
    imageUrl: yup.string(),
    workingHours: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            daysOfTheWeek: yup.object().required(errorMessages.required),
            openingTime: yup
              .number()
              .typeError(errorMessages.number)
              .required(errorMessages.required),
            closingTime: yup
              .number()
              .typeError(errorMessages.number)
              .required(errorMessages.required),
          })
          .required(errorMessages.required)
      )
      .required(errorMessages.required),
  })
  .required(errorMessages.required);

type IForm = yup.InferType<typeof schema>;

const stepDescriptions = [
  "enterInitialData",
  "enterDescription",
  "addingAPhoto",
  "addingWorkSchedule",
];

const triggerByStep = [
  [
    "type",
    "name",
    "abbreviation",
    "country",
    "address",
    "city",
    "phone1",
    "email",
  ],
  "description",
  "imageUrl",
  "workingHours",
];

export const NewFacilityModal = ({
  open,
  onClose,
}: Omit<IModalProps, "label" | "footerChildren">) => {
  const { createFacility } = useFacilities();
  const [step, setStep] = useState(0);
  const { t } = useTranslation();
  const { me } = useMe();
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      type: undefined,
      name: undefined,
      abbreviation: undefined,
      country: undefined,
      address: undefined,
      city: undefined,
      phone1: undefined,
      email: undefined,
      description: undefined,
      lat: 45.8034286,
      lng: 13.9253137,
      workingHours: [
        {
          closingTime: undefined,
          daysOfTheWeek: {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
          },
          openingTime: undefined,
        },
      ],
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
    name: "workingHours", // unique name for your Field Array
  });

  const allStates = Object.keys(locale.translation.countries).map((key) => ({
    value: key,
    label: t(`countries.${key}`),
  }));

  const incrementStep = useCallback(async () => {
    //@ts-ignore
    const isCorrect = await methods.trigger(triggerByStep[step]);
    if (isCorrect) {
      setStep((prevStep) =>
        Math.min(prevStep + 1, stepDescriptions.length - 1)
      );
    }
  }, [methods, step]);

  const decrementStep = useCallback(() => {
    setStep((prevStep) => Math.max(prevStep - 1, 0));
  }, []);

  const { requestFacilityImageLinks } = useFileUpload();
  const [imageForUpload, setImageForUpload] = useState<File>();

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      let facilityImageWithLinks: CreateFacilityFacilityImage | undefined;
      if (imageForUpload) {
        const uploadData = (await requestFacilityImageLinks({
          fileName: { fileName: imageForUpload.name },
        })) as RequestFacilityImageLinksResponseDto;
        await uploadFile(uploadData.uploadUrl, imageForUpload);
        facilityImageWithLinks = {
          name: imageForUpload.name,
          downloadUrl: uploadData.downloadUrl,
          fileUUID: uploadData.fileUUID,
        };
      }
      await createFacility({
        requestData: {
          ...values,
          workingHours: values.workingHours!,
          image: facilityImageWithLinks || undefined,
        },
      });
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  const isLastStep = step === stepDescriptions.length - 1;

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        //@ts-ignore
        onClose={onClose}
        label={t("addNewFacility") as string}
        footerChildren={
          <div css={[tw`flex justify-between gap-3`]}>
            <Button.Outlined
              containerCss={[tw`flex-1`]}
              onClick={decrementStep}
              disabled={step === 0}
            >
              {t("back")}
            </Button.Outlined>
            <Button.Contained
              containerCss={[tw`flex-5`]}
              onClick={isLastStep ? onSubmit : incrementStep}
            >
              {isLastStep ? t("save") : t("next")}
            </Button.Contained>
          </div>
        }
      >
        <Typography.BodyMedium containerCss={[tw`mb-6`]}>
          {t("step")} {step + 1}.{" "}
          <Typography.BodyMedium containerCss={[tw`font-700 inline`]}>
            {t(stepDescriptions[step])}
          </Typography.BodyMedium>
        </Typography.BodyMedium>
        {step === 0 && (
          <div>
            <Form.Select
              options={[
                { value: FacilityType.POLYCLINIC, label: t("POLYCLINIC") },
                {
                  value: FacilityType.SPECIAL_HOSPITAL,
                  label: t("SPECIAL_HOSPITAL"),
                },
                { value: FacilityType.REST, label: t("REST") },
              ]}
              name="type"
              required
              shouldOnlySelectValue
              label={t("typeOfMedicalFacility") as string}
              placeholder={t("typeOfMedicalFacilityPlaceholder") as string}
            />
            <div css={[tw`flex gap-4`]}>
              <Form.TextInput.Outlined
                required
                containerCss={[tw`flex-3`]}
                label={t("nameOfMedicalFacility") as string}
                placeholder={t("nameOfMedicalFacilityPlaceholder") as string}
                name="name"
              />
              <Form.TextInput.Outlined
                containerCss={[tw`flex-1`]}
                required
                label={t("abbreviation") as string}
                placeholder={t("abbreviationPlaceholder") as string}
                name="abbreviation"
              />
            </div>
            <div css={[tw`flex gap-4`]}>
              <Form.Select
                options={allStates}
                shouldOnlySelectValue
                name="country"
                required
                label={t("state") as string}
                placeholder={t("selectState") as string}
              />
              <Form.TextInput.Outlined
                name="address"
                required
                label={t("address") as string}
                placeholder={t("addressPlaceholder") as string}
              />
              <Form.TextInput.Outlined
                name="city"
                required
                label={t("city") as string}
                placeholder={t("cityPlaceholder") as string}
              />
            </div>

            <div css={[tw`flex gap-4`]}>
              <Form.TextInput.Outlined
                name="phone1"
                label={t("phone") as string}
                placeholder={t("phonePlaceholder") as string}
              />
              <Form.TextInput.Outlined
                name="email"
                required
                label={t("email") as string}
                placeholder={t("emailPlaceholder") as string}
              />
            </div>
          </div>
        )}
        {step === 1 && (
          <div>
            <Form.TextArea.Outlined
              name="description"
              rows={10}
              label={t("medicalFacultyDescription") as string}
              placeholder={t("medicalFacultyDescriptionPlaceholder") as string}
            />
          </div>
        )}
        {step === 2 && (
          <div>
            <Dropzone
              multiple={false}
              title={t("addOrDragDropPhoto") as string}
              subtitle={t("pngOrJpg10Mb") as string}
              maxFileSize={10}
              containerCss={[tw`p-1 mb-6`]}
              accept={{
                "image/png": [".png"],
                "image/jpg": [".jpg"],
              }}
              onUpload={(files) => setImageForUpload(files[0])}
            />
          </div>
        )}
        {step === 3 && (
          <div>
            {fields.map((field, index) => {
              return (
                <div css={[tw`bg-white p-6 rounded-md mb-6`]} key={field.id}>
                  <div css={[tw`flex justify-between gap-6 items-center`]}>
                    <Form.TimePicker
                      containerCss={[tw`flex-3`]}
                      placeholder="08:00"
                      shouldOnlySelectValue
                      dataParser={(data) =>
                        getMinutes(data) + getHours(data) * 60
                      }
                      name={`workingHours.${index}.openingTime`}
                      label={t("opening") as string}
                    />
                    <Form.TimePicker
                      containerCss={[tw`flex-3`]}
                      placeholder="16:00"
                      shouldOnlySelectValue
                      dataParser={(data) =>
                        getMinutes(data) + getHours(data) * 60
                      }
                      label={t("closing") as string}
                      name={`workingHours.${index}.closingTime`}
                    />
                    <Button.Text
                      lead={RiIndeterminateCircleLine}
                      onClick={() => remove(index)}
                      textCss={[tw`text-error`]}
                      leadCss={[tw`text-error`]}
                      containerCss={[tw`hover:(bg-error-light)`]}
                      disabled={fields.length === 1}
                    >
                      {t("delete")}
                    </Button.Text>
                  </div>
                  <Typography.BodySmall>{t("pickDays")}</Typography.BodySmall>
                  <div css={[tw`flex gap-6 mt-3`]}>
                    {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map(
                      (day, i) => {
                        return (
                          <div
                            css={[tw`flex items-center gap-2`]}
                            key={`${day}-${index}`}
                          >
                            <Form.Checkbox
                              name={`workingHours.${index}.daysOfTheWeek.${
                                i + 1
                              }`}
                              label={t(day) as string}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
            <div css={[tw`flex justify-center items-center py-6`]}>
              <Button.Text
                lead={RiAddFill}
                onClick={() =>
                  append({
                    closingTime: 960,
                    daysOfTheWeek: {
                      1: false,
                      2: false,
                      3: false,
                      4: false,
                      5: false,
                      6: false,
                      7: false,
                    },
                    openingTime: 960 + 8 * 60,
                  })
                }
                textCss={[tw`text-gray-900`]}
                leadCss={[tw`text-gray-900`]}
              >
                {t("addShift")}
              </Button.Text>
            </div>
          </div>
        )}
      </Modal>
    </FormProvider>
  );
};
