import { yupResolver } from "@hookform/resolvers/yup";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RiAddFill, RiIndeterminateCircleLine } from "react-icons/ri";
import tw from "twin.macro";
import * as yup from "yup";
import {
  PutEmployeeDefaultWorkingHoursForFacilityBodyRequestDto,
  WorkingHoursScheduleWithShifts,
} from "../../../api/api";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Nullable } from "../../../types";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { IModalProps, Modal } from "../../../ui/popups/Modal";
import { Typography } from "../../../ui/Typograhy";
import { errorMessages } from "../../../utils";
/** @jsxImportSource @emotion/react */

const schema: yup.SchemaOf<{ workingHours: WorkingHoursScheduleWithShifts[] }> =
  yup.object().shape({
    workingHours: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            daysOfTheWeek: yup.object().required(errorMessages.required),
            openingTime: yup
              .number()
              .typeError(errorMessages.number)
              .required(errorMessages.required),
            closingTime: yup
              .number()
              .typeError(errorMessages.number)
              .required(errorMessages.required),
          })
          .required(errorMessages.required)
      )
      .required(errorMessages.required),
  });

type IDetailsForm = Nullable<yup.InferType<typeof schema>>;

export const EditWeekWorkTimeModal = ({
  open,
  onClose,
  workingHours,
  updateWorkingHours,
}: Omit<IModalProps, "label" | "footerChildren"> & {
  workingHours: WorkingHoursScheduleWithShifts[];
  updateWorkingHours: (
    value: PutEmployeeDefaultWorkingHoursForFacilityBodyRequestDto
  ) => void;
}) => {
  const { t } = useTranslation();
  const defaultValues = {
    workingHours: workingHours.map((shift) => ({
      openingTime: shift.openingTime,
      closingTime: shift.closingTime,
      daysOfTheWeek: {
        //@ts-ignore
        1: shift.daysOfTheWeek[1],
        //@ts-ignore
        2: shift.daysOfTheWeek[2],
        //@ts-ignore
        3: shift.daysOfTheWeek[3],
        //@ts-ignore
        4: shift.daysOfTheWeek[4],
        //@ts-ignore
        5: shift.daysOfTheWeek[5],
        //@ts-ignore
        6: shift.daysOfTheWeek[6],
        //@ts-ignore
        7: shift.daysOfTheWeek[7],
      },
    })),
  };
  const methods = useForm<IDetailsForm>({
    //@ts-ignore
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
    //@ts-ignore
    name: "workingHours", // unique name for your Field Array
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      if (values.workingHours) {
        await updateWorkingHours({ workingHours: values.workingHours });
      }
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={`${t("editWeekWorkTime")}`}
        footerChildren={
          <div css={[tw`flex justify-between gap-3`]}>
            <Button.Outlined
              containerCss={[tw`flex-1`]}
              onClick={() =>
                //@ts-ignore
                onClose()
              }
            >
              {t("close")}
            </Button.Outlined>
            <Button.Contained containerCss={[tw`flex-5`]} onClick={onSubmit}>
              {t("save")}
            </Button.Contained>
          </div>
        }
      >
        <div>
          {fields.map((field, index) => {
            return (
              <div css={[tw`bg-white p-6 rounded-md mb-6`]} key={field.id}>
                <div css={[tw`flex justify-between gap-6 items-center`]}>
                  <Form.TimePicker
                    containerCss={[tw`flex-3`]}
                    placeholder="08:00"
                    shouldOnlySelectValue
                    dataParser={(data) =>
                      getMinutes(data) + getHours(data) * 60
                    }
                    name={`workingHours.${index}.openingTime`}
                    label={t("opening") as string}
                  />
                  <Form.TimePicker
                    containerCss={[tw`flex-3`]}
                    // disabled={field.notWorking}
                    placeholder="16:00"
                    shouldOnlySelectValue
                    dataParser={(data) =>
                      getMinutes(data) + getHours(data) * 60
                    }
                    label={t("closing") as string}
                    name={`workingHours.${index}.closingTime`}
                  />
                  <Button.Text
                    lead={RiIndeterminateCircleLine}
                    onClick={() => remove(index)}
                    textCss={[tw`text-error`]}
                    leadCss={[tw`text-error`]}
                    containerCss={[tw`hover:(bg-error-light)`]}
                    disabled={fields.length === 1}
                  >
                    {t("delete")}
                  </Button.Text>
                </div>
                <Typography.BodySmall>{t("pickDays")}</Typography.BodySmall>
                <div css={[tw`flex gap-6 mt-3`]}>
                  {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map(
                    (day, i) => {
                      return (
                        <div
                          css={[tw`flex items-center gap-2`]}
                          key={day + i + index}
                        >
                          <Form.Checkbox
                            name={`workingHours.${index}.daysOfTheWeek.${
                              i + 1
                            }`}
                            label={t(day) as string}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            );
          })}
          <div css={[tw`flex justify-center items-center py-6`]}>
            <Button.Text
              lead={RiAddFill}
              onClick={() =>
                append({
                  shiftId: 1,
                  openingTime: 8 * 60,
                  closingTime: 16 * 60,
                  daysOfTheWeek: {
                    //@ts-ignore
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                    7: false,
                  },
                })
              }
              textCss={[tw`text-gray-900`]}
              leadCss={[tw`text-gray-900`]}
            >
              {t("addShift")}
            </Button.Text>
          </div>
        </div>
      </Modal>
    </FormProvider>
  );
};
