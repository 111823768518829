import { Api } from "../../api";
import { useDelete } from "../../api-hooks/useDelete";
import { useUpdate } from "../../api-hooks/useUpdate";

export const useAward = ({ id }: { id: number }) => {
  const {
    awards: { awardControllerDeleteAward, awardControllerUpdateAward },
  } = new Api();

  const { mutateAsync: updateAward } = useUpdate(
    id,
    awardControllerUpdateAward,
    ["award", id]
  );

  const { mutateAsync: deleteAward } = useDelete(
    id,
    awardControllerDeleteAward,
    ["awards"]
  );

  return {
    deleteAward,
    updateAward,
  };
};
