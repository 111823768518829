/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import * as yup from "yup";
import { usePatient } from "../../../api/hooks/patients/usePatient";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { Modal } from "../../../ui/popups/Modal";
import { errorMessages } from "../../../utils";

const schema = yup.object().shape({
  points: yup
    .number()
    .typeError(errorMessages.number)
    .required(errorMessages.required),
});

type IForm = yup.InferType<typeof schema>;

interface ITextInputModalProps {
  open: boolean;
  onClose: () => void;
  patientId: number;
}

export const AddPointsModal = ({
  open,
  onClose,
  patientId,
}: ITextInputModalProps) => {
  const { updatePatientPoints } = usePatient({ id: patientId });
  const { t } = useTranslation();
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      points: undefined,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      if (values.points !== 0) {
        await updatePatientPoints({ requestData: values });
      }
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        containerCss={[tw`w-80`]}
        footerChildren={
          <Button.Contained containerCss={[tw`w-full`]} onClick={onSubmit}>
            {t("confirm")}
          </Button.Contained>
        }
      >
        <Form.TextInput.Outlined
          label={t("addOrRemovePoints") as string}
          required
          placeholder={t("addOrRemovePointsPlaceholder") as string}
          name="points"
        />
      </Modal>
    </FormProvider>
  );
};
