import { useRecoilValue } from "recoil";
import { selectedOrganisationIdState } from "../../../state";
import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";
import { organisationFacilitiesKey } from "../../query-keys";

export const useFacilities = () => {
  const {
    facilities: { facilityControllerCreateFacility },
  } = new Api();
  const organisationId = useRecoilValue(selectedOrganisationIdState);

  const { mutateAsync: createFacility, isLoading: isCreatingFacility } =
    useCreate(
      facilityControllerCreateFacility,
      organisationFacilitiesKey(organisationId)
    );

  return {
    createFacility,
    isCreatingFacility,
  };
};
