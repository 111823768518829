import { useRecoilValue } from "recoil";
import { selectedOrganisationIdState } from "../../../state";
import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";
import { organisationPatientsKey } from "../../query-keys";

export const useOrganisationPatients = () => {
  const {
    patients: { patientControllerGetOrganisationPatients },
  } = new Api();
  const organisationId = useRecoilValue(selectedOrganisationIdState);

  const patients = useGetById({
    id: Number(organisationId),
    query: patientControllerGetOrganisationPatients,
    queryKey: organisationPatientsKey(organisationId),
  });

  return {
    patients,
  };
};
