import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import { GetAwardsForFacilityResponseDto } from "../../../api/api";
import { useFacilityAwards } from "../../../api/hooks/facilities/useFacilityAwards";
import { searchState } from "../../../state";
import { AsyncDataTable } from "../../../ui/layout/data-table/async/AsyncDataTable";
import { TableCell } from "../../../ui/layout/data-table/cell";
import { PointsCollectionDetailsModal } from "../modals/PointsCollectionDetailsModal";
/** @jsxImportSource @emotion/react */

export const CollectingPointsByCouponsTab = () => {
  const search = useRecoilValue(searchState);
  const { awards } = useFacilityAwards();
  const [
    isCollectionMethodDetailsModalOpen,
    setIsCollectionMethodDetailsModalOpen,
  ] = useState(false);
  const [currentAward, setCurrentAward] = useState<
    GetAwardsForFacilityResponseDto | undefined
  >(undefined);
  const { t } = useTranslation();
  useEffect(() => {
    if (awards.filters.term !== search) {
      awards.setFilter("term", search);
    }
  }, [awards, search]);

  return (
    <>
      <AsyncDataTable
        loading={awards.isInitialLoading}
        data={awards.data?.items || []}
        onRowClick={(item) => {
          setCurrentAward(item);
          setIsCollectionMethodDetailsModalOpen(true);
        }}
        containerCss={[tw`flex flex-col justify-between h-full`]}
        columns={[
          {
            Header: t("name"),
            accessor: "name",
            align: "left",
            disableSortBy: true,
            cell: (s) => <TableCell.Text value={`${s?.name || ""}`} />,
          },
          {
            Header: t("service"),
            accessor: "service",
            disableSortBy: true,
            align: "right",
            cell: (s) => (
              <TableCell.Text value={(s?.service?.name || "-").toString()} />
            ),
          },
          {
            Header: t("usability"),
            accessor: "maxNoOfUsage",
            disableSortBy: true,
            align: "right",
            maxWidth: 70,
            cell: (s) => (
              <TableCell.Text
                value={
                  s?.maxNoOfUsage
                    ? (s?.maxNoOfUsage).toString()
                    : t("unlimited")
                }
              />
            ),
          },
          // {
          //   Header: t("used"),
          //   accessor: "",
          //   disableSortBy: true,
          //   align: "right",
          //   cell: (s) => <TableCell.Text value={`${s.timesActivated}`} />,
          // },
          {
            Header: t("validFrom"),
            accessor: "availableFrom",
            align: "right",
            maxWidth: 70,
            disableSortBy: true,
            cell: (s) => <TableCell.Date value={new Date(s.availableFrom)} />,
          },
          {
            Header: t("validTo"),
            accessor: "expirationDate",
            align: "right",
            maxWidth: 70,
            disableSortBy: true,
            cell: (s) => (
              <TableCell.Date
                value={new Date(s?.expirationDate || new Date())}
              />
            ),
          },
          {
            Header: t("points"),
            accessor: "awardPoints",
            align: "right",
            maxWidth: 50,
            disableSortBy: true,
            cell: (s) => <TableCell.Text value={`+${s.awardPoints}`} />,
          },
        ]}
        currentPage={awards.page}
        setPage={awards.setPage}
        pageSize={awards.size}
        maxPage={awards.data?.totalPages || 0}
        totalCount={awards.data?.totalItems || 0}
        setSortKey={awards.setOrderField}
        setSortOrder={awards.setOrder}
        sortKey={awards.orderField}
        sortOrder={awards.order}
      />
      {!!currentAward && (
        <PointsCollectionDetailsModal
          open={isCollectionMethodDetailsModalOpen}
          collectionMethod={currentAward}
          onClose={() => setIsCollectionMethodDetailsModalOpen(false)}
        />
      )}
    </>
  );
};
