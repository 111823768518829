import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { Button } from "../../../ui/buttons/Button";
import { Modal } from "../../../ui/popups/Modal";
import { getCroppedImg } from "../../../utils/image-utils";

/** @jsxImportSource @emotion/react */

interface IFilterEmployeesModal {
  open: boolean;
  onClose: () => void;
  image?: string;
  onSubmitImage: (image: string) => void;
}

export const ProfileImageModal = ({
  open,
  onClose,
  image,
  onSubmitImage,
}: IFilterEmployeesModal) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const { t } = useTranslation();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      onSubmitImage(croppedImage as string);
      setZoom(1);
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedAreaPixels, rotation, onClose, onSubmitImage]);

  return (
    <Modal
      containerCss={[tw`w-96`]}
      open={open}
      label={t("addProfileImage") as string}
      onClose={onClose}
      footerChildren={
        <div css={[tw`flex justify-between gap-3`]}>
          <Button.Outlined containerCss={[tw`flex-1`]} onClick={onClose}>
            {t("loadNew")}
          </Button.Outlined>
          <Button.Contained
            containerCss={[tw`flex-1`]}
            onClick={showCroppedImage}
          >
            {t("addImage")}
          </Button.Contained>
        </div>
      }
    >
      <div css={[tw`relative w-full h-112`]}>
        <Cropper
          image={image}
          crop={crop}
          cropShape="round"
          aspect={4 / 4}
          zoom={zoom}
          onRotationChange={setRotation}
          zoomSpeed={4}
          maxZoom={3}
          zoomWithScroll={true}
          showGrid={true}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </div>
    </Modal>
  );
};
