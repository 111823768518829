/** @jsxImportSource @emotion/react */
import { format, isAfter, isBefore, isToday, minutesToHours } from "date-fns";
import id from "date-fns/esm/locale/id/index.js";
import tw from "twin.macro";
import { WorkingHoursScheduleWithShifts } from "../../../api/api";
import { Typography } from "../../../ui/Typograhy";

interface IMonthCellProps {
  day: Date;
  index: number;
  isLast: boolean;
  schedule: WorkingHoursScheduleWithShifts[];
}

export const MonthCell = ({
  day,
  index,
  isLast,
  schedule,
}: IMonthCellProps) => {
  const currDayNum = day.getDay() === 0 ? 7 : day.getDay();
  const filteredSchedule = schedule.filter(
    //@ts-ignore
    (s) => s.daysOfTheWeek[currDayNum]
  );
  const today = new Date();
  const toDoubleDigit = (digit) => {
    if (digit < 10) return `0${digit}`;
    else return digit.toString();
  };

  return (
    <div
      key={format(day, "dd.MM")}
      css={[
        tw`flex flex-col flex-1 w-full h-28`,
        !isLast && tw`border-r-1 border-r-gray-100`,
        index !== 5 && tw`border-b-1 border-b-gray-100`,
      ]}
    >
      <div css={[tw`flex items-center justify-center mt-1`]}>
        <div
          css={[
            tw`flex items-center justify-center`,
            tw`w-6 h-6`,
            isToday(day) && tw`border-primary border-1 p-1 rounded-full`,
          ]}
        >
          <Typography.BodyXSmall
            containerCss={[
              tw`text-center`,
              tw`font-700`,
              isToday(day) && tw`text-primary`,
            ]}
          >
            {format(day, "dd")}
          </Typography.BodyXSmall>
        </div>
      </div>
      <div css={[tw`flex flex-col flex-1 p-2`]}>
        {filteredSchedule.map((s) => {
          const hasStarted =
            day.setHours(s.openingTime / 60, s.openingTime % 60) >
            today.getTime();

          const hasEnded =
            day.setHours(s.closingTime / 60, s.closingTime % 60) >
            today.getTime();

          const isActive = isToday(day) && hasStarted && !hasEnded;

          return (
            <div
              key={Math.random()}
              css={[
                tw`bg-primary-50 mt-1 p-0.75 rounded-[4px] h-full flex flex-col justify-end`,
                isBefore(day, today) && hasEnded && tw`bg-gray-50`,
                isActive && tw`bg-primary`,
              ]}
            >
              <Typography.BodyXSmall
                containerCss={[
                  tw`text-[10px] font-700`,
                  isBefore(day, today) && tw`text-gray-600`,
                  isActive && tw`text-white`,
                ]}
              >
                {toDoubleDigit(minutesToHours(s.openingTime))}:
                {toDoubleDigit(s.openingTime % 60)} -{" "}
                {toDoubleDigit(minutesToHours(s.closingTime))}:
                {toDoubleDigit(s.closingTime % 60)}
              </Typography.BodyXSmall>
            </div>
          );
        })}
      </div>
    </div>
  );
};
