import { FormAsyncMultiSelect } from "./FormAsyncMultiSelect";
import { FormAsyncSelect } from "./FormAsyncSelect";
import { FormCheckbox } from "./FormCheckbox";
import { FormDatePicker } from "./FormDatePicker";
import { FormFileField } from "./file-field/FormFileField";
import { FormMultiSelect } from "./FormMultiSelect";
import { FormRadioGroup } from "./FormRadioGroup";
import { FormRichTextEditor } from "./FormRichTextArea";
import { FormSelect } from "./FormSelect";
import { FormSlider } from "./FormSlider";
import { FormSwitch } from "./FormSwitch";
import { FormTextArea } from "./FormTextArea";
import { FormTextInput } from "./FormTextInput";
import { FormTimePicker } from "./FormTimePicker";

export const Form = {
  Switch: FormSwitch,
  Checkbox: FormCheckbox,
  Slider: FormSlider,
  TextInput: FormTextInput,
  DatePicker: FormDatePicker,
  TimePicker: FormTimePicker,
  RadioGroup: FormRadioGroup,
  TextArea: FormTextArea,
  Select: FormSelect,
  MultiSelect: FormMultiSelect,
  AsyncSelect: FormAsyncSelect,
  AsyncMultiSelect: FormAsyncMultiSelect,
  RichTextEditor: FormRichTextEditor,
  Files: FormFileField,
};
