import { useState } from "react";
import { useTranslation } from "react-i18next";
/** @jsxImportSource @emotion/react */
import { RiArrowLeftLine, RiEdit2Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { useMe } from "../../api/hooks/user/useMe";
import { PageContainer } from "../../components/layout/PageContainer";
import { Button } from "../../ui/buttons/Button";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { EmployeeScheduleTab } from "./tabs/EmployeeScheduleTab";
import { EmployeeWorkTimeTab } from "./tabs/EmployeeWorkTimeTab";

export const EmployeePage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isEditWorkWeekTimeModalOpen, setIsEditWorkWeekTimeModalOpen] =
    useState(false);
  const { t } = useTranslation();
  const { me } = useMe();
  const { pathname } = useLocation();

  const isMySchedule = pathname.includes("my_schedule");
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Tabs
        onTabChange={setActiveTab}
        defaultTab={0}
        leftComponent={
          !isMySchedule ? (
            <Button.Text
              containerCss={[tw`max-w-[146px]`]}
              lead={RiArrowLeftLine}
              leadCss={[tw`text-gray-900`]}
              textCss={[tw`text-gray-900`]}
              onClick={() => navigate("/employees")}
            >
              {t("goBackToTheList")}
            </Button.Text>
          ) : (
            <div></div>
          )
        }
        rightComponent={
          activeTab === 1 &&
          (isMySchedule
            ? me.role?.permissions?.MY_SCHEDULE?.UPDATE
            : me?.role?.permissions?.EMPLOYEES?.UPDATE) ? (
            <Button.Contained
              lead={RiEdit2Line}
              onClick={() => setIsEditWorkWeekTimeModalOpen(true)}
            >
              {t("editWorkTime")}
            </Button.Contained>
          ) : (
            <div css={[tw`w-[146px]`]} />
          )
        }
      >
        <Tabs.Item text={t("schedule")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <EmployeeScheduleTab activeTab={activeTab} />
        </Tabs.Item>
        <Tabs.Item text={t("workTime")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <EmployeeWorkTimeTab
            activeTab={activeTab}
            isEditWorkWeekTimeModalOpen={isEditWorkWeekTimeModalOpen}
            setIsEditWorkWeekTimeModalOpen={setIsEditWorkWeekTimeModalOpen}
          />
        </Tabs.Item>
        {/* <Tabs.Item text={t("loginHistory")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <EmployeeLoginHistoryTab />
        </Tabs.Item> */}
      </Tabs>
    </PageContainer>
  );
};
