/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import { Button, IButtonProps } from "../../ui/buttons/Button";
import { Modal } from "../../ui/popups/Modal";

interface IMessageModalProps {
  label: string;
  open: boolean;
  onClose: () => void;
  buttonLeft: IButtonProps & { title?: string | null };
  buttonRight: IButtonProps & { title?: string | null };
}

export const TwoButtonMessageModal = ({
  label,
  buttonLeft,
  buttonRight,
  open,
  onClose,
}: IMessageModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      label={label}
      footerChildren={
        <div css={[tw`flex items-center gap-3`]}>
          {!!buttonLeft.title && (
            <Button.Outlined containerCss={[tw`w-full`]} {...buttonLeft}>
              {buttonLeft.title}
            </Button.Outlined>
          )}
          {!!buttonRight.title && (
            <Button.Contained containerCss={[tw`w-full`]} {...buttonRight}>
              {buttonRight.title}
            </Button.Contained>
          )}
        </div>
      }
    />
  );
};
