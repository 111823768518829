/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiAddLine, RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { useMe } from "../../api/hooks/user/useMe";
import { PageContainer } from "../../components/layout/PageContainer";
import { Button } from "../../ui/buttons/Button";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { OrganisationSettingsTab } from "./tabs/OrganisationSettingsTab";

export const OrganisationPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { me } = useMe();
  const [isAddNewRoleModalOpen, setIsAddNewRoleModalOpen] = useState(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState<{
    state: boolean;
    onNext: (() => void) | undefined;
  }>({ state: false, onNext: undefined });
  const [isDirty, setIsDirty] = useState(false);

  const { t } = useTranslation();
  return (
    <PageContainer>
      <Tabs
        onTabChange={setActiveTab}
        defaultTab={0}
        leftComponent={
          <Button.Text
            lead={RiArrowLeftLine}
            leadCss={[tw`text-gray-900`]}
            textCss={[tw`text-gray-900`]}
            containerCss={[tw`px-2 min-w-min`]}
            onClick={() => {
              if (!isDirty) {
                navigate(-1);
              } else {
                setIsUnsavedChangesModalOpen({
                  state: true,
                  onNext: () => navigate(-1),
                });
              }
            }}
          >
            {t("return")}
          </Button.Text>
        }
        rightComponent={
          activeTab === 0 &&
          me.role?.permissions?.ROLES_AND_PERMISSIONS?.CREATE ? (
            <Button.Contained
              lead={RiAddLine}
              onClick={() => setIsAddNewRoleModalOpen(true)}
            >
              {t("addNewRole")}
            </Button.Contained>
          ) : (
            <div></div>
          )
        }
      >
        <Tabs.Item text={t("rolesAndPermissions")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <OrganisationSettingsTab
            isDirty={isDirty}
            setIsDirty={setIsDirty}
            setIsAddNewRoleModalOpen={setIsAddNewRoleModalOpen}
            isAddNewRoleModalOpen={isAddNewRoleModalOpen}
            isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
            setIsUnsavedChangesModalOpen={setIsUnsavedChangesModalOpen}
          />
        </Tabs.Item>
      </Tabs>
    </PageContainer>
  );
};
