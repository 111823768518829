import { Api } from "../../api";
import { useGetPaginated } from "../../api-hooks/useGetPaginated";

export const usePatientCoupons = ({ id }: { id?: number }) => {
  const {
    patients: {
      patientControllerGetPatientUsedCouponsPaginated,
      patientControllerGetPatientActivatedCouponsPaginated,
    },
  } = new Api();

  const patientUsedCoupons = useGetPaginated({
    id: Number(id),
    query: patientControllerGetPatientUsedCouponsPaginated,
    queryKey: ["patient", Number(id), "coupons", "used"],
  });

  const patientActivatedCoupons = useGetPaginated({
    id: Number(id),
    query: patientControllerGetPatientActivatedCouponsPaginated,
    queryKey: ["patient", Number(id), "coupons", "activated"],
  });

  return {
    patientUsedCoupons,
    patientActivatedCoupons,
  };
};
