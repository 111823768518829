import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { RequestParams, HttpResponse } from "../api";

export function useCreate<T, R>(
  mutation: (
    data: T,
    params?: RequestParams
  ) => Promise<AxiosResponse<HttpResponse & { payload?: R }, any>>,
  queryKey: Array<string | number>,
  onSuccess?:
    | ((
        data: R | null,
        variables: {
          requestData: T;
        },
        context: unknown
      ) => unknown)
    | undefined
) {
  const client = useQueryClient();
  const mutationResp = useMutation(
    async ({ requestData }: { requestData: T }) => {
      const { data } = await mutation(requestData);

      if (data.isSuccessful) {
        return data.payload!;
      }
      toast.error(data.message);
      return null;
    },
    {
      onSuccess: onSuccess
        ? onSuccess
        : () => {
            const allCacheKeys = client
              .getQueryCache()
              .getAll()
              .map((q) => q.queryKey)
              .filter((qk) => qk.includes(queryKey[0]));

            allCacheKeys.forEach((key) => {
              client.invalidateQueries({ queryKey: key, type: "all" });
            });
          },
    }
  );
  return mutationResp;
}
