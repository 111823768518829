/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import tw from "twin.macro";
import { useService } from "../../../api/hooks/services/useService";
import { useServiceEmployees } from "../../../api/hooks/services/useServiceEmployees";
import { useServiceRooms } from "../../../api/hooks/services/useServiceRooms";
import { useMe } from "../../../api/hooks/user/useMe";
import { Button } from "../../../ui/buttons/Button";
import { Typography } from "../../../ui/Typograhy";
import { NewServiceModal } from "../../departments/modals/NewServiceModal";

export const ServiceInfoSection = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { service } = useService({ id: Number(id) });
  const { rooms } = useServiceRooms({ id: Number(id) });
  const { employees } = useServiceEmployees({ id: Number(id) });
  const [isEditServiceDetailsModalOpen, setIsEditServiceDetailsModalOpen] =
    useState(false);
  const { t } = useTranslation();
  const { me } = useMe();
  return (
    <div
      css={[
        tw`flex flex-col items-end justify-between`,
        tw`border-r-1 border-r-gray-200`,
      ]}
    >
      <div css={[tw`max-w-80 w-full `, tw`pr-12`]}>
        <div css={[tw`px-4`]}>
          <Typography.BodyXLarge containerCss={[tw`mt-6 mb-12`]}>
            {service?.name || "-"}
          </Typography.BodyXLarge>
          <div css={[tw`flex gap-8`]}>
            <div>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("serviceDuration")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-8`]}>
                {`${service?.duration} min` || "-"}
              </Typography.BodySmall>
            </div>
            <div>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("price")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-8`]}>
                {service?.price ? `${service?.price} €` : "-"}{" "}
                {service?.price
                  ? `(${((service?.price || 0) * 7.435).toFixed(2)} kn)`
                  : "-"}
              </Typography.BodySmall>
            </div>
          </div>
          {(rooms?.data || [])?.length > 0 && (
            <>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("rooms")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-8`]}>
                {rooms.data?.map((r) => `${r.name} (${r.position})`).join(", ")}
              </Typography.BodySmall>
            </>
          )}
          {(employees?.data || [])?.length > 0 && (
            <>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("employees")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-8`]}>
                {employees.data
                  ?.map((e) => `${e.firstName} ${e.lastName}`)
                  .join(", ")}
              </Typography.BodySmall>
            </>
          )}

          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("description")}
          </Typography.BodyXSmall>
          <Typography.BodySmall
            containerCss={[tw`max-h-80 overflow-y-scroll scrollbar-hide`]}
          >
            {service?.description || "-"}
          </Typography.BodySmall>
        </div>
      </div>
      <div
        css={[
          tw`max-w-80 w-full py-8`,
          tw`border-t-1 border-t-gray-200`,
          tw`pr-12`,
        ]}
      >
        {me?.role?.permissions?.SERVICES?.UPDATE && (
          <Button.Outlined
            onClick={() => setIsEditServiceDetailsModalOpen(true)}
          >
            {t("editInfo")}
          </Button.Outlined>
        )}
      </div>
      {!!service?.id && rooms.isFetched && employees.isFetched && (
        <NewServiceModal
          open={isEditServiceDetailsModalOpen}
          service={{
            ...service,
            rooms: (rooms?.data || []).map((r) => r.id),
            employees: (employees?.data || []).map((e) => e.id),
          }}
          onClose={() => setIsEditServiceDetailsModalOpen(false)}
          departmentId={Number(state?.departmentId!)}
          categoryId={Number(state?.categoryId!)}
        />
      )}
    </div>
  );
};
