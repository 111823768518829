/** @jsxImportSource @emotion/react */

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RiAddFill,
  RiCalendarTodoLine,
  RiInformationLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import tw from "twin.macro";
import { useOrganisation } from "../../../../api/hooks/organisations/useOrganisation";
import { useMe } from "../../../../api/hooks/user/useMe";
import { EmptyState } from "../../../../components/empty/EmptyState";
import { hasSeenMessageState, searchState } from "../../../../state";
import { Maybe } from "../../../../types";
import { Button } from "../../../../ui/buttons/Button";
import { AsyncDataTable } from "../../../../ui/layout/data-table/async/AsyncDataTable";
import { TableCell } from "../../../../ui/layout/data-table/cell";
import { Typography } from "../../../../ui/Typograhy";
import { PatientServiceCellChip } from "../../components/PatientServiceCellChip";

export const PatientsListTab = ({
  setIsAddNewPatientModalOpen,
}: {
  setIsAddNewPatientModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [activeRowIndex, setActiveRowIndex] = useState<Maybe<number>>(null);
  const [hasSeenMessage, setHasSeenMessage] =
    useRecoilState(hasSeenMessageState);
  const search = useRecoilValue(searchState);
  const {
    organisationPatients: {
      data: organisationPatients,
      filters,
      setFilter,
      isFetching,
      page,
      setPage,
      size,
      setOrder,
      setOrderField,
      order,
      orderField,
      isFetched,
    },
  } = useOrganisation();

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (filters.term !== search) {
      setFilter("term", search);
    }
  }, [filters.term, search, setFilter]);

  const records = organisationPatients?.items ?? [];
  const total = organisationPatients?.totalItems ?? 0;
  const { me } = useMe();
  if ((organisationPatients?.items || []).length === 0 && isFetched) {
    return (
      <EmptyState
        title={t("noEnteredPatients")}
        buttonText={t("addNewPatient")}
        subtitle={t("addNewPatient")}
        buttonIcon={RiAddFill}
        onButtonClick={() => setIsAddNewPatientModalOpen(true)}
        containerCss={[tw`min-h-[calc(100vh - 10rem)]`]}
        hasButton={me.role?.permissions?.PATIENTS?.CREATE}
      />
    );
  }

  return (
    <div css={[tw`flex flex-col flex-1 h-full`]}>
      {records.length > 0 && !hasSeenMessage && (
        <div
          css={[
            tw`flex justify-between items-center`,
            tw`border-b-1 border-b-primary-300 bg-primary-100`,
            tw`h-14`,
            tw`lg: pl-5 pr-5`,
            tw`xl: p-0`,
          ]}
        >
          <div css={[tw`flex max-w-screen-xl w-full m-auto justify-between`]}>
            <div css={[tw`flex items-center`]}>
              <RiInformationLine css={[tw`mr-3.5 fill-primary-900 w-5 h-5`]} />
              <Typography.BodySmall containerCss={[tw`text-primary-900`]}>
                {t("usersSameForAllObjects")}
              </Typography.BodySmall>
            </div>
            <Button.Contained
              variant="small"
              onClick={() => setHasSeenMessage(true)}
            >
              {t("understandDoNotShow")}
            </Button.Contained>
          </div>
        </div>
      )}
      <AsyncDataTable
        activeRowIndex={activeRowIndex}
        loading={isFetching}
        data={records}
        containerCss={[tw`flex flex-col justify-between h-full`]}
        onRowClick={(u, i) => {
          navigate(`/patients/${u.id}`);
          setActiveRowIndex(u.id);
        }}
        hidePagination={total === 0}
        columns={[
          {
            Header: t("headerNameAndSurname"),
            accessor: "firstName",
            disableSortBy: true,
            sticky: "left",
            minWidth: 300,
            cell: (s) => (
              <TableCell.DottedText
                value={`${s.firstName} ${s.lastName}`}
                isSelected={s.id === activeRowIndex}
              />
            ),
          },
          {
            Header: t("headerBirthDate"),
            accessor: "birthDate",
            minWidth: 140,
            disableSortBy: true,
            cell: (s) => (
              <TableCell.Date
                value={new Date(s.birthDate)}
              />
            ),
          },
          {
            Header: t("headerEmail"),
            minWidth: 220,
            disableSortBy: true,
            accessor: "email",
            cell: (s) => <TableCell.Text value={s.email} />,
          },
          {
            Header: t("appointments"),
            accessor: "appointments",
            width: 420,
            disableSortBy: true,
            cell: (s) => (
              <div css={[tw`flex gap-2 overflow-x-scroll scrollbar-hide`]}>
                {s.appointments
                  .filter((ap) => !!ap?.service?.name)
                  .map((a) => (
                    <PatientServiceCellChip appointment={a} key={a.id} />
                  ))}
              </div>
            ),
          },
          {
            Header: "",
            align: "left",
            disableSortBy: true,
            minWidth: 200,
            sticky: "right",
            accessor: "note",
            cell: (s) =>
              me.role?.permissions?.APPOINTMENTS?.CREATE && (
                <TableCell.Button
                  lead={RiCalendarTodoLine}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate("/appointments", {
                      state: { patientId: s.id },
                    });
                  }}
                  variant="small"
                  containerCss={[tw`pl-2 pr-2 min-w-2`]}
                >
                  {t("order")}
                </TableCell.Button>
              ),
          },
        ]}
        currentPage={page}
        setPage={setPage}
        pageSize={size}
        maxPage={organisationPatients?.totalPages || 0}
        totalCount={total}
        setSortKey={setOrderField}
        setSortOrder={setOrder}
        sortKey={orderField}
        sortOrder={order}
      />
    </div>
  );
};
