import "twin.macro";

/** @jsxImportSource @emotion/react */
import { FC, PropsWithChildren } from "react";

import { useOnClickOutside } from "../hooks/use-on-outside-click";
import tw from "twin.macro";

export interface IOutsideClickProps {
  onOutsideClick: () => void;
}

export const OutsideClick: FC<PropsWithChildren<IOutsideClickProps>> = (
  props
) => {
  const ref = useOnClickOutside(props.onOutsideClick);
  return (
    <div css={[tw`relative`]} ref={ref}>
      {props.children}
    </div>
  );
};
