import { format } from "date-fns";

import invariant from "invariant";
import { Maybe } from "../types";

export async function copyToClipboard(text: string) {
  invariant(navigator?.clipboard, "Clipboard not supported");
  try {
    return navigator.clipboard.writeText(text);
  } catch (error) {
    console.warn("Copy failed", error);
  }
}

export interface IFormatDateOptions {
  dateFormat?: string;
  uppercase?: boolean;
}
export function formatDate(date: Date, options?: IFormatDateOptions) {
  const { dateFormat = "yyyy-MM-dd", uppercase = false } = options || {};

  const startDate = format(new Date(date), dateFormat);

  return uppercase ? startDate.toUpperCase() : startDate;
}

export function getInitialsFromName(name: string) {
  const allNames = name.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");
  return initials;
}

export function addAsterisk(label?: Maybe<string>, required?: boolean): string {
  if (!label) {
    return "";
  } else if (!required) {
    return label;
  } else {
    return `${label} *`;
  }
}

export const errorMessages = {
  required: "requiredError",
  fieldShort: "fieldShortError",
  fieldLong: "fieldLongError",
  max: "maxError",
  min: "minError",
  numberMin: "numberMinError",
  number: "numberError",
  emailBadFormat: "emailBadFormatError",
};

export function onKeyDownA11Y(options: {
  open?: Function;
  close?: Function;
}): React.KeyboardEventHandler {
  const { open, close } = options;
  return (e) => {
    if ([" ", "Enter"].includes(e.key)) {
      return open && open();
    } else if (["Escape"].includes(e.key)) {
      return close && close();
    }
  };
}
