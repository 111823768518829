/** @jsxImportSource @emotion/react */

import "twin.macro";

import { HTMLInputTypeAttribute, PropsWithChildren } from "react";
import {
  IFormFieldComponentBaseProps,
  Maybe,
  ReactComponent,
  TwinStyle,
} from "../../types";
import { useFormError, useFormField } from "./hooks";

import { FieldError } from "../components/FieldError";
import { TextInput } from "../controlled/TextInput";
import tw from "twin.macro";
import { RiErrorWarningLine } from "react-icons/ri";

interface IBaseFormTextInputProps extends IFormFieldComponentBaseProps {
  input:
    | typeof TextInput["Contained"]
    | typeof TextInput["Underlined"]
    | typeof TextInput["Outlined"];
  lead?: ReactComponent<{}>;
  trail?: ReactComponent<{}>;
  type?: Omit<HTMLInputTypeAttribute, "number"> | undefined;
  leadCss?: Maybe<TwinStyle>;
  trailCss?: Maybe<TwinStyle>;
  autofocus?: boolean;
  autocomplete?: Maybe<string>;
  position?: "first" | "last" | "middle";
}

const BaseFormTextInputForm = (props: IBaseFormTextInputProps) => {
  const { name, input, containerCss, position, ...rest } = props;
  const field = useFormField(name);
  const error = useFormError(name);
  const Input = input;
  return (
    <div css={[tw`flex-1 w-full`, containerCss]}>
      <Input
        onChange={field.onChange}
        value={field.value}
        onBlur={field.onBlur}
        error={error}
        trail={error ? RiErrorWarningLine : rest.trail}
        trailCss={error ? [tw`fill-error`] : rest.trailCss}
        {...rest}
      />
      {(!position || position === "last") && (
        <FieldError name={name} containerCss={[tw`ml-4`]} />
      )}
    </div>
  );
};

export type IFormTextInputProps = Omit<IBaseFormTextInputProps, "input">;

export const FormTextInput = {
  Contained: (props: PropsWithChildren<IFormTextInputProps>) => {
    return <BaseFormTextInputForm {...props} input={TextInput.Contained} />;
  },
  Underlined: (props: PropsWithChildren<IFormTextInputProps>) => {
    return <BaseFormTextInputForm {...props} input={TextInput.Underlined} />;
  },
  Outlined: (props: PropsWithChildren<IFormTextInputProps>) => {
    return <BaseFormTextInputForm {...props} input={TextInput.Outlined} />;
  },
};
