import { useQuery } from "@tanstack/react-query";
import { Api } from "../../api";
import { serviceEmployees } from "../../query-keys";

export const useServiceEmployees = ({ id }: { id?: number }) => {
  const {
    services: { serviceControllerGetServiceEmployees },
  } = new Api();

  const employees = useQuery(
    serviceEmployees(id),
    async () => {
      const { data } = await serviceControllerGetServiceEmployees(id!);

      if (data.isSuccessful) {
        return data.payload?.map((c) => ({
          ...c,
          serviceId: id,
        }));
      }
      return [];
    },
    {
      enabled: !!id,
    }
  );

  return {
    employees,
  };
};
