import "twin.macro";

import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { useOrganisation } from "../api/hooks/organisations/useOrganisation";
import { useMe } from "../api/hooks/user/useMe";
import { NoFacilities } from "../components/empty/NoFacilities";
import { Header } from "../components/layout/Header";
import { LoginPage } from "../pages/auth/LoginPage";
import { NoPermissionsPage } from "../pages/NoPermissionsPage";
import { OrganisationPage } from "../pages/organisation/OrganisationPage";
import { isLoggedInState, selectedOrganisationIdState } from "../state";
import { routeComponents, routes } from "./routing";
import { PageContainer } from "../components/layout/PageContainer";
import tw from "twin.macro";

/** @jsxImportSource @emotion/react */
const headerRoutes = ["PATIENTS", "DEPARTMENTS", "EMPLOYEES", "MY_SCHEDULE"];
export const AppRouter = () => {
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const organisationId = useRecoilValue(selectedOrganisationIdState);
  const { me, isLoadingMe } = useMe();
  const {
    organisationFacilities: { data: organisationFacilities, isInitialLoading },
  } = useOrganisation();
  const hasFacility = (organisationFacilities || [])?.length > 0;

  const availableRoutes = useMemo(
    () =>
      me?.role?.permissions
        ? Object.entries(routes({ permissions: me?.role?.permissions })).sort(
            (a, b) =>
              headerRoutes.indexOf(a[0]) > headerRoutes.indexOf(b[0]) ? 1 : -1
          )
        : [],
    [me?.role?.permissions]
  );

  const backupRoute = availableRoutes.find((ar) =>
    headerRoutes.includes(ar[0])
  );

  const availableRouteComponents = useMemo(
    () =>
      availableRoutes
        .map((value) => {
          const [AllComponent, ByIdComponent] = [
            routeComponents[value[0]]?.all,
            routeComponents[value[0]]?.byId,
          ];

          const permissionRoute = availableRoutes.find(
            (r) => r[0] === value[0]
          );
          return [
            !!AllComponent ? (
              <Route
                key={permissionRoute?.[1]?.all}
                element={<AllComponent />}
                path={permissionRoute?.[1]?.all}
              />
            ) : undefined,
            !!ByIdComponent ? (
              <Route
                key={permissionRoute?.[1]?.byId()}
                element={<ByIdComponent />}
                path={
                  permissionRoute?.[0] === "MY_SCHEDULE"
                    ? permissionRoute?.[1]?.byId(me?.id?.toString())
                    : permissionRoute?.[1]?.byId()
                }
              />
            ) : undefined,
          ];
        })
        .flat()
        .filter((r) => !!r),
    [availableRoutes, me?.id]
  );

  return (
    <Routes>
      {!isLoggedIn ? (
        <>
          <Route path={"/login"} element={<LoginPage />} />
          <Route path={"*"} element={<Navigate to={"/login"} />} />
        </>
      ) : (
        <Route
          path={"/"}
          element={
            <div>
              <Header />
              <div tw="flex flex-row justify-center items-center">
                <Outlet />
              </div>
            </div>
          }
        >
          {me?.id && (availableRouteComponents || []).length > 0 ? (
            hasFacility ? (
              <>
                {availableRouteComponents}
                <Route
                  path={"/"}
                  element={
                    <Navigate
                      to={backupRoute ? backupRoute[1].all : "/patients"}
                    />
                  }
                />
                <Route
                  path={"/my_schedule"}
                  element={<Navigate to={`/my_schedule/${me?.id}`} />}
                />
                <Route
                  path={"*"}
                  element={
                    <Navigate
                      to={backupRoute ? backupRoute[1].all : "/patients"}
                    />
                  }
                />
              </>
            ) : (
              <>
                <Route path={"/"} element={<NoFacilities />} />
                {me?.role?.permissions.ROLES_AND_PERMISSIONS?.READ && (
                  <Route
                    path={`/roles_and_permissions/${organisationId}`}
                    element={<OrganisationPage />}
                  />
                )}
                <Route path={"*"} element={<Navigate to={"/"} />} />
              </>
            )
          ) : isLoadingMe || isInitialLoading ? (
            <>
              <Route
                path={"/"}
                element={
                  <PageContainer
                    containerCss={[tw`justify-center items-center flex`]}
                  >
                    <div
                      css={[
                        tw`w-10 h-10 border-2 border-t-transparent border-solid rounded-full animate-spin`,
                      ]}
                    />
                  </PageContainer>
                }
              />
              <Route path={"*"} element={<Navigate to={"/"} />} />
            </>
          ) : (
            <>
              <Route path={"/"} element={<NoPermissionsPage />} />
              <Route path={"*"} element={<Navigate to={"/"} />} />
            </>
          )}
        </Route>
      )}
    </Routes>
  );
};
