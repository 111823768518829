/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiAddLine, RiCoupon3Line } from "react-icons/ri";
import tw from "twin.macro";
import { useFacilityCoupons } from "../../api/hooks/facilities/useFacilityCoupons";
import { useMe } from "../../api/hooks/user/useMe";
import { PageContainer } from "../../components/layout/PageContainer";
import { Button } from "../../ui/buttons/Button";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { AddNewCollectionOfPointsModal } from "./modals/AddNewCollectionOfPointsModal";
import { ManageCouponModal } from "./modals/ManageCouponModal";
import { ArchivedCouponsTab } from "./tabs/ArchivedCouponsTab";
import { CollectingPointsByCouponsTab } from "./tabs/CollectingPointsByCouponsTab";
import { CouponsInAnnouncementTab } from "./tabs/CouponsInAnnouncementTab";
import { CurrentCouponsTab } from "./tabs/CurrentCouponsTab";

export const CouponsPage = () => {
  const {
    currentCoupons: {
      data: currentCoupons,
      isInitialLoading: isCurrentCouponsInitialLoading,
    },
    pastCoupons: {
      data: pastCoupons,
      isInitialLoading: isPastCouponsInitialLoading,
    },
    upcomingCoupons: {
      data: upcomingCoupons,
      isInitialLoading: isUpcommingCouponsInitialLoading,
    },
  } = useFacilityCoupons();
  const [isAddNewCouponModalOpen, setIsAddNewCouponModalOpen] = useState(false);
  const [
    isAddNewCollectionOfPointsModalOpen,
    setIsAddNewCollectionOfPointsModalOpen,
  ] = useState(false);
  const { me } = useMe();

  const { t } = useTranslation();
  return (
    <PageContainer>
      {!isCurrentCouponsInitialLoading &&
        !isPastCouponsInitialLoading &&
        !isUpcommingCouponsInitialLoading && (
          <Tabs
            rightComponent={
              <div css={[tw`flex justify-between gap-3`]}>
                {me.role?.permissions?.AWARDS?.CREATE && (
                  <Button.Outlined
                    lead={RiAddLine}
                    onClick={() => setIsAddNewCollectionOfPointsModalOpen(true)}
                  >
                    {t("addNewWayOfCollectiingPoints")}
                  </Button.Outlined>
                )}
                {me.role?.permissions?.COUPONS?.CREATE && (
                  <Button.Contained
                    lead={RiCoupon3Line}
                    onClick={() => setIsAddNewCouponModalOpen(true)}
                  >
                    {t("addCoupon")}
                  </Button.Contained>
                )}
              </div>
            }
            containerCss={[tw`m-auto`]}
          >
            <Tabs.Item
              text={t("currentCoupons")}
              notificationNumber={currentCoupons?.totalItems || 0}
            >
              <Divider
                type="vertical"
                containerCss={[tw`bg-gray-200 h-0.25`]}
              />
              <CurrentCouponsTab />
            </Tabs.Item>
            <Tabs.Item
              text={t("announcementCoupons")}
              notificationNumber={upcomingCoupons?.totalItems || 0}
            >
              <Divider
                type="vertical"
                containerCss={[tw`bg-gray-200 h-0.25`]}
              />
              <CouponsInAnnouncementTab />
            </Tabs.Item>
            <Tabs.Item
              text={t("couponArchive")}
              notificationNumber={pastCoupons?.totalItems || 0}
            >
              <Divider
                type="vertical"
                containerCss={[tw`bg-gray-200 h-0.25`]}
              />
              <ArchivedCouponsTab />
            </Tabs.Item>
            <Tabs.Item text={t("collectingPoints")}>
              <Divider
                type="vertical"
                containerCss={[tw`bg-gray-200 h-0.25`]}
              />
              <CollectingPointsByCouponsTab />
            </Tabs.Item>
          </Tabs>
        )}
      <ManageCouponModal
        onClose={() => setIsAddNewCouponModalOpen(false)}
        open={isAddNewCouponModalOpen}
      />
      <AddNewCollectionOfPointsModal
        onClose={() => setIsAddNewCollectionOfPointsModalOpen(false)}
        open={isAddNewCollectionOfPointsModalOpen}
      />
    </PageContainer>
  );
};
