import { Api } from "../../api";
import { useGetPaginated } from "../../api-hooks/useGetPaginated";

export const useServiceAppointments = ({ id }: { id?: number }) => {
  const {
    appointments: {
      appointmentControllerGetServiceAppointmentsPaginated,
      appointmentControllerGetServiceHistoryAppointmentsPaginated,
    },
  } = new Api();

  const serviceAppointments = useGetPaginated({
    id: id!,
    query: appointmentControllerGetServiceAppointmentsPaginated,
    queryKey: ["service", id!, "appointments"],
  });

  const serviceHistoryAppointments = useGetPaginated({
    id: id!,
    query: appointmentControllerGetServiceHistoryAppointmentsPaginated,
    queryKey: ["service", id!, "appointments", "history"],
  });

  return {
    serviceAppointments,
    serviceHistoryAppointments,
  };
};
