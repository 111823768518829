import { CustomRouting, FeatureType, PermissionType } from "../enums";
import { NewAppointmentPage } from "../pages/appointments/NewAppointmentPage";
import { CouponDetailsPage } from "../pages/coupons/CouponDetailsPage";
import { CouponsPage } from "../pages/coupons/CouponsPage";
import { DepartmentPage } from "../pages/departments/DepartmentPage";
import { DepartmentsPage } from "../pages/departments/DepartmentsPage";
import { EmployeePage } from "../pages/employees/EmployeePage";
import { EmployeesPage } from "../pages/employees/EmployeesPage";
import { FacilityPage } from "../pages/facilities/FacilityPage";
import { NewsPage } from "../pages/news/NewsPage";
import { OrganisationPage } from "../pages/organisation/OrganisationPage";
import { PatientDetailsPage } from "../pages/patients/PatientDetailsPage";
import { PatientsPage } from "../pages/patients/PatientsPage";
import { ServicePage } from "../pages/services/ServicePage";

export const routeComponents = {
  PATIENTS: {
    byId: PatientDetailsPage,
    all: PatientsPage,
  },
  DEPARTMENTS: {
    byId: DepartmentPage,
    all: DepartmentsPage,
  },
  EMPLOYEES: {
    byId: EmployeePage,
    all: EmployeesPage,
  },
  APPOINTMENTS: {
    all: NewAppointmentPage,
  },
  MY_SCHEDULE: {
    byId: EmployeePage,
  },
  COUPONS: {
    byId: CouponDetailsPage,
    all: CouponsPage,
  },
  NEWS: {
    all: NewsPage,
  },
  ROLES_AND_PERMISSIONS: {
    byId: OrganisationPage,
  },
  FACILITIES: {
    byId: FacilityPage,
  },
  SERVICES: {
    byId: ServicePage,
  },
  ORGANISATION: {
    byId: OrganisationPage,
  },
};

export const routes = ({
  permissions,
}: {
  permissions?: Record<FeatureType, Record<PermissionType, boolean>>;
}) =>
  [
    ...Object.entries(permissions || {})
      .filter((entry) => entry[1].READ)
      .map((entry) => entry[0]),
    "LOGIN",
    "HOME",
  ].reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: {
        byId:
          curr === "HOME"
            ? () => "/"
            : (id?: string) => `/${curr.toLowerCase()}/${id ?? ":id"}`,
        all: curr === "HOME" ? "/" : `/${curr.toLowerCase()}`,
      },
    };
  }, {}) as Record<
    Lowercase<FeatureType | CustomRouting>,
    { byId: (id?: string) => string; all: string }
  >;

// {
//   components: {
//     base: "/components",
//     buttons: "/components/buttons",
//     forms: "/components/forms",
//     layout: "/components/layout",
//     indicators: "/components/indicators",
//     typography: "/components/typography",
//     popups: "/components/popups",
//   },
//   home: {
//     base: "/",
//     initial: "/",
//   },
//   auth: {
//     login: "/login",
//   },
//   patients: {
//     all: "/patients",
//     byId: (id?: string) => `/patients/${id ?? ":id"}`,
//   },
//   departments: {
//     all: "/departments",
//     byId: (id?: string) => `/departments/${id ?? ":id"}`,
//   },
//   coupons: {
//     all: "/coupons",
//     byId: (id?: string) => `/coupons/${id ?? ":id"}`,
//   },
//   services: {
//     all: "/services",
//     byId: (id?: string) => `/services/${id ?? ":id"}`,
//   },
//   news: { all: "/news", byId: (id?: string) => `/news/${id ?? ":id"}` },
//   employees: {
//     all: "/employees",
//     byId: (id?: string) => `/employees/${id ?? ":id"}`,
//   },
//   appointments: {
//     new: "/appointments/new",
//   },
//   facilities: {
//     noFacility: "/no-facility",
//     all: "/facilities",
//     byId: (id?: string) => `/facilities/${id ?? ":id"}`,
//   },
//   organisation: {
//     byId: (id?: string) => `/organisation/${id ?? ":id"}`,
//   },
// };
