import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  Api,
  RequestAppointmentDocumentLinksRequestBodyDto as RequestMedicalRecordDocumentLinksRequestBodyDto,
  RequestCouponImageLinksRequestBodyDto,
  RequestEmployeeImageLinksRequestBodyDto,
  RequestFacilityImageLinksRequestBodyDto,
  RequestNewsImageLinksRequestBodyDto,
} from "../../api";

export const useFileUpload = () => {
  const {
    medicalRecords: {
      medicalRecordControllerRequestMedicalRecordDocumentLinks,
    },
    news: { newsControllerRequestNewsImageLinks },
    coupons: { couponControllerRequestCouponImageLinks },
    employees: { employeeControllerRequestEmployeeImageLinks },
    facilities: { facilityControllerRequestFacilityImageLinks },
  } = new Api();

  const { mutateAsync: requestMedicalRecordDocumentLinks } = useMutation(
    async ({
      fileName,
    }: {
      fileName: RequestMedicalRecordDocumentLinksRequestBodyDto;
    }) => {
      const { data } =
        await medicalRecordControllerRequestMedicalRecordDocumentLinks(
          fileName
        );
      if (data.isSuccessful) {
        return data.payload;
      } else {
        toast.error(data.message);
        return null;
      }
    }
  );

  const { mutateAsync: requestNewsImageLinks } = useMutation(
    async ({ fileName }: { fileName: RequestNewsImageLinksRequestBodyDto }) => {
      const { data } = await newsControllerRequestNewsImageLinks(fileName);
      if (data.isSuccessful) {
        return data.payload;
      } else {
        toast.error(data.message);
        return null;
      }
    }
  );

  const { mutateAsync: requestCouponImageLinks } = useMutation(
    async ({
      fileName,
    }: {
      fileName: RequestCouponImageLinksRequestBodyDto;
    }) => {
      const { data } = await couponControllerRequestCouponImageLinks(fileName);
      if (data.isSuccessful) {
        return data.payload;
      } else {
        toast.error(data.message);
        return null;
      }
    }
  );

  const { mutateAsync: requestEmployeeImageLinks } = useMutation(
    async ({
      fileName,
    }: {
      fileName: RequestEmployeeImageLinksRequestBodyDto;
    }) => {
      const { data } = await employeeControllerRequestEmployeeImageLinks(
        fileName
      );
      if (data.isSuccessful) {
        return data.payload;
      } else {
        toast.error(data.message);
        return null;
      }
    }
  );

  const { mutateAsync: requestFacilityImageLinks } = useMutation(
    async ({
      fileName,
    }: {
      fileName: RequestFacilityImageLinksRequestBodyDto;
    }) => {
      const { data } = await facilityControllerRequestFacilityImageLinks(
        fileName
      );
      if (data.isSuccessful) {
        return data.payload;
      } else {
        toast.error(data.message);
        return null;
      }
    }
  );

  return {
    requestMedicalRecordDocumentLinks,
    requestNewsImageLinks,
    requestCouponImageLinks,
    requestEmployeeImageLinks,
    requestFacilityImageLinks,
  };
};
