import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { GetFacilityDepartmentsDepartment } from "../../api/api";
import { Assets } from "../../assets";
import { Typography } from "../../ui/Typograhy";

/** @jsxImportSource @emotion/react */

export const DepartmentCard = ({
  id,
  name,
  iconUrl = "",
}: GetFacilityDepartmentsDepartment) => {
  const navigate = useNavigate();

  if (name === "") {
    return (
      <div key={Math.random() * 100} css={[tw`w-[calc(33% - 16px)]`]}></div>
    );
  }

  return (
    <div
      css={[
        tw`w-[calc(33% - 16px)] flex border-1 border-gray-200 rounded-lg justify-between`,
        tw`hover:(border-primary cursor-pointer)`,
        tw`flex gap-5 items-center  py-4 px-5`,
      ]}
      onClick={() => navigate(`/departments/${id}`)}
      key={id}
    >
      <div css={[tw`p-0.5 bg-primary-100 rounded-md`]}>
        {iconUrl.length > 0 ? (
          <img alt="department" src={iconUrl} />
        ) : (
          <Assets.DepartmentPlaceholder />
        )}
      </div>
      <Typography.Subtitle containerCss={[tw`text-right`]}>
        {name}
      </Typography.Subtitle>
    </div>
  );
};
