/** @jsxImportSource @emotion/react */

import { useState } from "react";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiTimeLine,
} from "react-icons/ri";
import tw from "twin.macro";
import { Button } from "../../ui/buttons/Button";
import { IconButton } from "../../ui/buttons/IconButton";
import { Modal } from "../../ui/popups/Modal";

interface IMessageModalProps {
  open: boolean;
  selectedDay?: Date;
  onClose: () => void;
  onDayClick: (value: Date) => void;
}

export const CalendarModal = ({
  onDayClick,
  selectedDay,
  open,
  onClose,
}: IMessageModalProps) => {
  const [tempSelectedDay, setTempSelectedDay] = useState(
    selectedDay || new Date()
  );
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={onClose}
      containerCss={[tw`bg-white w-fit`]}
      footerChildren={
        <div css={[tw`flex items-center gap-3`]}>
          <Button.Outlined containerCss={[tw`w-full`]} onClick={onClose}>
            {t("quit")}
          </Button.Outlined>
          <Button.Contained
            containerCss={[tw`w-full`]}
            onClick={() => {
              onDayClick(tempSelectedDay);
              onClose();
            }}
          >
            {t("apply")}
          </Button.Contained>
        </div>
      }
    >
      <Calendar
        showDoubleView={false}
        minDetail="month"
        value={tempSelectedDay}
        next2Label={null}
        tileDisabled={(props) => {
          if ([6, 0].includes(props.date.getDay())) {
            return true;
          }
          return false;
        }}
        tileClassName="tiletile"
        onClickDay={(date) => {
          date.setHours(2);
          setTempSelectedDay(date);
        }}
        tileContent={(props) => {
          const hasClock = false;
          return (
            <div css={[tw`relative`]}>
              {props.date.getDate() === new Date().getDate() &&
              props.date.getMonth() === new Date().getMonth() ? (
                <div css={[tw`w-2.5 h-1 bg-primary rounded-sm mt-2 -mb-2`]} />
              ) : (
                <div
                  css={[tw`w-2.5 h-1 bg-transparent rounded-sm mt-2 -mb-2`]}
                />
              )}
              {hasClock && <RiTimeLine css={[tw`absolute -top-9 left-1.5`]} />}
            </div>
          );
        }}
        prev2Label={null}
        nextLabel={
          <IconButton.Contained
            icon={RiArrowRightSLine}
            iconCss={[tw`w-6 h-6`]}
            containerCss={[
              tw`w-6 h-6 flex justify-center bg-gray-100 rounded-full`,
            ]}
          />
        }
        prevLabel={
          <IconButton.Contained
            icon={RiArrowLeftSLine}
            iconCss={[tw`w-6 h-6`]}
            containerCss={[
              tw`w-6 h-6 flex justify-center bg-gray-100 rounded-full`,
            ]}
          />
        }
        css={[tw`border-transparent w-full max-w-96 p-0 m-0`]}
      />
    </Modal>
  );
};
