import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import * as yup from "yup";

import { useEditNews } from "../../../api/hooks/news/useEditNews";
import { scrollToError } from "../../../fields/form/utils";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { Modal } from "../../../ui/popups/Modal";
import { errorMessages } from "../../../utils";

/** @jsxImportSource @emotion/react */

const schema = yup.object().shape({}).required(errorMessages.required);

interface IEditNewsModalProps {
  open: boolean;
  onClose: () => void;
  newsId: number;
}

type IForm = yup.InferType<typeof schema>;

export const PublishDraftNewsModal = ({
  open,
  onClose,
  newsId,
}: IEditNewsModalProps) => {
  const { t } = useTranslation();
  const { publishDraftNews } = useEditNews({ id: Number(newsId) });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      await publishDraftNews({
        requestData: {},
      });
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={t("publishNews") as string}
        footerChildren={
          <div
            css={[
              tw`flex bg-gray-100 pr-4 pl-4 pt-2 pb-2 gap-3 -ml-6 -mb-6 -mr-6 rounded-br-md`,
            ]}
          >
            <div css={[tw`flex w-full gap-3`]}>
              <Button.Outlined
                containerCss={[tw`w-full`]}
                onClick={() => onClose()}
              >
                {t("cancel")}
              </Button.Outlined>
            </div>
            <Button.Contained
              containerCss={[tw`w-full`]}
              onClick={() => onSubmit()}
            >
              {t("publishNews")}
            </Button.Contained>
          </div>
        }
      ></Modal>
    </FormProvider>
  );
};
