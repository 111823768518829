import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import { Api } from "../../../api/api";
import { useEmployeeWorkingHours } from "../../../api/hooks/employees/useEmployeeWorkingHours";
import { WeekCalendar } from "../../../components/calendar/WeekCalendar";
import { selectedFacilityIdState } from "../../../state";
import { EmployeeDetailsSection } from "../EmployeeDetailsSection";
/** @jsxImportSource @emotion/react */

export const EmployeeScheduleTab = ({ activeTab }) => {
  const [selectedDay, setSelectedDay] = useState(new Date());
  const { pathname } = useLocation();
  const altId = pathname.split("/");

  const { id: idParam } = useParams();
  const id = idParam || altId[altId.length - 1];

  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const {
    appointments: {
      appointmentControllerGetDepartmentRoomsWithAppointmentsByDate,
    },
  } = new Api();

  const {
    employeeWorkingHours: { data: employeeWorkingHours },
  } = useEmployeeWorkingHours({
    id: Number(id),
    facilityId: Number(selectedFacilityId),
  });

  const { data: appointments } = useQuery(
    ["employee", id, "appointments", selectedDay.getTime()],
    async () => {
      const { data } =
        await appointmentControllerGetDepartmentRoomsWithAppointmentsByDate(
          Number(id),
          { weekStartDate: selectedDay.toISOString() }
        );

      if (data.code === 200) {
        return data.payload?.appointments.map((app) => ({
          id: app.id,
          startDate: new Date(app.startTime),
          endDate: new Date(app.endTime),
          patientName: `${app.patient.firstName} ${app.patient.lastName}`,
          serviceName: app.service.name,
          doctorName: `${app.employee.firstName} ${app.employee.lastName}`,
        }));
      }
      return [];
    },
    {
      enabled: !!selectedDay && !!id,
    }
  );

  return (
    <div css={[tw`flex h-full justify-center`]}>
      <EmployeeDetailsSection />
      <div css={[tw`w-full max-w-[965px]`]}>
        <WeekCalendar
          schedule={appointments || []}
          activeTab={activeTab}
          workTimes={employeeWorkingHours?.payload || []}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          containerCss={[tw`ml-5`]}
        />
      </div>
    </div>
  );
};
