/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiAddFill } from "react-icons/ri";
import tw from "twin.macro";
import { useMe } from "../../api/hooks/user/useMe";
import { PageContainer } from "../../components/layout/PageContainer";
import { Button } from "../../ui/buttons/Button";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { NewDepartmentModal } from "./modals/NewDepartmentModal";
import { DepartmentsTab } from "./tabs/DepartmentsTab";

export const DepartmentsPage = () => {
  const [isNewDepartmentModalOpen, setIsNewDepartmentModalOpen] =
    useState(false);
  const { t } = useTranslation();
  const { me } = useMe();
  return (
    <PageContainer>
      <Tabs
        rightComponent={
          <div css={[tw`flex justify-between gap-3`]}>
            {me?.role?.permissions?.DEPARTMENTS?.CREATE && (
              <Button.Contained
                lead={RiAddFill}
                onClick={() => setIsNewDepartmentModalOpen(true)}
              >
                {t("addNewDepartment")}
              </Button.Contained>
            )}
          </div>
        }
        containerCss={[tw`m-auto`]}
      >
        <Tabs.Item text={t("departments")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <DepartmentsTab
            setIsNewDepartmentModalOpen={setIsNewDepartmentModalOpen}
          />
        </Tabs.Item>
      </Tabs>
      <NewDepartmentModal
        open={isNewDepartmentModalOpen}
        onClose={() => setIsNewDepartmentModalOpen(false)}
      />
    </PageContainer>
  );
};
