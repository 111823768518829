import { useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { refetchAllContainingQueryKey } from "../../../../fields/form/utils";
import { selectedOrganisationIdState } from "../../../../state";
import { Api } from "../../../api";
import { useGetById } from "../../../api-hooks/useGetById";
import { useUpdate } from "../../../api-hooks/useUpdate";
import {
  organisationRoleKey,
  organisationRoleWPermissionsKey,
} from "../../../query-keys";

export const useRole = ({ id }: { id: number }) => {
  const {
    roles: {
      roleControllerGetRole,
      roleControllerUpdateRolePermissions,
      roleControllerUpdateRole,
    },
  } = new Api();
  const client = useQueryClient();
  const organisationId = useRecoilValue(selectedOrganisationIdState);

  const role = useGetById({
    id: Number(id),
    query: roleControllerGetRole,
    queryKey: ["role", id],
  });

  const { mutateAsync: updateRolePermissions } = useUpdate(
    id,
    roleControllerUpdateRolePermissions,
    ["role", id],
    async () => {
      client.refetchQueries(["role", id, "permissions"]);
      client.refetchQueries(["me"]);
      client.refetchQueries(organisationRoleWPermissionsKey(organisationId));
      refetchAllContainingQueryKey({ client, queryKey: ["role", id] });
    }
  );
  const { mutateAsync: updateRole } = useUpdate(
    id,
    roleControllerUpdateRole,
    ["role", id],
    async () => {
      client.refetchQueries(["role", id]);
      client.refetchQueries(organisationRoleKey(organisationId));
      client.refetchQueries(organisationRoleWPermissionsKey(organisationId));
      refetchAllContainingQueryKey({ client, queryKey: ["role", id] });
    }
  );

  // const { mutateAsync: deleteCategory } = useDelete(
  //   id,
  //   categoryControllerDeleteCategory,
  //   ["categories"]
  // );

  return {
    role,
    updateRole,
    updateRolePermissions,
  };
};
