import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiCalendarTodoLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { GetEmployeeMeResponseDto } from "../../../api/api";
import { useMe } from "../../../api/hooks/user/useMe";
import { ServiceWithParentId } from "../../../types";
import { Button } from "../../../ui/buttons/Button";
import { Typography } from "../../../ui/Typograhy";
import { SearchPatientModal } from "../../patients/modals/SearchPatientModal";
/** @jsxImportSource @emotion/react */

export const ServiceRow = ({ service }: { service: ServiceWithParentId }) => {
  const [isSearchPatientModalOpen, setIsSearchPatientModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { me } = useMe();
  return (
    <>
      <div
        key={service.id}
        css={[tw`flex justify-center border-b-1 border-b-gray-100`]}
      >
        <div
          css={[tw`max-w-screen-xl w-full  flex justify-between items-center`]}
        >
          <div
            css={[
              tw`flex flex-1 items-center justify-between px-4 py-4 hover:(bg-gray-50 cursor-pointer)`,
            ]}
            onClick={() =>
              navigate(`/services/${service.id}`, {
                state: {
                  departmentId: service.departmentId,
                  categoryId: service?.categoryId,
                },
              })
            }
          >
            <Typography.BodyMedium key={service.id}>
              {service.name}
            </Typography.BodyMedium>
            <Typography.BodyMedium>
              {service.duration} min
            </Typography.BodyMedium>
          </div>
          {me.role?.permissions?.APPOINTMENTS?.CREATE && (
            <Button.Text
              containerCss={[tw`pl-2 pr-2 min-w-2`]}
              lead={RiCalendarTodoLine}
              onClick={() => setIsSearchPatientModalOpen(true)}
            >
              {t("order")}
            </Button.Text>
          )}
        </div>
      </div>
      <SearchPatientModal
        open={isSearchPatientModalOpen}
        onClose={() => setIsSearchPatientModalOpen(false)}
        serviceId={service.id}
        departmentId={service.departmentId!}
      />
    </>
  );
};
