import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";

export const useCoupons = () => {
  const {
    coupons: { couponControllerCreateCoupon },
  } = new Api();
  const { mutateAsync: createCoupon } = useCreate(
    couponControllerCreateCoupon,
    ["coupons"]
  );

  return {
    createCoupon,
  };
};
