/** @jsxImportSource @emotion/react */

import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import tw from "twin.macro";
import { useEmployee } from "../../api/hooks/employees/useEmployee";
import { useMe } from "../../api/hooks/user/useMe";
import { Button } from "../../ui/buttons/Button";
import { Typography } from "../../ui/Typograhy";
import { ManageEmployeeModal } from "./modals/ManageEmployeeModal";

export const EmployeeDetailsSection = () => {
  const { id: idParam } = useParams();
  const { pathname } = useLocation();
  const altId = pathname.split("/");
  const id = idParam || altId[altId.length - 1];
  const { me } = useMe();
  const {
    employee: { data: employee },
  } = useEmployee({ id: Number(id) });
  const isMySchedule = pathname.includes("my_schedule");

  const [isManageEmployeeModalOpen, setIsManageEmployeeModalOpen] =
    useState(false);

  const { t } = useTranslation();
  return (
    <div
      css={[
        tw`flex flex-col items-end max-w-80 w-full justify-between`,
        tw`border-r-1 border-r-gray-200`,
      ]}
    >
      <div css={[tw`w-full max-w-80 `]}>
        <div css={[tw`px-4`]}>
          <div tw="flex mt-6 mb-8 flex-row w-full justify-between">
            <Typography.BodyXLarge>
              {employee?.firstName || ""} {employee?.lastName || ""}
            </Typography.BodyXLarge>
            {employee?.imageUrl && (
              <img
                src={employee?.imageUrl}
                alt="profile"
                tw="rounded-full w-9 h-9 flex self-end"
              />
            )}
          </div>
          <div css={[tw`flex gap-8`]}>
            <div>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("createDate")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-8`]}>
                {format(
                  new Date(employee?.createdAt || new Date()),
                  "dd. MMMM yyyy."
                ) || "-"}
              </Typography.BodySmall>
            </div>
            <div>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("role")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-8`]}>
                {employee?.role?.name || "-"}
              </Typography.BodySmall>
            </div>
          </div>
          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("facility")}
          </Typography.BodyXSmall>
          <Typography.BodySmall containerCss={[tw`mb-8`]}>
            {employee?.facilityName}
          </Typography.BodySmall>
          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("email")}
          </Typography.BodyXSmall>
          <Typography.BodySmall containerCss={[tw`mb-8`]}>
            {employee?.email || "-"}
          </Typography.BodySmall>
          {/* <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("password")}
          </Typography.BodyXSmall>
          <div tw="flex mt-2.5">
            <GoPrimitiveDot />
            <GoPrimitiveDot />
            <GoPrimitiveDot />
            <GoPrimitiveDot />
            <GoPrimitiveDot />
            <GoPrimitiveDot />
            <GoPrimitiveDot />
            <GoPrimitiveDot />
          </div> */}
        </div>
      </div>
      <div css={[tw`max-w-80 w-full py-8`]}>
        {isMySchedule
          ? me.role?.permissions?.MY_SCHEDULE?.UPDATE
          : me?.role?.permissions?.EMPLOYEES?.UPDATE && (
              <Button.Outlined
                onClick={() => setIsManageEmployeeModalOpen(true)}
              >
                {t("editInfo")}
              </Button.Outlined>
            )}
      </div>
      {!!employee?.id && (
        <ManageEmployeeModal
          onClose={() => setIsManageEmployeeModalOpen(false)}
          open={isManageEmployeeModalOpen}
          employeeId={employee?.id}
        />
      )}
    </div>
  );
};
