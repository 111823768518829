import "twin.macro";

import React, { PropsWithChildren } from "react";

import { Button } from "../ui/buttons/Button";
import { CenterContainer } from "./layout/CenterContainer";
import { Typography } from "../ui/Typograhy";
import tw from "twin.macro";
import { t } from "i18next";
import { RiRefreshLine } from "react-icons/ri";
import { Assets } from "../assets";

/** @jsxImportSource @emotion/react */

export class ErrorBoundary extends React.Component<
  PropsWithChildren<any>,
  { hasError: boolean }
> {
  public constructor(props: PropsWithChildren<any>) {
    super(props);
    this.state = { hasError: false };
  }
  public static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  public componentDidCatch(error: any, errorInfo: any): void {
    console.error(error, errorInfo);
  }

  public render(): JSX.Element | any {
    if (!this.state.hasError) {
      return this.props.children;
    }
    return (
      <CenterContainer>
        <div css={[tw`flex flex-col justify-center items-center text-center`]}>
          <Assets.GeneralErrorImage css={[tw`mb-4`]} />
          <Typography.H3 containerCss={[tw`mb-6`]}>
            {t("somethingWentWrong")}
          </Typography.H3>
          <Typography.BodyLarge containerCss={[tw`mb-10`]}>
            {t("pleaseTryAgain")}
          </Typography.BodyLarge>
          <Button.Contained
            onClick={() => {
              window.location.reload();
            }}
            containerCss={[tw`min-w-60`]}
            lead={RiRefreshLine}
          >
            {t("reload")}
          </Button.Contained>
        </div>
      </CenterContainer>
    );
  }
}
