/** @jsxImportSource @emotion/react */

import { ArrayInterpolation, Interpolation } from "@emotion/react";
import { MouseEventHandler } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Theme } from "react-toastify";
import tw from "twin.macro";
import { IComponentBaseProps } from "../../types";
import { onKeyDownA11Y } from "../../utils";
import { Typography } from "../Typograhy";

export interface IChipProps extends IComponentBaseProps {
  value: string | number;
  textCss?: any;
  type:
    | "white"
    | "black"
    | "red"
    | "green"
    | "blue"
    | "yellow"
    | "gray"
    | "blue-invert"
    | "table";
  onClose?: () => void;
  onClick?: MouseEventHandler<HTMLSpanElement>;
}

export const Chip = (props: IChipProps) => {
  const clickable = props.onClick;
  const closeable = props.onClose;
  return (
    <div
      onClick={props.onClick}
      onKeyDown={onKeyDownA11Y({ open: props.onClick })}
      role={clickable ? "button" : undefined}
      tabIndex={clickable ? 0 : undefined}
      css={[
        tw`py-0.5 px-2.5 rounded-[4px] w-fit inline-flex flex-row items-center gap-2`,
        clickable && tw`cursor-pointer`,
        props.type === "table" && tw`bg-white border border-gray-200 py-1 px-2`,
        props.type === "gray" && tw`bg-gray-300`,
        props.type === "green" && tw`bg-success`,
        props.type === "blue" && tw`bg-info`,
        props.type === "blue-invert" && tw`bg-primary-100`,
        props.type === "yellow" && tw`bg-warning`,
        props.type === "red" && tw`bg-error`,
        props.type === "white" &&
          tw`text-black bg-white border border-gray-300`,
        props.type === "black" && tw`bg-gray`,
        props.containerCss,
      ]}
    >
      <Typography.BodyXSmall
        containerCss={[
          tw`inline text-inherit font-400`,
          props.type === "table" && tw`text-gray-900`,
          props.type === "gray" && tw`text-gray`,
          props.type === "green" && tw`text-white`,
          props.type === "blue" && tw`text-white`,
          props.type === "blue-invert" && tw`text-primary`,
          props.type === "yellow" && tw`text-black`,
          props.type === "red" && tw`text-white`,
          props.type === "white" && tw`text-black`,
          props.type === "black" && tw`text-white`,
          props.textCss,
        ]}
      >
        {props.value}
      </Typography.BodyXSmall>
      {closeable && (
        <AiOutlineClose
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDownA11Y({ open: props.onClose })}
          css={[
            tw`h-4 w-4 cursor-pointer text-inherit`,
            props.type === "gray" && tw`text-gray`,
            props.type === "green" && tw`text-white`,
            props.type === "blue" && tw`text-white`,
            props.type === "blue-invert" && tw`text-primary`,
            props.type === "yellow" && tw`text-black`,
            props.type === "red" && tw`text-white`,
            props.type === "white" && tw`text-black`,
            props.type === "black" && tw`text-white`,
          ]}
          onClick={(e) => {
            e.stopPropagation();
            props.onClose!();
          }}
        />
      )}
    </div>
  );
};
