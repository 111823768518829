/** @jsxImportSource @emotion/react */

import { IFormFieldComponentBaseProps, IOption } from "../../types";
import { useFormError, useFormField } from "./hooks";

import { FieldError } from "../components/FieldError";
import { FieldLabel } from "../components/FieldLabel";
import { RadioGroup } from "../controlled/RadioGroup";
import { addAsterisk } from "../../utils";
import tw from "twin.macro";

type IFormRadioGroupProps = IFormFieldComponentBaseProps & {
  options: (IOption<string> & { description?: string })[];
  shouldOnlySelectValue?: boolean;
};

export const FormRadioGroup = (props: IFormRadioGroupProps) => {
  const field = useFormField(props.name);
  const error = useFormError(props.name);

  return (
    <div css={[tw`flex-1 w-full`]}>
      <FieldLabel isInErrorState={!!error} containerCss={[tw`mb-0.5`]}>
        {addAsterisk(props.label, props.required)}
      </FieldLabel>
      <div css={[props.containerCss]}>
        <RadioGroup
          error={error}
          onChange={(option) =>
            field.onChange(props.shouldOnlySelectValue ? option?.value : option)
          }
          value={field.value ?? false}
          shouldOnlySelectValue={props.shouldOnlySelectValue}
          disabled={props.disabled}
          options={props.options}
        />
      </div>
      <FieldError disabled={props.disabled} name={props.name} />
    </div>
  );
};
