import { useQuery } from "@tanstack/react-query";
import { differenceInMinutes, isSameHour } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiAddFill } from "react-icons/ri";
import { useTime } from "react-timer-hook";
import tw from "twin.macro";
import { Api } from "../../../api/api";
import { useMe } from "../../../api/hooks/user/useMe";
import { CalendarHeader } from "../../../components/calendar/CalendarHeader";
import { EmptyState } from "../../../components/empty/EmptyState";
import { CalendarModal } from "../../../components/modals/CalendarModal";
import { TimeItem } from "../../../components/TimeItem";
import { createTimeOptions } from "../../../fields/controlled/TimePicker";
import { Typography } from "../../../ui/Typograhy";
import { RoomNameRow } from "../components/RoomNameRow";
import { NewRoomModal } from "../modals/NewRoomModal";

/** @jsxImportSource @emotion/react */

export const RoomsSchedulesTab = ({ activeTab, departmentId }) => {
  const {
    departments: {
      departmentControllerGetDepartmentRoomsWithAppointmentsByDate,
    },
  } = new Api();
  const [isNewRoomModalOpen, setIsNewRoomModalOpen] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const { hours, minutes } = useTime({ format: undefined });
  const { t } = useTranslation();
  const times = createTimeOptions(30, selectedDay);
  const timelineRef = useRef<HTMLDivElement>(null);
  const currentPosition = useMemo(() => {
    return ((hours * 60 + minutes) / 30) * 128;
  }, [hours, minutes]);
  const { me } = useMe();
  useEffect(() => {
    if (activeTab === 1) {
      timelineRef.current?.scrollIntoView({
        block: "center",
        inline: "center",
      });
    }
  }, [activeTab]);

  const { data: roomsData } = useQuery(
    [
      "department",
      departmentId,
      "rooms",
      "appointments",
      selectedDay.getTime(),
    ],
    async () => {
      const { data } =
        await departmentControllerGetDepartmentRoomsWithAppointmentsByDate(
          departmentId,
          { date: selectedDay.toISOString() }
        );

      if (data.code === 200) {
        return data.payload?.rooms.map((r) => ({ ...r, departmentId: r.id }));
      }
      return [];
    },
    {
      enabled: !!selectedDay && !!departmentId,
    }
  );

  if ((roomsData || []).length === 0) {
    return (
      <>
        <EmptyState
          title={t("noEnteredRooms")}
          buttonText={t("addNewRoom")}
          subtitle={t("enterRooms")}
          buttonIcon={RiAddFill}
          onButtonClick={() => setIsNewRoomModalOpen(true)}
          containerCss={[tw`min-h-[calc(100vh - 10rem)]`]}
          hasButton={me.role?.permissions?.ROOMS?.CREATE}
        />
        {me.role?.permissions?.ROOMS?.CREATE && (
          <NewRoomModal
            onClose={() => setIsNewRoomModalOpen(false)}
            open={isNewRoomModalOpen}
            departmentId={departmentId}
          />
        )}
      </>
    );
  }

  return (
    <div>
      <CalendarHeader
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        type="day"
      />
      <div css={[tw`flex`]}>
        <div>
          <div
            css={[
              tw`min-w-[256px] justify-end h-12 px-4 py-4 bg-gray-50 flex items-center border-y-1 border-y-gray-200 border-r-1 border-r-gray-200`,
            ]}
          ></div>
          {(roomsData || []).map((r) => (
            <RoomNameRow roomId={r.id} departmentId={r.departmentId} />
          ))}
        </div>
        <div css={[tw`flex overflow-scroll relative`]}>
          {times.map((t) => (
            <div>
              <div
                css={[
                  tw`min-w-[128px] h-12 px-1 py-4 bg-gray-50 flex items-center border-y-1 border-y-gray-200`,
                ]}
              >
                <Typography.BodySmall
                  containerCss={[tw`border-l-1 border-l-gray-200 pl-1`]}
                >
                  {t.label}
                </Typography.BodySmall>
              </div>
              {(roomsData || [])?.map((r) => (
                <div
                  key={r.id}
                  css={[
                    tw`min-w-[128px] h-22 px-1 py-4 flex items-center border-b-1 border-b-gray-200 relative`,
                  ]}
                >
                  {r.appointments
                    .filter((sc) => {
                      return (
                        isSameHour(t.value, new Date(sc.startTime)) &&
                        differenceInMinutes(new Date(sc.startTime), t.value) <
                          30 &&
                        differenceInMinutes(new Date(sc.startTime), t.value) >=
                          0
                      );
                    })
                    .map((sc) => (
                      <TimeItem
                        currentTimeInMinutes={minutes + hours * 60}
                        key={sc.startTime}
                        slot={{
                          doctorName: `${sc.employee.firstName} ${sc.employee.lastName}`,
                          patientName: `${sc.patient.firstName} ${sc.patient.lastName}`,
                          serviceName: sc.service.name,
                          id: sc.id,
                          startDate: new Date(sc.startTime),
                          endDate: new Date(sc.endTime),
                        }}
                        offset={differenceInMinutes(
                          new Date(sc.startTime),
                          t.value
                        )}
                      />
                    ))}
                </div>
              ))}
            </div>
          ))}
          <div
            css={[tw`absolute h-10 w-0.5 rounded bg-primary top-1`]}
            style={{ left: currentPosition }}
            ref={timelineRef}
          >
            <Typography.BodyXSmall
              containerCss={[tw`text-primary font-bold ml-1`]}
            >
              {hours}:{minutes < 10 ? `0${minutes}` : minutes}
            </Typography.BodyXSmall>
          </div>
        </div>
      </div>
      <CalendarModal
        selectedDay={selectedDay}
        open={isCalendarModalOpen}
        onClose={() => setIsCalendarModalOpen(false)}
        onDayClick={(value) => setSelectedDay(value)}
      />
    </div>
  );
};
