import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import * as yup from "yup";
import { CreateRoomRequestDto, GetRoomResponseDto } from "../../../api/api";
import { useFacilityEmployees } from "../../../api/hooks/facilities/useFacilityEmployees";
import { useRooms } from "../../../api/hooks/rooms/useRooms";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { IModalProps, Modal } from "../../../ui/popups/Modal";
import { errorMessages } from "../../../utils";
/** @jsxImportSource @emotion/react */

const schema: yup.SchemaOf<CreateRoomRequestDto> = yup
  .object()
  .shape({
    name: yup.string().required(errorMessages.required),
    position: yup.string().required(errorMessages.required),
    employeeIds: yup
      .array()
      .of(yup.number().required(errorMessages.required))
      .required(errorMessages.required)
      .min(1, errorMessages.min),
    departmentId: yup
      .number()
      .typeError(errorMessages.number)
      .required(errorMessages.required),
  })
  .required(errorMessages.required);

type IForm = yup.InferType<typeof schema>;

export const NewRoomModal = ({
  open,
  onClose,
  room,
  departmentId,
}: Omit<IModalProps, "label" | "footerChildren"> & {
  room?: GetRoomResponseDto;
  departmentId: number;
}) => {
  const {
    employees: { data: employees },
  } = useFacilityEmployees();
  const { createRoom } = useRooms();
  const { t } = useTranslation();
  const methods = useForm<IForm>({
    defaultValues: {
      name: room?.name,
      position: room?.position,
      employeeIds: (room?.employees || []).map((e) => Number(e.id)) || [],
      departmentId,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const isEdit = !!room?.id;

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      if (!isEdit) {
        if ((values.employeeIds || [])?.length > 0) {
          //@ts-ignore
          await createRoom({ requestData: values });
        }
      }
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={(isEdit ? t("editRoomDetails") : t("addNewRoom")) as string}
        footerChildren={
          <Button.Contained containerCss={[tw`w-full`]} onClick={onSubmit}>
            {t("save")}
          </Button.Contained>
        }
      >
        <Form.TextInput.Outlined
          required
          containerCss={[tw`flex-3`]}
          label={t("roomName") as string}
          placeholder={t("roomNamePlaceholder") as string}
          name="name"
        />
        <Form.TextInput.Outlined
          containerCss={[tw`flex-1`]}
          required
          label={t("position") as string}
          placeholder={t("positionPlaceholder") as string}
          name="position"
        />
        <Form.MultiSelect
          name="employeeIds"
          shouldOnlySelectValue
          label={t("doctors") as string}
          options={
            employees?.map((em) => ({
              label: `${em.firstName} ${em.lastName}`,
              value: em.id,
            })) || []
          }
        />
      </Modal>
    </FormProvider>
  );
};
