import { Api } from "../../../api";
import { useGetAll } from "../../../api-hooks/useGetAll";

export const usePermissions = () => {
  const {
    permissions: { permissionControllerGetAllPermissions },
  } = new Api();
  const permissions = useGetAll(permissionControllerGetAllPermissions, [
    "permissions",
  ]);

  return {
    permissions,
  };
};
