import { FocusEventHandler, ReactElement, useCallback } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { IMultiFieldComponentBaseProps, IOption, Maybe } from "../../types";
/** @jsxImportSource @emotion/react */
import ReactSelect, {
  components as Components,
  ControlProps,
  IndicatorsContainerProps,
  InputProps,
  MenuListProps,
  MenuProps,
  MultiValueProps,
  PlaceholderProps,
  ValueContainerProps,
} from "react-select";

import { AiOutlineClose } from "react-icons/ai";
import { FieldLabel } from "../components/FieldLabel";
import { Typography } from "../../ui/Typograhy";
import { css } from "@emotion/react";
import tw from "twin.macro";
import { addAsterisk } from "../../utils";
import { RiCheckboxCircleLine } from "react-icons/ri";

const CustomMenuList = (props: MenuListProps & { footer?: ReactElement }) => {
  const {
    selectOption,
    selectProps: { value, inputValue, isSearchable },
    footer,
  } = props;
  const options = props.options as unknown as IOption<any>[];
  const selectedOptions = value as IOption<any>[];
  const term = (inputValue ?? "").toLowerCase().trim();
  const filteredOptions = isSearchable
    ? options.filter(({ label }: IOption<any>) => {
        return label.toLowerCase().trim().includes(term);
      })
    : options;
  return (
    <Components.MenuList {...props}>
      {filteredOptions.length === 0 ? (
        <div tw="cursor-auto">
          <Typography.BodyMedium containerCss={[tw`text-gray-100 py-1 px-3`]}>
            No results
          </Typography.BodyMedium>
        </div>
      ) : (
        <div css={[tw`cursor-pointer`]}>
          {filteredOptions.map((o: IOption<any>) => {
            const isSelected = (selectedOptions || []).some(
              (so: IOption<any>) => so?.value === o?.value
            );
            return (
              <div
                css={[
                  tw`bg-white py-3 px-2 text-gray-900 flex items-center cursor-pointer`,
                  tw`hover:(bg-gray-50)`,
                  isSelected &&
                    tw`bg-primary-50 text-primary-900 hover:(bg-primary-100 text-primary-900)`,
                ]}
                key={o?.value}
                onClick={() => selectOption(o)}
              >
                {isSelected && (
                  <RiCheckboxCircleLine
                    css={[tw`mr-3 h-4 w-4`]}
                    height={16}
                    width={16}
                  />
                )}
                <Typography.BodySmall containerCss={[tw`text-inherit`]}>
                  {o?.label}
                </Typography.BodySmall>
              </div>
            );
          })}
        </div>
      )}
      {footer}
    </Components.MenuList>
  );
};

const CustomPlaceholder = (props: PlaceholderProps) => {
  return (
    <Typography.BodySmall
      containerCss={[
        css`
          grid-area: 1/1/2/3;
          ${tw`text-gray-200 overflow-hidden ml-0.5`}
        `,
        props.selectProps.isDisabled && tw`text-gray-200`,
      ]}
    >
      {props.selectProps.placeholder}
    </Typography.BodySmall>
  );
};

const CustomIndicatorsContainer = (props: IndicatorsContainerProps) => {
  const {
    selectProps: { menuIsOpen },
  } = props;

  const DropdownIcon = menuIsOpen ? HiChevronUp : HiChevronDown;

  return (
    <div css={[tw`flex flex-row items-center gap-x-2`]} {...props.innerProps}>
      <DropdownIcon
        css={[
          tw`h-6 w-6 text-gray-600`,
          props.selectProps.isDisabled && tw`text-gray-100`,
        ]}
      />
    </div>
  );
};

const CustomInput = (props: InputProps) => {
  return <Components.Input {...props} tw="m-0 p-0 caret-primary" />;
};

const CustomMultiValue = (props: MultiValueProps) => {
  const {
    selectProps: { value, isDisabled },
    index,
    selectOption,
  } = props;
  const option = (value as IOption<any>[])[index];

  return (
    <div
      css={[
        tw`py-1 px-3.5 bg-primary rounded-md w-fit inline-flex flex-row items-center gap-2`,
        isDisabled && tw`bg-gray-200`,
      ]}
    >
      <Typography.Caption
        containerCss={[tw`inline font-400 text-white leading-5`]}
      >
        {option.label}
      </Typography.Caption>

      <AiOutlineClose
        css={[tw`h-4 w-4 cursor-pointer text-white`]}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
          selectOption(option);
        }}
      />
    </div>
  );
};
const CustomControl = (props: ControlProps & { error: Maybe<string> }) => {
  const { error, isFocused } = props;

  return (
    <Components.Control
      {...props}
      css={[
        tw`border-1 border-gray-300 pl-4 pr-3 py-2 hover:(border-gray-300) rounded-md`,
        isFocused &&
          tw`border-primary-100 ring-2 ring-primary-400 hover:(border-primary-100)`,
        error && tw`border-error ring-error-light ring-2 hover:(border-error)`,
        props.isDisabled &&
          tw`bg-gray-400 border-b-gray-300 hover:(bg-gray-400) cursor-not-allowed`,
      ]}
    />
  );
};

interface IMultiSelectProps<T>
  extends IMultiFieldComponentBaseProps<IOption<T>> {
  autofocus?: boolean;
  shouldOnlySelectValue?: boolean;
  dataParser?(data: any): any;
  options: IOption<T>[];
  footer?: ReactElement;
  onFocus?: FocusEventHandler<any> | undefined;
  onBlur?: FocusEventHandler<any> | undefined;
}

export const MultiSelect = <T extends Object>(props: IMultiSelectProps<T>) => {
  const {
    error,
    value,
    // dataParser = (data) => data,
    shouldOnlySelectValue,
  } = props;

  const tempValue = shouldOnlySelectValue
    ? //@ts-ignore
      (value || []).map((num: number) =>
        //@ts-ignore
        props.options.find((op) => op.value === num)
      )
    : value;

  const CustomControlMemo = useCallback(
    (props: ControlProps) => <CustomControl {...props} error={error} />,
    [error]
  );
  const CustomValueContainerMemo = useCallback(
    (props: ValueContainerProps) => (
      <Components.ValueContainer {...props} tw="p-0 m-0 gap-y-1 gap-x-1" />
    ),
    []
  );
  const CustomMenuMemo = useCallback(
    (props: MenuProps) => (
      <Components.Menu {...props} tw="shadow-menu rounded-md z-10" />
    ),
    []
  );
  return (
    <div css={[props.containerCss]}>
      <FieldLabel isInErrorState={!!props.error} containerCss={[tw`mb-1`]}>
        {addAsterisk(props.label, props.required)}
      </FieldLabel>
      <ReactSelect
        //@ts-ignore
        value={tempValue}
        isSearchable
        isMulti
        autoFocus={props.autofocus}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        backspaceRemovesValue
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        isDisabled={props.disabled}
        onChange={(option) => props.onChange(option as IOption<T>[])}
        placeholder={props.placeholder}
        options={props.options as any}
        styles={
          // STATEMENT: How do I override specific style?
          // Since we override specific components in this template some of the
          // options provided in the "styles" object may not work.
          // It's discouraged to use this object. Please use component override.
          {
            // input: (provided) => ({
            //   ...provided,
            //   "input:focus": {
            //     boxShadow: "none",
            //   },
            // }),
          }
        }
        // STATEMENT: How these overrides work?
        // Find here more: https://react-select.com/components
        // If you have any problem overriding any property, it may be best to
        // directly check the source here: https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/Select.tsx
        components={{
          // @ts-ignore
          Control: CustomControlMemo,
          // @ts-ignore
          ValueContainer: CustomValueContainerMemo,
          // @ts-ignore
          MultiValue: CustomMultiValue,
          // @ts-ignore
          Placeholder: CustomPlaceholder,
          // @ts-ignore
          IndicatorsContainer: CustomIndicatorsContainer,
          // @ts-ignore
          Input: CustomInput,
          // @ts-ignore
          MenuList: (menuProps) => (
            // @ts-ignore
            <CustomMenuList {...menuProps} footer={props.footer} />
          ),
          // @ts-ignore
          Menu: CustomMenuMemo,
          // SelectContainer: (props) => {
          //   return <Components.SelectContainer {...props} />;
          // },
          // MenuPortal: (props) => {
          //   return <Components.MenuPortal {...props} />;
          // },
          // Menu: (props) => {
          //   return <Components.Menu {...props} />;
          // },
          // // NOT VISIBLE SINCE MENU LIST IS OVERRIDDEN
          // NoOptionsMessage: (props) => {
          //   return <Components.NoOptionsMessage {...props} />;
          // },
          // // NOT VISIBLE SINCE MENU LIST IS OVERRIDDEN
          // Option: (props) => {
          //   return <Components.Option {...props} />;
          // },
          // // NOT USED SINCE ALL OPTIONS ARE STATIC
          // LoadingMessage: (props) => {
          //   return <Components.LoadingMessage {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // ClearIndicator: (props) => {
          //   return <Components.ClearIndicator {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // DropdownIndicator: (props) => {
          //   return <Components.DropdownIndicator {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // LoadingIndicator: (props) => {
          //   return <Components.LoadingIndicator {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // IndicatorSeparator: (props) => {
          //   return <Components.IndicatorSeparator {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // CrossIcon: (props) => {
          //   return <Components.CrossIcon {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // DownChevron: (props) => {
          //   return <Components.DownChevron {...props} />;
          // },
        }}
      />
    </div>
  );
};
