import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";

export const useRooms = () => {
  const {
    rooms: { roomControllerCreateRoom },
  } = new Api();

  const { mutateAsync: createRoom, isLoading: isCreatingRoom } = useCreate(
    roomControllerCreateRoom,
    ["rooms"]
  );

  return {
    createRoom,
    isCreatingRoom,
  };
};
