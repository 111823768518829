import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from "react";

import tw from "twin.macro";
import { Typography } from "../../../Typograhy";
import { buildPaginationItems } from "./build-pagination-items";
import { useTranslation } from "react-i18next";

interface IAsyncDataTablePaginationProps {
  currentPage: number;
  maxPage: number;
  setCurrentPage(value: number): void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageSize: number;
  totalOnPage: number;
  totalCount: number;
}

export const AsyncDataTablePagination = (
  props: IAsyncDataTablePaginationProps
) => {
  const { setCurrentPage, maxPage, canNextPage, canPreviousPage } = props;

  const [page, setPage] = useState(props.currentPage);
  const { t } = useTranslation();

  useEffect(() => {
    setPage(props.currentPage);
  }, [props.currentPage]);

  const paginationItems = useMemo(
    () => buildPaginationItems(page, maxPage),
    [page, maxPage]
  );

  const onPageChange = (pageProp: number) => {
    setPage(pageProp);
    setCurrentPage(pageProp);
  };

  const showDivider =
    maxPage > 3 && paginationItems[2] + 1 !== paginationItems[3];
  // const initialItem = page === 1 ? 1 : (page - 1) * pageSize + 1;
  // const finalItem = initialItem + totalOnPage - 1;

  const iconStyle = tw`h-9 w-9 mx-2 text-gray-500`;
  const iconDisabledStyle = tw`cursor-not-allowed text-gray-300 hover:(text-gray-300)`;

  return (
    <div css={[tw`items-center flex flex-col`]}>
      {/* <Typography.BodySmall containerCss={[tw`text-gray mr-3`]}>
        {`${initialItem}-${finalItem} of ${totalCount}`}
      </Typography.BodySmall> */}
      <div
        css={[
          tw`max-w-screen-xl w-full px-4`,
          tw`flex flex-row items-center justify-between`,
        ]}
      >
        <div
          className="group"
          css={[
            tw`flex flex-row items-center`,
            !(canPreviousPage || page > 1)
              ? iconDisabledStyle
              : tw`cursor-pointer`,
          ]}
          onClick={() =>
            canPreviousPage || page > 1
              ? onPageChange(Math.max(1, page - 1))
              : () => {}
          }
        >
          <HiOutlineArrowNarrowLeft
            css={[
              iconStyle,
              (canPreviousPage || page > 1) && tw`group-hover:(text-primary)`,
            ]}
          />
          <Typography.BodySmall
            containerCss={[
              tw`text-gray-500 font-500`,
              (canPreviousPage || page > 1) && tw`group-hover:(text-primary)`,
            ]}
          >
            {t("previous")}
          </Typography.BodySmall>
        </div>

        <div css={[tw`flex flex-row items-center gap-x-1`]}>
          {paginationItems.map((p, i) => {
            const key = `${i}-${p}`;
            return (
              <div key={key} css={[tw`flex flex-row items-center`]}>
                <Typography.BodyMedium
                  onClick={() => onPageChange(p)}
                  containerCss={[
                    tw`py-4 px-4 cursor-pointer text-gray-500`,
                    tw`hover:(text-primary)`,
                    p === page &&
                      tw`cursor-default border-t-2 border-t-primary text-primary`,
                  ]}
                >
                  {p}
                </Typography.BodyMedium>

                {showDivider && i === 2 && (
                  <Typography.BodyMedium
                    onClick={() => onPageChange(p)}
                    containerCss={[tw`text-gray-500 mx-2`]}
                  >
                    {`...`}
                  </Typography.BodyMedium>
                )}
              </div>
            );
          })}
        </div>
        <div
          className="group"
          onClick={() =>
            canNextPage ? onPageChange(Math.min(maxPage, page + 1)) : () => {}
          }
          css={[
            tw`flex flex-row items-center`,
            canNextPage && tw`cursor-pointer`,
            !canNextPage && iconDisabledStyle,
          ]}
        >
          <Typography.BodySmall
            containerCss={[
              tw`text-gray-500 font-500`,
              canNextPage && tw`group-hover:(text-primary)`,
            ]}
          >
            {t("next")}
          </Typography.BodySmall>
          <HiOutlineArrowNarrowRight
            css={[iconStyle, canNextPage && tw`group-hover:(text-primary)`]}
          />
        </div>
      </div>
    </div>
  );
};
