import { useRecoilValue } from "recoil";
import { selectedOrganisationIdState } from "../../../state";
import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";
import {
  organisationRoleKey,
  organisationRoleWPermissionsKey,
} from "../../query-keys";

export const useOrganisationRoles = () => {
  const {
    organisations: {
      organisationControllerGetOrganisationRoles,
      organisationControllerGetOrganisationRolesWithPermissions,
    },
  } = new Api();
  const organisationId = useRecoilValue(selectedOrganisationIdState);
  const roles = useGetById({
    id: Number(organisationId),
    query: organisationControllerGetOrganisationRoles,
    queryKey: organisationRoleKey(organisationId),
  });
  const rolesWithPermissions = useGetById({
    id: Number(organisationId),
    query: organisationControllerGetOrganisationRolesWithPermissions,
    queryKey: organisationRoleWPermissionsKey(organisationId),
  });

  return {
    roles,
    rolesWithPermissions,
  };
};
