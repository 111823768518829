/** @jsxImportSource @emotion/react */

import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiEdit2Line } from "react-icons/ri";
import { useParams } from "react-router-dom";
import tw from "twin.macro";
import { GetPatientByIdResponseDto } from "../../../api/api";
import { usePatient } from "../../../api/hooks/patients/usePatient";
import { Button } from "../../../ui/buttons/Button";
import { Typography } from "../../../ui/Typograhy";
import { EditRemarkModal } from "../modals/EditRemarkModal";
import { ManagePatientModal } from "../modals/ManagePatientModal";

export const PatientInfoSection = ({ patientId }: { patientId?: number }) => {
  const { id } = useParams();
  const {
    patient: { data: patient },
  } = usePatient({ id: Number(patientId || id) });
  const [isEditRemarkModalOpen, setIsEditRemarkModalOpen] = useState(false);
  const [isEditPatientModalOpen, setIsEditPatientModalOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      css={[
        tw`flex flex-col items-end justify-between`,
        tw`border-r-1 border-r-gray-200`,
        tw`pr-28`,
        tw`h-full`,
      ]}
    >
      <div css={[tw`w-full`]}>
        <div css={[tw`px-4`]}>
          <Typography.BodyXLarge containerCss={[tw`mt-6 mb-12`]}>
            {`${patient?.firstName || "-"} ${patient?.lastName || ""}`}
          </Typography.BodyXLarge>
          <div css={[tw`flex gap-8`]}>
            <div>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("dateOfBirth")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-8`]}>
                {format(
                  new Date(patient?.birthDate || new Date()),
                  "dd. MMMM yyyy."
                ) || "-"}
              </Typography.BodySmall>
            </div>
            <div>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("gender")}
              </Typography.BodyXSmall>
              {patient?.sex && (
                <Typography.BodySmall containerCss={[tw`mb-8`]}>
                  {t(patient?.sex.toLowerCase()) || "-"}
                </Typography.BodySmall>
              )}
            </div>
          </div>
          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("contactPhone")}
          </Typography.BodyXSmall>
          <Typography.BodySmall containerCss={[tw`mb-8`]}>
            {patient?.phone || "-"}
          </Typography.BodySmall>
          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("emailAddress")}
          </Typography.BodyXSmall>
          <Typography.BodySmall containerCss={[tw`mb-8`]}>
            {patient?.email || "-"}
          </Typography.BodySmall>
          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("addressOfLiving")}
          </Typography.BodyXSmall>
          <Typography.BodySmall containerCss={[tw`mb-8`]}>
            {patient?.address || "-"}
          </Typography.BodySmall>
          {/* <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("hasApplication")}
          </Typography.BodyXSmall>
          <Typography.BodySmall containerCss={[tw`mb-8`]}>
            da
          </Typography.BodySmall> */}
          {/* <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("remark")}
          </Typography.BodyXSmall>
          <Button.Outlined
            variant="small"
            onClick={() => setIsEditRemarkModalOpen(true)}
            lead={RiAddFill}
          >
            {t("addRemark")}
          </Button.Outlined> */}
        </div>
      </div>
      <div css={[tw`w-full py-8`]}>
        <Button.Outlined
          onClick={() => setIsEditPatientModalOpen(true)}
          lead={RiEdit2Line}
        >
          {t("editInfo")}
        </Button.Outlined>
      </div>
      <EditRemarkModal
        onClose={() => setIsEditRemarkModalOpen(false)}
        open={isEditRemarkModalOpen}
      />
      {patient?.id && (
        <ManagePatientModal
          onClose={() => setIsEditPatientModalOpen(false)}
          open={isEditPatientModalOpen}
          patient={patient as GetPatientByIdResponseDto | undefined}
        />
      )}
    </div>
  );
};
