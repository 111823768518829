import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RiAddFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import { useFacilityEmployeesPaginated } from "../../../api/hooks/facilities/useFacilityEmployeesPaginated";
import { useMe } from "../../../api/hooks/user/useMe";
import { EmptyState } from "../../../components/empty/EmptyState";
import { searchState } from "../../../state";
import { AsyncDataTable } from "../../../ui/layout/data-table/async/AsyncDataTable";
import { TableCell } from "../../../ui/layout/data-table/cell";
/** @jsxImportSource @emotion/react */

export const EmployeesListTab = ({
  setIsManageEmployeeModalOpen,
}: {
  setIsManageEmployeeModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const search = useRecoilValue(searchState);
  const navigate = useNavigate();
  const {
    employees: {
      data: employees,
      isFetching,
      isFetched,
      filters,
      setFilter,
      page,
      setPage,
      size,
      setOrderField,
      setOrder,
      order,
      orderField,
    },
  } = useFacilityEmployeesPaginated();
  const { t } = useTranslation();

  useEffect(() => {
    if (filters.term !== search) {
      setFilter("term", search);
    }
  }, [filters.term, search, setFilter]);
  const records = employees?.items ?? [];
  const total = employees?.totalItems ?? 0;
  const { me } = useMe();
  if ((employees?.items || []).length === 0 && isFetched) {
    return (
      <EmptyState
        title={t("noEnteredEmployees")}
        buttonText={t("addNewEmployee")}
        subtitle={t("addNewEmployee")}
        buttonIcon={RiAddFill}
        onButtonClick={() => setIsManageEmployeeModalOpen(true)}
        containerCss={[tw`min-h-[calc(100vh - 10rem)]`]}
        hasButton={me.role?.permissions?.EMPLOYEES?.CREATE}
      />
    );
  }

  return (
    <>
      <AsyncDataTable
        loading={isFetching}
        data={records}
        containerCss={[tw`flex flex-col justify-between h-full`]}
        onRowClick={(u, i) => {
          navigate(`/employees/${u.id}`);
        }}
        hidePagination={total === 0}
        columns={[
          {
            Header: t("nameAndSurname"),
            accessor: "firstName",
            disableSortBy: true,
            cell: (s) => (
              <TableCell.Text value={`${s.firstName} ${s.lastName}`} />
            ),
          },
          {
            Header: t("createDate"),
            accessor: "createdAt",
            align: "right",
            width: 145,
            disableSortBy: true,
            cell: (s) => <TableCell.Date value={new Date(s.createdAt)} />,
          },
          // {
          //   Header: t("lastLogin"),
          //   accessor: "lastLogin",
          //   align: "right",
          //   width: 145,
          //   disableSortBy: true,
          //   cell: (s) => (
          //     <TableCell.Date
          //       value={new Date(s.lastLogin)}
          //     />
          //   ),
          // },
          // {
          //   Header: t("healthFacility"),
          //   accessor: "departmentEmployees",
          //   align: "right",

          //   cell: (s) => (
          //     <TableCell.Text
          //       value={[
          //         ...new Set(
          //           s.departmentEmployees.map(
          //             (de) => de.department.facility.name
          //           )
          //         ),
          //       ].join(", ")}
          //     />
          //   ),
          // },
          {
            Header: t("roleType"),
            accessor: "role",
            align: "right",
            disableSortBy: true,
            cell: (s) => <TableCell.Text value={t(s?.role?.name || "")} />,
          },
        ]}
        currentPage={page}
        setPage={setPage}
        pageSize={size}
        maxPage={employees?.totalPages || 0}
        totalCount={total}
        setSortKey={setOrderField}
        setSortOrder={setOrder}
        sortKey={orderField}
        sortOrder={order}
      />
    </>
  );
};
