import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import * as yup from "yup";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Nullable } from "../../../types";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { Modal } from "../../../ui/popups/Modal";

/** @jsxImportSource @emotion/react */

const schema = yup.object().shape({
  role: yup.string().nullable(),
});

type IForm = Nullable<yup.InferType<typeof schema>>;

interface IFilterEmployeesModal {
  open: boolean;
  onClose: () => void;
}

export const FilterEmployeesModal = ({
  open,
  onClose,
}: IFilterEmployeesModal) => {
  const { t } = useTranslation();
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      console.log(values);
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        containerCss={[tw`w-96 `]}
        open={open}
        onClose={onClose}
        footerChildren={
          <div css={[tw`flex justify-between gap-3`]}>
            <Button.Outlined containerCss={[tw`flex-1`]} onClick={onClose}>
              {t("cancel")}
            </Button.Outlined>
            <Button.Contained containerCss={[tw`flex-1`]} onClick={onSubmit}>
              {t("changeFilter")}
            </Button.Contained>
          </div>
        }
      >
        <div>
          <Form.Select
            options={[]}
            name="role"
            label={t("role") as string}
            placeholder={t("role") as string}
          />
        </div>
      </Modal>
    </FormProvider>
  );
};
