import { IFormFieldComponentBaseProps, IOption } from "../../types";
import { useFormError, useFormField } from "./hooks";

import { FieldError } from "../components/FieldError";
import { MultiSelect } from "../controlled/MultiSelect";
import tw from "twin.macro";
import { ReactElement } from "react";

/** @jsxImportSource @emotion/react */

export interface IFormMultiSelectProps<T> extends IFormFieldComponentBaseProps {
  autofocus?: boolean;
  options: IOption<T>[];
  footer?: ReactElement;
  shouldOnlySelectValue?: boolean;
  dataParser?(data: any): any;
}

export const FormMultiSelect = (props: IFormMultiSelectProps<any>) => {
  const field = useFormField(props.name);
  const error = useFormError(props.name);

  return (
    <div css={[tw`flex-1 w-full`, props.containerCss]}>
      <MultiSelect
        disabled={props.disabled}
        value={field.value}
        autofocus={props.autofocus}
        footer={props.footer}
        onChange={(option) => {
          field.onChange(
            props.shouldOnlySelectValue
              ? (option || []).map((v) =>
                  typeof v === "number" ? v : v?.value
                )
              : option
          );
        }}
        label={props.label}
        error={error}
        required={props.required}
        placeholder={props.placeholder}
        options={props.options}
        shouldOnlySelectValue={props.shouldOnlySelectValue}
      />
      <FieldError name={props.name} containerCss={[tw`ml-4`]} />
    </div>
  );
};
