import { RiCheckboxCircleLine, RiSettings3Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { ISelectOption } from "../../types";
import { Typography } from "../../ui/Typograhy";
/** @jsxImportSource @emotion/react */

interface ISelectRowProps {
  option: ISelectOption<any>;
  selectedOption: ISelectOption<any>;
  selectOption: (value: ISelectOption<any>) => void;
}

export const SelectRow = ({
  option,
  selectedOption,
  selectOption,
}: ISelectRowProps) => {
  const isSelected = option.value === selectedOption?.value;
  const Icon = option.rightIcon?.icon;

  return (
    <div
      css={[
        tw`bg-white py-2 px-3 z-10 text-gray-900 flex items-center justify-between cursor-pointer`,
        tw`hover:(bg-gray-50)`,
        isSelected &&
          tw`bg-primary-50 text-primary-900 hover:(bg-primary-100 text-primary-900)`,
      ]}
      key={option.value}
      className={"group"}
      onClick={(e) => {
        selectOption(option);
      }}
    >
      <div css={[tw`flex items-center`]}>
        {isSelected && (
          <RiCheckboxCircleLine
            css={[tw`mr-3 h-4 w-4`]}
            height={16}
            width={16}
          />
        )}
        <Typography.BodySmall containerCss={[tw`text-inherit truncate`]}>
          {option.label}
        </Typography.BodySmall>
      </div>
      {!!Icon && (
        <Icon
          css={[tw`z-20`, tw`hidden group-hover:(flex)`, tw`h-4 w-4`]}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            option.rightIcon?.onClick();
          }}
        />
      )}
    </div>
  );
};
