/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import * as yup from "yup";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Nullable } from "../../../types";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { Modal } from "../../../ui/popups/Modal";
import { errorMessages } from "../../../utils";

const schema = yup.object().shape({
  remark: yup.string().nullable().required(errorMessages.required),
});

type IForm = Nullable<yup.InferType<typeof schema>>;

interface ITextInputModalProps {
  open: boolean;
  onClose: () => void;
}

export const EditRemarkModal = ({ open, onClose }: ITextInputModalProps) => {
  const { t } = useTranslation();
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      remark: "",
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      console.log({ values });
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={t("editRemark") as string}
        footerChildren={
          <Button.Contained containerCss={[tw`w-full`]} onClick={onSubmit}>
            {t("save")}
          </Button.Contained>
        }
      >
        <Form.TextArea.Outlined
          label={t("remark") as string}
          required
          placeholder={t("enterRemark") as string}
          name="remark"
        />
      </Modal>
    </FormProvider>
  );
};
