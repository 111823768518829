import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { RiAddFill } from "react-icons/ri";
import tw from "twin.macro";
import { useMe } from "../../../api/hooks/user/useMe";
import { EmptyState } from "../../../components/empty/EmptyState";
import { CategoriesWithServicesWithParentIds } from "../../../types";
import { Typography } from "../../../ui/Typograhy";
import { Reorder } from "../../../utils/list-utils";
import { EditCategoryRow } from "../components/EditCategoryRow";
import { EditServiceRow } from "../components/EditServiceRow";
import { ServiceRow } from "../components/ServiceRow";
import { NewServiceModal } from "../modals/NewServiceModal";
/** @jsxImportSource @emotion/react */

export const ListOfServicesTab = ({
  departmentId,
  categories,
  isEditing = false,
  isFetched = false,
}: {
  departmentId: number;
  categories: CategoriesWithServicesWithParentIds;
  isEditing?: boolean;
  isFetched?: boolean;
}) => {
  const { t } = useTranslation();
  const [isNewServiceModalOpen, setIsNewServiceModalOpen] = useState(false);
  const [categoriesState, setCategoriesState] =
    useState<CategoriesWithServicesWithParentIds>(categories);
  const { me } = useMe();
  useEffect(() => {
    if (categories !== categoriesState) {
      setCategoriesState(categories);
    }
  }, [categories]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type === "CATEGORIES") {
      const categoriesTemp = Reorder(
        categoriesState,
        result.source.index,
        result.destination.index
      ) as CategoriesWithServicesWithParentIds;

      setCategoriesState(categoriesTemp);
    } else {
      const services = Reorder(
        categoriesState[parseInt(result.type, 10)].services,
        result.source.index,
        result.destination.index
      );

      const categoriesTemp = JSON.parse(JSON.stringify(categoriesState));

      categoriesTemp[result.type].services = services;

      setCategoriesState(categoriesTemp);
    }
  };

  const allServicesLength = categoriesState.reduce(
    (prev, curr) => prev + curr.services.length,
    0
  );

  const allCategoriesLength = categoriesState.length;

  if (allServicesLength === 0 && allCategoriesLength === 0 && isFetched) {
    return (
      <>
        <EmptyState
          title={t("noEnteredServices")}
          buttonText={t("addNewService")}
          subtitle={t("enterService")}
          buttonIcon={RiAddFill}
          onButtonClick={() => setIsNewServiceModalOpen(true)}
          containerCss={[tw`min-h-[calc(100vh - 10rem)]`]}
          hasButton={me.role?.permissions?.FACILITIES?.CREATE}
        />
        {me.role?.permissions?.FACILITIES?.CREATE && (
          <NewServiceModal
            onClose={() => setIsNewServiceModalOpen(false)}
            open={isNewServiceModalOpen}
            departmentId={departmentId}
          />
        )}
      </>
    );
  }

  return (
    <div>
      {!isEditing &&
        categoriesState.map((c) => {
          return (
            <div key={c.id}>
              <div
                css={[
                  tw`bg-gray-50 py-4 flex justify-center border-b-1 border-b-gray-200`,
                ]}
              >
                <div css={[tw`max-w-screen-xl w-full px-4`]}>
                  <Typography.BodySmall
                    containerCss={[
                      tw`uppercase leading-4 font-medium tracking-wider`,
                    ]}
                  >
                    {c.name}
                  </Typography.BodySmall>
                </div>
              </div>
              {c.services.map((s) => (
                <ServiceRow service={s} />
              ))}
            </div>
          );
        })}
      {isEditing && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="CATEGORIES">
            {(provided) => (
              <div ref={provided.innerRef}>
                {categoriesState.map((category, index) => (
                  <Draggable
                    key={category.id.toString()}
                    draggableId={category.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div
                          css={[
                            tw`bg-gray-50 py-4 flex justify-center border-b-1 border-b-gray-200`,
                          ]}
                        >
                          <div css={[tw`max-w-screen-xl w-full px-4`]}>
                            <EditCategoryRow
                              category={category}
                              provided={provided}
                            />
                          </div>
                        </div>
                        <Droppable
                          droppableId={`droppable${category.id.toString()}`}
                          type={`${index}`}
                        >
                          {(provided) => (
                            <div ref={provided.innerRef}>
                              {category.services.map((service, index2) => {
                                return (
                                  <Draggable
                                    key={`${index}${index2}`}
                                    draggableId={`${index}${index2}`}
                                    index={index2}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                      >
                                        <EditServiceRow
                                          service={service}
                                          provided={provided}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};
