import { useRecoilValue } from "recoil";
import { selectedOrganisationIdState } from "../../../state";
import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";
import { organisationPatientsKey } from "../../query-keys";

export const usePatients = () => {
  const {
    patients: {
      patientControllerCreatePatientWeb,
      patientControllerCreatePatientMobile,
    },
  } = new Api();
  const organisationId = useRecoilValue(selectedOrganisationIdState);
  const { mutateAsync: createPatient, isLoading: isCreatingPatient } =
    useCreate(
      patientControllerCreatePatientWeb,
      organisationPatientsKey(organisationId)
    );
  const {
    mutateAsync: createPatientMobile,
    isLoading: isCreatingPatientMobile,
  } = useCreate(
    patientControllerCreatePatientMobile,
    organisationPatientsKey(organisationId)
  );

  return {
    createPatientMobile,
    createPatient,
    isCreatingPatient,
  };
};
