import React, {
  FocusEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { IconType } from "react-icons";
import tw from "twin.macro";
import { IComponentBaseProps } from "../../types";
import { Chip } from "../buttons/Chip";
import { Typography } from "../Typograhy";

/** @jsxImportSource @emotion/react */

interface ITabItemProps extends IComponentBaseProps {
  text: string;
  index: number;
  trail?: IconType;
  lead?: IconType;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isLast: boolean;
  isSelected: boolean;
  isFirst: boolean;
  onFocus?: FocusEventHandler<any> | undefined;
  notificationNumber?: number;
}

const horizontalMargin = 15;

export type ITabItemPropsPublic = Omit<
  ITabItemProps,
  "onClick" | "isLast" | "isFirst" | "onFocus" | "isSelected" | "index"
>;

const TabItem = (props: ITabItemProps) => {
  const Lead = props.lead;
  const Trail = props.trail;
  return (
    <button
      css={[
        tw`flex flex-row items-center justify-center outline-none`,
        tw`px-3 pb-4.5 mt-4.5`,
        tw`duration-200`,
        tw`border-b-4 border-b-transparent hover:(border-b-primary)`,
        !props.isSelected && tw`hover:(border-b-gray-200)`,
        tw`cursor-pointer text-gray`,
      ]}
      style={{ marginRight: horizontalMargin }}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
      onFocus={props.onFocus}
    >
      {Lead && <Lead tw="mr-2 w-4 h-4" />}
      <Typography.BodySmall containerCss={[tw`text-inherit font-400`]}>
        {props.text}
      </Typography.BodySmall>
      {!!props.notificationNumber && Number(props.notificationNumber) > 0 && (
        <Chip
          value={props.notificationNumber}
          type="blue-invert"
          textCss={[!props.isSelected && tw`text-gray-800`]}
          containerCss={[tw`ml-2`, !props.isSelected && tw`bg-gray-100`]}
        />
      )}
      {Trail && <Trail tw="ml-2 w-4 h-4" />}
    </button>
  );
};

export interface ITabsProps extends IComponentBaseProps {
  defaultTab?: number;
  onTabChange?: (index: number) => void;
  leftComponent?: ReactElement;
  rightComponent?: ReactElement;
}

const Tabs: React.FC<PropsWithChildren<ITabsProps>> & {
  Item: React.FC<PropsWithChildren<ITabItemPropsPublic>>;
} = (props) => {
  const { onTabChange, leftComponent, rightComponent } = props;
  const [activeIndex, setActiveIndex] = useState(props.defaultTab ?? 0);
  const [lineAtIndex, setLineAtIndex] = useState(props.defaultTab ?? 0);
  const [tabWidths, setTabWidth] = useState<Array<number>>([]);
  const tabRef = useRef<HTMLDivElement>(null);

  const onChange = useCallback(
    (value: number) => {
      setActiveIndex(value);
      setLineAtIndex(value);
      onTabChange && onTabChange(value);
    },
    [setActiveIndex, setLineAtIndex, onTabChange]
  );
  const children = React.Children.toArray(props.children);
  const tabs =
    React.Children.map(children, (child: any, index: number) =>
      React.cloneElement(child, {
        isFirst: index === 0,
        isSelected: index === activeIndex,
        isLast: index + 1 === children.length,
        index,
        onClick: () => onChange(index),
        onFocus: () => setLineAtIndex(index),
      })
    ) ?? [];

  const components = children.map((c: any) => c.props.children);

  useEffect(() => {
    const tabWidthsInside = Array.from(tabRef?.current?.children ?? []).map(
      (c) => c.clientWidth
    );
    if (tabWidths.length !== tabWidthsInside.length) {
      setTabWidth(tabWidthsInside);
    }
  }, [tabWidths.length]);

  const totalWidth =
    tabWidths
      .filter((_, i) => i < lineAtIndex)
      .reduce((prev, curr) => {
        return prev + curr + horizontalMargin;
      }, 0) ?? 0;

  return (
    <div css={props.containerCss}>
      <div
        css={[
          tw`flex flex-row items-center justify-between h-14 max-w-screen-xl m-auto`,
          tw`lg: pl-5 pr-5`,
          tw`xl: pl-0 pr-0`,
        ]}
      >
        {leftComponent}
        <div>
          <div ref={tabRef} css={[tw`flex flex-row items-center h-14`]}>
            {tabs}
          </div>
          <div
            css={[tw`h-0.5 bg-primary duration-200`]}
            style={{
              transform: `translateX(${totalWidth}px)`,
              width: tabWidths[lineAtIndex] ?? 0,
            }}
          />
        </div>
        {rightComponent || <div css={[tw`w-1 h-1 bg-transparent`]}></div>}
      </div>
      {components.map((c, i) => (
        <div
          key={i}
          css={[
            i !== activeIndex && tw`hidden`,
            tw`h-[calc(100vh - 8rem - 5px)]`,
          ]}
        >
          {c}
        </div>
      ))}
    </div>
  );
};

// @ts-ignore
Tabs.Item = TabItem;
export { Tabs };
