/** @jsxImportSource @emotion/react */

import { useFormError, useFormField } from "./hooks";

import { Checkbox } from "../controlled/Checkbox";
import { IFormFieldComponentBaseProps } from "../../types";
import tw from "twin.macro";

type IFormCheckboxProps = IFormFieldComponentBaseProps;

export const FormCheckbox = (props: IFormCheckboxProps) => {
  const field = useFormField(props.name);
  const error = useFormError(props.name);
  return (
    <div css={[tw`flex-1 w-full`, props.containerCss]}>
      <Checkbox
        label={props.label}
        onChange={field.onChange}
        value={field.value ?? false}
        disabled={props.disabled}
        error={error}
      />
      {/* <FieldError disabled={props.disabled} name={props.name} /> */}
    </div>
  );
};
