import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import { useServiceAppointments } from "../../../api/hooks/services/useServiceAppointments";
import { searchState } from "../../../state";
import { Maybe } from "../../../types";
import { AsyncDataTable } from "../../../ui/layout/data-table/async/AsyncDataTable";
import { TableCell } from "../../../ui/layout/data-table/cell";
import { AppointmentDetailsModal } from "../../appointments/modals/AppointmentDetailsModal";
import { ServiceInfoSection } from "../components/ServiceInfoSection";
/** @jsxImportSource @emotion/react */

export const ArrangedAppointmentsTab = () => {
  const [activeRowIndex, setActiveRowIndex] = useState<Maybe<number>>(null);
  const { id } = useParams();
  const [selectedAppointmentId, setSelectedAppointmentId] =
    useState<Maybe<number>>(null);
  const [isEnterAppointmentInfoModalOpen, setIsEnterAppointmentInfoModalOpen] =
    useState(false);
  const {
    serviceAppointments: {
      data: serviceAppointments,
      isFetching,
      filters,
      setFilter,
      page,
      setPage,
      size,
      setOrderField,
      setOrder,
      order,
      orderField,
    },
  } = useServiceAppointments({ id: Number(id) });

  const search = useRecoilValue(searchState);

  const { t } = useTranslation();

  useEffect(() => {
    if (filters.term !== search) {
      setFilter("term", search);
    }
  }, [filters.term, search, setFilter]);

  const records = serviceAppointments?.items ?? [];
  const total = serviceAppointments?.totalItems ?? 0;

  return (
    <div css={[tw`flex h-full justify-center`]}>
      <ServiceInfoSection />
      <AsyncDataTable
        activeRowIndex={activeRowIndex}
        loading={isFetching}
        data={records}
        onRowClick={(u, i) => {
          setActiveRowIndex(u.id);
          setSelectedAppointmentId(u.id);
          setIsEnterAppointmentInfoModalOpen(true);
        }}
        containerCss={[tw`max-w-[965px] w-full flex flex-col justify-between`]}
        columns={[
          {
            Header: t("patient"),
            accessor: "patientFirstName",
            disableSortBy: true,

            cell: (s) => (
              <TableCell.Text
                value={`${s.patientFirstName} ${s.patientLastName}`}
              />
            ),
          },
          {
            Header: t("date"),
            accessor: "patientLastName",
            align: "right",
            disableSortBy: true,
            cell: (s) => (
              <TableCell.Date
                value={new Date(s.scheduledFor)}
              />
            ),
          },
          {
            Header: t("time"),
            accessor: "scheduledFor",
            disableSortBy: true,
            align: "right",
            cell: (s) => (
              <TableCell.Date
                value={new Date(s.scheduledFor)}
                dateFormat="HH:mm"
              />
            ),
          },
          {
            Header: t("doctor"),
            accessor: "employeeFirstName",
            disableSortBy: true,
            align: "left",
            cell: (s) => (
              <TableCell.Text
                value={`${s.employeeFirstName} ${s.employeeLastName}`}
              />
            ),
          },
          // {
          //   Header: t("price"),
          //   accessor: "price",
          //   disableSortBy: true,
          //   align: "right",
          //   cell: (s) => (
          //     <TableCell.Text
          //       value={`€ ${s.price} (${(s.price * 7.435).toFixed(2)} kn)`}
          //     />
          //   ),
          // },
        ]}
        currentPage={page}
        setPage={setPage}
        pageSize={size}
        maxPage={Math.ceil(total / size)}
        totalCount={total}
        setSortKey={setOrderField}
        setSortOrder={setOrder}
        sortKey={orderField}
        sortOrder={order}
      />
      {!!selectedAppointmentId && (
        <AppointmentDetailsModal
          appointmentId={selectedAppointmentId}
          onClose={() => setIsEnterAppointmentInfoModalOpen(false)}
          open={isEnterAppointmentInfoModalOpen}
          hideReportArrival
        />
      )}
    </div>
  );
};
