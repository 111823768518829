import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import { Api } from "../../../api/api";
import { useEmployeeWorkingHours } from "../../../api/hooks/employees/useEmployeeWorkingHours";
import { MonthCalendar } from "../../../components/calendar/MonthCalendar";
import { selectedFacilityIdState } from "../../../state";
import { EditWeekWorkTimeModal } from "../../facilities/modals/EditWeekWorkTimeModal";
import { EmployeeDetailsSection } from "../EmployeeDetailsSection";
/** @jsxImportSource @emotion/react */

export const EmployeeWorkTimeTab = ({
  activeTab,
  isEditWorkWeekTimeModalOpen,
  setIsEditWorkWeekTimeModalOpen,
}) => {
  const [selectedDay, setSelectedDay] = useState(new Date());
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const { pathname } = useLocation();
  const altId = pathname.split("/");

  const { id: idParam } = useParams();
  const id = idParam || altId[altId.length - 1];
  const {
    employees: {
      employeeControllerGetEmployeeExceptionWorkingHoursForFacility,
    },
  } = new Api();
  const {
    employeeWorkingHours: { data: employeeWorkingHours },
    updateEmployeeDefaultWorkingHours,
  } = useEmployeeWorkingHours({
    id: Number(id),
    facilityId: Number(selectedFacilityId),
  });

  const { data: employeeExceptionWorkingHours } = useQuery(
    [
      "employee",
      id,
      "facility",
      selectedFacilityId,
      "working-hours",
      "exception",
    ],
    async () => {
      const { data } =
        await employeeControllerGetEmployeeExceptionWorkingHoursForFacility(
          Number(id),
          Number(selectedFacilityId),
          { date: selectedDay.toISOString() }
        );

      return {
        ...data,
        payload: data.payload?.exceptionWorkingHoursScheduleWithShifts,
      };
    },
    {
      enabled: !!id,
    }
  );

  return (
    <div css={[tw`flex h-full justify-center`]}>
      <EmployeeDetailsSection />
      <div css={[tw`w-full max-w-[965px]`]}>
        <MonthCalendar
          schedule={
            [
              ...(employeeWorkingHours?.payload || []),
              ...(employeeExceptionWorkingHours?.payload || []),
            ] || []
          }
          activeTab={activeTab}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          containerCss={[tw`ml-5`]}
        />
      </div>
      {employeeWorkingHours?.isSuccessful &&
        employeeExceptionWorkingHours?.isSuccessful && (
          <EditWeekWorkTimeModal
            onClose={() => setIsEditWorkWeekTimeModalOpen(false)}
            open={isEditWorkWeekTimeModalOpen}
            updateWorkingHours={updateEmployeeDefaultWorkingHours}
            workingHours={
              [
                ...(employeeWorkingHours?.payload || []),
                ...(employeeExceptionWorkingHours?.payload || []),
              ] || []
            }
          />
        )}
    </div>
  );
};
