/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { format } from "date-fns";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RiCloseLine, RiMailSendLine } from "react-icons/ri";
import tw from "twin.macro";
import * as yup from "yup";

import {
  CreateMedicalRecordRequestDto,
  MedicalRecordAttachment,
  RequestMedicalRecordDocumentLinksResponseDto,
} from "../../../api/api";
import { useAppointment } from "../../../api/hooks/appointments/useAppointment";
import { useFileUpload } from "../../../api/hooks/file-upload/useFileUpload";
import { useMedicalRecords } from "../../../api/hooks/medical-records/useMedicalRecords";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Button } from "../../../ui/buttons/Button";
import { IconButton } from "../../../ui/buttons/IconButton";
import { Dropzone } from "../../../ui/Dropzone";
import { toast } from "../../../ui/indicators/Toast";
import { Modal } from "../../../ui/popups/Modal";
import { Typography } from "../../../ui/Typograhy";
import { errorMessages } from "../../../utils";
import { uploadFile } from "../../../utils/upload-file";

const schema: yup.SchemaOf<CreateMedicalRecordRequestDto> = yup.object().shape({
  diagnosis: yup.string().required(errorMessages.required),
  treatment: yup.string().required(errorMessages.required),
  remark: yup.string(),
  attachments: yup.array(),
  appointmentId: yup.number().required(),
});

type IForm = yup.InferType<typeof schema>;

interface ITextInputModalProps {
  open: boolean;
  onClose: () => void;
  appointmentId: number;
}

export const EnterAppointmentInfoModal = ({
  open,
  onClose,
  appointmentId,
}: ITextInputModalProps) => {
  const { t } = useTranslation();
  const {
    appointment: { data: appointment },
  } = useAppointment({ id: Number(appointmentId) });
  const { createMedicalRecord } = useMedicalRecords();
  const { requestMedicalRecordDocumentLinks } = useFileUpload();
  const [filesForUpload, useFilesForUpload] = useState<File[]>([]);

  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      appointmentId: appointmentId,
      diagnosis: "",
      treatment: "",
      remark: "",
      attachments: [],
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      const attachmentsWithLinks: MedicalRecordAttachment[] = [];
      for (const file of filesForUpload) {
        const uploadData = (await requestMedicalRecordDocumentLinks({
          fileName: { fileName: file.name },
        })) as RequestMedicalRecordDocumentLinksResponseDto;
        await uploadFile(uploadData.uploadUrl, file);
        attachmentsWithLinks.push({
          name: file.name,
          downloadUrl: uploadData.downloadUrl,
          fileUUID: uploadData.fileUUID,
        });
      }
      await createMedicalRecord({
        requestData: {
          ...values,
          appointmentId,
          attachments: attachmentsWithLinks,
        },
      });
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  const RequestLinksForMedicalRecordDocuments = async (files: File[]) => {
    useFilesForUpload(files);
  };

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        containerCss={[
          tw`2xs:max-w-xs xs:max-w-md sm:max-w-xl md:max-w-2xl xl:max-w-3xl`,
        ]}
      >
        <div css={[tw`flex`]}>
          <div
            css={[
              tw`flex flex-col flex-1 -ml-6 rounded-l-md -mt-6 -mb-6 bg-white`,
            ]}
          >
            <div css={[tw`px-6 border-b-1 border-b-gray-200`]}>
              <Typography.BodyXLarge
                containerCss={[tw`mt-6 mb-6 leading-6 font-medium`]}
              >
                {t("patientData")}
              </Typography.BodyXLarge>
              <div css={[tw`flex gap-8`]}>
                <div css={[tw`flex flex-1 flex-col`]}>
                  <Typography.BodyXSmall
                    containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
                  >
                    {t("name")}
                  </Typography.BodyXSmall>
                  <Typography.BodySmall containerCss={[tw`mb-6`]}>
                    {appointment?.patient.firstName}
                  </Typography.BodySmall>
                </div>
                <div css={[tw`flex flex-1 flex-col`]}>
                  <Typography.BodyXSmall
                    containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
                  >
                    {t("surname")}
                  </Typography.BodyXSmall>
                  <Typography.BodySmall containerCss={[tw`mb-6`]}>
                    {appointment?.patient.lastName}
                  </Typography.BodySmall>
                </div>
              </div>
              <div css={[tw`flex gap-8`]}>
                <div css={[tw`flex flex-1 flex-col`]}>
                  <Typography.BodyXSmall
                    containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
                  >
                    {t("dateOfBirth")}
                  </Typography.BodyXSmall>
                  <Typography.BodySmall containerCss={[tw`mb-6`]}>
                    {format(
                      new Date(appointment?.patient.birthDate || new Date()),
                      "dd. MMMM yyyy."
                    )}
                  </Typography.BodySmall>
                </div>
                <div css={[tw`flex flex-1 flex-col`]}>
                  <Typography.BodyXSmall
                    containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
                  >
                    {t("gender")}
                  </Typography.BodyXSmall>
                  <Typography.BodySmall containerCss={[tw`mb-6`]}>
                    {t(`${appointment?.patient?.sex.toLowerCase()}`) || "-"}
                  </Typography.BodySmall>
                </div>
              </div>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("contactPhone")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-6`]}>
                {appointment?.patient.phone}
              </Typography.BodySmall>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("emailAddress")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-6`]}>
                {appointment?.patient.email}
              </Typography.BodySmall>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("addressOfLiving")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-6`]}>
                {`${appointment?.patient.address}, ${appointment?.patient.city}, ${appointment?.patient.country}`}
              </Typography.BodySmall>
            </div>
            <div css={[tw`px-6`]}>
              <Typography.BodyXLarge
                containerCss={[tw`mt-6 mb-6 leading-6 font-medium`]}
              >
                {t("serviceData")}
              </Typography.BodyXLarge>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("serviceName")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-6`]}>
                {appointment?.service.name}
              </Typography.BodySmall>
              <div css={[tw`flex gap-8`]}>
                <div css={[tw`flex flex-1 flex-col`]}>
                  <Typography.BodyXSmall
                    containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
                  >
                    {t("dateOfAppointment")}
                  </Typography.BodyXSmall>
                  <Typography.BodySmall containerCss={[tw`mb-6`]}>
                    {format(
                      new Date(appointment?.scheduledFor || new Date()),
                      "dd. MMMM yyyy."
                    )}
                  </Typography.BodySmall>
                </div>
                <div css={[tw`flex flex-1 flex-col`]}>
                  <Typography.BodyXSmall
                    containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
                  >
                    {t("time")}
                  </Typography.BodyXSmall>
                  <Typography.BodySmall containerCss={[tw`mb-6`]}>
                    {format(
                      new Date(appointment?.scheduledFor || new Date()),
                      "HH:mm"
                    )}
                  </Typography.BodySmall>
                </div>
              </div>
              {/* <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("healthInstitution")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-6`]}>
                Mare&Sun, Zadarska ulica 80, Zagreb
              </Typography.BodySmall> */}
            </div>
          </div>
          <div css={[tw`flex flex-col flex-1 pl-6`]}>
            <div css={[tw`flex items-center justify-between mb-6`]}>
              <Typography.BodyXLarge containerCss={[tw`leading-6 font-medium`]}>
                {t("enterAppointmentData")}
              </Typography.BodyXLarge>
              <IconButton.Contained
                icon={RiCloseLine}
                onClick={onClose}
                iconCss={[tw`h-6 w-6 text-gray-900`]}
                containerCss={[tw`bg-gray-100 rounded-full`]}
              />
            </div>
            <Form.Select
              options={[]}
              containerCss={[tw`flex-none`]}
              name="doctor"
              label={t("doctor") as string}
              placeholder={`${appointment?.employee.firstName} ${appointment?.employee.lastName}`}
              disabled
            />
            <Form.TextArea.Outlined
              label={t("diagnosis") as string}
              required
              rows={3}
              placeholder={t("enterDiagnosis") as string}
              name="diagnosis"
            />
            <Form.TextArea.Outlined
              label={t("treatment") as string}
              required
              rows={3}
              placeholder={t("enterTreatment") as string}
              name="treatment"
            />
            <Form.TextArea.Outlined
              label={t("remark") as string}
              required
              rows={2}
              placeholder={t("enterRemark") as string}
              name="remark"
            />
            <Dropzone
              multiple={true}
              title={t("addOrDragDropPhoto") as string}
              subtitle={t("pngOrJpgOrPdf10Mb") as string}
              maxFileSize={10}
              containerCss={[tw`p-1 mb-6`]}
              accept={{
                "image/png": [".png"],
                "image/jpg": [".jpg"],
                "application/pdf": [".pdf"],
              }}
              onUpload={(files) => RequestLinksForMedicalRecordDocuments(files)}
            />
            <div
              css={[
                tw`flex bg-gray-100 p-6 gap-3 -ml-6 -mb-6 -mr-6 rounded-br-md`,
              ]}
            >
              <Button.Outlined containerCss={[tw`flex-1`]} onClick={onSubmit}>
                {t("save")}
              </Button.Outlined>
              <Button.Contained
                lead={RiMailSendLine}
                containerCss={[tw`flex-5`]}
                onClick={onSubmit}
              >
                {t("saveAndSendToPatientMail")}
              </Button.Contained>
            </div>
          </div>
        </div>
      </Modal>
    </FormProvider>
  );
};
