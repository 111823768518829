/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiUserAddLine } from "react-icons/ri";
import tw from "twin.macro";
import { useMe } from "../../api/hooks/user/useMe";
import { PageContainer } from "../../components/layout/PageContainer";
import { Button } from "../../ui/buttons/Button";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { FilterPatientsModal } from "./modals/FilterPatientsModal";
import { ManagePatientModal } from "./modals/ManagePatientModal";
import { PatientsListTab } from "./tabs/patients/PatientsListTab";

export const PatientsPage = () => {
  const [isAddNewPatientModalOpen, setIsAddNewPatientModalOpen] =
    useState(false);
  const [isFilterPatientModalOpen, setFilterNewPatientModalOpen] =
    useState(false);
  const { t } = useTranslation();
  const { me } = useMe();
  return (
    <PageContainer>
      <Tabs
        rightComponent={
          <div css={[tw`flex justify-end gap-3`]}>
            {/* <Button.Outlined
                  lead={RiFilterLine}
                  onClick={() => setFilterNewPatientModalOpen(true)}
                >
                  {t("filter")}
                </Button.Outlined> */}
            {me.role?.permissions?.PATIENTS?.CREATE && (
              <Button.Contained
                lead={RiUserAddLine}
                onClick={() => setIsAddNewPatientModalOpen(true)}
              >
                {t("addNewPatient")}
              </Button.Contained>
            )}
          </div>
        }
        containerCss={[tw`m-auto`]}
      >
        <Tabs.Item text={t("patientList")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <PatientsListTab
            setIsAddNewPatientModalOpen={setIsAddNewPatientModalOpen}
          />
        </Tabs.Item>
        {/* <Tabs.Item text={t("archive")}>
            <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
            <PatientsArchiveTab />
          </Tabs.Item> */}
      </Tabs>
      {me.role?.permissions?.PATIENTS?.CREATE && (
        <ManagePatientModal
          onClose={() => setIsAddNewPatientModalOpen(false)}
          open={isAddNewPatientModalOpen}
        />
      )}
      <FilterPatientsModal
        onClose={() => setFilterNewPatientModalOpen(false)}
        open={isFilterPatientModalOpen}
      />
    </PageContainer>
  );
};
