import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";

export const usePatientCouponsAwards = ({ id }: { id?: number }) => {
  const {
    patients: { patientControllerGetPatientCouponsAwards },
  } = new Api();

  const patientCouponsAwards = useGetById({
    id: Number(id),
    query: patientControllerGetPatientCouponsAwards,
    queryKey: ["patient", Number(id), "coupons", "awards"],
  });

  return {
    patientCouponsAwards,
  };
};
