import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import * as yup from "yup";
import { CreateDepartmentRequestDto } from "../../../api/api";
import { useDepartment } from "../../../api/hooks/departments/useDepartment";
import { useDepartments } from "../../../api/hooks/departments/useDepartments";
import { Assets } from "../../../assets";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { selectedFacilityIdState } from "../../../state";
import { Button } from "../../../ui/buttons/Button";
import { IconButton } from "../../../ui/buttons/IconButton";
import { toast } from "../../../ui/indicators/Toast";
import { IModalProps, Modal } from "../../../ui/popups/Modal";
import { Typography } from "../../../ui/Typograhy";
import { errorMessages } from "../../../utils";
/** @jsxImportSource @emotion/react */

const schema: yup.SchemaOf<CreateDepartmentRequestDto> = yup.object().shape({
  facilityId: yup
    .number()
    .typeError(errorMessages.number)
    .required(errorMessages.required),
  iconUrl: yup.string().required(errorMessages.required),
  name: yup.string().required(errorMessages.required),
});

type IForm = yup.InferType<typeof schema>;

const stepDescriptions = ["enterDepartmentName", "chooseDepartmentIcon"];

const triggerByStep = ["name", "iconUrl"];
const baseIconUrl =
  "https://s3.eu-west-1.amazonaws.com/staging.clinicore.plus-assets/icons";

const icons = [
  { icon: Assets.HeartIcon, name: "heart", url: `${baseIconUrl}/heart.svg` },
  {
    icon: Assets.BacteriaIcon,
    name: "virus",
    url: `${baseIconUrl}/virus.svg`,
  },
  {
    icon: Assets.SurgeryIcon,
    name: "goggles",
    url: `${baseIconUrl}/goggles.svg`,
  },
  {
    icon: Assets.CartonIcon,
    name: "medical-report",
    url: `${baseIconUrl}/medical-report.svg`,
  },
  { icon: Assets.CaughIcon, name: "flu", url: `${baseIconUrl}/flu.svg` },
  {
    icon: Assets.PillRoundIcon,
    name: "tablet",
    url: `${baseIconUrl}/tablet.svg`,
  },
  { icon: Assets.ReportIcon, name: "report", url: `${baseIconUrl}/report.svg` },
  {
    icon: Assets.PillIcon,
    name: "pill",
    url: `${baseIconUrl}/pill.svg`,
  },
  {
    icon: Assets.GinecologyIcon,
    name: "gynecology",
    url: `${baseIconUrl}/gynecology.svg`,
  },
  {
    icon: Assets.StetoscopeIcon,
    name: "stethoscope",
    url: `${baseIconUrl}/stethoscope.svg`,
  },
];

export const NewDepartmentModal = ({
  open,
  onClose,
  department,
}: Omit<IModalProps, "label" | "footerChildren"> & {
  department?: {
    id?: number;
    name?: string;
    iconUrl?: string;
  };
}) => {
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const { createDepartment } = useDepartments();
  const { updateDepartment } = useDepartment({ id: department?.id! });
  const [step, setStep] = useState(0);
  const { t } = useTranslation();
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      name: department?.name,
      facilityId: Number(selectedFacilityId!),
      iconUrl: department?.iconUrl,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const isEdit = !!department?.id;

  const incrementStep = useCallback(async () => {
    //@ts-ignore
    const isCorrect = await methods.trigger(triggerByStep[step]);

    if (isCorrect) {
      setStep((prevStep) =>
        Math.min(prevStep + 1, stepDescriptions.length - 1)
      );
    }
  }, [methods, step]);

  const decrementStep = useCallback(() => {
    setStep((prevStep) => Math.max(prevStep - 1, 0));
  }, []);

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      if (isEdit) {
        await updateDepartment({ requestData: values });
      } else {
        await createDepartment({ requestData: values });
      }
      methods.clearErrors();
      methods.reset();
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  const isLastStep = step === stepDescriptions.length - 1;

  const selectedIcon = methods.watch("iconUrl");

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={(isEdit ? t("editDepartment") : t("addNewDepartment")) as string}
        footerChildren={
          <div css={[tw`flex justify-between gap-3`]}>
            <Button.Outlined
              containerCss={[tw`flex-1`]}
              onClick={decrementStep}
              disabled={step === 0}
            >
              {t("back")}
            </Button.Outlined>
            <Button.Contained
              containerCss={[tw`flex-5`, isLastStep && tw`flex-2`]}
              onClick={
                isLastStep
                  ? () => {
                      onSubmit();
                      //navigate to add services
                    }
                  : incrementStep
              }
            >
              {isLastStep ? t("saveDepartment") : t("next")}
            </Button.Contained>
            {/* <Button.Contained
              containerCss={[tw`flex-5`, isLastStep && tw`flex-2`]}
              onClick={
                isLastStep
                  ? () => {
                      onSubmit();
                      //navigate to add services
                    }
                  : incrementStep
              }
            >
              {isLastStep ? t("saveDepartmentAndAddService") :  t("next")}
            </Button.Contained> */}
          </div>
        }
      >
        <Typography.BodyMedium containerCss={[tw`mb-6`]}>
          {t("step")} {step + 1}.{" "}
          <Typography.BodyMedium containerCss={[tw`font-700 inline`]}>
            {t(stepDescriptions[step])}
          </Typography.BodyMedium>
        </Typography.BodyMedium>
        {step === 0 && (
          <div>
            <Form.TextInput.Outlined
              name="name"
              required
              label={t("departmentName") as string}
              placeholder={t("enterDepartmentName") as string}
            />
          </div>
        )}
        {step === 1 && (
          <div css={[tw`bg-white rounded-md p-4 flex mb-6 justify-evenly`]}>
            {icons.map(({ icon, url }) => (
              <IconButton.Text
                icon={icon}
                iconCss={[tw`w-10 h-10`]}
                containerCss={[
                  tw`p-3 rounded-md hover:(bg-gray-100)`,
                  tw`border-1 border-transparent`,
                  selectedIcon === url &&
                    tw`bg-primary-100 border-1 border-primary hover:(bg-primary-200)`,
                ]}
                onClick={() => methods.setValue("iconUrl", url)}
              />
            ))}
          </div>
        )}
      </Modal>
    </FormProvider>
  );
};
