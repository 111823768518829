import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import { usePatientAppointments } from "../../../../api/hooks/patients/usePatientAppointments";
import { searchState } from "../../../../state";
import { Maybe } from "../../../../types";
import { AsyncDataTable } from "../../../../ui/layout/data-table/async/AsyncDataTable";
import { TableCell } from "../../../../ui/layout/data-table/cell";
import { AppointmentDetailsModal } from "../../../appointments/modals/AppointmentDetailsModal";
import { MedicalRecordDetailsModal } from "../../../appointments/modals/MedicalRecordDetailsModal";
import { PatientInfoSection } from "../../components/PatientInfoSection";

/** @jsxImportSource @emotion/react */

export const PatientAppointmentHistoryTab = () => {
  const { id } = useParams();

  const [activeRowIndex, setActiveRowIndex] = useState<Maybe<number>>(null);
  // const [isMedicalRecordDetailsModalOpen, setIsMedicalRecordDetailsModalOpen] =
  //   useState(false);
  const [isEnterAppointmentInfoModalOpen, setIsEnterAppointmentInfoModalOpen] =
    useState(false);
  const search = useRecoilValue(searchState);
  const [selectedAppointmentId, setSelectedAppointmentId] =
    useState<Maybe<number>>(null);
  const {
    patientHistoryAppointments: {
      data: patientHistoryAppointments,
      isFetching,
      filters,
      setFilter,
      page,
      setPage,
      size,
      setOrderField,
      setOrder,
      order,
      orderField,
    },
  } = usePatientAppointments({ id: Number(id) });
  const { t } = useTranslation();

  useEffect(() => {
    if (filters.term !== search) {
      setFilter("term", search);
    }
  }, [filters.term, search, setFilter]);

  const records = patientHistoryAppointments?.items ?? [];
  const total = patientHistoryAppointments?.totalItems ?? 0;
  return (
    <div css={[tw`flex h-full justify-center`]}>
      <PatientInfoSection />
      <AsyncDataTable
        activeRowIndex={activeRowIndex}
        loading={isFetching}
        data={records}
        onRowClick={(u, i) => {
          setActiveRowIndex(u.id);
          setSelectedAppointmentId(u.id);
          setIsEnterAppointmentInfoModalOpen(true);
          // setIsMedicalRecordDetailsModalOpen(true);
        }}
        containerCss={[tw`max-w-[965px] w-full flex flex-col justify-between`]}
        columns={[
          {
            Header: t("serviceName"),
            accessor: "serviceName",
            disableSortBy: true,

            cell: (s) => <TableCell.Text value={`${s.serviceName}`} />,
          },
          {
            Header: t("zu"),
            accessor: "facilityName",
            align: "right",
            disableSortBy: true,
            cell: (s) => <TableCell.Text value={`${s.facilityName}`} />,
          },
          {
            Header: t("date"),
            accessor: "employeeFirstName",
            disableSortBy: true,
            align: "right",
            cell: (s) => <TableCell.Date value={new Date(s.scheduledFor)} />,
          },
          {
            Header: t("time"),
            accessor: "scheduledFor",
            disableSortBy: true,
            align: "right",
            cell: (s) => (
              <TableCell.Date
                value={new Date(s.scheduledFor)}
                dateFormat="HH:mm"
              />
            ),
          },

          {
            Header: t("doctor"),
            accessor: "employeeLastName",
            disableSortBy: true,
            align: "right",
            cell: (s) => (
              <TableCell.Text
                value={`${s.employeeFirstName} ${s.employeeLastName}`}
              />
            ),
          },
          {
            Header: t("price"),
            accessor: "price",
            disableSortBy: true,
            align: "right",
            cell: (s) => (
              <TableCell.Text
                value={`€ ${s.price} (${(s.price * 7.435).toFixed(2)} kn)`}
              />
            ),
          },
        ]}
        currentPage={page}
        setPage={setPage}
        pageSize={size}
        maxPage={patientHistoryAppointments?.totalPages || 0}
        totalCount={total}
        setSortKey={setOrderField}
        setSortOrder={setOrder}
        sortKey={orderField}
        sortOrder={order}
      />
      {!!selectedAppointmentId && (
        <AppointmentDetailsModal
          appointmentId={selectedAppointmentId}
          onClose={() => setIsEnterAppointmentInfoModalOpen(false)}
          open={isEnterAppointmentInfoModalOpen}
          hideReportArrival
        />
      )}
      {/* <MedicalRecordDetailsModal
        open={isMedicalRecordDetailsModalOpen}
        onClose={() => setIsMedicalRecordDetailsModalOpen(false)}
      /> */}
    </div>
  );
};
