import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { selectedFacilityIdState } from "../../../state";
import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";

export const useFacilityServices = () => {
  const {
    services: { serviceControllerGetFacilityServices },
  } = new Api();
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const {
    data: facilityServices,
    isLoading: isLoadingFacilityServices,
    refetch: refetchFacilityServices,
  } = useGetById({
    id: Number(selectedFacilityId),
    query: serviceControllerGetFacilityServices,
    queryKey: ["facility", selectedFacilityId!, "services"],
  });

  const parsedFacilityServices = useMemo(
    () =>
      facilityServices?.map((fe) => ({
        value: fe.id,
        label: `${fe.name}`,
      })),
    [facilityServices]
  );

  return {
    facilityServices: parsedFacilityServices,
    isLoadingFacilityServices,
    refetchFacilityServices,
  };
};
