import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Api,
  PutEmployeeDefaultWorkingHoursForFacilityBodyRequestDto,
  PutEmployeeExceptionWorkingHoursForFacilityBodyRequestDto,
} from "../../api";

export const useEmployeeWorkingHours = ({
  id,
  facilityId,
}: {
  id: number;
  facilityId: number;
}) => {
  const client = useQueryClient();
  const {
    employees: {
      employeeControllerGetEmployeeDefaultWorkingHoursForFacility,
      employeeControllerPutEmployeeDefaultWorkingHoursForFacility,
      employeeControllerPutEmployeeExceptionWorkingHoursForFacility,
    },
  } = new Api();

  const employeeWorkingHours = useQuery(
    ["employee", id, "facility", facilityId, "working-hours"],
    async () => {
      const { data } =
        await employeeControllerGetEmployeeDefaultWorkingHoursForFacility(
          id,
          facilityId
        );

      return {
        ...data,
        payload: data.payload?.defaultWorkingHoursScheduleWithShifts,
      };
    },
    {
      enabled: !!id,
    }
  );

  const { mutateAsync: updateEmployeeDefaultWorkingHours } = useMutation(
    ({
      workingHours,
    }: PutEmployeeDefaultWorkingHoursForFacilityBodyRequestDto) =>
      employeeControllerPutEmployeeDefaultWorkingHoursForFacility(
        id,
        facilityId,
        { workingHours }
      ),
    {
      onSuccess: () =>
        client.refetchQueries([
          "employee",
          id,
          "facility",
          facilityId,
          "working-hours",
        ]),
    }
  );

  const { mutateAsync: updateEmployeeExceptionWorkingHours } = useMutation(
    ({
      date,
      workingHours,
    }: PutEmployeeExceptionWorkingHoursForFacilityBodyRequestDto) =>
      employeeControllerPutEmployeeExceptionWorkingHoursForFacility(
        id,
        facilityId,
        { date, workingHours }
      ),
    {
      onSuccess: () =>
        client.refetchQueries([
          "employee",
          id,
          "facility",
          facilityId,
          "working-hours",
          "exception",
        ]),
    }
  );

  return {
    employeeWorkingHours,
    updateEmployeeDefaultWorkingHours,
    updateEmployeeExceptionWorkingHours,
  };
};
