import { useState } from "react";
import { TableCell } from "../../../ui/layout/data-table/cell";
import { AppointmentDetailsModal } from "../../appointments/modals/AppointmentDetailsModal";

export const PatientServiceCellChip = ({ appointment }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsModalOpen(true);
      }}
    >
      <TableCell.Chip value={appointment?.service?.name || ""} type="table" />
      <AppointmentDetailsModal
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        appointmentId={appointment.id}
      />
    </div>
  );
};
