import "twin.macro";

import React from "react";

import { IconType } from "react-icons";
import tw from "twin.macro";
import { Assets } from "../../assets";
import { Button } from "../../ui/buttons/Button";
import { Typography } from "../../ui/Typograhy";
import { PageContainer } from "../layout/PageContainer";
import { Maybe, TwinStyle } from "../../types";
import { useTranslation } from "react-i18next";

/** @jsxImportSource @emotion/react */

export interface IEmptyStateProps {
  title: string;
  subtitle: string;
  image?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  buttonText: string;
  onButtonClick?: () => void;
  buttonIcon?: IconType;
  containerCss?: Maybe<TwinStyle>;
  hasButton?: boolean;
}

export const EmptyState = ({
  title,
  subtitle,
  image,
  buttonText,
  buttonIcon,
  onButtonClick,
  containerCss,
  hasButton,
}: IEmptyStateProps) => {
  const Image = image || Assets.EmptyStateImage;
  const Icon = buttonIcon;
  const { t } = useTranslation();
  return (
    <PageContainer
      containerCss={[
        tw`flex flex-col justify-center items-center`,
        containerCss,
      ]}
    >
      <Image css={[tw`mb-4`]} />
      <Typography.H3 containerCss={[tw`mb-6`]}>
        {title || t("somethingWentWrong")}
      </Typography.H3>
      <Typography.BodyLarge containerCss={[tw`mb-10`]}>
        {subtitle || t("pleaseTryAgain")}
      </Typography.BodyLarge>
      {hasButton && (
        <Button.Contained
          onClick={onButtonClick}
          containerCss={[tw`min-w-44`]}
          lead={Icon}
        >
          {buttonText || ""}
        </Button.Contained>
      )}
    </PageContainer>
  );
};
