/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiAddFill, RiArrowLeftLine } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import tw from "twin.macro";
import { useMe } from "../../api/hooks/user/useMe";
import { PageContainer } from "../../components/layout/PageContainer";
import { Button } from "../../ui/buttons/Button";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { SearchPatientModal } from "../patients/modals/SearchPatientModal";
import { ArrangedAppointmentsTab } from "./tabs/ArrangedAppointmentsTab";
import { PastAppointmentsTab } from "./tabs/PastAppointmentsTab";

export const ServicePage = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { me } = useMe();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isSearchPatientModalOpen, setIsSearchPatientModalOpen] =
    useState(false);

  return (
    <PageContainer>
      <Tabs
        leftComponent={
          <div css={[tw`flex gap-4 items-center flex-1`]}>
            <Button.Text
              lead={RiArrowLeftLine}
              leadCss={[tw`text-gray-900`]}
              textCss={[tw`text-gray-900`]}
              containerCss={[tw`px-2`]}
              onClick={() => navigate(-1)}
            >
              {t("backToList")}
            </Button.Text>
          </div>
        }
        rightComponent={
          <div css={[tw`flex justify-end gap-3 flex-1`]}>
            {me?.role?.permissions?.APPOINTMENTS?.CREATE && (
              <Button.Contained
                lead={RiAddFill}
                onClick={() => setIsSearchPatientModalOpen(true)}
              >
                {t("newAppointment")}
              </Button.Contained>
            )}
          </div>
        }
        containerCss={[tw`m-auto`]}
      >
        <Tabs.Item text={t("arrangedAppointments")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <ArrangedAppointmentsTab />
        </Tabs.Item>
        <Tabs.Item text={t("history")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <PastAppointmentsTab />
        </Tabs.Item>
      </Tabs>
      <SearchPatientModal
        open={isSearchPatientModalOpen}
        onClose={() => setIsSearchPatientModalOpen(false)}
        serviceId={Number(id)}
        departmentId={state.departmentId}
      />
    </PageContainer>
  );
};
