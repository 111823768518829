// import "twin.macro";

import { Chip, IChipProps } from "../../buttons/Chip";
/** @jsxImportSource @emotion/react */
import { IComponentBaseProps, Maybe } from "../../../types";

import { Typography } from "../../Typograhy";
import { formatDate } from "../../../utils";
import tw from "twin.macro";
import { Button, IButtonProps } from "../../buttons/Button";
import { MouseEventHandler, PropsWithChildren } from "react";

interface ITextCellProps extends IComponentBaseProps {
  value: Maybe<string>;
  showEmptyString?: boolean;
}

interface IDottedTextCellProps extends IComponentBaseProps {
  value: Maybe<string>;
  isSelected: boolean;
}

const TextCell = (props: ITextCellProps) => {
  const { value, showEmptyString } = props;
  const isEmpty = value == null || value.trim() === "";
  return (
    <Typography.BodySmall containerCss={[props.containerCss, tw`text-gray`]}>
      {!isEmpty || showEmptyString ? value : "N/A"}
    </Typography.BodySmall>
  );
};

const DottedTextCell = (props: IDottedTextCellProps) => {
  const { value, isSelected } = props;
  const isEmpty = value == null || value.trim() === "";
  return (
    <div css={[tw`flex items-center`]}>
      <div
        css={[
          tw`w-2 h-2 rounded-full bg-gray-300 mr-3`,
          isSelected && tw`bg-primary`,
        ]}
      />
      <Typography.BodySmall containerCss={[props.containerCss, tw`text-gray`]}>
        {!isEmpty ? value : "N/A"}
      </Typography.BodySmall>
    </div>
  );
};

interface IDateCellProps extends IComponentBaseProps {
  value: Maybe<Date>;
  dateFormat?: string;
}

const DateCell = (props: IDateCellProps) => {
  const { value } = props;
  const isEmpty = value == null;
  const dateFormat = props.dateFormat ?? "d. MMM. yyyy.";
  return (
    <Typography.BodySmall containerCss={[props.containerCss]}>
      {!isEmpty ? formatDate(new Date(value), { dateFormat }) : "N/A"}
    </Typography.BodySmall>
  );
};

interface IYesNoCellProps extends IComponentBaseProps {
  value: Maybe<boolean>;
}

const YesNoCell = (props: IYesNoCellProps) => {
  const { value } = props;
  const isEmpty = value == null;
  return (
    <Typography.BodySmall containerCss={[props.containerCss]}>
      {!isEmpty ? (value ? "Yes" : "No") : "N/A"}
    </Typography.BodySmall>
  );
};

interface IChipCellProps extends IComponentBaseProps {
  value: Maybe<string>;
  type: IChipProps["type"];
  onClick?: MouseEventHandler<HTMLSpanElement>;
}

const ChipCell = (props: IChipCellProps) => {
  const { value, type, onClick } = props;
  const isEmpty = value == null || value.trim() === "";
  if (isEmpty) {
    return (
      <Typography.BodySmall containerCss={[props.containerCss]}>
        N/A
      </Typography.BodySmall>
    );
  }
  return (
    <Chip
      containerCss={[props.containerCss, tw`rounded-[6px]`]}
      type={type}
      value={value}
      onClick={onClick}
    />
  );
};

interface ICurrencyCellProps extends IComponentBaseProps {
  value: Maybe<number>;
}

const CurrencyCell = (props: ICurrencyCellProps) => {
  const { value } = props;
  const isEmpty = value == null;
  const formattedValue = !isEmpty
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }).format(value)
    : "N/A";

  return (
    <Typography.BodySmall containerCss={[props.containerCss]}>
      {formattedValue}
    </Typography.BodySmall>
  );
};

interface IPercentageCellProps extends IComponentBaseProps {
  value: Maybe<number>;
}

const PercentageCell = (props: IPercentageCellProps) => {
  const { value } = props;
  const isEmpty = value == null;

  return (
    <Typography.BodySmall containerCss={[props.containerCss]}>
      {!isEmpty ? `${value}%` : "N/A"}
    </Typography.BodySmall>
  );
};

interface IButtonCellProps
  extends IComponentBaseProps,
    PropsWithChildren<IButtonProps> {}

const ButtonCell = (props: IButtonCellProps) => {
  const { children } = props;

  return (
    <Button.Text containerCss={[props.containerCss]} {...props}>
      {children}
    </Button.Text>
  );
};
export const TableCell = {
  Text: TextCell,
  DottedText: DottedTextCell,
  Date: DateCell,
  YesNo: YesNoCell,
  Chip: ChipCell,
  Currency: CurrencyCell,
  Percentage: PercentageCell,
  Button: ButtonCell,
};
