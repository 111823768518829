import { useState } from "react";
import tw from "twin.macro";
import { useRoom } from "../../../api/hooks/rooms/useRoom";
import { RoomDetailsModal } from "../modals/RoomDetailsModal";
/** @jsxImportSource @emotion/react */

export const RoomNameRow = ({ roomId, departmentId }) => {
  const [isRoomDetailModalOpen, setIsRoomDetailModalOpen] = useState(false);
  const {
    room: { data: room },
  } = useRoom({ id: Number(roomId) });

  return (
    <>
      <div
        css={[
          tw`min-w-[256px] h-22 px-4 py-4 flex justify-end items-center border-b-1 border-b-gray-200 border-r-1 border-r-gray-200`,
          tw`hover:(bg-gray-50 cursor-pointer)`,
        ]}
        onClick={() => setIsRoomDetailModalOpen(true)}
      >
        {room?.name}
      </div>
      {room?.id && (
        <RoomDetailsModal
          departmentId={departmentId}
          open={isRoomDetailModalOpen}
          onClose={() => setIsRoomDetailModalOpen(false)}
          room={room}
        />
      )}
    </>
  );
};
