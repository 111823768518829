import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiCoupon3Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";

import { useFacilityNews } from "../../../api/hooks/news/useFacilityNews";
import { searchState } from "../../../state";
import { AsyncDataTable } from "../../../ui/layout/data-table/async/AsyncDataTable";
import { TableCell } from "../../../ui/layout/data-table/cell";
import { ArchivedNewsModal } from "../modals/ArchivedNewsModal";
/** @jsxImportSource @emotion/react */

export const ArchiveNewsTab = () => {
  const search = useRecoilValue(searchState);
  const { pastFacilityNews } = useFacilityNews();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isEditNewsModalOpen, setIsEditNewsModalOpen] = useState(false);
  const [selectedNewsId, setSelectedNewsId] = useState(0);

  useEffect(() => {
    if (pastFacilityNews.filters.term !== search) {
      pastFacilityNews.setFilter("term", search);
    }
  }, [pastFacilityNews, search]);

  const onNewsClick = (id: number) => {
    setSelectedNewsId(id);
    setIsEditNewsModalOpen(true);
  };

  return (
    <>
      <AsyncDataTable
        loading={pastFacilityNews.isInitialLoading}
        data={pastFacilityNews.data?.items || []}
        containerCss={[tw`flex flex-col justify-between h-full`]}
        onRowClick={(u) => onNewsClick(u.id)}
        columns={[
          {
            Header: t("headline"),
            accessor: "title",
            align: "left",
            cell: (s) => <TableCell.Text value={`${s.title}`} />,
          },
          {
            Header: t("author"),
            accessor: "author",
            align: "right",
            cell: (s) => (
              <TableCell.Text
                value={`${s.author.firstName} ${s.author.lastName}`}
              />
            ),
          },
          {
            Header: t("coupon"),
            accessor: "coupon",
            disableSortBy: true,
            align: "right",
            cell: (s) =>
              s.coupon ? (
                <TableCell.Button
                  lead={RiCoupon3Line}
                  variant="small"
                  onClick={() => {
                    navigate(`/coupons/${s.coupon?.id}`);
                  }}
                >
                  {t("openCoupon")}
                </TableCell.Button>
              ) : (
                <TableCell.Text value={""} showEmptyString />
              ),
          },
          {
            Header: t("publishDate"),
            accessor: "availableFrom",
            align: "right",
            cell: (s) => <TableCell.Date value={new Date(s.availableFrom)} />,
          },
        ]}
        currentPage={pastFacilityNews.page}
        setPage={pastFacilityNews.setPage}
        pageSize={pastFacilityNews.size}
        maxPage={pastFacilityNews.data?.totalPages || 0}
        totalCount={pastFacilityNews.data?.totalItems || 0}
        setSortKey={pastFacilityNews.setOrderField}
        setSortOrder={pastFacilityNews.setOrder}
        sortKey={pastFacilityNews.orderField}
        sortOrder={pastFacilityNews.order}
      />
      {selectedNewsId && isEditNewsModalOpen && (
        <ArchivedNewsModal
          onClose={() => setIsEditNewsModalOpen(false)}
          open={isEditNewsModalOpen}
          newsId={selectedNewsId}
        />
      )}
    </>
  );
};
