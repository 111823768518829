import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";

export const useAppointments = () => {
  const {
    appointments: { appointmentControllerCreateAppointment },
  } = new Api();
  const { mutateAsync: createAppointment } = useCreate(
    appointmentControllerCreateAppointment,
    ["appointments"]
  );

  return {
    createAppointment,
  };
};
