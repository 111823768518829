/** @jsxImportSource @emotion/react */

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { RiCheckboxCircleLine } from "react-icons/ri";
import tw from "twin.macro";

import { useAppointment } from "../../../api/hooks/appointments/useAppointment";
import { Assets } from "../../../assets";
import { Button } from "../../../ui/buttons/Button";
import { Modal } from "../../../ui/popups/Modal";
import { Typography } from "../../../ui/Typograhy";

interface ITextInputModalProps {
  open: boolean;
  onClose: () => void;
  appointmentId: number;
  hideReportArrival?: boolean;
}

export const AppointmentDetailsModal = ({
  open,
  onClose,
  appointmentId,
  hideReportArrival,
}: ITextInputModalProps) => {
  const { t } = useTranslation();
  const {
    appointment: { data: appointment },
    reportArrival,
  } = useAppointment({ id: Number(appointmentId) });

  return (
    <Modal
      open={open}
      onClose={onClose}
      label={appointment?.service?.name || ""}
      footerChildren={
        hideReportArrival || appointment?.arrivedAt ? null : (
          <div css={[tw`flex gap-3`]}>
            {/* <Button.Outlined containerCss={[tw`flex-1`]}>
            {t("cancelAppointment")}
          </Button.Outlined> */}
            {/* <Button.Outlined containerCss={[tw`flex-1`]} lead={RiPrinterLine}>
            {t("printPurchaseOrder")}
          </Button.Outlined> */}

            <Button.Contained
              containerCss={[tw`flex-3`]}
              lead={RiCheckboxCircleLine}
              onClick={() => reportArrival()}
            >
              {t("reportArrival")}
            </Button.Contained>
          </div>
        )
      }
      containerCss={[tw`bg-white`]}
    >
      <div css={[tw`flex flex-col`]}>
        <div css={[tw`flex gap-6`]}>
          <div
            css={[
              tw`flex flex-1 flex-col border-b-1 border-b-gray-200 mb-3 pb-3 `,
            ]}
          >
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("patient")}
            </Typography.BodyXSmall>
            <Typography.BodySmall>
              {appointment?.patient.firstName} {appointment?.patient.lastName}
            </Typography.BodySmall>
          </div>
          <div
            css={[
              tw`flex flex-1 flex-col border-b-1 border-b-gray-200 mb-3 pb-3 `,
            ]}
          >
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("birthDate")}
            </Typography.BodyXSmall>
            <Typography.BodySmall>
              {format(
                new Date(appointment?.patient.birthDate || new Date()),
                "dd. MMMM yyyy."
              )}
            </Typography.BodySmall>
          </div>
        </div>
        <div css={[tw`flex gap-6`]}>
          <div
            css={[
              tw`flex flex-1 flex-col border-b-1 border-b-gray-200 mb-3 pb-3 `,
            ]}
          >
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("appointmentDate")}
            </Typography.BodyXSmall>
            <Typography.BodySmall>
              {format(
                new Date(appointment?.scheduledFor || new Date()),
                "dd. MMMM yyyy."
              )}
            </Typography.BodySmall>
          </div>
          <div
            css={[
              tw`flex flex-1 flex-col border-b-1 border-b-gray-200 mb-3 pb-3 `,
            ]}
          >
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("appointmentTime")}
            </Typography.BodyXSmall>
            <Typography.BodySmall>
              {format(
                new Date(appointment?.scheduledFor || new Date()),
                "HH:mm"
              )}
            </Typography.BodySmall>
          </div>
          {/* <div
            css={[
              tw`flex flex-2 flex-col border-b-1 border-b-gray-200 mb-3 pb-3 `,
            ]}
          >
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("healthInstitution")}
            </Typography.BodyXSmall>
            <Typography.BodySmall>
              Mare&Sun, Zadarska ulica 80, Zagreb
            </Typography.BodySmall>
          </div> */}
        </div>
        {appointment?.arrivedAt && (
          <>
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("arrivedAt")}
            </Typography.BodyXSmall>
            <Typography.BodySmall
              containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
            >
              {format(
                new Date(appointment?.arrivedAt || new Date()),
                "d. MMMM. yyyy. - HH:mm"
              ) || "-"}
            </Typography.BodySmall>
          </>
        )}

        <div css={[tw`flex gap-6`]}>
          <div
            css={[
              tw`flex flex-1 flex-col border-b-1 border-b-gray-200 mb-3 pb-3 `,
            ]}
          >
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("room")}
            </Typography.BodyXSmall>
            <Typography.BodySmall>
              {appointment?.room?.name || ""} (
              {appointment?.room?.position || ""})
            </Typography.BodySmall>
          </div>
          <div
            css={[
              tw`flex flex-1 flex-col border-b-1 border-b-gray-200 mb-3 pb-3 `,
            ]}
          >
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("doctor")}
            </Typography.BodyXSmall>
            <Typography.BodySmall>
              {appointment?.employee.firstName} {appointment?.employee.lastName}
            </Typography.BodySmall>
          </div>
        </div>
        <Typography.BodyXSmall
          containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
        >
          {t("remark")}
        </Typography.BodyXSmall>
        <Typography.BodySmall
          containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
        >
          {appointment?.remark || "-"}
        </Typography.BodySmall>
        <Typography.BodyXSmall
          containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
        >
          {t("documents")}
        </Typography.BodyXSmall>
        <div css={[tw`border-1 border-gray-200 rounded-md mb-6`]}>
          {appointment?.appointmentDocuments
            ? appointment?.appointmentDocuments?.map((d, i, all) => {
                return (
                  <div
                    css={[
                      tw`flex justify-between items-center p-2`,
                      i !== all.length - 1 && tw`border-b-1 border-b-gray-200`,
                    ]}
                  >
                    <div css={[tw`flex items-center gap-2`]}>
                      <Assets.Paperclip />
                      <Typography.BodySmall>{d.name}</Typography.BodySmall>
                    </div>
                    <a
                      href={`https://staging-clinicoreplus-assets.s3.eu-west-1.amazonaws.com/medical-record-documents${d.path}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button.Text>{t("download")}</Button.Text>
                    </a>
                  </div>
                );
              })
            : "-"}
        </div>
      </div>
    </Modal>
  );
};
