/** @jsxImportSource @emotion/react */

import * as yup from "yup";

import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { useLogin } from "../../api/hooks/user/useLogin";
import { Assets } from "../../assets";
import { CenterContainer } from "../../components/layout/CenterContainer";
import { Form } from "../../fields/form";
import { scrollToError } from "../../fields/form/utils";
import { Button } from "../../ui/buttons/Button";
import { toast } from "../../ui/indicators/Toast";
import { Typography } from "../../ui/Typograhy";
import { errorMessages } from "../../utils";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .required(errorMessages.required)
    .email(errorMessages.emailBadFormat),
  password: yup.string().nullable().required(errorMessages.required),
});

type IForm = yup.InferType<typeof schema>;

export const LoginPage = () => {
  const { employeeLogin } = useLogin();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      email:
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? "test.employee@gmail.com"
          : "",
      password:
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? "password"
          : "",
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      await employeeLogin({ employeeLoginData: values });
      navigate("/");
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);
  return (
    <div css={[tw`flex`]}>
      <div
        css={[tw`flex flex-1 bg-slate-500`]}
        style={{
          backgroundImage: "url(/login_image.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div css={[tw`flex flex-1`]}>
        <CenterContainer>
          <FormProvider {...methods}>
            <form
              css={[tw`flex flex-col items-center`]}
              onSubmit={methods.handleSubmit(() => onSubmit())}
            >
              <Assets.LogoColor css={[tw`mb-6`]} width={148} />
              <Typography.H3 containerCss={[tw`mb-8 w-full text-center`]}>
                {t("loginToClinic")}
              </Typography.H3>
              <Form.TextInput.Outlined
                name="email"
                type="email"
                autocomplete="email"
                placeholder={t("emailPlaceholder") || ""}
              />
              <Form.TextInput.Outlined
                name="password"
                type="password"
                autocomplete="current-password"
                placeholder={t("passwordPlaceholder") || ""}
                containerCss={[tw`mb-3`]}
              />
              <Button.Contained
                allowLoader
                containerCss={[tw`w-full`]}
                type="submit"
              >
                {t("signIn")}
              </Button.Contained>
            </form>
          </FormProvider>
        </CenterContainer>
      </div>
    </div>
  );
};
