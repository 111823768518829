import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdate } from "../../api-hooks/useUpdate";
import { useDelete } from "../../api-hooks/useDelete";

export const useEditNews = ({ id }: { id: number }) => {
  const {
    news: {
      newsControllerGetNews,
      newsControllerEditNews,
      newsControllerPublishDraftNews,
      newsControllerDeleteNews,
    },
  } = new Api();
  const client = useQueryClient();

  const news = useGetById({
    id,
    query: newsControllerGetNews,
    queryKey: ["news", id],
  });

  const { mutateAsync: updateNews } = useUpdate(
    Number(id),
    newsControllerEditNews,
    ["news", id],
    async () => {
      await client.refetchQueries(["news", id]);
    }
  );

  const { mutateAsync: publishDraftNews } = useUpdate(
    Number(id),
    newsControllerPublishDraftNews,
    ["news", id],
    async () => {
      await client.refetchQueries(["news", id]);
    }
  );

  const { mutateAsync: deleteNews } = useDelete(id, newsControllerDeleteNews, [
    "news",
  ]);

  return {
    news,
    updateNews,
    publishDraftNews,
    deleteNews,
  };
};
