import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import { User } from "../../../../api-types";
import { useSearch } from "../../../../hooks/use-search";
import { useUsers } from "../../../../queries";
import { searchState } from "../../../../state";
import { Maybe, PatientCouponsAwardsParsed } from "../../../../types";
import { AsyncDataTable } from "../../../../ui/layout/data-table/async/AsyncDataTable";
import { TableCell } from "../../../../ui/layout/data-table/cell";
import { PatientInfoSection } from "../../components/PatientInfoSection";
import { useParams } from "react-router-dom";
import { usePatientCouponsAwards } from "../../../../api/hooks/patients/usePatientCouponsAwards";

/** @jsxImportSource @emotion/react */

export const PatientPointsTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    patientCouponsAwards: { data: patientCouponsAwards, error, isFetching },
  } = usePatientCouponsAwards({ id: Number(id) });

  const patientCouponsAwardsParsed = useMemo(() => {
    const parsedCoupons = (patientCouponsAwards?.patientCoupons || []).map(
      (pc) => ({
        id: pc.id,
        createdAt: pc.createdAt,
        name: pc.coupon.name,
        points: -pc.coupon.requiredPoints,
      })
    );
    const parsedAwards = (patientCouponsAwards?.patientAwards || []).map(
      (pa) => ({
        id: pa.id,
        createdAt: pa.createdAt,
        name: pa.award.name,
        points: pa.award.awardPoints,
      })
    );
    return [...parsedCoupons, ...parsedAwards].sort((a, b) =>
      new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1
    );
  }, [
    patientCouponsAwards?.patientAwards,
    patientCouponsAwards?.patientCoupons,
  ]) as PatientCouponsAwardsParsed[];

  const search = useRecoilValue(searchState);

  const [params, action] = useSearch<{}, keyof PatientCouponsAwardsParsed>({
    orderField: "id",
    size: 9,
  });

  useEffect(() => {
    if (params.filters.term !== search) {
      action.setFilter("term", search);
    }
  }, [action, params.filters.term, search]);

  if (error) {
    throw error;
  }

  const total = patientCouponsAwardsParsed?.length ?? 0;

  return (
    <div css={[tw`flex h-full justify-center`]}>
      <PatientInfoSection />
      <AsyncDataTable
        loading={isFetching}
        data={patientCouponsAwardsParsed}
        containerCss={[tw`max-w-[965px] w-full flex flex-col justify-between`]}
        columns={[
          {
            Header: t("collectionMethod"),
            accessor: "name",
            cell: (s) => <TableCell.Text value={s.name} />,
          },
          {
            Header: t("date"),
            accessor: "createdAt",
            align: "right",
            disableSortBy: true,
            cell: (s) => <TableCell.Date value={new Date(s.createdAt)} />,
          },
          {
            Header: t("points"),
            accessor: "points",
            disableSortBy: true,
            align: "right",
            cell: (s) => <TableCell.Text value={s.points.toString()} />,
          },
          // {
          //   Header: t("pointsState"),
          //   accessor: "email",
          //   disableSortBy: true,
          //   align: "right",
          //   cell: (s) => (
          //     <TableCell.Text value={`${s.firstName} ${s.lastName}`} />
          //   ),
          // },
        ]}
        currentPage={params.page}
        setPage={action.setPage}
        pageSize={params.size}
        maxPage={Math.ceil(total / params.size)}
        totalCount={total}
        setSortKey={action.setOrderField}
        setSortOrder={action.setOrder}
        sortKey={params.orderField}
        sortOrder={params.order}
      />
    </div>
  );
};
