/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import tw from "twin.macro";
import { scrollToError } from "../../fields/form/utils";
import { Nullable } from "../../types";
import { Button } from "../../ui/buttons/Button";
import { Modal } from "../../ui/popups/Modal";
import { errorMessages } from "../../utils";
import * as yup from "yup";
import { toast } from "../../ui/indicators/Toast";
import { Form } from "../../fields/form";
import { useEffect } from "react";

const schema = yup.object().shape({
  categoryName: yup.string().nullable().required(errorMessages.required),
});

type IForm = Nullable<yup.InferType<typeof schema>>;

interface ITextInputModalProps {
  label: string;
  buttonTitle: string;
  placeholderText: string;
  handleSubmit: (values: any) => void;
  open: boolean;
  onClose: () => void;
  name: string;
  value?: any;
}

export const TextInputModal = ({
  label,
  buttonTitle,
  placeholderText,
  handleSubmit,
  open,
  onClose,
  value,
  name,
}: ITextInputModalProps) => {
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      [name]: value,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
  const watchedValue = methods.watch(name);

  useEffect(() => {
    if (watchedValue !== value) {
      methods.setValue(name, value);
    }
  }, [value, name]);

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      handleSubmit(values);
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={label}
        footerChildren={
          <Button.Contained containerCss={[tw`w-full`]} onClick={onSubmit}>
            {buttonTitle}
          </Button.Contained>
        }
      >
        <Form.TextInput.Outlined
          required
          placeholder={placeholderText}
          name={name}
        />
      </Modal>
    </FormProvider>
  );
};
