/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import tw from "twin.macro";
import * as yup from "yup";
import { Api, CreateAppointmentRequestDto } from "../../api/api";
import { useAppointments } from "../../api/hooks/appointments/useAppointments";
import { useFacilityServices } from "../../api/hooks/facilities/useFacilityServices";
import { serviceEmployees } from "../../api/query-keys";
import { PageContainer } from "../../components/layout/PageContainer";
import { Form } from "../../fields/form";
import { scrollToError } from "../../fields/form/utils";
import { Button } from "../../ui/buttons/Button";
import { toast } from "../../ui/indicators/Toast";
import { Modal } from "../../ui/popups/Modal";
import { Typography } from "../../ui/Typograhy";
import { errorMessages } from "../../utils";
import { PatientInfoSection } from "../patients/components/PatientInfoSection";

//@ts-ignore
const schema: yup.SchemaOf<
  CreateAppointmentRequestDto & {
    date: Date;
    time: {
      label: string;
      value: Date;
    };
    scheduledFor?: string;
  }
> = yup.object().shape({
  scheduledFor: yup.string(),
  remark: yup.string(),
  patientId: yup
    .number()
    .typeError(errorMessages.number)
    .required(errorMessages.required),
  employeeId: yup
    .number()
    .typeError(errorMessages.number)
    .required(errorMessages.required),
  roomId: yup
    .number()
    .typeError(errorMessages.number)
    .required(errorMessages.required),
  serviceId: yup
    .number()
    .typeError(errorMessages.number)
    .required(errorMessages.required),
  attachment: yup.string(),
  date: yup.date().required(errorMessages.required),
  time: yup.object().required(errorMessages.required),
});

type IForm = yup.InferType<typeof schema>;

export const NewAppointmentPage = () => {
  const { state } = useLocation();
  const {
    appointments: {
      appointmentControllerGetEmptyAppointmentSlotsWeb,
      appointmentControllerGetPossibleRoomsForEmployeeAndService,
    },
    services: { serviceControllerGetServiceEmployees },
  } = new Api();
  const navigate = useNavigate();
  const [isOrderSentWithMailModalOpen, setIsOrderSentWithMailModalOpen] =
    useState(false);
  const [isOrderSentWithoutMailModalOpen, setIsOrderSentWithoutMailModalOpen] =
    useState(false);
  const [isOrderCancelledModalOpen, setIsOrderCancelledModalOpen] =
    useState(false);
  const { createAppointment } = useAppointments();
  const { t } = useTranslation();

  const { facilityServices } = useFacilityServices();

  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      serviceId: state?.serviceId,
      attachment: undefined,
      employeeId: undefined,
      patientId: state.patientId,
      remark: undefined,
      roomId: undefined,
      scheduledFor: new Date().toString(),
      date: undefined,
      time: undefined,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const [serviceId, employeeId, roomId, date] = methods.watch([
    "serviceId",
    "employeeId",
    "roomId",
    "date",
  ]);

  const { data: employeesData } = useQuery(
    serviceEmployees(serviceId),
    async () => {
      const { data } = await serviceControllerGetServiceEmployees(serviceId!);

      if (data.isSuccessful) {
        return data.payload?.map((r) => ({
          label: `${r.firstName} ${r.lastName}`,
          value: r.id,
        }));
      }
      return [];
    },
    {
      enabled: !!serviceId,
    }
  );

  const { data: roomsData } = useQuery(
    ["service", serviceId, "employee", employeeId, "rooms"],
    async () => {
      const { data } =
        await appointmentControllerGetPossibleRoomsForEmployeeAndService({
          employeeId,
          serviceId,
        });

      if (data.code === 200) {
        return data.payload?.map((r) => ({
          label: r.name,
          value: r.id,
        }));
      }
      return [];
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!serviceId && !!employeeId, // disable this query from automatically running
    }
  );

  const { data: timeSlots } = useQuery(
    [
      "service",
      serviceId,
      "employee",
      employeeId,
      "room",
      roomId,
      "date",
      date,
      "timeslots",
    ],
    async () => {
      const { data } = await appointmentControllerGetEmptyAppointmentSlotsWeb({
        employeeId,
        serviceId,
        roomId,
        date: format(date, "yyyy-MM-dd"),
      });
      if (data.code === 200) {
        return data.payload?.timeSlots.map((t) => ({
          label: `${format(new Date(t.startTime), "HH:mm")} - ${format(
            new Date(t.endTime),
            "HH:mm"
          )}`,
          value: t.startTime,
        }));
      }
      return [];
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!serviceId && !!employeeId && !!roomId, // disable this query from automatically running
    }
  );

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      const tempScheduledFor = values.date;
      tempScheduledFor.setHours(
        new Date(values.time.value).getHours(),
        new Date(values.time.value).getMinutes()
      );

      const requestData = {
        ...values,
        attachments: [],
        scheduledFor: tempScheduledFor.toISOString(),
      };
      const resp = await createAppointment({ requestData });
      if (resp?.id) {
        setIsOrderSentWithoutMailModalOpen(true);
      }
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <PageContainer>
      <FormProvider {...methods}>
        <div
          css={[tw`p-4 border-b-1 border-b-gray-200`, tw`flex justify-center`]}
        >
          <div
            css={[
              tw`max-w-screen-xl w-full`,
              tw`flex items-center justify-between`,
            ]}
          >
            <Typography.BodyMedium containerCss={[tw`leading-6 font-bold`]}>
              {t("orderingForService")}
            </Typography.BodyMedium>
            <Button.Text
              leadCss={[tw`text-gray-900`]}
              lead={RiCloseLine}
              textCss={[tw`text-gray-900`]}
              containerCss={[tw`px-2`]}
              onClick={() => navigate(-1)}
            >
              {t("quit")}
            </Button.Text>
          </div>
        </div>
        <div css={[tw`flex flex-row justify-center`]}>
          <div css={[tw`flex w-full h-full min-h-[calc(100vh - 9rem + 2px)]`]}>
            <div css={[tw`w-full -ml-[660px] flex flex-col items-end`]}>
              <PatientInfoSection patientId={state.patientId} />
            </div>
            <div css={[tw`bg-gray-50 w-full flex flex-col justify-between`]}>
              <div css={[tw`max-w-[956px] w-full`]}>
                <Typography.BodyXLarge containerCss={[tw`mt-8 mb-6 pl-20`]}>
                  {t("appointmentData")}
                </Typography.BodyXLarge>
                <div css={[tw`flex flex-col justify-between h-full`]}>
                  <div css={[tw`pl-20`]}>
                    <Form.Select
                      required
                      options={facilityServices || []}
                      label={t("service") as string}
                      placeholder={t("selectService") as string}
                      name="serviceId"
                      shouldOnlySelectValue
                    />
                    <div css={[tw`flex gap-6`]}>
                      <Form.Select
                        required
                        disabled={!serviceId}
                        shouldOnlySelectValue
                        options={employeesData || []}
                        label={t("doctor") as string}
                        placeholder={t("selectDoctor") as string}
                        name="employeeId"
                      />
                      <Form.Select
                        required
                        shouldOnlySelectValue
                        disabled={!employeeId}
                        options={roomsData || []}
                        label={t("room") as string}
                        placeholder={t("roomPlaceholder") as string}
                        name="roomId"
                      />
                    </div>
                    <div css={[tw`flex gap-6`]}>
                      <Form.DatePicker
                        required
                        disabled={!roomId}
                        label={t("date") as string}
                        placeholder={t("datePlaceholder") as string}
                        name="date"
                        minDate={new Date(Date.now())}
                      />
                      <Form.Select
                        required
                        options={timeSlots || []}
                        disabled={!date}
                        label={t("time") as string}
                        placeholder={t("timePlaceholder") as string}
                        name="time"
                      />
                    </div>
                    <Form.TextArea.Outlined
                      name="remark"
                      label={t("remark") as string}
                      placeholder={t("remarkPlaceholder") as string}
                    />
                    {/* <Dropzone
                      containerCss={[tw`mb-10 p-6`]}
                      multiple={false}
                      title={t("attachment") as string}
                      subtitle={t("pngOrJpg10Mb") as string}
                      maxFileSize={10}
                      accept={{
                        "image/png": [".png"],
                        "image/jpg": [".jpg"],
                      }}
                      onUpload={(files) =>
                        methods.setValue("attachment", files)
                      }
                    /> */}
                  </div>
                </div>
              </div>
              <div css={[tw`bg-gray-100`, tw`py-6 pl-20`]}>
                <div css={[tw`max-w-[880px] w-full flex gap-3`]}>
                  <Button.Outlined
                    containerCss={[tw`flex-1`]}
                    onClick={onSubmit}
                  >
                    {t("arrange")}
                  </Button.Outlined>
                  {/* <Button.Contained
                    containerCss={[tw`flex-5`]}
                    onClick={() => onSubmit({ shouldSendEmail: true })}
                  >
                    {t("arrangeAndSendEmailConfirmation")}
                  </Button.Contained> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={isOrderSentWithMailModalOpen}
          onClose={() => setIsOrderSentWithMailModalOpen(false)}
          label={t("orderSuccessfullMailSent") as string}
          footerChildren={
            <div css={[tw`flex gap-3`]}>
              <Button.Outlined
                containerCss={[tw`w-full flex-1`]}
                onClick={() => {
                  setIsOrderSentWithMailModalOpen(false);
                  setTimeout(() => {
                    setIsOrderCancelledModalOpen(true);
                  }, 500);
                }}
              >
                {t("cancelOrder")}
              </Button.Outlined>
              <Button.Outlined
                containerCss={[tw`w-full flex-1`]}
                onClick={() => setIsOrderSentWithMailModalOpen(false)}
              >
                {t("printOrder")}
              </Button.Outlined>
              <Button.Contained
                containerCss={[tw`w-full flex-2`]}
                onClick={() => setIsOrderSentWithMailModalOpen(false)}
              >
                {t("okay")}
              </Button.Contained>
            </div>
          }
        />
        <Modal
          open={isOrderSentWithoutMailModalOpen}
          onClose={() => setIsOrderSentWithoutMailModalOpen(false)}
          label={t("orderSuccessfullMailNotSent") as string}
          footerChildren={
            <div css={[tw`flex gap-3`]}>
              {/* <Button.Outlined
                containerCss={[tw`w-full flex-1`]}
                onClick={() => {
                  setIsOrderSentWithoutMailModalOpen(false);
                  setTimeout(() => {
                    setIsOrderCancelledModalOpen(true);
                  }, 500);
                }}
              >
                {t("cancelOrder")}
              </Button.Outlined> */}
              {/* <Button.Outlined
                containerCss={[tw`w-full flex-1`]}
                onClick={() => setIsOrderSentWithoutMailModalOpen(false)}
              >
                {t("printOrder")}
              </Button.Outlined> */}
              <Button.Contained
                containerCss={[tw`w-full flex-2`]}
                onClick={() => {
                  setIsOrderSentWithoutMailModalOpen(false);
                  navigate(`/patients/${state.patientId}`);
                }}
              >
                {t("okay")}
              </Button.Contained>
            </div>
          }
        />
        <Modal
          open={isOrderCancelledModalOpen}
          onClose={() => setIsOrderCancelledModalOpen(false)}
          label={t("orderCancelled") as string}
          footerChildren={
            <Button.Contained
              containerCss={[tw`w-full`]}
              onClick={() => setIsOrderCancelledModalOpen(false)}
            >
              {t("okay")}
            </Button.Contained>
          }
        />
      </FormProvider>
    </PageContainer>
  );
};
