import { useRecoilValue } from "recoil";
import { selectedFacilityIdState } from "../../../state";
import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";
import { useDelete } from "../../api-hooks/useDelete";
import { useGetPaginated } from "../../api-hooks/useGetPaginated";

export const useFacilityAwards = () => {
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const selectedFacilityIdNumber = Number(selectedFacilityId);
  const {
    awards: {
      awardControllerGetCurrentCouponsForFacilityPaginated,
      awardControllerCreateAward,
    },
  } = new Api();

  const awards = useGetPaginated({
    id: selectedFacilityIdNumber,
    query: awardControllerGetCurrentCouponsForFacilityPaginated,
    queryKey: ["facility", selectedFacilityIdNumber, "awards"],
  });

  const { mutateAsync: createAward } = useCreate(awardControllerCreateAward, [
    "awards",
  ]);

  return {
    awards,
    createAward,
  };
};
