import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RiPercentLine } from "react-icons/ri";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import * as yup from "yup";

import {
  CreateCouponRequestDto,
  RequestNewsImageLinksResponseDto,
} from "../../../api/api";
import { CreateCouponCouponImage } from "../../../api/api-new";
import { useCoupon } from "../../../api/hooks/coupons/useCoupon";
import { useCoupons } from "../../../api/hooks/coupons/useCoupons";
import { useFacilityServices } from "../../../api/hooks/facilities/useFacilityServices";
import { useFileUpload } from "../../../api/hooks/file-upload/useFileUpload";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { selectedFacilityIdState } from "../../../state";
import { Button } from "../../../ui/buttons/Button";
import { Dropzone } from "../../../ui/Dropzone";
import { toast } from "../../../ui/indicators/Toast";
import { Modal } from "../../../ui/popups/Modal";
import { Typography } from "../../../ui/Typograhy";
import { errorMessages } from "../../../utils";
import { uploadFile } from "../../../utils/upload-file";

/** @jsxImportSource @emotion/react */

const schema: yup.SchemaOf<CreateCouponRequestDto> = yup
  .object()
  .shape({
    name: yup.string().required(errorMessages.required),
    requiredPoints: yup
      .number()
      .typeError(errorMessages.number)
      .required(errorMessages.required),
    discountPercentage: yup
      .number()
      .typeError(errorMessages.number)
      .required(errorMessages.required),
    availableFrom: yup.string().required(errorMessages.required),
    expirationDate: yup.string(),
    description: yup.string().required(errorMessages.required),
    imageUrl: yup.string(),
    asNews: yup.boolean().required(errorMessages.required),
    facilityId: yup
      .number()
      .typeError(errorMessages.number)
      .required(errorMessages.required),
    serviceId: yup
      .number()
      .typeError(errorMessages.number)
      .required(errorMessages.required),
  })
  .required(errorMessages.required);

interface ITextInputModalProps {
  open: boolean;
  onClose: () => void;
  coupon?: any;
}

type IForm = yup.InferType<typeof schema>;

export const ManageCouponModal = ({
  open,
  onClose,
  coupon,
}: ITextInputModalProps) => {
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const selectedFacilityIdNumber = Number(selectedFacilityId);
  const { createCoupon } = useCoupons();
  const { updateCoupon } = useCoupon({ id: coupon?.id! });
  const { facilityServices } = useFacilityServices();
  const { t } = useTranslation();
  const { requestCouponImageLinks } = useFileUpload();
  const [imageForUpload, setImageForUpload] = useState<File>();

  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      ...coupon,
      availableFrom: coupon?.availableFrom
        ? new Date(coupon?.availableFrom)
        : undefined,
      expirationDate: coupon?.expirationDate
        ? new Date(coupon?.expirationDate)
        : undefined,
      facilityId: selectedFacilityIdNumber,
      serviceId: coupon?.service?.id,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const isEdit = !!coupon?.id;

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      let couponImageWithLinks: CreateCouponCouponImage | undefined = undefined;
      if (imageForUpload) {
        const uploadData = (await requestCouponImageLinks({
          fileName: { fileName: imageForUpload.name },
        })) as RequestNewsImageLinksResponseDto;
        await uploadFile(uploadData.uploadUrl, imageForUpload);
        couponImageWithLinks = {
          name: imageForUpload.name,
          downloadUrl: uploadData.downloadUrl,
          fileUUID: uploadData.fileUUID,
        };
      }
      if (isEdit) {
        await updateCoupon({
          requestData: {
            ...values,
            availableFrom: new Date(values.availableFrom).toISOString(),
            expirationDate: values?.expirationDate
              ? new Date(values.expirationDate).toISOString()
              : undefined,
          },
        });
      } else {
        await createCoupon({
          requestData: {
            ...values,
            availableFrom: new Date(values.availableFrom).toISOString(),
            expirationDate: values?.expirationDate
              ? new Date(values.expirationDate).toISOString()
              : undefined,
            image: couponImageWithLinks ? couponImageWithLinks : undefined,
          },
        });
      }
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={
          (isEdit ? t("editExistingCoupon") : t("addingNewCoupon")) as string
        }
        footerChildren={
          <Button.Contained containerCss={[tw`w-full`]} onClick={onSubmit}>
            {isEdit ? t("save") : t("addCoupon")}
          </Button.Contained>
        }
      >
        <div css={[tw`flex flex-col`]}>
          <Form.TextInput.Outlined
            label={t("couponName") as string}
            name="name"
            placeholder={t("enterName") as string}
          />
          <div css={[tw`flex gap-6`]}>
            <Form.TextInput.Outlined
              label={t("neededPoints") as string}
              name="requiredPoints"
              placeholder={t("enterNeededNumberOfPoints") as string}
            />
            <Form.TextInput.Outlined
              label={t("discount") as string}
              name="discountPercentage"
              trail={RiPercentLine}
              trailCss={[tw`w-4 text-gray-600`]}
              placeholder={`-    ${t("enterDiscount")}` as string}
            />
          </div>
          <div css={[tw`flex gap-6`]}>
            <Form.DatePicker
              required
              label={t("dateFrom") as string}
              placeholder={t("chooseDate") as string}
              name="availableFrom"
            />
            <Form.DatePicker
              required
              label={t("dateTo") as string}
              placeholder={t("chooseDate") as string}
              name="expirationDate"
            />
          </div>
          <Form.Select
            options={facilityServices || []}
            name="serviceId"
            shouldOnlySelectValue
            placeholder={t("chooseService") as string}
            label={t("connectedService") as string}
          />
          <Form.TextArea.Outlined
            name="description"
            rows={3}
            label={t("couponDescription") as string}
            placeholder={t("enterDescription") as string}
          />
          <Dropzone
            multiple={false}
            title={t("addOrDragDropPhoto") as string}
            subtitle={t("pngOrJpg10Mb") as string}
            maxFileSize={10}
            containerCss={[tw`p-1 mb-6`]}
            accept={{
              "image/png": [".png"],
              "image/jpg": [".jpg"],
            }}
            onUpload={(files) => setImageForUpload(files[0])}
          />

          <div css={[tw`flex items-center justify-between w-full mb-6`]}>
            <div>
              <Typography.BodyXSmall>
                {t("showCouponInNews")}
              </Typography.BodyXSmall>
              <Typography.BodySmall>
                {t("notificationWithPush")}
              </Typography.BodySmall>
            </div>
            <Form.Switch
              name="asNews"
              containerCss={[tw`flex-none w-fit flex items-center`]}
            />
          </div>
        </div>
      </Modal>
    </FormProvider>
  );
};
