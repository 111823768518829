/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import { Button } from "../../ui/buttons/Button";
import { Modal } from "../../ui/popups/Modal";
import { QrReader } from "react-qr-reader";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Assets } from "../../assets";
import { Typography } from "../../ui/Typograhy";
import { TextInput } from "../../fields/controlled/TextInput";
import { MessageModal } from "./MessageModal";
import { TwoButtonMessageModal } from "./TwoButtonMessageModal";
import { useNavigate } from "react-router-dom";

interface IMessageModalProps {
  open: boolean;
  onClose: () => void;
}

export const QRCodeScannerModal = ({ open, onClose }: IMessageModalProps) => {
  const [isPatientNotFoundModalOpen, setIsPatientNotFoundModalOpen] =
    useState<boolean>(false);
  const [isPatientReportedModalOpen, setIsPatientReportedModalOpen] =
    useState<boolean>(false);
  const [data, setData] = useState<string | null>("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
          setData(null);
        }}
        label={t("reportPatient") as string}
        containerCss={[tw`xl:max-w-screen-xs`]}
        footerChildren={
          <Button.Contained containerCss={[tw`w-full`]} onClick={() => {}}>
            {t("reportPatientArrival")}
          </Button.Contained>
        }
      >
        <div css={[tw``]}>
          <Typography.BodyXSmall
            containerCss={[
              tw`leading-4 font-semibold tracking-wide uppercase mb-2`,
            ]}
          >
            {t("putQrCodeReaderTowardsCamera")}
          </Typography.BodyXSmall>
          <QrReader
            onResult={(result, error) => {
              if (!!result) {
                //@ts-ignore
                setData(result?.text);
                setIsPatientReportedModalOpen(true);
              }

              // if (!!error) {
              //   console.info(error);
              // }
            }}
            ViewFinder={() => (
              <div
                css={[
                  tw`w-full h-full absolute top-0 left-0 z-10 flex justify-center items-center rounded-md`,
                ]}
              >
                <Assets.ScannerBoundary css={[tw`bg-transparent z-20`]} />
              </div>
            )}
            css={[tw`w-full relative rounded-md mb-4`]}
            containerStyle={{ borderRadius: 5 }}
            videoStyle={{ borderRadius: 5 }}
            videoContainerStyle={{ borderRadius: 5 }}
            constraints={{ facingMode: "user", aspectRatio: 1 }}
          />
          <TextInput.Outlined
            onChange={setData}
            value={data || ""}
            containerCss={[tw`mb-5`]}
            label={t("orEnterPatientCode")}
            placeholder={"1234 5678"}
          />
        </div>
      </Modal>
      <MessageModal
        buttonTitle={t("tryAgain")}
        handleSubmit={() => setIsPatientNotFoundModalOpen(false)}
        label={t("patientNotFound")}
        onClose={() => setIsPatientNotFoundModalOpen(false)}
        open={isPatientNotFoundModalOpen}
      />
      <TwoButtonMessageModal
        label={t("patientReportedSuccessfully")}
        open={isPatientReportedModalOpen}
        onClose={() => setIsPatientReportedModalOpen(false)}
        buttonLeft={{
          title: t("showProfile"),
          onClick: () => {
            onClose();
            setData(null);
            setIsPatientReportedModalOpen(false);
            if (JSON.parse(data || "")?.id) {
              navigate(`/patients/${JSON.parse(data || "")?.id}`);
            }
          },
        }}
        buttonRight={{
          title: t("reportNewPatient"),
          onClick: () => {
            setData(null);
            setIsPatientReportedModalOpen(false);
          },
        }}
      />
    </>
  );
};
