/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { PageContainer } from "../../components/layout/PageContainer";
import { Button } from "../../ui/buttons/Button";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { ActivatedCouponsTab } from "./tabs/coupon/ActivatedCouponsTab";
import { UsedCouponsTab } from "./tabs/coupon/UsedCouponsTab";

export const CouponDetailsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Tabs
        leftComponent={
          <Button.Text
            lead={RiArrowLeftLine}
            leadCss={[tw`text-gray-900`]}
            textCss={[tw`text-gray-900`]}
            containerCss={[tw`px-2`]}
            onClick={() => navigate(-1)}
          >
            {t("backToList")}
          </Button.Text>
        }
        containerCss={[tw`m-auto`]}
      >
        <Tabs.Item text={t("active")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <ActivatedCouponsTab />
        </Tabs.Item>
        <Tabs.Item text={t("used")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <UsedCouponsTab />
        </Tabs.Item>
      </Tabs>
    </PageContainer>
  );
};
