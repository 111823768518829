/** @jsxImportSource @emotion/react */

import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiEdit2Line } from "react-icons/ri";
import { useParams } from "react-router-dom";
import tw from "twin.macro";
import { useCoupon } from "../../../api/hooks/coupons/useCoupon";
import { useMe } from "../../../api/hooks/user/useMe";
import { TwoButtonMessageModal } from "../../../components/modals/TwoButtonMessageModal";
import { Button } from "../../../ui/buttons/Button";
import { Typography } from "../../../ui/Typograhy";
import { ManageCouponModal } from "../modals/ManageCouponModal";

export const CouponInfoSection = () => {
  const { id } = useParams();
  const {
    coupon: { data: coupon },
    deactivateCoupon,
  } = useCoupon({ id: Number(id) });
  const { me } = useMe();
  const [isEditCouponDetailsModalOpen, setIsEditCouponDetailsModalOpen] =
    useState(false);
  const [isDeactivateCouponModalOpen, setIsDeactivateCouponModalOpen] =
    useState(false);
  const { t } = useTranslation();
  return (
    <div
      css={[
        tw`flex flex-col items-end justify-between`,
        tw`border-r-1 border-r-gray-200`,
      ]}
    >
      <div css={[tw`max-w-80 w-full`]}>
        <div css={[tw`px-4`]}>
          <Typography.BodyXLarge containerCss={[tw`mt-6 mb-12`]}>
            {coupon?.name}
          </Typography.BodyXLarge>
          <div css={[tw`flex gap-8`]}>
            <div>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("neededNumberOfPoints")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-8`]}>
                -{coupon?.requiredPoints}
              </Typography.BodySmall>
            </div>
            <div>
              <Typography.BodyXSmall
                containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
              >
                {t("discountAmount")}
              </Typography.BodyXSmall>
              <Typography.BodySmall containerCss={[tw`mb-8`]}>
                -{coupon?.discountPercentage}%
              </Typography.BodySmall>
            </div>
          </div>
          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("duration")}
          </Typography.BodyXSmall>
          <Typography.BodySmall containerCss={[tw`mb-8`]}>
            {format(
              new Date(coupon?.availableFrom || new Date()),
              "d. MMM. yyyy."
            )}{" "}
            –{" "}
            {format(
              new Date(coupon?.expirationDate || new Date()),
              "d. MMM. yyyy."
            )}
          </Typography.BodySmall>
          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("connectedToServices")}
          </Typography.BodyXSmall>
          <Typography.BodySmall containerCss={[tw`mb-8`]}>
            {coupon?.service?.name}
          </Typography.BodySmall>
          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("description")}
          </Typography.BodyXSmall>
          <Typography.BodySmall containerCss={[tw`max-h-80 pr-3`]}>
            {coupon?.description || "-"}
          </Typography.BodySmall>
        </div>
      </div>
      {(me?.role?.permissions?.COUPONS?.DELETE ||
        me?.role?.permissions?.COUPONS?.UPDATE) && (
        <div
          css={[
            tw`max-w-80 w-full py-8 pr-4 flex items-center justify-between gap-3`,
            tw`border-t-1 border-t-gray-200`,
          ]}
        >
          {me?.role?.permissions?.COUPONS?.DELETE && (
            <Button.Outlined
              containerCss={[tw`flex-1`]}
              onClick={() => setIsDeactivateCouponModalOpen(true)}
            >
              {t("deactivate")}
            </Button.Outlined>
          )}
          {me?.role?.permissions?.COUPONS?.UPDATE && (
            <Button.Outlined
              containerCss={[tw`flex-2`]}
              onClick={() => setIsEditCouponDetailsModalOpen(true)}
              lead={RiEdit2Line}
            >
              {t("editInfo")}
            </Button.Outlined>
          )}
        </div>
      )}
      {coupon?.id && (
        <ManageCouponModal
          coupon={coupon}
          open={isEditCouponDetailsModalOpen}
          onClose={() => setIsEditCouponDetailsModalOpen(false)}
        />
      )}
      <TwoButtonMessageModal
        label={t("couponDeactivationReassurance")}
        open={isDeactivateCouponModalOpen}
        onClose={() => setIsDeactivateCouponModalOpen(false)}
        buttonLeft={{
          title: t("quit"),
          onClick: () => setIsDeactivateCouponModalOpen(false),
        }}
        buttonRight={{
          title: t("deactivate"),
          onClick: async () => {
            await deactivateCoupon({ requestData: {} });
            setIsDeactivateCouponModalOpen(false);
          },
        }}
      />
    </div>
  );
};
