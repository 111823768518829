/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import { GetFacilityResponseDto } from "../../../api/api";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiEdit2Line } from "react-icons/ri";
import { useMe } from "../../../api/hooks/user/useMe";
import { Button } from "../../../ui/buttons/Button";
import { Typography } from "../../../ui/Typograhy";
import { EditFacilityModal } from "../modals/EditFacilityModal";

export const AboutFacilityTab = ({
  facility,
}: {
  facility: GetFacilityResponseDto;
}) => {
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState(false);
  const [isEditDescriptionModalOpen, setIsEditDescriptionModalOpen] =
    useState(false);
  const { t } = useTranslation();
  const { me } = useMe();
  return (
    <>
      <div css={[tw`flex max-w-screen-xl m-auto h-full`]}>
        <div css={[tw`flex flex-col flex-2`]}>
          {/* {facility?.imageUrl ? (
            <img src={facility.imageUrl} alt="facility" />
          ) : (
            <Assets.FacilityTestImage css={[tw`mb-2`]} />
          )} */}
          <div css={[tw`p-6`]}>
            <div css={[tw`flex justify-between mb-6`]}>
              <Typography.BodyLarge>
                {t("generalInformation")}
              </Typography.BodyLarge>
              {me?.role?.permissions?.FACILITIES?.UPDATE && (
                <Button.Outlined
                  variant="small"
                  lead={RiEdit2Line}
                  onClick={() => setIsEditDetailsModalOpen(true)}
                >
                  {t("edit")}
                </Button.Outlined>
              )}
            </div>
            <Typography.BodyXSmall containerCss={[tw`mb-2`]}>
              {t("type")}
            </Typography.BodyXSmall>
            <Typography.BodySmall containerCss={[tw`mb-8`]}>
              {t(facility?.type) || ""}
            </Typography.BodySmall>
            <Typography.BodyXSmall containerCss={[tw`mb-2`]}>
              {t("address")}
            </Typography.BodyXSmall>
            <Typography.BodySmall containerCss={[tw`mb-8`]}>
              {facility?.address || ""}
            </Typography.BodySmall>
            <Typography.BodyXSmall containerCss={[tw`mb-2`]}>
              {t("phone")}
            </Typography.BodyXSmall>
            <Typography.BodySmall containerCss={[tw`mb-8`]}>
              {facility?.phone1 || ""}
            </Typography.BodySmall>
            <Typography.BodyXSmall containerCss={[tw`mb-2`]}>
              {t("email")}
            </Typography.BodyXSmall>
            <Typography.BodySmall>{facility?.email || ""}</Typography.BodySmall>
          </div>
        </div>
        <div css={[tw`p-6 flex flex-col flex-2 border-x-1 border-gray-200`]}>
          <div css={[tw`flex justify-between mb-6`]}>
            <Typography.BodyLarge>{t("description")}</Typography.BodyLarge>
            {me?.role?.permissions?.FACILITIES?.UPDATE && (
              <Button.Outlined
                variant="small"
                lead={RiEdit2Line}
                onClick={() => setIsEditDescriptionModalOpen(true)}
              >
                {t("edit")}
              </Button.Outlined>
            )}
          </div>
          <Typography.BodyMedium>
            {facility?.description || ``}
          </Typography.BodyMedium>
        </div>
        <div css={[tw`flex flex-col flex-1 p-6`]}></div>
      </div>
      {!!facility && (
        <EditFacilityModal
          type="details"
          facility={facility}
          open={isEditDetailsModalOpen}
          onClose={() => setIsEditDetailsModalOpen(false)}
        />
      )}
      {!!facility && (
        <EditFacilityModal
          type="description"
          facility={facility}
          open={isEditDescriptionModalOpen}
          onClose={() => setIsEditDescriptionModalOpen(false)}
        />
      )}
      {/* <EditDayWorkTimeModal
        date={selectedDay || new Date()}
        onClose={() => setIsEditDayTimeDetailsModalOpen(false)}
        open={isEditDayTimeDetailsModalOpen}
      /> */}
      {/* <EditWeekWorkTimeModal
        open={isEditWeekTimeDetailsModalOpen}
        workingHours={
          facility?.workingHoursScheduleWithShifts ||
          ([
            {
              closingTime: 16 * 60,
              shiftId: 1,
              openingTime: 8 * 60,
              daysOfWeek: new Map([
                [1, true],
                [2, false],
                [3, true],
                [4, false],
                [5, true],
                [6, false],
                [7, false],
              ]),
            },
            {
              closingTime: 20 * 60,
              shiftId: 2,
              openingTime: 12 * 60,
              daysOfWeek: new Map([
                [1, true],
                [2, false],
                [3, false],
                [4, true],
                [5, true],
                [6, false],
                [7, false],
              ]),
            },
          ] as unknown as WorkingHoursScheduleWithShifts[])
        }
        onClose={() => setIsEditWeekTimeDetailsModalOpen(false)}
      /> */}
    </>
  );
};
