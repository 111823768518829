import { useState } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import {
  RiAddLine,
  RiEdit2Line,
  RiIndeterminateCircleLine,
} from "react-icons/ri";
import tw from "twin.macro";
import { useCategory } from "../../../api/hooks/categories/useCategory";
import { useMe } from "../../../api/hooks/user/useMe";
import { Assets } from "../../../assets";
import { TwoButtonMessageModal } from "../../../components/modals/TwoButtonMessageModal";
import { CategoriesWithServicesWithParentId } from "../../../types";
import { Button } from "../../../ui/buttons/Button";
import { Typography } from "../../../ui/Typograhy";
import { ManageCategoryModal } from "../modals/ManageCategoryModal";
import { NewServiceModal } from "../modals/NewServiceModal";
/** @jsxImportSource @emotion/react */

export const EditCategoryRow = ({
  provided,
  category,
}: {
  provided: DraggableProvided;
  category: CategoriesWithServicesWithParentId;
}) => {
  const { deleteCategory } = useCategory({ id: category.id });
  const { t } = useTranslation();
  const [isAvailable, setIsAvailable] = useState(true);

  const [isEditCategoryNameModalOpen, setIsEditCategoryNameModalOpen] =
    useState(false);
  const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] =
    useState(false);
  const [isAddServiceToCategoryModalOpen, setIsAddServiceToCategoryModalOpen] =
    useState(false);
  const { me } = useMe();
  const handleDeleteCategory = async () => {
    await deleteCategory();
    setIsDeleteCategoryModalOpen(false);
  };
  return (
    <>
      <div css={[tw`flex items-center justify-between`]}>
        <div css={[tw`flex items-center gap-2`]} {...provided.dragHandleProps}>
          <Assets.DragMoveLine />
          <Typography.BodySmall
            containerCss={[tw`uppercase leading-4 font-medium tracking-wider`]}
          >
            {category.name}
          </Typography.BodySmall>
          {me.role?.permissions?.CATEGORIES?.UPDATE && (
            <Button.Outlined
              variant="small"
              lead={RiEdit2Line}
              containerCss={[tw`ml-3`]}
              onClick={() => {
                setIsEditCategoryNameModalOpen(true);
              }}
            >
              {t("editCategory")}
            </Button.Outlined>
          )}
          {me.role?.permissions?.SERVICES?.CREATE && (
            <Button.Outlined
              variant="small"
              lead={RiAddLine}
              containerCss={[tw`ml-3`]}
              onClick={() => {
                setIsAddServiceToCategoryModalOpen(true);
              }}
            >
              {t("addService")}
            </Button.Outlined>
          )}
        </div>

        <div css={[tw`flex items-center gap-2`]}>
          {/* <Typography.BodySmall containerCss={[tw`text-gray-600`]}>
            {!isAvailable
              ? t("categoryNotCurrentlyAvailable")
              : t("categoryAvailable")}
          </Typography.BodySmall>
          <Switch
            value={isAvailable}
            onChange={() => setIsAvailable(!isAvailable)}
          /> */}
          {me.role?.permissions?.CATEGORIES?.DELETE && (
            <Button.Text
              containerCss={[tw`pl-2 pr-2 min-w-2`, tw`hover:(bg-error-light)`]}
              textCss={[tw`text-error`]}
              leadCss={[tw`text-error`]}
              lead={RiIndeterminateCircleLine}
              onClick={() => {
                setIsDeleteCategoryModalOpen(true);
              }}
            >
              {t("delete")}
            </Button.Text>
          )}
        </div>
      </div>
      {category.id && (
        <ManageCategoryModal
          departmentId={Number(category.departmentId)}
          onClose={() => setIsEditCategoryNameModalOpen(false)}
          open={isEditCategoryNameModalOpen}
          category={category}
        />
      )}
      <NewServiceModal
        onClose={() => setIsAddServiceToCategoryModalOpen(false)}
        open={isAddServiceToCategoryModalOpen}
        departmentId={Number(category.departmentId)}
        categoryId={category.id}
      />
      <TwoButtonMessageModal
        buttonLeft={{
          title: t("delete"),
          onClick: handleDeleteCategory,
        }}
        label={t("deleteCategoryReassurance")}
        onClose={() => setIsDeleteCategoryModalOpen(false)}
        open={isDeleteCategoryModalOpen}
        buttonRight={{
          title: undefined,
          onClick: () => {},
        }}
      />
    </>
  );
};
