import { IFormFieldComponentBaseProps, ISelectOption } from "../../types";
import { useFormError, useFormField } from "./hooks";

import { FieldError } from "../components/FieldError";
import { Select } from "../controlled/Select";
import tw from "twin.macro";
import { ReactElement } from "react";

/** @jsxImportSource @emotion/react */

export interface IFormSelectProps<T> extends IFormFieldComponentBaseProps {
  autofocus?: boolean;
  options: ISelectOption<T>[];
  footer?: ReactElement;
  shouldOnlySelectValue?: boolean;
  dataParser?(data: any): any;
}

export const FormSelect = (props: IFormSelectProps<any>) => {
  const field = useFormField(props.name);
  const error = useFormError(props.name);
  return (
    <div css={[tw`flex-1 w-full`, props.containerCss]}>
      <Select
        disabled={props.disabled}
        value={field.value}
        autofocus={props.autofocus}
        onChange={(option) =>
          field.onChange(props.shouldOnlySelectValue ? option?.value : option)
        }
        label={props.label}
        dataParser={props.dataParser}
        shouldOnlySelectValue={props.shouldOnlySelectValue}
        error={error}
        required={props.required}
        placeholder={props.placeholder}
        options={props.options}
        footer={props.footer}
      />
      <FieldError name={props.name} containerCss={[tw`ml-4`]} />
    </div>
  );
};
