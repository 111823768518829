import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RiEyeCloseLine,
  RiEyeLine,
  RiIndeterminateCircleLine,
} from "react-icons/ri";
import tw from "twin.macro";
import { useService } from "../../../api/hooks/services/useService";
import { useMe } from "../../../api/hooks/user/useMe";
import { Assets } from "../../../assets";
import { TwoButtonMessageModal } from "../../../components/modals/TwoButtonMessageModal";
import { Switch } from "../../../fields/controlled/Switch";
import { Button } from "../../../ui/buttons/Button";
import { Typography } from "../../../ui/Typograhy";
/** @jsxImportSource @emotion/react */

export const EditServiceRow = ({ service, provided }) => {
  const { t } = useTranslation();
  const { deleteService } = useService({ id: service.id });
  const [isDeleteServiceModalOpen, setIsDeleteServiceModalOpen] =
    useState(false);
  const [isAvailable, setIsAvailable] = useState(!service.isHidden);
  const handleDeleteService = async () => await deleteService();
  const { me } = useMe();
  return (
    <>
      <div
        key={service.id}
        css={[tw`py-4 flex justify-center border-b-1 border-b-gray-100`]}
      >
        <div css={[tw`max-w-screen-xl w-full px-4 flex justify-between`]}>
          <div
            css={[tw`flex items-center gap-2`]}
            {...provided.dragHandleProps}
          >
            <Assets.DragMoveLine />
            <Typography.BodyMedium
              key={service.id}
              containerCss={[!isAvailable && tw`text-gray-600`]}
            >
              {service.name}
            </Typography.BodyMedium>
          </div>
          <div css={[tw`flex items-center gap-2`]}>
            {/* <Typography.BodySmall containerCss={[tw`text-gray-600`]}>
              {!isAvailable
                ? t("serviceNotCurrentlyAvailable")
                : t("serviceAvailable")}
            </Typography.BodySmall>
            <Switch
              value={isAvailable}
              onChange={() => setIsAvailable(!isAvailable)}
            /> */}
            {me.role?.permissions.SERVICES.DELETE && (
              <Button.Text
                containerCss={[
                  tw`pl-2 pr-2 min-w-2`,
                  tw`hover:(bg-error-light)`,
                ]}
                textCss={[tw`text-error`]}
                leadCss={[tw`text-error`]}
                lead={RiIndeterminateCircleLine}
                onClick={() => {
                  setIsDeleteServiceModalOpen(true);
                }}
              >
                {t("delete")}
              </Button.Text>
            )}
          </div>
        </div>
      </div>
      <TwoButtonMessageModal
        buttonLeft={{
          title: t("delete"),
          onClick: handleDeleteService,
        }}
        label={t("deleteServiceReassurance")}
        onClose={() => setIsDeleteServiceModalOpen(false)}
        open={isDeleteServiceModalOpen}
        buttonRight={{
          title: t("markAsUnavailable"),
          onClick: () => {},
        }}
      />
    </>
  );
};
