import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { GetOrganisationRolesRole } from "../../../api/api";
import { useOrganisationRoles } from "../../../api/hooks/organisations/useOrganisationRoles";
import { Typography } from "../../../ui/Typograhy";
import { RoleDetails } from "../components/RoleDetails";
import { ManageRoleModal } from "../modals/ManageRoleModal";
/** @jsxImportSource @emotion/react */

export const OrganisationSettingsTab = ({
  isAddNewRoleModalOpen,
  setIsAddNewRoleModalOpen,
  isDirty,
  setIsDirty,
  isUnsavedChangesModalOpen,
  setIsUnsavedChangesModalOpen,
}) => {
  const {
    roles: { data: roles, isFetched, dataUpdatedAt },
  } = useOrganisationRoles();
  const [selectedRole, setSelectedRole] = useState<
    GetOrganisationRolesRole | undefined
  >(undefined);

  useEffect(() => {
    if (!selectedRole && (roles?.roles || []).length > 0 && !selectedRole) {
      setSelectedRole(roles?.roles[0]);
    }
  }, [dataUpdatedAt, roles?.roles, selectedRole]);

  const { t } = useTranslation();
  return (
    <div css={[tw`flex h-full justify-center`]}>
      {isFetched && selectedRole && (
        <div css={[tw`w-full max-w-screen-xl flex mt-8`]}>
          <div css={[tw`mr-40 flex-1`]}>
            {roles?.roles.map((role) => {
              const isSelected = selectedRole?.id === role.id;
              return (
                <div>
                  <Typography.MenuButton
                    key={role.id}
                    isSelected={isSelected}
                    onClick={() => {
                      if (!isDirty) {
                        setSelectedRole(role);
                      } else {
                        setIsUnsavedChangesModalOpen({
                          state: true,
                          onNext: () => setSelectedRole(role),
                        });
                      }
                    }}
                  >
                    {t(role?.name)}
                  </Typography.MenuButton>
                </div>
              );
            })}
          </div>
          <RoleDetails
            isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
            setIsUnsavedChangesModalOpen={setIsUnsavedChangesModalOpen}
            roleId={selectedRole?.id}
            setIsDirty={setIsDirty}
          />
        </div>
      )}
      <ManageRoleModal
        open={isAddNewRoleModalOpen}
        onClose={() => setIsAddNewRoleModalOpen(false)}
        setSelectedRole={setSelectedRole}
      />
    </div>
  );
};
