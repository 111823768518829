import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import * as yup from "yup";
import { GetOrganisationRolesRole } from "../../../api/api";
import { useRole } from "../../../api/hooks/roles-permissions/roles/useRole";
import { useRoles } from "../../../api/hooks/roles-permissions/roles/useRoles";
import { useMe } from "../../../api/hooks/user/useMe";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { IModalProps, Modal } from "../../../ui/popups/Modal";
import { errorMessages } from "../../../utils";
/** @jsxImportSource @emotion/react */

const schema = yup
  .object()
  .shape({
    name: yup.string().required(errorMessages.required),
    description: yup.string().nullable(),
  })
  .required(errorMessages.required);

type IForm = yup.InferType<typeof schema>;

export const ManageRoleModal = ({
  open,
  onClose,
  role,
  setSelectedRole,
}: Omit<IModalProps, "label" | "footerChildren"> & {
  role?: {
    id: number;
    name: string;
    description?: string;
  };
  setSelectedRole?: Dispatch<
    SetStateAction<GetOrganisationRolesRole | undefined>
  >;
}) => {
  const { t } = useTranslation();
  const { createRole } = useRoles();

  const { updateRole } = useRole({ id: Number(role?.id) });
  const methods = useForm<IForm>({
    defaultValues: {
      name: role?.name,
      description: role?.description,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const isEdit = !!role?.id;

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      if (!isEdit) {
        const newRole = await createRole({ requestData: values });
        if (newRole?.id && !!setSelectedRole) {
          setSelectedRole(newRole);
        }
      } else {
        await updateRole({ requestData: values });
      }
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={(isEdit ? t("editRole") : t("addNewRole")) as string}
        footerChildren={
          <Button.Contained containerCss={[tw`w-full`]} onClick={onSubmit}>
            {t("save")}
          </Button.Contained>
        }
      >
        <Form.TextInput.Outlined
          required
          label={t("roleName") as string}
          placeholder={t("roomNamePlaceholder") as string}
          name="name"
        />
        <Form.TextInput.Outlined
          label={t("description") as string}
          placeholder={t("descriptionPlaceholder") as string}
          name="description"
        />
      </Modal>
    </FormProvider>
  );
};
