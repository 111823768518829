// import { useQueryClient } from "@tanstack/react-query";
import { Api } from "../../api";
import { useGetAll } from "../../api-hooks/useGetAll";
import { useEmployee } from "../employees/useEmployee";

export const useMe = () => {
  const {
    employees: { employeeControllerGetEmployeeMe },
  } = new Api();

  const { data: me, isLoading: isLoadingMe } = useGetAll(
    employeeControllerGetEmployeeMe,
    ["me"]
  );

  const {
    employee: { data: employeeMe },
  } = useEmployee({ id: me?.id! });
  return {
    me: { ...employeeMe, ...me, role: me?.role },
    isLoadingMe,
  };
};
