import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiCalendarTodoLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import { usePatientCoupons } from "../../../../api/hooks/patients/usePatientCoupons";
import { useMe } from "../../../../api/hooks/user/useMe";
import { searchState } from "../../../../state";
import { Maybe } from "../../../../types";
import { AsyncDataTable } from "../../../../ui/layout/data-table/async/AsyncDataTable";
import { TableCell } from "../../../../ui/layout/data-table/cell";
import { PatientInfoSection } from "../../components/PatientInfoSection";

/** @jsxImportSource @emotion/react */

export const PatientCouponsTab = () => {
  const { id } = useParams();
  const { patientUsedCoupons, patientActivatedCoupons } = usePatientCoupons({
    id: Number(id),
  });
  const [activeRowIndex, setActiveRowIndex] = useState<Maybe<number>>(null);
  const search = useRecoilValue(searchState);
  const { me } = useMe();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (patientUsedCoupons.filters.term !== search) {
      patientUsedCoupons.setFilter("term", search);
    }
  }, [patientUsedCoupons, search]);

  useEffect(() => {
    if (patientActivatedCoupons.filters.term !== search) {
      patientActivatedCoupons.setFilter("term", search);
    }
  }, [patientActivatedCoupons, search]);

  return (
    <div css={[tw`flex h-full justify-center`]}>
      <PatientInfoSection />
      <div css={[tw`flex flex-col max-w-[965px] w-full`]}>
        <AsyncDataTable
          activeRowIndex={activeRowIndex}
          loading={patientActivatedCoupons?.isFetching}
          data={patientActivatedCoupons?.data?.items || []}
          onRowClick={(u, i) => {
            setActiveRowIndex(u.id);
          }}
          containerCss={[
            tw`max-w-[965px] w-full flex flex-col justify-between`,
          ]}
          columns={[
            {
              Header: t("activeCoupons"),
              accessor: "name",
              disableSortBy: true,
              align: "left",
              cell: (s) => <TableCell.Text value={s?.service?.name || "-"} />,
            },
            {
              Header: t("activeFrom"),
              accessor: "availableFrom",
              align: "right",
              disableSortBy: true,
              cell: (s) => <TableCell.Date value={new Date(s.availableFrom)} />,
            },
            {
              Header: t("activeTo"),
              accessor: "expirationDate",
              disableSortBy: true,
              maxWidth: 80,
              align: "right",
              cell: (s) =>
                s.expirationDate ? (
                  <TableCell.Date value={new Date(s.expirationDate)} />
                ) : (
                  <TableCell.Text value={"-"} />
                ),
            },
            {
              Header: t("discount"),
              accessor: "discountPercentage",
              disableSortBy: true,
              maxWidth: 70,
              align: "right",
              cell: (s) => (
                <TableCell.Text
                  value={
                    s?.discountPercentage ? `-${s?.discountPercentage}%` : ""
                  }
                />
              ),
            },
            {
              Header: t("points"),
              accessor: "requiredPoints",
              disableSortBy: true,
              maxWidth: 50,
              align: "right",
              cell: (s) => (
                <TableCell.Text
                  value={s?.requiredPoints ? `-${s?.requiredPoints}` : "-"}
                />
              ),
            },
            {
              Header: "",
              align: "left",
              disableSortBy: true,
              minWidth: 120,
              sticky: "right",
              accessor: "description",
              cell: (s) =>
                me.role?.permissions?.APPOINTMENTS?.CREATE && (
                  <TableCell.Button
                    lead={RiCalendarTodoLine}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate("/appointments", {
                        state: { serviceId: s?.service?.id, patientId: id },
                      });
                    }}
                    variant="small"
                    containerCss={[tw`pl-2 pr-2 min-w-2`]}
                  >
                    {t("order")}
                  </TableCell.Button>
                ),
            },
          ]}
          currentPage={patientActivatedCoupons.page}
          setPage={patientActivatedCoupons.setPage}
          pageSize={patientActivatedCoupons.size}
          maxPage={patientActivatedCoupons?.data?.totalPages || 0}
          totalCount={patientActivatedCoupons?.data?.totalItems || 0}
          setSortKey={patientActivatedCoupons.setOrderField}
          setSortOrder={patientActivatedCoupons.setOrder}
          sortKey={patientActivatedCoupons.orderField}
          sortOrder={patientActivatedCoupons.order}
          hidePagination
        />
        <AsyncDataTable
          activeRowIndex={activeRowIndex}
          loading={patientUsedCoupons.isFetching}
          data={patientUsedCoupons?.data?.items || []}
          onRowClick={(u, i) => {
            setActiveRowIndex(u.id);
          }}
          containerCss={[
            tw`max-w-[965px] w-full flex flex-1 flex-col justify-between`,
          ]}
          columns={[
            {
              Header: t("usedCoupons"),
              accessor: "name",
              cell: (s) => <TableCell.Text value={`${s.name}`} />,
            },
            {
              Header: t("activeFrom"),
              accessor: "availableFrom",
              align: "right",
              disableSortBy: true,
              cell: (s) =>
                s.availableFrom ? (
                  <TableCell.Date value={new Date(s.availableFrom)} />
                ) : (
                  <TableCell.Text value={`-`} />
                ),
            },
            {
              Header: t("activeTo"),
              accessor: "expirationDate",
              disableSortBy: true,
              align: "right",
              cell: (s) =>
                s.expirationDate ? (
                  <TableCell.Date value={new Date(s.expirationDate)} />
                ) : (
                  <TableCell.Text value={`-`} />
                ),
            },
            {
              Header: t("discount"),
              accessor: "discountPercentage",
              disableSortBy: true,
              align: "right",
              cell: (s) => <TableCell.Text value={`${s.discountPercentage}`} />,
            },
            {
              Header: t("points"),
              accessor: "requiredPoints",
              disableSortBy: true,
              align: "right",
              cell: (s) => <TableCell.Text value={`${s.requiredPoints}`} />,
            },
          ]}
          currentPage={patientUsedCoupons.page}
          setPage={patientUsedCoupons.setPage}
          pageSize={patientUsedCoupons.size}
          maxPage={patientUsedCoupons?.data?.totalPages || 0}
          totalCount={patientUsedCoupons?.data?.totalItems || 0}
          setSortKey={patientUsedCoupons.setOrderField}
          setSortOrder={patientUsedCoupons.setOrder}
          sortKey={patientUsedCoupons.orderField}
          sortOrder={patientUsedCoupons.order}
        />
      </div>
    </div>
  );
};
