import { Api } from "../../../api";
import { useCreate } from "../../../api-hooks/useCreate";

export const useRoles = () => {
  const {
    roles: { roleControllerCreateRole },
  } = new Api();
  const { mutateAsync: createRole, isLoading: isCreatingRole } = useCreate(
    roleControllerCreateRole,
    ["roles"]
  );

  return {
    createRole,
    isCreatingRole,
  };
};
