import { useMutation, useQueryClient } from "@tanstack/react-query";
import { refetchAllContainingQueryKey } from "../../../fields/form/utils";
import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";

export const useAppointment = ({ id }: { id: number }) => {
  const {
    appointments: {
      appointmentControllerGetAppointmentInfo,
      appointmentControllerReportArrivalAppointment,
    },
  } = new Api();
  const client = useQueryClient();
  const appointment = useGetById({
    id,
    query: appointmentControllerGetAppointmentInfo,
    queryKey: ["appointment", id],
  });

  const { mutateAsync: reportArrival } = useMutation(
    () => appointmentControllerReportArrivalAppointment(id),
    {
      onSuccess: () => {
        client.refetchQueries(["appointment", id]);
        refetchAllContainingQueryKey({ client, queryKey: ["appointments"] });
      },
    }
  );

  return {
    appointment,
    reportArrival,
  };
};
