/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiUserAddLine } from "react-icons/ri";
import tw from "twin.macro";
import { useMe } from "../../api/hooks/user/useMe";
import { PageContainer } from "../../components/layout/PageContainer";
import { Button } from "../../ui/buttons/Button";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { FilterEmployeesModal } from "./modals/FilterEmployeesModal";
import { ManageEmployeeModal } from "./modals/ManageEmployeeModal";
import { EmployeesListTab } from "./tabs/EmployeesListTab";

export const EmployeesPage = () => {
  const [isManageEmployeeModalOpen, setIsManageEmployeeModalOpen] =
    useState(false);
  const [isFilterEmployeesModalOpen, setIsFilterEmployeesModalOpen] =
    useState(false);
  const { t } = useTranslation();
  const { me } = useMe();
  return (
    <PageContainer>
      <Tabs
        rightComponent={
          <div css={[tw`flex justify-between gap-3`]}>
            {/* <Button.Outlined
              lead={RiFilterLine}
              onClick={() => setIsFilterEmployeesModalOpen(true)}
            >
              {t("filter")}
            </Button.Outlined> */}
            {me?.role?.permissions?.EMPLOYEES?.CREATE && (
              <Button.Contained
                lead={RiUserAddLine}
                onClick={() => setIsManageEmployeeModalOpen(true)}
              >
                {t("addNewEmployee")}
              </Button.Contained>
            )}
          </div>
        }
        containerCss={[tw`m-auto`]}
      >
        <Tabs.Item text={t("employees")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <EmployeesListTab
            setIsManageEmployeeModalOpen={setIsManageEmployeeModalOpen}
          />
        </Tabs.Item>
        {/* <Tabs.Item text={t("archive")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <EmployeesArchiveTab />
        </Tabs.Item> */}
      </Tabs>
      <ManageEmployeeModal
        onClose={() => setIsManageEmployeeModalOpen(false)}
        open={isManageEmployeeModalOpen}
      />
      <FilterEmployeesModal
        onClose={() => setIsFilterEmployeesModalOpen(false)}
        open={isFilterEmployeesModalOpen}
      />
    </PageContainer>
  );
};
