import { useQueryClient } from "@tanstack/react-query";
import { CustomDepartmentCategoriesWithServices } from "../../../api-types";
import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";

export const useServices = () => {
  const {
    services: { serviceControllerCreateService },
  } = new Api();
  const client = useQueryClient();
  const { mutateAsync: createService, isLoading: isCreatingService } =
    useCreate(
      serviceControllerCreateService,
      ["services"],
      (data, variables) => {
        const queryData = client.getQueryData([
          "department",
          //@ts-ignore
          Number(variables.requestData.departmentId),
          "categories",
          "services",
        ]) as CustomDepartmentCategoriesWithServices;

        client.setQueryData(["service", data?.id], data);
        client.setQueryData(
          [
            "department",
            //@ts-ignore
            Number(variables.requestData.departmentId),
            "categories",
            "services",
          ],
          queryData.map((c) => ({
            ...c,
            services:
              c.id === variables.requestData.categoryId
                ? [
                    ...c.services,
                    { ...data, departmentId: c.departmentId, categoryId: c.id },
                  ]
                : c.services,
          }))
        );
      }
    );

  return {
    createService,
    isCreatingService,
  };
};
