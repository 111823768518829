/** @jsxImportSource @emotion/react */

import { useFormError, useFormField } from "./hooks";

import { FieldError } from "../components/FieldError";
import { IFormFieldComponentBaseProps, Maybe, TwinStyle } from "../../types";
import { Switch } from "../controlled/Switch";
import tw from "twin.macro";

type IFormSwitchProps = IFormFieldComponentBaseProps & {
  switchContainerCss?: Maybe<TwinStyle>;
  hideError?: boolean;
  onChange?: (val: boolean) => void;
};

export const FormSwitch = (props: IFormSwitchProps) => {
  const field = useFormField(props.name);
  const error = useFormError(props.name);
  return (
    <div css={[tw`flex-1 w-full`, props.containerCss]}>
      <Switch
        onChange={(val) => {
          field.onChange(val);
          if (!!props.onChange && !!val) {
            props.onChange(val);
          }
        }}
        value={field.value ?? false}
        label={props.label}
        disabled={props.disabled}
        error={error}
        containerCss={props.switchContainerCss}
      />
      {!props.hideError && (
        <FieldError disabled={props.disabled} name={props.name} />
      )}
    </div>
  );
};
