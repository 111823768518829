import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import { useFacilityCoupons } from "../../../api/hooks/facilities/useFacilityCoupons";
import { searchState } from "../../../state";
import { AsyncDataTable } from "../../../ui/layout/data-table/async/AsyncDataTable";
import { TableCell } from "../../../ui/layout/data-table/cell";
/** @jsxImportSource @emotion/react */

export const CouponsInAnnouncementTab = () => {
  const search = useRecoilValue(searchState);
  const { upcomingCoupons } = useFacilityCoupons();

  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (upcomingCoupons.filters.term !== search) {
      upcomingCoupons.setFilter("term", search);
    }
  }, [upcomingCoupons, search]);

  return (
    <>
      <AsyncDataTable
        loading={upcomingCoupons.isInitialLoading}
        data={upcomingCoupons.data?.items || []}
        containerCss={[tw`flex flex-col justify-between h-full`]}
        onRowClick={(u) => {
          navigate(`/coupons/${u.id}`);
        }}
        columns={[
          {
            Header: t("couponName"),
            accessor: "name",
            align: "left",
            disableSortBy: true,
            cell: (s) => <TableCell.Text value={`${s.name}`} />,
          },
          {
            Header: t("discount"),
            accessor: "discountPercentage",
            align: "right",
            disableSortBy: true,
            cell: (s) => <TableCell.Text value={`${s.discountPercentage}%`} />,
          },
          {
            Header: t("asNews"),
            accessor: "asNews",
            disableSortBy: true,
            align: "right",
            cell: (s) => (
              <TableCell.Text value={s.asNews ? t("yes") : t("no")} />
            ),
          },
          {
            Header: t("activated"),
            accessor: "timesActivated",
            disableSortBy: true,
            align: "right",
            cell: (s) => <TableCell.Text value={`${s.timesActivated}`} />,
          },
          {
            Header: t("used"),
            accessor: "timesUsed",
            align: "right",
            disableSortBy: true,
            cell: (s) => <TableCell.Text value={`${s.timesUsed}`} />,
          },
          {
            Header: t("validFrom"),
            accessor: "availableFrom",
            align: "right",
            disableSortBy: true,
            cell: (s) => <TableCell.Date value={new Date(s.availableFrom)} />,
          },
          {
            Header: t("validTo"),
            accessor: "expirationDate",
            align: "right",
            disableSortBy: true,
            cell: (s) => (
              <TableCell.Date
                value={new Date(s?.expirationDate || new Date())}
              />
            ),
          },
          {
            Header: t("points"),
            accessor: "requiredPoints",
            align: "right",
            disableSortBy: true,
            cell: (s) => <TableCell.Text value={`-${s.requiredPoints}`} />,
          },
        ]}
        currentPage={upcomingCoupons.page}
        setPage={upcomingCoupons.setPage}
        pageSize={upcomingCoupons.size}
        maxPage={upcomingCoupons.data?.totalPages || 0}
        totalCount={upcomingCoupons.data?.totalItems || 0}
        setSortKey={upcomingCoupons.setOrderField}
        setSortOrder={upcomingCoupons.setOrder}
        sortKey={upcomingCoupons.orderField}
        sortOrder={upcomingCoupons.order}
      />
    </>
  );
};
