/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiEdit2Line } from "react-icons/ri";
import tw from "twin.macro";
import { GetRoomResponseDto } from "../../../api/api";
import { TwoButtonMessageModal } from "../../../components/modals/TwoButtonMessageModal";
import { Button } from "../../../ui/buttons/Button";
import { Modal } from "../../../ui/popups/Modal";
import { Typography } from "../../../ui/Typograhy";
import { NewRoomModal } from "./NewRoomModal";

interface ITextInputModalProps {
  open: boolean;
  onClose: () => void;
  room: GetRoomResponseDto;
  departmentId: number;
}

export const RoomDetailsModal = ({
  open,
  onClose,
  room,
  departmentId,
}: ITextInputModalProps) => {
  const [isEditRoomModalOpen, setIsEditRoomModalOpen] = useState(false);
  const [isDeleteReassuranceModalOpen, setIsDeleteReassuranceModalOpen] =
    useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        key={room.id}
        open={open}
        onClose={onClose}
        label={room.name}
        // footerChildren={
        //   <div css={[tw`flex gap-3`]}>
        //     <Button.Outlined
        //       containerCss={[tw`flex-1`]}
        //       onClick={() => setIsDeleteReassuranceModalOpen(true)}
        //     >
        //       {t("deleteRoom")}
        //     </Button.Outlined>
        //     <Button.Contained
        //       containerCss={[tw`flex-3`]}
        //       lead={RiEdit2Line}
        //       onClick={() => setIsEditRoomModalOpen(true)}
        //     >
        //       {t("editRoomData")}
        //     </Button.Contained>
        //   </div>
        // }
        containerCss={[tw`bg-white xl:max-w-xl`]}
      >
        <div css={[tw`flex flex-col`]}>
          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("situation")}
          </Typography.BodyXSmall>
          <Typography.BodySmall
            containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
          >
            {room.position}
          </Typography.BodySmall>
          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("doctors")}
          </Typography.BodyXSmall>
          <Typography.BodySmall
            containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
          >
            {room.employees
              .map((em) => `${em.firstName} ${em.lastName}`)
              .join(", ")}
          </Typography.BodySmall>
          <Typography.BodyXSmall
            containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
          >
            {t("services")}
          </Typography.BodyXSmall>
          {room.services
            .filter((s) => !!s.id)
            .map((s) => (
              <Typography.BodySmall
                key={s.id}
                containerCss={[tw`mb-2 list-item ml-4`]}
              >
                {s.name}
              </Typography.BodySmall>
            ))}
        </div>
      </Modal>
      <NewRoomModal
        open={isEditRoomModalOpen}
        onClose={() => setIsEditRoomModalOpen(false)}
        room={room}
        departmentId={departmentId}
      />
      <TwoButtonMessageModal
        buttonLeft={{
          title: t("no"),
          onClick: () => setIsDeleteReassuranceModalOpen(false),
        }}
        label={t("deleteServiceReassurance")}
        onClose={() => setIsDeleteReassuranceModalOpen(false)}
        open={isDeleteReassuranceModalOpen}
        buttonRight={{
          title: t("yes"),
          onClick: () => setIsDeleteReassuranceModalOpen(false),
        }}
      />
    </>
  );
};
