/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiNewspaperLine } from "react-icons/ri";
import tw from "twin.macro";
import { useMe } from "../../api/hooks/user/useMe";
import { PageContainer } from "../../components/layout/PageContainer";
import { Button } from "../../ui/buttons/Button";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { CreateNewsModal } from "./modals/CreateNewsModal";
import { ArchiveNewsTab } from "./tabs/ArchiveNewsTab";
import { CurrentNewsTab } from "./tabs/CurrentNewsTab";
import { DraftNewsTab } from "./tabs/DraftNewsTab";
import { IncomingNewsTab } from "./tabs/IncomingNewsTab";

export const NewsPage = () => {
  const { t } = useTranslation();
  const { me } = useMe();
  const [isAddNewNewsModalOpen, setIsAddNewNewsModalOpen] = useState(false);

  return (
    <PageContainer>
      <Tabs
        rightComponent={
          <div css={[tw`flex justify-between gap-3`]}>
            {me?.role?.permissions?.NEWS?.CREATE && (
              <Button.Contained
                lead={RiNewspaperLine}
                onClick={() => setIsAddNewNewsModalOpen(true)}
              >
                {t("addNews")}
              </Button.Contained>
            )}
          </div>
        }
        containerCss={[tw`m-auto`]}
      >
        <Tabs.Item text={t("currentNews")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <CurrentNewsTab />
        </Tabs.Item>
        <Tabs.Item text={t("incomingNews")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <IncomingNewsTab />
        </Tabs.Item>
        <Tabs.Item text={t("drafts")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <DraftNewsTab />
        </Tabs.Item>
        <Tabs.Item text={t("archivedNews")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <ArchiveNewsTab />
        </Tabs.Item>
      </Tabs>
      <CreateNewsModal
        onClose={() => setIsAddNewNewsModalOpen(false)}
        open={isAddNewNewsModalOpen}
      />
    </PageContainer>
  );
};
