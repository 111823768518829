import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { config } from "./config";
import { AppRouter } from "./navigation/AppRouter";
import resources from "./utils/locale";
import "./Calendar.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ONE MINUTE
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng: "en", // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter basename={config.APP_PREFIX}>
          <ErrorBoundary>
            <AppRouter />
            <ToastContainer
              position="bottom-right"
              hideProgressBar
              autoClose={5000}
              newestOnTop={false}
              closeOnClick
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover
              closeButton={false}
            />
          </ErrorBoundary>
        </BrowserRouter>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
