/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { Assets } from "../assets";
import { PageContainer } from "../components/layout/PageContainer";
import { Typography } from "../ui/Typograhy";

export const NoPermissionsPage = () => {
  const { t } = useTranslation();
  return (
    <PageContainer
      containerCss={[tw`justify-center items-center flex flex-col`]}
    >
      <Assets.NoPermissionsImage css={[tw`mb-4`]} />
      <Typography.H3 containerCss={[tw`mb-6 max-w-120 text-center`]}>
        {t("noRoleByAdmin")}
      </Typography.H3>
      <Typography.BodyLarge containerCss={[tw`mb-10`]}>
        {t("contactSupervisor")}
      </Typography.BodyLarge>
    </PageContainer>
  );
};
