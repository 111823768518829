import { IComponentBaseProps } from "../types";
/** @jsxImportSource @emotion/react */
import { MouseEventHandler } from "react";
import tw from "twin.macro";
import { getInitialsFromName, onKeyDownA11Y } from "../utils";
import { Typography } from "./Typograhy";

interface IAvatarProps extends IComponentBaseProps {
  name: string;
  imageUrl?: string;
  url?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const Avatar = (props: IAvatarProps) => {
  const initials = getInitialsFromName(props.name);

  const clickable = props.onClick;

  return (
    <div
      onClick={props.onClick}
      role={clickable ? "button" : undefined}
      tabIndex={clickable ? 0 : undefined}
      onKeyDown={onKeyDownA11Y({ open: props.onClick })}
      css={[
        tw`bg-white`,
        tw`h-10 w-10 border-1 border-transparent`,
        props.url && tw`cursor-pointer`,
        tw`flex flex-row items-center justify-center rounded-full`,
        tw`hover:(border-white)`,
        props.containerCss,
      ]}
      // style={{
      //   backgroundImage: props.imageUrl ? `url(${props.imageUrl})` : "initial",
      // }}
    >
      {!props.imageUrl ? (
        <Typography.BodyLarge>{initials}</Typography.BodyLarge>
      ) : (
        <img
          src={props.imageUrl}
          alt="profile"
          width={40}
          height={40}
          css={[tw`rounded-full object-contain w-10 h-10`]}
        />
      )}
    </div>
  );
};
