import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { isLoggedInState } from "../../state";
import { RequestParams, HttpResponse } from "../api";

export function useGetById<T>({
  id,
  query,
  queryKey,
  queryParams,
  onSuccess,
}: {
  id?: number;
  query: (
    id: number,
    query?: any,
    params?: RequestParams
  ) => Promise<AxiosResponse<HttpResponse & { payload?: T }, any>>;
  queryKey: Array<string | number>;
  onSuccess?: ((data: T | null) => unknown) | undefined;
  queryParams?: any;
}) {
  const isLoggedIn = useRecoilValue(isLoggedInState);

  const qFn = async () => {
    const { data } = await query(id!, queryParams);

    if (data.isSuccessful) {
      return data.payload;
    } else {
      toast.error(data.message);
      return null;
    }
  };

  const queryResp = useQuery(queryKey, qFn, {
    enabled: isLoggedIn && !!id,
    onSuccess,
  });
  return queryResp;
}
