import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedFacilityIdState,
  selectedOrganisationIdState,
} from "../../../state";
import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";
import { useGetPaginated } from "../../api-hooks/useGetPaginated";
import {
  organisationFacilitiesKey,
  organisationPatientsKey,
  organisationPatientsPaginatedKey,
} from "../../query-keys";

export const useOrganisation = () => {
  const [selectedFacilityId, setSelectedFacilityId] = useRecoilState(
    selectedFacilityIdState
  );
  const organisationId = useRecoilValue(selectedOrganisationIdState);

  const queryClient = useQueryClient();
  const {
    organisations: { organisationControllerGetOrganisationFacilities },
    patients: {
      patientControllerGetOrganisationPatientsPaginated,
      patientControllerGetOrganisationPatients,
    },
    facilities: { facilityControllerGetFacility },
  } = new Api();

  const organisationFacilities = useQuery(
    organisationFacilitiesKey(organisationId),
    async () => {
      const { data } = await organisationControllerGetOrganisationFacilities(
        Number(organisationId)
      );
      if (data.isSuccessful) {
        return data.payload?.facilities.map((f) => ({
          ...f,
          organisationId,
        }));
      }
      return [];
    },
    {
      onSuccess: async (data) => {
        if (selectedFacilityId === null && data?.[0].id) {
          setSelectedFacilityId(data[0].id!.toString());
          queryClient.prefetchQuery(["facility", data[0].id], async () => {
            const f = await facilityControllerGetFacility(Number(data[0].id));
            return f.data.isSuccessful ? f.data.payload : null;
          });
        }
      },
      enabled: !!organisationId,
    }
  );

  const organisationPatients = useGetPaginated({
    id: Number(organisationId)!,
    query: patientControllerGetOrganisationPatientsPaginated,
    queryKey: organisationPatientsPaginatedKey(organisationId),
  });

  const patients = useGetById({
    id: Number(organisationId)!,
    query: patientControllerGetOrganisationPatients,
    queryKey: organisationPatientsKey(organisationId),
  });

  return {
    organisationFacilities,
    organisationPatients,
    patients,
  };
};
