import { useQueryClient } from "@tanstack/react-query";
import { Api } from "../../api";
import { useCreate } from "../../api-hooks/useCreate";
import { useDelete } from "../../api-hooks/useDelete";
import { useGetById } from "../../api-hooks/useGetById";
import { useUpdate } from "../../api-hooks/useUpdate";
export const useCoupon = ({ id }: { id: number }) => {
  const client = useQueryClient();

  const {
    coupons: {
      couponControllerDeleteCoupon,
      couponControllerUpdateCoupon,
      couponControllerUseCoupon,
      couponControllerGetCoupon,
      couponControllerDeactivateCoupon,
    },
  } = new Api();

  const coupon = useGetById({
    id: Number(id),
    query: couponControllerGetCoupon,
    queryKey: ["coupon", id],
  });

  const { mutateAsync: updateCoupon } = useUpdate(
    id,
    couponControllerUpdateCoupon,
    ["coupon", id]
  );

  const { mutateAsync: deleteCoupon } = useDelete(
    id,
    couponControllerDeleteCoupon,
    ["coupons"]
  );

  const { mutateAsync: activateCoupon } = useCreate(
    couponControllerUseCoupon,
    ["coupon", id],
    async (data) => {
      if (data?.couponId) {
        await client.invalidateQueries({
          queryKey: ["patient", data?.patientId, "coupons"],
        });
        await client.invalidateQueries({
          queryKey: ["coupon", data?.couponId],
        });
      }
    }
  );

  const { mutateAsync: deactivateCoupon } = useUpdate(
    id,
    couponControllerDeactivateCoupon,
    ["coupon", id]
  );

  return {
    coupon,
    updateCoupon,
    deleteCoupon,
    activateCoupon,
    deactivateCoupon,
  };
};
