import { useRecoilValue } from "recoil";
import { selectedFacilityIdState } from "../../../state";
import { Api } from "../../api";
import { useGetPaginated } from "../../api-hooks/useGetPaginated";

export const useFacilityCoupons = () => {
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const selectedFacilityIdNumber = Number(selectedFacilityId);
  const {
    coupons: {
      couponControllerGetCurrentCouponsForFacilityPaginated,
      couponControllerGetPastCouponsForFacilityPaginated,
      couponControllerGetUpcomingCouponsForFacilityPaginated,
    },
  } = new Api();

  const upcomingCoupons = useGetPaginated({
    id: selectedFacilityIdNumber,
    query: couponControllerGetUpcomingCouponsForFacilityPaginated,
    queryKey: ["facility", selectedFacilityIdNumber, "coupons", "upcoming"],
  });

  const currentCoupons = useGetPaginated({
    id: selectedFacilityIdNumber,
    query: couponControllerGetCurrentCouponsForFacilityPaginated,
    queryKey: ["facility", selectedFacilityIdNumber, "coupons", "current"],
  });

  const pastCoupons = useGetPaginated({
    id: selectedFacilityIdNumber,
    query: couponControllerGetPastCouponsForFacilityPaginated,
    queryKey: ["facility", selectedFacilityIdNumber, "coupons", "past"],
  });

  return {
    upcomingCoupons,
    currentCoupons,
    pastCoupons,
  };
};
