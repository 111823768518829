import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import * as yup from "yup";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Nullable } from "../../../types";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { Modal } from "../../../ui/popups/Modal";

/** @jsxImportSource @emotion/react */

const schema = yup.object().shape({
  serviceType: yup.object().nullable(),
  birthYear: yup.object().nullable(),
  country: yup.object().nullable(),
  gender: yup.object().nullable(),
  filter: yup.object().nullable(),
});

type IForm = Nullable<yup.InferType<typeof schema>>;

interface IFilterEmployeesModal {
  open: boolean;
  onClose: () => void;
}

export const FilterPatientsModal = ({
  open,
  onClose,
}: IFilterEmployeesModal) => {
  const { t } = useTranslation();
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      console.log(values);
      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <Modal
      containerCss={[tw`w-96 `]}
      open={open}
      onClose={onClose}
      footerChildren={
        <div css={[tw`flex justify-between gap-3`]}>
          <Button.Outlined containerCss={[tw`flex-1`]} onClick={onClose}>
            {t("cancel")}
          </Button.Outlined>
          <Button.Contained containerCss={[tw`flex-1`]} onClick={onSubmit}>
            {t("changeFilter")}
          </Button.Contained>
        </div>
      }
    >
      <FormProvider {...methods}>
        <Form.Select
          options={[]}
          name="typeOfService"
          label={t("typeOfService") as string}
          placeholder={t("chooseService") as string}
        />
        <div css={[tw`flex gap-4`]}>
          <Form.Select
            options={[]}
            name="birthYear"
            label={t("birthYear") as string}
            placeholder={t("year") as string}
          />
          <Form.Select
            options={[]}
            name="city"
            label={t("cityOrCounty") as string}
            placeholder={t("chooseCity") as string}
          />
        </div>
        <Form.RadioGroup
          name="gender"
          containerCss={[tw`border-1 border-gray-200 rounded-md bg-white`]}
          options={[
            { label: t("women"), value: "1" },
            { label: t("men"), value: "2" },
            { label: t("rest"), value: "3" },
          ]}
        />
        <Form.RadioGroup
          name="filter"
          containerCss={[tw`border-1 border-gray-200 rounded-md bg-white`]}
          options={[
            { label: t("orderedPatients"), value: "1" },
            { label: t("hasApplication"), value: "2" },
          ]}
        />
      </FormProvider>
    </Modal>
  );
};
