import { useCallback, useState } from "react";

import { isEqual } from "lodash";

type ArrayActions<T> = {
  push: (value: T | T[]) => T[];
  replace: (value: T | T[]) => T[];
  addAtIndex: (index: number, value: T) => T[];
  removeAt: (index: number, compareBy?: keyof T) => T[];
  remove: (value: T, compareBy?: keyof T) => T[];
  subset: (indexStart: number, indexEnd: number) => T[];
  clear: () => T[];
};

export const useArray = <T>(initialValue: T | T[]): [T[], ArrayActions<T>] => {
  const [array, setArray] = useState<T[]>(
    Array.isArray(initialValue) ? initialValue : [initialValue]
  );

  return [
    array,
    {
      push: useCallback(
        (value) => {
          const values = Array.isArray(value) ? value : [value];
          const state = [...array, ...values];
          setArray(state);
          return state;
        },
        [array]
      ),
      replace: useCallback(
        (value: T | T[]) => {
          const values = Array.isArray(value) ? value : [value];
          const state = values;
          setArray(state);
          return state;
        },
        [setArray]
      ),
      addAtIndex: useCallback(
        (index: number, value: T) => {
          const state = [
            ...array.slice(0, index + 1),
            value,
            ...array.slice(index + 1),
          ];
          setArray(state);
          return state;
        },
        [array]
      ),
      removeAt: useCallback(
        (index: number) => {
          const state = [...array.slice(0, index), ...array.slice(index + 1)];
          setArray(state);
          return state;
        },
        [array]
      ),
      remove: useCallback(
        (value, compareBy) => {
          const index = array.findIndex((obj: T) => {
            return compareBy
              ? isEqual(obj[compareBy], value[compareBy])
              : isEqual(obj, value);
          });
          const state =
            index >= 0
              ? [...array.slice(0, index), ...array.slice(index + 1)]
              : array;
          setArray(state);
          return state;
        },
        [array]
      ),
      subset: useCallback(
        (indexStart, indexEnd) => {
          const state = array.slice(indexStart, indexEnd + 1);
          setArray(state);
          return state;
        },
        [array]
      ),
      clear: useCallback(() => {
        const state: T[] = [];
        setArray(state);
        return state;
      }, [setArray]),
    },
  ];
};
