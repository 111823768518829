import { QueryClient, useQueryClient } from "@tanstack/react-query";

function getScrollParent(node: any): HTMLElement {
  const isElement = node instanceof HTMLElement;
  const overflowY = isElement && window.getComputedStyle(node).overflowY;
  const isScrollable = overflowY !== "visible" && overflowY !== "hidden";

  if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node;
  }

  const parentNode = node.parentNode ? getScrollParent(node.parentNode) : null;
  return (parentNode || document.scrollingElement || document.body) as any;
}

export const refetchAllContainingQueryKey = ({
  client,
  queryKey,
}: {
  client: QueryClient;
  queryKey: (string | number)[];
}) => {
  const allCacheKeys = client
    .getQueryCache()
    .getAll()
    .map((q) => q.queryKey)
    .filter((qk) => qk.includes(queryKey[0]));

  allCacheKeys.forEach((key) => {
    client.invalidateQueries({ queryKey: key, type: "all" });
  });
};

export function omit(obj, ...props) {
  const result = { ...obj };
  props.forEach(function (prop) {
    delete result[prop];
  });
  return result;
}

export const scrollToError = () => {
  const elements = Array.from(
    document.getElementsByClassName("scrollable-error") ?? []
  );

  if (elements.length === 0) {
    return;
  }
  const element = elements[0];
  const { top } = element.getBoundingClientRect();

  // STATEMENT: Why can't I use window.scroll or element.scrollIntoView?
  // Even though these methods would work in most cases, there are situations(scroll in overlay, drawer, etc.)
  // where window element isn't scrollable. Idea is to traverse the DOM and find the first scrollable node.
  // Find out more about implementation here: https://gist.github.com/twxia/bb20843c495a49644be6ea3804c0d775
  const parent = getScrollParent(element);
  parent.scrollTo({
    // STATEMENT: Why do we scroll with -200 offset?
    // It's done to avoid the unpredictability of the element with the "scrollable-error" class.
    // We don't know for sure if this class is mounted above, left, rigt or below the form element.
    // Therefore, it's easier to just to go a bit more up.
    top: top + window.pageYOffset - 200,
    behavior: "smooth",
  });
};

export const getBase64 = (file: Blob, setImage) => {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    setImage(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

export const getDaysArray = function (
  start: string | number | Date,
  end: string | number | Date
) {
  for (
    var arr = [], dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    //@ts-ignore
    arr.push(new Date(dt));
  }
  return arr as Array<Date>;
};
