export const departmentCategoriesServicesKey = (id: number) => [
  "department",
  id,
  "categories",
  "services",
];
export const departmentCategoriesKey = (id: number) => [
  "department",
  id,
  "categories",
];

export const organisationPatientsKey = (organisationId: any) => [
  "organisation",
  organisationId,
  "patients",
];

export const organisationRoleKey = (organisationId: any) => [
  "organisation",
  organisationId,
  "roles",
];

export const organisationRoleWPermissionsKey = (organisationId: any) => [
  "organisation",
  organisationId,
  "roles",
  "permissions",
];

export const organisationPatientsPaginatedKey = (organisationId: any) => [
  "organisation",
  organisationId,
  "patients",
  "paginated",
];
export const facilityEmployeesKey = (facilityId: string | null) => [
  "facility",
  Number(facilityId),
  "employees",
];

export const facilityEmployeesPaginatedKey = (facilityId: string | null) => [
  "facility",
  Number(facilityId),
  "employees",
  "paginated",
];

export const organisationFacilitiesKey = (organisationId: any) => [
  "organisation",
  organisationId,
  "facilities",
];

export const serviceRooms = (id: number | undefined) => [
  "service",
  id,
  "rooms",
];
export const serviceEmployees = (id: number | undefined) => [
  "service",
  id,
  "employees",
];
