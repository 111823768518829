/** @jsxImportSource @emotion/react */

import invariant from "invariant";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { IComponentBaseProps } from "../../types";
import { onKeyDownA11Y } from "../../utils";
import { FloatingContent } from "../layout/FloatingContent";
import { Typography } from "../Typograhy";

export interface IMenuItem {
  label: string;
  url?: string;
  externalUrl?: string;
  onClick?(): void;
}
export interface IMenuProps extends IComponentBaseProps {
  initialOpen?: boolean;
  items: IMenuItem[];
  header?: any;
  extraYMovement?: number;
  extraXMovement?: number;
}
export const Menu = (props: PropsWithChildren<IMenuProps>) => {
  invariant(props.children, "Children must be specified");
  const navigate = useNavigate();

  return (
    <FloatingContent
      placement="bottom"
      offset={12}
      closeOnClick
      extraXMovement={props.extraXMovement}
      extraYMovement={props.extraYMovement}
      renderContent={(__: any, { setOpen }: any) => (
        <div
          css={[props.containerCss, tw`z-50 min-w-36 bg-white flex flex-col`]}
        >
          {props.header}
          {props.items.map((item, index) => {
            invariant(
              item.onClick || item.url || item.externalUrl,
              "Either url or onClick must be specified"
            );

            return (
              <button
                key={index}
                css={[
                  tw`text-gray-700 text-left`,
                  tw`hover:(bg-primary-50 text-primary-900)`,
                  tw`focus-visible:(bg-transparent text-primary outline-none)`,
                ]}
                onKeyDown={onKeyDownA11Y({ close: () => setOpen(false) })}
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.onClick) {
                    item.onClick();
                  } else if (item.url) {
                    navigate(item.url);
                  } else {
                    window.open(item.externalUrl);
                  }
                  setOpen(false);
                }}
              >
                <Typography.BodySmall
                  containerCss={[
                    tw`text-inherit`,
                    tw`cursor-pointer px-4 py-3`,
                  ]}
                >
                  {item.label}
                </Typography.BodySmall>
              </button>
            );
          })}
        </div>
      )}
      containerCss={[tw`z-50 focus-visible:(ring-transparent outline-none)`]}
    >
      {
        ((open: boolean) => {
          const children =
            typeof props.children === "function"
              ? // @ts-ignore
                props.children(open)
              : props.children;
          return children;
        }) as any
      }
    </FloatingContent>
  );
};
