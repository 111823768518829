/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiAddLine, RiArrowLeftLine, RiCalendarTodoLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import tw from "twin.macro";
import { usePatientCoupons } from "../../api/hooks/patients/usePatientCoupons";
import { useMe } from "../../api/hooks/user/useMe";
import { PageContainer } from "../../components/layout/PageContainer";
import { Button } from "../../ui/buttons/Button";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { AddPointsModal } from "./modals/AddPointsModal";
import { PatientAppointmentHistoryTab } from "./tabs/patient/PatientAppointmentHistoryTab";
import { PatientArrangedAppointmentsTab } from "./tabs/patient/PatientArrangedAppointmentsTab";
import { PatientCouponsTab } from "./tabs/patient/PatientCouponsTab";
import { PatientOngoingAppointmentsTab } from "./tabs/patient/PatientOngoingAppointmentsTab";
import { PatientPointsTab } from "./tabs/patient/PatientPointsTab";
import { usePatient } from "../../api/hooks/patients/usePatient";

export const PatientDetailsPage = () => {
  const { id } = useParams();
  const { patientActivatedCoupons } = usePatientCoupons({
    id: Number(id),
  });
  const {
    patient: { data: patient, isInitialLoading: isPatientInitialLoading },
  } = usePatient({ id: Number(id) });
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAddPointsModalOpen, setIsAddPointsModalOpen] = useState(false);
  const { me } = useMe();
  return (
    <PageContainer>
      {!patientActivatedCoupons.isInitialLoading && !isPatientInitialLoading && (
        <Tabs
          onTabChange={(tabIndex) => setCurrentTabIndex(tabIndex)}
          leftComponent={
            <div css={[tw`flex gap-4 items-center flex-1`]}>
              <Button.Text
                lead={RiArrowLeftLine}
                leadCss={[tw`text-gray-900`]}
                textCss={[tw`text-gray-900`]}
                containerCss={[tw`px-2`]}
                onClick={() => navigate("/patients")}
              >
                {t("backToList")}
              </Button.Text>
            </div>
          }
          rightComponent={
            <div css={[tw`flex justify-end gap-3 flex-1`]}>
              {currentTabIndex === 3 && (
                <Button.Outlined
                  lead={RiAddLine}
                  onClick={() => setIsAddPointsModalOpen(true)}
                >
                  {t("addPoints")}
                </Button.Outlined>
              )}
              {me.role?.permissions?.APPOINTMENTS?.CREATE && (
                <Button.Contained
                  lead={RiCalendarTodoLine}
                  onClick={() =>
                    navigate("/appointments", { state: { patientId: id } })
                  }
                >
                  {t("arrangeNewAppointment")}
                </Button.Contained>
              )}
            </div>
          }
          containerCss={[tw`m-auto`]}
        >
          <Tabs.Item text={t("arrangedAppointments")}>
            <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
            <PatientArrangedAppointmentsTab />
          </Tabs.Item>
          <Tabs.Item text={t("ongoingAppointments")}>
            <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
            <PatientOngoingAppointmentsTab />
          </Tabs.Item>
          <Tabs.Item text={t("appointmentHistory")}>
            <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
            <PatientAppointmentHistoryTab />
          </Tabs.Item>
          <Tabs.Item
            text={t("coupons")}
            notificationNumber={patientActivatedCoupons?.data?.totalItems}
          >
            <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
            <PatientCouponsTab />
          </Tabs.Item>
          <Tabs.Item
            text={t("points")}
            notificationNumber={patient?.points || 0}
          >
            <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
            <PatientPointsTab />
          </Tabs.Item>
        </Tabs>
      )}
      <AddPointsModal
        open={isAddPointsModalOpen}
        onClose={() => setIsAddPointsModalOpen(false)}
        patientId={Number(id)}
      />
    </PageContainer>
  );
};
