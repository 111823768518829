import { yupResolver } from "@hookform/resolvers/yup";
import format from "date-fns/format";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import * as yup from "yup";
import { CreateAwardRequestDto } from "../../../api/api";
import { useFacilityAwards } from "../../../api/hooks/facilities/useFacilityAwards";
import { useFacilityServices } from "../../../api/hooks/facilities/useFacilityServices";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { selectedFacilityIdState } from "../../../state";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { IModalProps, Modal } from "../../../ui/popups/Modal";
import { Typography } from "../../../ui/Typograhy";
import { errorMessages } from "../../../utils";
import { AwardType } from "../../../enums";
/** @jsxImportSource @emotion/react */

const schema: yup.SchemaOf<CreateAwardRequestDto> = yup.object().shape({
  facilityId: yup.number().required(errorMessages.required),
  serviceId: yup.number().required(errorMessages.required),
  name: yup.string().required(errorMessages.required),
  type: yup.string().required(errorMessages.required),
  maxNoOfUsage: yup.number().nullable(),
  awardPoints: yup.number().required(errorMessages.required),
  availableFrom: yup.string().required(errorMessages.required),
  expirationDate: yup.string(),
  description: yup.string().required(errorMessages.required),
});

type IForm = yup.InferType<typeof schema>;

const stepDescriptions = ["addingDetail", "viewInformation"];

const triggerByStep = [
  [
    "maxNoOfUsage",
    "additionOfPoints",
    "availableFrom",
    "expirationDate",
    "description",
    "name",
    "awardPoints",
    "serviceId",
  ],
  [],
];

export const AddNewCollectionOfPointsModal = ({
  open,
  onClose,
}: Omit<IModalProps, "label" | "footerChildren">) => {
  const [step, setStep] = useState(0);
  const { facilityServices } = useFacilityServices();
  const { createAward } = useFacilityAwards();
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const { t } = useTranslation();
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      type: AwardType.USE_SERVICE,
      serviceId: undefined,
      name: undefined,
      awardPoints: undefined,
      description: "",
      maxNoOfUsage: undefined,
      availableFrom: undefined,
      expirationDate: undefined,
      facilityId: Number(selectedFacilityId),
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const watchAll = methods.watch();

  const incrementStep = useCallback(async () => {
    //@ts-ignore
    const isCorrect = await methods.trigger(triggerByStep[step]);

    if (isCorrect) {
      setStep((prevStep) =>
        Math.min(prevStep + 1, stepDescriptions.length - 1)
      );
    }
  }, [methods, step]);

  const decrementStep = useCallback(() => {
    methods.clearErrors();
    setStep((prevStep) => Math.max(prevStep - 1, 0));
  }, [methods]);

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      await createAward({
        requestData: {
          ...values,
          availableFrom: new Date(values.availableFrom).toISOString(),
          expirationDate: values?.expirationDate
            ? new Date(values.expirationDate).toISOString()
            : undefined,
        },
      });
      onClose();
      methods.reset();
      setStep(0);
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  const isLastStep = step === stepDescriptions.length - 1;

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={() => {
          onClose();
          setStep(0);
        }}
        label={t("addNewCollectionOfPoints") as string}
        containerCss={[isLastStep && tw`bg-white`]}
        footerChildren={
          <div css={[tw`flex justify-between gap-3`]}>
            <Button.Outlined
              containerCss={[tw`flex-1`]}
              onClick={decrementStep}
              disabled={step === 0}
            >
              {t("back")}
            </Button.Outlined>
            <Button.Contained
              containerCss={[tw`flex-5`]}
              onClick={isLastStep ? onSubmit : incrementStep}
            >
              {isLastStep ? t("add") : t("next")}
            </Button.Contained>
          </div>
        }
      >
        <Typography.BodyMedium containerCss={[tw`mb-6`]}>
          {t("step")} {step + 1}.{" "}
          <Typography.BodyMedium containerCss={[tw`font-700 inline`]}>
            {t(stepDescriptions[step])}
          </Typography.BodyMedium>
        </Typography.BodyMedium>
        {step === 0 && (
          <div>
            <Form.TextInput.Outlined
              name="name"
              required
              label={t("name") as string}
              placeholder={t("enterAwardName") as string}
            />
            <Form.Select
              options={facilityServices || []}
              name="serviceId"
              required
              shouldOnlySelectValue
              label={t("connectedService") as string}
              placeholder={t("chooseService") as string}
            />
            <div css={[tw`flex gap-4`]}>
              <Form.Select
                options={[
                  { label: t("unlimited"), value: null },
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                  { label: "4", value: 4 },
                  { label: "5", value: 5 },
                ]}
                required
                shouldOnlySelectValue
                label={t("numberOfUsageTimes") as string}
                placeholder={t("enterNumberOfUsages") as string}
                name="maxNoOfUsage"
              />
              <Form.TextInput.Outlined
                required
                label={t("additionOfPoints") as string}
                placeholder={t("enterNumberOfPoints") as string}
                name="awardPoints"
              />
            </div>
            <div css={[tw`flex gap-4`]}>
              <Form.DatePicker
                name="availableFrom"
                required
                label={t("validFrom") as string}
                placeholder={t("chooseDate") as string}
              />
              <Form.DatePicker
                name="expirationDate"
                label={t("validTo") as string}
                placeholder={t("chooseDate") as string}
              />
            </div>
            <Form.TextArea.Outlined
              name="description"
              rows={5}
              label={t("collectingPointsDescription") as string}
              placeholder={t("enterDescription") as string}
            />
          </div>
        )}
        {step === 1 && (
          <div>
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("name")}
            </Typography.BodyXSmall>
            <Typography.BodySmall
              containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
            >
              {watchAll.name || "-"}
            </Typography.BodySmall>
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("connectedServices")}
            </Typography.BodyXSmall>
            <Typography.BodySmall
              containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
            >
              {facilityServices?.find((s) => s.value === watchAll.serviceId)
                ?.label || "-"}
            </Typography.BodySmall>
            <div css={[tw`flex gap-6`]}>
              <div
                css={[
                  tw`flex flex-1 flex-col border-b-1 border-b-gray-200 mb-3 pb-3 `,
                ]}
              >
                <Typography.BodyXSmall
                  containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
                >
                  {t("usage")}
                </Typography.BodyXSmall>
                <Typography.BodySmall>
                  {watchAll?.maxNoOfUsage !== null
                    ? watchAll?.maxNoOfUsage
                    : t("unlimited")}
                </Typography.BodySmall>
              </div>
              <div
                css={[
                  tw`flex flex-1 flex-col border-b-1 border-b-gray-200 mb-3 pb-3 `,
                ]}
              >
                <Typography.BodyXSmall
                  containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
                >
                  {t("points")}
                </Typography.BodyXSmall>
                <Typography.BodySmall>
                  +{watchAll.awardPoints}
                </Typography.BodySmall>
              </div>
            </div>
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("duration")}
            </Typography.BodyXSmall>
            <Typography.BodySmall
              containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
            >
              {format(new Date(watchAll.availableFrom), "dd. MMMM yyyy.")} -{" "}
              {watchAll?.expirationDate
                ? format(new Date(watchAll.expirationDate), "dd. MMMM yyyy.")
                : "/"}
            </Typography.BodySmall>
            <Typography.BodyXSmall
              containerCss={[tw`mb-2 leading-4 font-semibold uppercase`]}
            >
              {t("description")}
            </Typography.BodyXSmall>
            <Typography.BodySmall
              containerCss={[tw`mb-3 pb-3 border-b-1 border-b-gray-200`]}
            >
              {watchAll.description || "-"}
            </Typography.BodySmall>
          </div>
        )}
      </Modal>
    </FormProvider>
  );
};
