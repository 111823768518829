import { Api } from "../../api";
import { useGetPaginated } from "../../api-hooks/useGetPaginated";

export const useCouponPatients = ({ id }: { id: number }) => {
  const {
    coupons: {
      couponControllerGetPatientsActivatedCouponByCouponId,
      couponControllerGetPatientsUsedCouponByCouponId,
    },
  } = new Api();

  const patientsThatUsedCoupon = useGetPaginated({
    id,
    query: couponControllerGetPatientsUsedCouponByCouponId,
    queryKey: ["coupon", id, "used", "patients"],
  });

  const patientsThatActivatedCoupon = useGetPaginated({
    id,
    query: couponControllerGetPatientsActivatedCouponByCouponId,
    queryKey: ["coupon", id, "activated", "patients"],
  });

  return {
    patientsThatUsedCoupon,
    patientsThatActivatedCoupon,
  };
};
