import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { useSearch } from "../../hooks/use-search";
import { isLoggedInState } from "../../state";
import { RequestParams, HttpResponse } from "../api";

interface IPaginatedItems<T> {
  items: T[];
  itemsPerPage: number;
  totalPages: number;
  totalItems: number;
  currentPage: number;
}

interface IUseGetPaginatedProps<T> {
  id: number;
  query: (
    id: number,
    query: { page: number; itemsPerPage: number },
    params?: RequestParams
  ) => Promise<
    AxiosResponse<HttpResponse & { payload?: IPaginatedItems<T> }, any>
  >;
  queryKey: Array<string | number>;
  onSuccess?: ((data: IPaginatedItems<T> | null) => unknown) | undefined;
}

export function useGetPaginated<T>({
  id,
  query,
  queryKey,
  onSuccess,
}: IUseGetPaginatedProps<T>) {
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const [params, actions] = useSearch<{}, keyof T>({
    //@ts-ignore
    orderField: "id",
    page: 1,
    size: 10,
  });

  const qFn = async () => {
    const { data } = await query(id, {
      itemsPerPage: params.size,
      page: params.page,
    });

    if (data.isSuccessful) {
      return data.payload;
    } else {
      toast.error(data.message);
      return null;
    }
  };

  const queryResp = useQuery([...queryKey, params.page, params.size], qFn, {
    enabled: isLoggedIn && !!id,
    onSuccess,
  });
  return { ...queryResp, ...actions, ...params };
}
