import { FocusEventHandler, ReactElement, useCallback } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import {
  IFieldComponentBaseProps,
  IOption,
  ISelectOption,
  Maybe,
  TwinStyle,
} from "../../types";
/** @jsxImportSource @emotion/react */
import ReactSelect, {
  components as Components,
  ControlProps,
  IndicatorsContainerProps,
  InputProps,
  MenuListProps,
  MenuProps,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps,
} from "react-select";

import { css } from "@emotion/react";
import { AiFillCloseCircle } from "react-icons/ai";
import tw from "twin.macro";
import { Typography } from "../../ui/Typograhy";
import { addAsterisk } from "../../utils";
import { FieldLabel } from "../components/FieldLabel";
import { SelectRow } from "./SelectRow";

interface StringArray {
  [index: string]: ISelectOption<any>[];
}

const CustomMenuList = (props: MenuListProps & { footer?: ReactElement }) => {
  const {
    selectOption,
    selectProps: { value, inputValue, isSearchable },
    footer,
  } = props;
  const options = props.options as unknown as ISelectOption<any>[];
  const selectedOption = value as ISelectOption<any>;
  const term = (inputValue ?? "").toLowerCase().trim();
  const filteredOptions = isSearchable
    ? options.filter(({ label, belongsTo }: ISelectOption<any>) => {
        return (
          (label || "").toLowerCase().trim().includes(term) ||
          (belongsTo || "").toLowerCase().trim().includes(term)
        );
      })
    : options;

  const hasHeaders = options.every((op) => !!op.belongsTo);
  //@ts-ignore
  const optionsByHeader = filteredOptions.reduce((prev, curr) => {
    //@ts-ignore
    const currHeaderItems = prev[curr.belongsTo];
    if (!currHeaderItems) {
      //@ts-ignore
      prev[curr.belongsTo] = [curr];
      return prev;
    } else {
      const newHeaderItems = [...currHeaderItems, curr];
      //@ts-ignore
      prev[curr.belongsTo] = newHeaderItems;
      return prev;
    }
  }, {}) as StringArray;

  return (
    <Components.MenuList {...props} tw="shadow-none">
      {filteredOptions.length === 0 ? (
        <div tw="cursor-auto">
          <Typography.BodyMedium containerCss={[tw`text-gray-100 py-1 px-3`]}>
            No results
          </Typography.BodyMedium>
        </div>
      ) : !hasHeaders ? (
        <div css={[tw`cursor-pointer`]}>
          {filteredOptions.map((o: IOption<any>) => (
            <SelectRow
              key={o.value}
              option={o}
              selectOption={selectOption}
              selectedOption={selectedOption}
            />
          ))}
        </div>
      ) : (
        <div>
          {Object.entries(optionsByHeader).map((item) => {
            return (
              <div key={item[0]}>
                <Typography.BodyXSmall
                  containerCss={[
                    tw`px-3 py-2 uppercase text-gray-800 font-600`,
                  ]}
                >
                  {item[0]}
                </Typography.BodyXSmall>
                {item[1].map((o: IOption<any>) => (
                  <SelectRow
                    key={o.value}
                    option={o}
                    selectOption={selectOption}
                    selectedOption={selectedOption}
                  />
                ))}
              </div>
            );
          })}
        </div>
      )}
      {footer}
    </Components.MenuList>
  );
};

const CustomPlaceholder = (props: PlaceholderProps) => {
  return (
    <Typography.BodySmall
      containerCss={[
        css`
          grid-area: 1/1/2/3;
          ${tw`text-gray-200 overflow-hidden ml-0.5`}
        `,
        props.selectProps.isDisabled && tw`text-gray-100`,
      ]}
    >
      {props.selectProps.placeholder}
    </Typography.BodySmall>
  );
};

const CustomIndicatorsContainer = (
  props: IndicatorsContainerProps & { indicatorCss?: Maybe<TwinStyle> }
) => {
  const {
    clearValue,
    hasValue,
    indicatorCss,
    selectProps: { menuIsOpen, isClearable },
  } = props;

  const DropdownIcon = menuIsOpen ? HiChevronUp : HiChevronDown;

  const showClear = isClearable && hasValue;

  return (
    <div css={[tw`flex flex-row items-center gap-x-2`]} {...props.innerProps}>
      {showClear && (
        <AiFillCloseCircle
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onClick={clearValue}
          css={[
            tw`h-6 w-6 text-primary-100 cursor-pointer`,
            props.selectProps.isDisabled && tw`text-gray-100`,
          ]}
        />
      )}
      <DropdownIcon
        css={[
          tw`h-6 w-6 text-gray-600`,
          indicatorCss,
          props.selectProps.isDisabled && tw`text-gray-100`,
        ]}
      />
    </div>
  );
};

const CustomSingleValue = (
  props: SingleValueProps & { singleValueCss?: Maybe<TwinStyle> }
) => {
  const {
    selectProps: { value, isDisabled },
    singleValueCss,
  } = props;
  const selectedOption = value as IOption<any>;

  return (
    <Typography.BodySmall
      containerCss={[
        css`
          grid-area: 1/1/2/3;
        `,
        tw`text-gray-900 truncate max-w-36 ml-0.5`,
        singleValueCss,
        isDisabled && tw`text-gray-100`,
      ]}
    >
      {selectedOption?.label}
    </Typography.BodySmall>
  );
};

const CustomControl = (
  props: ControlProps & { error: Maybe<string>; controlCss: Maybe<TwinStyle> }
) => {
  const { error, isFocused, controlCss } = props;

  return (
    <Components.Control
      {...props}
      className="group"
      css={[
        tw`bg-white border-1 border-gray-300 px-3 py-2 hover:(border-gray-600) rounded-md`,
        error && tw`border-error ring-error-light ring-1 hover:(border-error)`,
        controlCss,
        isFocused && tw`border-white ring-1 ring-primary hover:(text-gray-900)`,
        props.isDisabled && tw`bg-white border-gray-100 cursor-not-allowed`,
      ]}
    />
  );
};

interface ISelectProps<T> extends IFieldComponentBaseProps<ISelectOption<T>> {
  autofocus?: boolean;
  shouldOnlySelectValue?: boolean;
  dataParser?(data: any): any;
  options: ISelectOption<T>[];
  onFocus?: FocusEventHandler<any> | undefined;
  onBlur?: FocusEventHandler<any> | undefined;
  isClearable?: boolean;
  footer?: ReactElement;
  controlCss?: Maybe<TwinStyle>;
  indicatorCss?: Maybe<TwinStyle>;
  singleValueCss?: Maybe<TwinStyle>;
}

export const Select = <T extends Object>(props: ISelectProps<T>) => {
  const {
    error,
    controlCss,
    indicatorCss,
    singleValueCss,
    dataParser = (data) => data,
  } = props;

  const tempValue = props.shouldOnlySelectValue
    ? //@ts-ignore
      props.options.find((op) => dataParser(op.value) === props.value)
    : props.value;

  const CustomControlMemo = useCallback(
    (props: ControlProps) => (
      <CustomControl {...props} error={error} controlCss={controlCss} />
    ),
    [controlCss, error]
  );
  const CustomValueContainerMemo = useCallback(
    (props: ValueContainerProps) => (
      <Components.ValueContainer {...props} tw="p-0 m-0" />
    ),
    []
  );
  const CustomMenuMemo = useCallback(
    (props: MenuProps) => (
      <Components.Menu {...props} tw="shadow-lg rounded-md z-10 bg-white" />
    ),
    []
  );

  const CustomIndicatorsMemo = useCallback(
    (props: IndicatorsContainerProps & { indicatorCss?: Maybe<TwinStyle> }) => (
      <CustomIndicatorsContainer {...props} indicatorCss={indicatorCss} />
    ),
    [indicatorCss]
  );

  const CustomSingleValueMemo = useCallback(
    (props: SingleValueProps & { singleValueCss?: Maybe<TwinStyle> }) => (
      <CustomSingleValue {...props} singleValueCss={singleValueCss} />
    ),
    [singleValueCss]
  );

  const CustomInputMemo = useCallback(
    (props: InputProps) => {
      return (
        <Components.Input
          {...props}
          css={singleValueCss}
          tw="m-0 p-0 caret-primary"
        />
      );
    },
    [singleValueCss]
  );

  return (
    <div css={[props.containerCss]}>
      {props.label && (
        <FieldLabel isInErrorState={!!props.error} containerCss={[tw`mb-1`]}>
          {addAsterisk(props.label, props.required)}
        </FieldLabel>
      )}
      <ReactSelect
        value={tempValue}
        isClearable={props.isClearable}
        isSearchable
        autoFocus={props.autofocus}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        isDisabled={props.disabled}
        onChange={(option) => props.onChange(option as IOption<T>)}
        placeholder={props.placeholder}
        options={props.options as any}
        styles={
          // STATEMENT: How do I override specific style?
          // Since we override specific components in this template some of the
          // options provided in the "styles" object may not work.
          // It's discouraged to use this object. Please use component override.
          {
            // input: (provided) => ({
            //   ...provided,
            //   "input:focus": {
            //     boxShadow: "none",
            //   },
            // }),
          }
        }
        // STATEMENT: How these overrides work?
        // Find here more: https://react-select.com/components
        // If you have any problem overriding any property, it may be best to
        // directly check the source here: https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/Select.tsx
        components={{
          // @ts-ignore
          Control: CustomControlMemo,
          // @ts-ignore
          ValueContainer: CustomValueContainerMemo,
          // @ts-ignore
          SingleValue: CustomSingleValueMemo,
          // @ts-ignore
          Placeholder: CustomPlaceholder,
          // @ts-ignore
          IndicatorsContainer: CustomIndicatorsMemo,
          // @ts-ignore
          Input: CustomInputMemo,
          // @ts-ignore
          MenuList: (menuProps) => (
            // @ts-ignore
            <CustomMenuList {...menuProps} footer={props.footer} />
          ),
          // @ts-ignore
          Menu: CustomMenuMemo,
          // SelectContainer: (props) => {
          //   return <Components.SelectContainer {...props} />;
          // },
          // MenuPortal: (props) => {
          //   return <Components.MenuPortal {...props} />;
          // },
          // // NOT VISIBLE SINCE MENU LIST IS OVERRIDDEN
          // NoOptionsMessage: (props) => {
          //   return <Components.NoOptionsMessage {...props} />;
          // },
          // // NOT VISIBLE SINCE MENU LIST IS OVERRIDDEN
          // Option: (props) => {
          //   return <Components.Option {...props} />;
          // },
          // // NOT USED SINCE ALL OPTIONS ARE STATIC
          // LoadingMessage: (props) => {
          //   return <Components.LoadingMessage {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // ClearIndicator: (props) => {
          //   return <Components.ClearIndicator {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // DropdownIndicator: (props) => {
          //   return <Components.DropdownIndicator {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // LoadingIndicator: (props) => {
          //   return <Components.LoadingIndicator {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // IndicatorSeparator: (props) => {
          //   return <Components.IndicatorSeparator {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // CrossIcon: (props) => {
          //   return <Components.CrossIcon {...props} />;
          // },
          // // NOT VISIBLE SINCE INDICATORS CONTAINER IS OVERRIDDEN
          // DownChevron: (props) => {
          //   return <Components.DownChevron {...props} />;
          // },
        }}
      />
    </div>
  );
};
