import { useQueryClient } from "@tanstack/react-query";
import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";
import { useUpdate } from "../../api-hooks/useUpdate";

export const useEmployee = ({ id }: { id: number }) => {
  const {
    employees: {
      employeeControllerGetEmployeeById,
      employeeControllerUpdateEmployee,
    },
  } = new Api();
  const client = useQueryClient();
  const employee = useGetById({
    id,
    query: employeeControllerGetEmployeeById,
    queryKey: ["employee", id],
  });

  const { mutateAsync: updateEmployee } = useUpdate(
    Number(id),
    employeeControllerUpdateEmployee,
    ["employee", id],
    async () => {
      await client.refetchQueries(["employee", id]);
    }
  );

  return {
    employee,
    updateEmployee,
  };
};
