/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import * as yup from "yup";
import { useOrganisationPatients } from "../../../api/hooks/organisations/useOrganisationPatients";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { Nullable } from "../../../types";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { Modal } from "../../../ui/popups/Modal";
import { errorMessages } from "../../../utils";

const schema = yup.object().shape({
  patient: yup
    .number()
    .typeError(errorMessages.number)
    .nullable()
    .required(errorMessages.required),
});

type IForm = Nullable<yup.InferType<typeof schema>>;

interface ITextInputModalProps {
  open: boolean;
  onClose: () => void;
  serviceId: number;
  departmentId: number;
}

export const SearchPatientModal = ({
  open,
  onClose,
  serviceId,
  departmentId,
}: ITextInputModalProps) => {
  const {
    patients: { data },
  } = useOrganisationPatients();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      patient: null,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      onClose();
      navigate("/appointments", {
        state: { patientId: values.patient, serviceId, departmentId },
      });
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={t("searchPatients") as string}
        footerChildren={
          <div css={[tw`flex gap-3`]}>
            {/* <Button.Outlined
              containerCss={[tw`w-full`]}
              onClick={() => {}}
              disabled={!!patientWatch}
            >
              {t("addNewPatient")}
            </Button.Outlined> */}
            <Button.Contained containerCss={[tw`w-full`]} onClick={onSubmit}>
              {t("addAppointment")}
            </Button.Contained>
          </div>
        }
      >
        <Form.Select
          options={(data?.patients || []).map((p) => ({
            label: `${p.firstName} ${p.lastName}`,
            value: p.id,
          }))}
          required
          shouldOnlySelectValue
          placeholder={t("searchPatientsByNameAndSurname") as string}
          name="patient"
        />
      </Modal>
    </FormProvider>
  );
};
