import { Api } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";

export const useRoom = ({ id }: { id: number }) => {
  const {
    rooms: { roomControllerGetRoom, roomControllerGetRoomEmployees },
  } = new Api();

  const room = useGetById({
    id,
    query: roomControllerGetRoom,
    queryKey: ["room", id],
  });

  const roomEmployees = useGetById({
    id,
    query: roomControllerGetRoomEmployees,
    queryKey: ["roomEmployees", id],
  });

  return {
    room,
    roomEmployees,
  };
};
