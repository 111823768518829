import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import * as yup from "yup";
import {
  CreateCategoryRequestDto,
  CreateCategoryResponseDto,
} from "../../../api/api";
import { useCategories } from "../../../api/hooks/categories/useCategories";
import { useCategory } from "../../../api/hooks/categories/useCategory";
import { Form } from "../../../fields/form";
import { scrollToError } from "../../../fields/form/utils";
import { CategoriesWithServicesWithParentId } from "../../../types";
import { Button } from "../../../ui/buttons/Button";
import { toast } from "../../../ui/indicators/Toast";
import { IModalProps, Modal } from "../../../ui/popups/Modal";
import { errorMessages } from "../../../utils";
/** @jsxImportSource @emotion/react */

const schema: yup.SchemaOf<CreateCategoryRequestDto> = yup
  .object()
  .shape({
    name: yup.string().required(errorMessages.required),
    departmentId: yup
      .number()
      .typeError(errorMessages.number)
      .required(errorMessages.required),
  })
  .required(errorMessages.required);

type IForm = yup.InferType<typeof schema>;

export const ManageCategoryModal = ({
  open,
  onClose,
  departmentId,
  category,
}: Omit<IModalProps, "label" | "footerChildren"> & {
  departmentId: number;
  category?: CategoriesWithServicesWithParentId;
}) => {
  const { createCategory } = useCategories();
  const { updateCategory } = useCategory({ id: category?.id! });
  const { t } = useTranslation();
  const isEdit = !!category?.id;

  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      name: category?.name || undefined,
      departmentId,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (!!category?.name && category?.name !== methods.getValues("name")) {
      methods.setValue("name", category?.name);
    }
  }, [category?.name, methods]);

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      if (isEdit) {
        await updateCategory({ requestData: { name: values.name } });
      } else {
        await createCategory({
          requestData: { departmentId, name: values.name },
        });
      }

      onClose();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e?.response.data.message);
    }
  }, scrollToError);

  return (
    <FormProvider {...methods}>
      <Modal
        open={open}
        onClose={onClose}
        label={(isEdit ? t("editCategory") : t("addNewCategory")) as string}
        footerChildren={
          <Button.Contained containerCss={[tw`w-full`]} onClick={onSubmit}>
            {isEdit ? t("save") : t("addCategory")}
          </Button.Contained>
        }
      >
        <Form.TextInput.Outlined
          required
          containerCss={[tw`flex-3`]}
          label={t("categoryName") as string}
          placeholder={t("categoryPlaceholder") as string}
          name="name"
        />
      </Modal>
    </FormProvider>
  );
};
