import { useFormError, useFormField } from "./hooks";

import { FieldError } from "../components/FieldError";
import {
  IFormFieldComponentBaseProps,
  ISelectOption,
  Maybe,
  TwinStyle,
} from "../../types";
import { TimePicker } from "../controlled/TimePicker";
import tw from "twin.macro";

/** @jsxImportSource @emotion/react */

export interface IFormTimePickerProps extends IFormFieldComponentBaseProps {
  timeIntervals?: number;
  autofocus?: boolean;
  timePickerContainerCss?: Maybe<TwinStyle>;
  shouldOnlySelectValue?: boolean;
  dataParser?(data: any): any;
  customOptions?: ISelectOption<Date>[];
}

export const FormTimePicker = (props: IFormTimePickerProps) => {
  const field = useFormField(props.name);
  const error = useFormError(props.name);

  return (
    <div css={[tw`flex-1 w-full`, props.containerCss]}>
      <TimePicker
        disabled={props.disabled}
        value={field.value}
        onChange={(option) =>
          field.onChange(
            props.shouldOnlySelectValue
              ? !!props.dataParser
                ? props?.dataParser(option?.value)
                : option?.value
              : option
          )
        }
        label={props.label}
        error={error}
        customOptions={props.customOptions}
        dataParser={props.dataParser}
        autofocus={props.autofocus}
        timeIntervals={props.timeIntervals}
        required={props.required}
        placeholder={props.placeholder}
        containerCss={props.timePickerContainerCss}
        shouldOnlySelectValue={props.shouldOnlySelectValue}
      />
      <FieldError name={props.name} containerCss={[tw`ml-4`]} />
    </div>
  );
};
