/** @jsxImportSource @emotion/react */

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiEdit2Line } from "react-icons/ri";
import { useParams } from "react-router-dom";
import tw from "twin.macro";
import { Api } from "../../api/api";
import { useFacility } from "../../api/hooks/facilities/useFacility";
import { useFacilityWorkingHours } from "../../api/hooks/facilities/useFacilityWorkingHours";
import { useMe } from "../../api/hooks/user/useMe";
import { PageContainer } from "../../components/layout/PageContainer";
import { Button } from "../../ui/buttons/Button";
import { Divider } from "../../ui/layout/Divider";
import { Tabs } from "../../ui/layout/Tabs";
import { EditWeekWorkTimeModal } from "./modals/EditWeekWorkTimeModal";
import { AboutFacilityTab } from "./tabs/AboutFacilityTab";
import { FacilityScheduleTab } from "./tabs/FacilityScheduleTab";

export const FacilityPage = () => {
  let { id } = useParams();
  const { facility } = useFacility({ id: Number(id) });
  const { me } = useMe();
  const {
    facilityWorkingHours: { data: facilityWorkingHours },
    updateFacilityDefaultWorkingHours,
  } = useFacilityWorkingHours({ id: Number(id) });

  const [activeTab, setActiveTab] = useState(0);

  const [
    isEditWorkWeekForPatientsTimeModalOpen,
    setIsEditWorkWeekForPatientsTimeModalOpen,
  ] = useState(false);

  const { t } = useTranslation();
  return (
    <PageContainer>
      <Tabs
        onTabChange={setActiveTab}
        defaultTab={0}
        rightComponent={
          activeTab === 0 && me.role?.permissions?.FACILITIES?.UPDATE ? (
            <Button.Contained
              lead={RiEdit2Line}
              onClick={() => setIsEditWorkWeekForPatientsTimeModalOpen(true)}
            >
              {t("editWorkTime")}
            </Button.Contained>
          ) : (
            <div></div>
          )
        }
      >
        <Tabs.Item text={t("workTime")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <FacilityScheduleTab
            workTimes={
              facilityWorkingHours?.defaultWorkingHoursScheduleWithShifts
            }
            activeTab={activeTab}
          />
          {!!facilityWorkingHours?.defaultWorkingHoursScheduleWithShifts && (
            <EditWeekWorkTimeModal
              onClose={() => setIsEditWorkWeekForPatientsTimeModalOpen(false)}
              open={isEditWorkWeekForPatientsTimeModalOpen}
              workingHours={
                facilityWorkingHours?.defaultWorkingHoursScheduleWithShifts
              }
              updateWorkingHours={updateFacilityDefaultWorkingHours}
            />
          )}
        </Tabs.Item>
        {/* <Tabs.Item text={t("workTimeForEmployees")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <FacilityScheduleTab workTimes={workTimes} activeTab={activeTab} />
          <EditWeekWorkTimeModal
            key="employees"
            onClose={() => setIsEditWorkWeekForPatientsTimeModalOpen(false)}
            open={isEditWorkWeekForPatientsTimeModalOpen}
            workingHours={workTimes}
          />
        </Tabs.Item>
        <Tabs.Item text={t("workTimeForPatients")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <FacilityWorkTimeTab activeTab={activeTab} schedule={schedule} />
          <EditWeekWorkTimeModal
            key="patients"
            onClose={() => setIsEditWorkWeekForEmployeesTimeModalOpen(false)}
            open={isEditWorkWeekForEmployeesTimeModalOpen}
            workingHours={schedule}
          />
        </Tabs.Item> */}
        <Tabs.Item text={t("aboutFacility")}>
          <Divider type="vertical" containerCss={[tw`bg-gray-200 h-0.25`]} />
          <AboutFacilityTab facility={facility!} />
        </Tabs.Item>
      </Tabs>
    </PageContainer>
  );
};
