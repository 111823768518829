import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getHours, getMinutes } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RiAddFill, RiIndeterminateCircleLine } from "react-icons/ri";
import { useRecoilValue } from "recoil";
import tw from "twin.macro";
import * as yup from "yup";
import {
  Api,
  CreateEmployeeEmployeeImage,
  CreateEmployeeRequestDto,
  PutEmployeeDefaultWorkingHoursForFacilityBodyRequestDto,
  RequestEmployeeImageLinksResponseDto,
} from "../../../api/api";
import { useEmployee } from "../../../api/hooks/employees/useEmployee";
import { useEmployees } from "../../../api/hooks/employees/useEmployees";
import { useFacilityDepartments } from "../../../api/hooks/facilities/useFacilityDepartments";
import { useFileUpload } from "../../../api/hooks/file-upload/useFileUpload";
import { useOrganisationRoles } from "../../../api/hooks/organisations/useOrganisationRoles";
import { facilityEmployeesPaginatedKey } from "../../../api/query-keys";
import { Form } from "../../../fields/form";
import { omit, scrollToError } from "../../../fields/form/utils";
import { selectedFacilityIdState } from "../../../state";
import { ISelectOption } from "../../../types";
import { Button } from "../../../ui/buttons/Button";
import { Dropzone } from "../../../ui/Dropzone";
import { toast } from "../../../ui/indicators/Toast";
import { Modal } from "../../../ui/popups/Modal";
import { Typography } from "../../../ui/Typograhy";
import { errorMessages } from "../../../utils";
import { uploadFile } from "../../../utils/upload-file";
import { ProfileImageModal } from "./ProfileImageModal";
/** @jsxImportSource @emotion/react */

const schema =
  // : yup.SchemaOf<
  //   CreateEmployeeRequestDto &
  //     PutEmployeeDefaultWorkingHoursForFacilityBodyRequestDto
  // >
  yup.object().shape({
    firstName: yup.string().required(errorMessages.required),
    lastName: yup.string().required(errorMessages.required),
    email: yup.string().required(errorMessages.required),
    password: yup.string(),
    birthDate: yup.string().required(errorMessages.required),
    roleId: yup.number().required(errorMessages.required),
    departmentIds: yup
      .array()
      .of(
        yup
          .number()
          .typeError(errorMessages.number)
          .required(errorMessages.required)
      )
      .required(errorMessages.required)
      .min(1, errorMessages.min),
    image: yup.object(),
    workingHours: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            daysOfTheWeek: yup.object().required(errorMessages.required),
            openingTime: yup
              .number()
              .typeError(errorMessages.number)
              .required(errorMessages.required),
            closingTime: yup
              .number()
              .typeError(errorMessages.number)
              .required(errorMessages.required),
          })
          .required(errorMessages.required)
      )
      .required(errorMessages.required),
  });

type IForm = yup.InferType<typeof schema>;

interface IManageEmployeeModal {
  open: boolean;
  onClose: () => void;
  employeeId?: number | null;
}

const stepDescriptions = ["enterEmployeeInformation", "enterEmployeeWorkTime"];

const triggerByStep = [
  [
    "firstName",
    "lastName",
    "email",
    "password",
    "roleId",
    "birthDate",
    "departmentIds",
  ],
  "workingHours",
];

export const ManageEmployeeModal = ({
  open,
  onClose,
  employeeId,
}: IManageEmployeeModal) => {
  const [employeeImage, setEmployeeImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [isProfileImageModalOpen, setIsProfileImageModalOpen] = useState(false);
  const {
    roles: { data: roles },
  } = useOrganisationRoles();
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const [step, setStep] = useState(0);
  const { requestEmployeeImageLinks } = useFileUpload();
  const [imageForUpload, setImageForUpload] = useState<File>();

  const client = useQueryClient();
  const {
    employees: { employeeControllerPutEmployeeDefaultWorkingHoursForFacility },
  } = new Api();
  const {
    facilityDepartments: { data: facilityDepartments },
  } = useFacilityDepartments();
  const { createEmployee } = useEmployees();
  const {
    updateEmployee,
    employee: { data: employee, isFetched, isSuccess },
  } = useEmployee({ id: Number(employeeId)! });
  const { t } = useTranslation();
  const mappedRoles = (roles?.roles || []).map((r) => ({
    label: r?.name,
    value: r?.id,
  }));
  const methods = useForm<IForm>({
    //@ts-ignore
    defaultValues: {
      firstName: employee?.firstName ?? undefined,
      lastName: employee?.lastName ?? undefined,
      email: employee?.email ?? undefined,
      password: undefined,
      roleId: employee?.role?.id ?? undefined,
      //@ts-ignore
      birthDate: new Date(employee?.birthDate || new Date()),
      departmentIds: employee?.departmentIds.map((item) => Number(item)) || [],
      workingHours: [
        {
          openingTime: 8 * 60,
          closingTime: 16 * 60,
          daysOfTheWeek: {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
          },
        },
      ],
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (employee === undefined) {
      return; // loading
    }

    methods.reset({
      ...methods.getValues(),
      ...employee,
      //@ts-ignore
      birthDate: new Date(employee?.birthDate || new Date()),
    });
  }, [employee, methods]);

  const { fields, append, remove } = useFieldArray({
    control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
    //@ts-ignore
    name: "workingHours", // unique name for your Field Array
  });

  const { mutateAsync: updateEmployeeDefaultWorkingHours } = useMutation(
    ({
      employeeId,
      workingHours,
    }: PutEmployeeDefaultWorkingHoursForFacilityBodyRequestDto & {
      employeeId: number;
    }) =>
      employeeControllerPutEmployeeDefaultWorkingHoursForFacility(
        employeeId,
        Number(selectedFacilityId),
        { workingHours }
      ),
    {
      onSuccess: async ({ request }, variables) => {
        await client.refetchQueries([
          "employee",
          variables.employeeId,
          "facility",
          Number(selectedFacilityId),
          "working-hours",
        ]);
        await client.refetchQueries(
          facilityEmployeesPaginatedKey(selectedFacilityId)
        );
      },
    }
  );

  const isEdit = !!employee?.id;
  const parsedDepartments = (facilityDepartments?.departments || []).map(
    (d) => ({
      label: d.name,
      value: d.id,
    })
  ) as ISelectOption<any>[];

  const onSubmit = methods.handleSubmit(async (values) => {
    const requestData = omit(
      values,
      "workingHours"
    ) as CreateEmployeeRequestDto;
    try {
      let employeeImageWithLinks: CreateEmployeeEmployeeImage | undefined =
        undefined;
      if (imageForUpload) {
        const uploadData = (await requestEmployeeImageLinks({
          fileName: { fileName: imageForUpload.name },
        })) as RequestEmployeeImageLinksResponseDto;
        await uploadFile(uploadData.uploadUrl, imageForUpload);
        employeeImageWithLinks = {
          name: imageForUpload.name,
          downloadUrl: uploadData.downloadUrl,
          fileUUID: uploadData.fileUUID,
        };
      }
      if (!isEdit) {
        const resp = await createEmployee({
          requestData: {
            ...requestData,
            birthDate: new Date(requestData.birthDate).toISOString(),
            image: employeeImageWithLinks ? employeeImageWithLinks : undefined,
          },
        });
        if (resp?.id) {
          await updateEmployeeDefaultWorkingHours({
            employeeId: resp.id,
            workingHours: values.workingHours || [],
          });
        }
      } else {
        await updateEmployee({
          requestData: {
            ...requestData,
            birthDate: new Date(requestData.birthDate).toISOString(),
          },
        });
      }
      onClose();
      setEmployeeImage(null);
      methods.reset();
    } catch (e) {
      console.error(e);
      //@ts-ignore
      toast.error(e.response.data.message);
    }
  }, scrollToError);

  const incrementStep = useCallback(async () => {
    //@ts-ignore
    const isCorrect = await methods.trigger(triggerByStep[step]);

    if (isCorrect) {
      setStep((prevStep) =>
        Math.min(prevStep + 1, stepDescriptions.length - 1)
      );
    }
  }, [methods, step]);

  const decrementStep = useCallback(() => {
    setStep((prevStep) => Math.max(prevStep - 1, 0));
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <Modal
          open={open}
          onClose={() => {
            onClose();
            setEmployeeImage(null);
          }}
          label={
            isEdit
              ? (t("editEmployeeData") as string)
              : (t("addNewEmploye") as string)
          }
          footerChildren={
            isEdit ? (
              <div css={[tw`flex justify-between gap-3`]}>
                {/* <Button.Outlined containerCss={[tw`flex-1`]} onClick={() => {}}>
                  {t("archive")}
                </Button.Outlined> */}
                <Button.Contained
                  containerCss={[tw`flex-5`]}
                  onClick={onSubmit}
                >
                  {t("save")}
                </Button.Contained>
              </div>
            ) : (
              <div css={[tw`flex  gap-3`]}>
                <Button.Outlined
                  containerCss={[tw`flex-1`]}
                  onClick={decrementStep}
                  disabled={step === 0}
                >
                  {t("back")}
                </Button.Outlined>
                <Button.Contained
                  containerCss={[tw`flex-4`]}
                  onClick={step === 0 ? incrementStep : onSubmit}
                >
                  {step === 0 ? t("next") : t("addNewEmployee")}
                </Button.Contained>
              </div>
            )
          }
        >
          <Typography.BodyMedium containerCss={[tw`mb-6`]}>
            {t("step")} {step + 1}.{" "}
            <Typography.BodyMedium containerCss={[tw`font-700 inline`]}>
              {t(stepDescriptions[step])}
            </Typography.BodyMedium>
          </Typography.BodyMedium>
          {step === 0 && (
            <div>
              <div css={[tw`flex gap-4`]}>
                <Form.TextInput.Outlined
                  required
                  label={t("firstName") as string}
                  placeholder={t("firstName") as string}
                  name="firstName"
                />
                <Form.TextInput.Outlined
                  required
                  label={t("lastName") as string}
                  placeholder={t("lastName") as string}
                  name="lastName"
                />
              </div>
              <div css={[tw`flex gap-4`]}>
                <Form.TextInput.Outlined
                  required
                  label={t("email") as string}
                  placeholder={t("email") as string}
                  name="email"
                />
                {!isEdit ? (
                  <Form.TextInput.Outlined
                    required
                    label={t("password") as string}
                    placeholder={t("password") as string}
                    name="password"
                  />
                ) : (
                  <Form.DatePicker
                    required
                    label={t("birthDate") as string}
                    placeholder={t("enterBirthDateDate") as string}
                    name="birthDate"
                  />
                )}
              </div>
              <div css={[tw`flex gap-4`]}>
                <Form.DatePicker
                  required
                  label={t("birthDate") as string}
                  placeholder={t("enterBirthDateDate") as string}
                  name="birthDate"
                />
                <Form.Select
                  options={mappedRoles}
                  required
                  label={t("role") as string}
                  shouldOnlySelectValue
                  placeholder={t("role") as string}
                  name="roleId"
                />
              </div>
              <Form.MultiSelect
                options={parsedDepartments || []}
                shouldOnlySelectValue
                name="departmentIds"
                required
                label={t("department") as string}
                placeholder={t("department") as string}
              />

              <Dropzone
                multiple={false}
                title={t("addOrDragDropPhoto") as string}
                subtitle={t("pngOrJpg10Mb") as string}
                maxFileSize={10}
                containerCss={[tw`p-1 mb-6`]}
                accept={{
                  "image/png": [".png"],
                  "image/jpg": [".jpg"],
                }}
                onUpload={(files) => setImageForUpload(files[0])}
              />
            </div>
          )}
          {step === 1 && (
            <div>
              {fields.map((field, index) => {
                return (
                  <div css={[tw`bg-white p-6 rounded-md mb-6`]} key={field.id}>
                    <div css={[tw`flex justify-between gap-6 items-center`]}>
                      <Form.TimePicker
                        containerCss={[tw`flex-3`]}
                        placeholder="08:00"
                        shouldOnlySelectValue
                        dataParser={(data) =>
                          getMinutes(data) + getHours(data) * 60
                        }
                        name={`workingHours.${index}.openingTime`}
                        label={t("opening") as string}
                      />
                      <Form.TimePicker
                        containerCss={[tw`flex-3`]}
                        // disabled={field.notWorking}
                        placeholder="16:00"
                        shouldOnlySelectValue
                        dataParser={(data) =>
                          getMinutes(data) + getHours(data) * 60
                        }
                        label={t("closing") as string}
                        name={`workingHours.${index}.closingTime`}
                      />
                      <Button.Text
                        lead={RiIndeterminateCircleLine}
                        onClick={() => remove(index)}
                        textCss={[tw`text-error`]}
                        leadCss={[tw`text-error`]}
                        containerCss={[tw`hover:(bg-error-light)`]}
                        disabled={fields.length === 1}
                      >
                        {t("delete")}
                      </Button.Text>
                    </div>
                    <Typography.BodySmall>{t("pickDays")}</Typography.BodySmall>
                    <div css={[tw`flex gap-6 mt-3`]}>
                      {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map(
                        (day, i) => {
                          return (
                            <div
                              css={[tw`flex items-center gap-2`]}
                              key={day + i + index}
                            >
                              <Form.Checkbox
                                name={`workingHours.${index}.daysOfTheWeek.${
                                  i + 1
                                }`}
                                label={t(day) as string}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              })}
              <div css={[tw`flex justify-center items-center py-6`]}>
                <Button.Text
                  lead={RiAddFill}
                  onClick={() =>
                    append({
                      openingTime: 8 * 60,
                      closingTime: 16 * 60,
                      daysOfTheWeek: {
                        //@ts-ignore
                        1: false,
                        2: false,
                        3: false,
                        4: false,
                        5: false,
                        6: false,
                        7: false,
                      },
                    })
                  }
                  textCss={[tw`text-gray-900`]}
                  leadCss={[tw`text-gray-900`]}
                >
                  {t("addShift")}
                </Button.Text>
              </div>
            </div>
          )}
        </Modal>
      </FormProvider>
      <ProfileImageModal
        open={isProfileImageModalOpen}
        onClose={() => setIsProfileImageModalOpen(false)}
        image={employeeImage?.toString()}
        onSubmitImage={setEmployeeImage}
      />
    </>
  );
};
