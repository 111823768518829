import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { refetchAllContainingQueryKey } from "../../fields/form/utils";
import { RequestParams, HttpResponse } from "../api";

export function useUpdate<T, R>(
  id: number,
  mutation: (
    id: number,
    data: T,
    params?: RequestParams
  ) => Promise<AxiosResponse<HttpResponse & { payload?: R }, any>>,
  queryKey: Array<string | number>,
  onSuccess?:
    | ((
        data: R | null,
        variables: {
          requestData: T;
        },
        context: unknown
      ) => unknown)
    | undefined
) {
  const client = useQueryClient();
  const mutationResp = useMutation(
    async ({ requestData }: { requestData: T }) => {
      const { data } = await mutation(id, requestData);

      if (data.isSuccessful) {
        return data.payload!;
      }
      toast.error(data.message);
      return null;
    },
    {
      onSuccess: !!onSuccess
        ? onSuccess
        : () => refetchAllContainingQueryKey({ client, queryKey }),
    }
  );
  return mutationResp;
}
