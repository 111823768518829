/** @jsxImportSource @emotion/react */

import "twin.macro";

import {
  FocusEventHandler,
  KeyboardEventHandler,
  PropsWithChildren,
} from "react";
import { IFieldComponentBaseProps, Maybe, TwinStyle } from "../../types";

import { FieldLabel } from "../components/FieldLabel";
import { addAsterisk } from "../../utils";
import tw from "twin.macro";

interface IBaseTextAreaProps extends IFieldComponentBaseProps<string> {
  rows?: number;
  autofocus?: boolean;
  inputCss?: Maybe<TwinStyle>;
  textCss?: Maybe<TwinStyle>;
  disabledCss?: Maybe<TwinStyle>;
  errorCss?: Maybe<TwinStyle>;
  onFocus?: FocusEventHandler<any> | undefined;
  onBlur?: FocusEventHandler<any> | undefined;
  onKeyDown?: KeyboardEventHandler<any> | undefined;
  onKeyUp?: KeyboardEventHandler<any> | undefined;
}
const BaseTextArea = (props: PropsWithChildren<IBaseTextAreaProps>) => {
  return (
    <div css={[tw`flex flex-col`, props.containerCss]}>
      <FieldLabel isInErrorState={!!props.error} containerCss={[tw`mb-1`]}>
        {addAsterisk(props.label, props.required)}
      </FieldLabel>
      <textarea
        value={props.value ?? ""}
        rows={props.rows ?? 5}
        autoFocus={props.autofocus}
        placeholder={props.placeholder}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        disabled={props.disabled}
        onKeyDown={props.onKeyDown}
        onKeyUp={props.onKeyUp}
        onChange={(e) => props.onChange(e.target.value)}
        css={[
          tw`py-3 px-4`,
          props.inputCss,
          props.textCss,
          props.error && props.errorCss,
          props.disabled && tw`cursor-not-allowed`,
          props.disabled && props.disabledCss,
        ]}
      />
    </div>
  );
};

export type ITextAreaProps = Omit<
  IBaseTextAreaProps,
  "inputCss" | "errorCss" | "textCss" | "disabledCss"
>;

export const TextArea = {
  Contained: (props: PropsWithChildren<ITextAreaProps>) => {
    return (
      <BaseTextArea
        {...props}
        inputCss={[
          tw`rounded-md bg-gray-400 border-1 border-transparent`,
          tw`focus:(border-1 bg-gray-400 border-primary-100 ring-2 ring-primary-400 outline-none)`,
          !props.error && tw`hover:bg-primary-500`,
        ]}
        errorCss={[
          tw`border-error`,
          tw`focus:(border-1 bg-gray-400 border-error ring-2 ring-error-light outline-none)`,
        ]}
        textCss={[
          tw`text-body-small font-400 text-gray placeholder:(text-gray-200)`,
          props.disabled && tw`text-gray-300 placeholder:text-gray-300`,
        ]}
        disabledCss={[tw`bg-gray-400 hover:(bg-gray-400)`]}
      >
        {props.children}
      </BaseTextArea>
    );
  },
  Underlined: (props: PropsWithChildren<ITextAreaProps>) => {
    const { error, value, ...rest } = props;

    const hasValue = (value ?? "").trim().length > 0;

    return (
      <BaseTextArea
        {...rest}
        error={error}
        value={value}
        inputCss={[
          tw`rounded-t-md bg-gray-400 border-b-1 border-b-gray-300`,
          hasValue && tw`border-b-1 border-b-primary-100`,
          tw`focus:(border-b-2 bg-gray-400 border-primary-100 outline-none)`,
          !error &&
            !hasValue &&
            tw`hover:(bg-primary-500 border-b-primary-300 border-b-1)`,
        ]}
        errorCss={[
          tw`border-error border-b-2 bg-gray-400 focus:(outline-none border-error)`,
        ]}
        textCss={[
          tw`text-body-small font-400 text-gray placeholder:(text-gray-200)`,
          props.disabled && tw`text-gray-300 placeholder:text-gray-300`,
        ]}
        disabledCss={[tw`bg-gray-400  border-b-gray-300 hover:(bg-gray-400) `]}
      >
        {props.children}
      </BaseTextArea>
    );
  },
  Outlined: (props: PropsWithChildren<ITextAreaProps>) => {
    return (
      <BaseTextArea
        {...props}
        inputCss={[
          tw`transition-all rounded-md bg-white border-1 border-gray-300`,
          tw`hover:(border-gray-600)`,
          tw`focus:(border-1 bg-white border-white ring-1 ring-primary outline-none hover:(ring-2))`,
        ]}
        errorCss={[
          tw`border-error`,
          tw`focus:(border-1 bg-white border-error ring-1 ring-error-light outline-none hover:(ring-2))`,
        ]}
        textCss={[
          tw`text-body-small font-400 text-gray placeholder:(text-gray-200)`,
          props.disabled && tw`text-gray-300 placeholder:text-gray-300`,
        ]}
        disabledCss={[tw`bg-gray-400 hover:(bg-gray-400)`]}
      >
        {props.children}
      </BaseTextArea>
    );
  },
};
