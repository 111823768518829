import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Api, PutFacilityDefaultWorkingHoursBodyRequestDto } from "../../api";
import { useGetById } from "../../api-hooks/useGetById";
import { useUpdate } from "../../api-hooks/useUpdate";

export const useFacilityWorkingHours = ({ id }: { id: number }) => {
  const {
    facilities: {
      facilityControllerGetFacilityDefaultWorkingHours,
      facilityControllerPutFacilityDefaultWorkingHours,
      facilityControllerPutFacilityExceptionWorkingHours,
    },
  } = new Api();
  const client = useQueryClient();
  const facilityWorkingHours = useGetById({
    id,
    query: facilityControllerGetFacilityDefaultWorkingHours,
    queryKey: ["facility", id, "working-hours"],
  });

  // const facilityExceptionWorkingHours = useGetById({
  //   id,
  //   query: facilityControllerGetFacilityExceptionWorkingHours,
  //   queryKey: ["facility", id, "working-hours", 'exception'],
  //   queryParams: {},
  // });

  const { mutateAsync: updateFacilityDefaultWorkingHours } = useMutation(
    ({ workingHours }: PutFacilityDefaultWorkingHoursBodyRequestDto) =>
      facilityControllerPutFacilityDefaultWorkingHours(id, { workingHours }),
    {
      onSuccess: () => client.refetchQueries(["facility", id, "working-hours"]),
    }
  );

  const { mutateAsync: updateFacilityExceptionWorkingHours } = useUpdate(
    id,
    facilityControllerPutFacilityExceptionWorkingHours,
    ["facility", id, "working-hours", "exception"]
  );

  return {
    updateFacilityDefaultWorkingHours,
    updateFacilityExceptionWorkingHours,
    facilityWorkingHours,
  };
};
