import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import {
  hasSeenMessageState,
  isLoggedInState,
  selectedFacilityIdState,
} from "../../../state";
import { toast } from "../../../ui/indicators/Toast";
import { Api } from "../../api";

export const useLogout = () => {
  const {
    employees: { employeeControllerLogoutEmployee },
    patients: { patientControllerLogoutPatient },
  } = new Api();
  const [, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const queryClient = useQueryClient();
  const [, setSelectedFacilityId] = useRecoilState(selectedFacilityIdState);
  const [, setHasSeenMessageState] = useRecoilState(hasSeenMessageState);
  const { mutateAsync: employeeLogout } = useMutation(
    async () => {
      const { data } = await employeeControllerLogoutEmployee();
      if (data.isSuccessful) {
        return data.payload;
      } else {
        toast.error(data.message);
        return null;
      }
    },
    {
      onSuccess: () => {
        setIsLoggedIn(false);
        setSelectedFacilityId(null);
        setHasSeenMessageState(false);
        queryClient.invalidateQueries();
      },
    }
  );

  const { mutateAsync: patientLogout } = useMutation(
    async () => {
      const { data } = await patientControllerLogoutPatient();
      if (data.isSuccessful) {
        return data.payload;
      } else {
        toast.error(data.message);
        return null;
      }
    },
    {
      onSuccess: () => {
        setIsLoggedIn(false);
      },
    }
  );

  return {
    employeeLogout,
    patientLogout,
  };
};
