import "twin.macro";

/** @jsxImportSource @emotion/react */
import { PropsWithChildren, ReactNode, useRef, useState } from "react";

import { useAsync } from "react-use";
import tw from "twin.macro";
import { IComponentBaseProps } from "../../types";
import { onKeyDownA11Y } from "../../utils";
import { waitUntilAnimationFinish } from "../../utils/wailt-until-animation-finish";
import { IconButton } from "../buttons/IconButton";
import { RiCloseLine } from "react-icons/ri";
import { Typography } from "../Typograhy";
import { useFormContext } from "react-hook-form";

export interface IModalProps extends IComponentBaseProps {
  open: boolean;
  onClose: () => void;
  label?: string;
  footerChildren?: ReactNode;
}

export const Modal = (props: PropsWithChildren<IModalProps>) => {
  const [softOpen, setSoftOpen] = useState(() => props.open);
  const [hardOpen, setHardOpen] = useState(false);
  const methods = useFormContext(); // retrieve all hook methods

  const parentRef = useRef();
  const contentRef = useRef();

  useAsync(async () => {
    document.body.ariaHidden = `${props.open}`;
    if (props.open) {
      setSoftOpen(true);
      setTimeout(() => {
        setHardOpen(true);
        // const element = contentRef.current as any;
        // if (element) {
        //   element.focus();
        // }
      }, 10);
    } else {
      setHardOpen(false);
      await waitUntilAnimationFinish(parentRef.current);
      setSoftOpen(false);
    }
  }, [props.open]);

  const onClose = async () => {
    setHardOpen(false);
    await waitUntilAnimationFinish(parentRef.current);
    setSoftOpen(false);
    if (methods) {
      methods.clearErrors();
      methods.reset();
    }
    props.onClose();
  };
  if (!softOpen) {
    return null;
  }
  return (
    <div
      aria-hidden={false}
      id="modal-overlay"
      onMouseDown={(e) => {
        e.stopPropagation();
        const target = e.target;
        //@ts-ignore
        if (target.id === "modal-overlay") {
          onClose();
        }
      }}
      // @ts-ignore
      ref={parentRef}
      css={[
        tw`flex justify-center items-center`,
        tw`fixed left-0 right-0 top-0 bottom-0 z-20`,
        tw`transition-colors duration-300`,
        hardOpen ? tw`bg-black/60` : tw`bg-transparent`,
      ]}
    >
      <div
        tabIndex={0}
        role="dialog"
        onKeyDown={onKeyDownA11Y({ close: onClose })}
        onClick={(e) => e.stopPropagation()}
        // @ts-ignore
        ref={contentRef}
        css={[
          tw`m-6 shadow rounded-md relative`,
          tw`bg-gray-50`,
          tw`transition-opacity duration-300`,
          tw`flex justify-center items-center`,
          tw`w-full 2xs:max-w-xs xs:max-w-md sm:max-w-xl md:max-w-2xl xl:max-w-4xl`,
          tw`max-h-[90%] overflow-scroll`,
          hardOpen ? tw`opacity-100` : tw`opacity-0`,
          props.containerCss,
        ]}
      >
        <div
          onKeyDown={(e) => e.stopPropagation()}
          css={[tw`w-full rounded-md`]}
        >
          {props.label && (
            <div css={[tw`flex justify-between items-center w-full p-6`]}>
              <Typography.BodyLarge>{props.label || ""}</Typography.BodyLarge>
              <IconButton.Contained
                icon={RiCloseLine}
                onClick={onClose}
                iconCss={[tw`h-6 w-6 text-gray-900`]}
                containerCss={[tw`bg-gray-100 rounded-full`]}
              />
            </div>
          )}
          <div css={[tw`px-6`, !props.label && tw`p-6`]}>{props.children}</div>
          {props.footerChildren && (
            <div css={[tw`p-6 gap-3 bg-gray-100 rounded-b-md`]}>
              {props.footerChildren}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
