import { useRecoilValue } from "recoil";
import { selectedFacilityIdState } from "../../../state";
import { Api } from "../../api";
import { useGetPaginated } from "../../api-hooks/useGetPaginated";
import { facilityEmployeesPaginatedKey } from "../../query-keys";

export const useFacilityEmployeesPaginated = () => {
  const {
    employees: { employeeControllerGetFacilityEmployeesPaginated },
  } = new Api();
  const selectedFacilityId = useRecoilValue(selectedFacilityIdState);
  const employees = useGetPaginated({
    id: Number(selectedFacilityId),
    query: employeeControllerGetFacilityEmployeesPaginated,
    queryKey: facilityEmployeesPaginatedKey(selectedFacilityId),
  });

  return {
    employees,
  };
};
