import {
  add,
  differenceInMinutes,
  endOfWeek,
  format,
  getMinutes,
  isSameHour,
  startOfWeek,
} from "date-fns";
import { useRef, useMemo, useEffect, Dispatch, SetStateAction } from "react";
import { useTime } from "react-timer-hook";
import tw from "twin.macro";
import { WorkingHoursScheduleWithShifts } from "../../api/api";
import { createTimeOptions } from "../../fields/controlled/TimePicker";
import { getDaysArray } from "../../fields/form/utils";
import { Maybe, TwinStyle } from "../../types";
import { Typography } from "../../ui/Typograhy";
import { TimeItem } from "../TimeItem";
import { CalendarHeader } from "./CalendarHeader";
/** @jsxImportSource @emotion/react */

interface IWeekCalendarProps {
  selectedDay: Date;
  setSelectedDay: Dispatch<SetStateAction<Date>>;
  activeTab: number;
  schedule: {
    id: number;
    startDate: Date;
    endDate: Date;
    patientName: string;
    serviceName: string;
    doctorName: string;
  }[];
  workTimes: WorkingHoursScheduleWithShifts[];
  containerCss?: Maybe<TwinStyle>;
}

export const WeekCalendar = ({
  selectedDay,
  setSelectedDay,
  activeTab,
  schedule,
  workTimes,
  containerCss,
}: IWeekCalendarProps) => {
  const times = createTimeOptions(15, selectedDay, "HH");
  const start = startOfWeek(selectedDay, { weekStartsOn: 1 });
  const end = endOfWeek(selectedDay, { weekStartsOn: 1 });
  const { hours, minutes } = useTime({ format: undefined });
  const daysOfWeek = getDaysArray(start, end);
  const timelineRef = useRef<HTMLDivElement>(null);
  const currentPosition = useMemo(() => {
    return ((hours * 60 + minutes) / 30) * 80;
  }, [hours, minutes]);

  useEffect(() => {
    if (activeTab === 0) {
      timelineRef.current?.scrollIntoView({
        block: "center",
        inline: "center",
      });
    }
  }, [activeTab]);

  return (
    <div css={containerCss}>
      <CalendarHeader
        setSelectedDay={setSelectedDay}
        selectedDay={selectedDay}
        type={"week"}
      />
      <div css={[tw`flex`, tw`max-w-[inherit] w-full rounded-t-md z-50`]}>
        <div
          css={[
            tw`w-[48px] flex items-center border-t-1 border-l-1 border-t-gray-100 border-l-gray-100 rounded-tl-md`,
          ]}
        />
        {daysOfWeek.map((d, i, all) => (
          <div
            key={d.getTime()}
            css={[
              tw`flex flex-1 justify-between items-center py-2.5 px-4 border-t-1 border-t-gray-100`,
              i === all.length - 1 && tw`rounded-tr-md`,
              i === 0 && tw`border-l-1 border-l-gray-100`,
              tw`border-r-1 border-r-gray-100`,
              tw`bg-white`,
            ]}
          >
            <Typography.BodySmall>
              {new Date(d).toLocaleString("en-hr", {
                weekday: "short",
              })}
            </Typography.BodySmall>
            <Typography.BodyXSmall containerCss={[tw`font-bold`]}>
              {format(d, "dd.MM.")}
            </Typography.BodyXSmall>
          </div>
        ))}
      </div>
      <div
        css={[
          tw`max-h-[calc(100vh - 18rem)] overflow-scroll relative scrollbar-hide w-full`,
          tw`border-1 border-gray-100 rounded-b-md`,
          tw`flex`,
        ]}
      >
        <div css={[tw`w-full`]}>
          {times.map((t, i) => (
            <div css={[tw`flex w-full`]} key={t.value.getTime()}>
              <div
                css={[tw`absolute ml-0.5 h-0.5 w-10 rounded bg-primary top-1`]}
                style={{ top: currentPosition }}
                ref={timelineRef}
              >
                <Typography.BodyXSmall
                  containerCss={[tw`text-primary ml-1.5 mt-1`]}
                >
                  {hours}:{minutes < 10 ? `0${minutes}` : minutes}
                </Typography.BodyXSmall>
              </div>
              <div
                css={[
                  tw`h-10 w-[50px] flex items-start justify-center border-r-1 border-r-gray-100`,
                ]}
              >
                <Typography.BodySmall containerCss={[tw`-mt-2`]}>
                  {i % 4 !== 0 || i === 0 ? "" : t.label}
                </Typography.BodySmall>
              </div>
              <div css={[tw`w-full max-w-[inherit] relative`]}>
                <div css={[tw`flex h-full w-full`]}>
                  {daysOfWeek.map((d, index, all) => {
                    const currentDate = new Date(d);
                    currentDate.setHours(
                      t.value.getHours(),
                      t.value.getMinutes()
                    );

                    const minutesFromCurrentDate =
                      currentDate.getHours() * 60 + currentDate.getMinutes();

                    const doesWork = workTimes.find(
                      (wt) =>
                        minutesFromCurrentDate >= wt.openingTime &&
                        minutesFromCurrentDate < wt.closingTime &&
                        //@ts-ignore
                        wt.daysOfTheWeek[currentDate.getDay()]
                    );

                    const firstBehindStart = workTimes.find(
                      (wt) =>
                        //@ts-ignore
                        wt.daysOfTheWeek[currentDate.getDay()] &&
                        minutesFromCurrentDate === wt.openingTime
                    );
                    const firstAfterEnd = workTimes.find(
                      (wt) =>
                        //@ts-ignore
                        wt.daysOfTheWeek[currentDate.getDay()] &&
                        minutesFromCurrentDate === wt.closingTime
                    );

                    return (
                      <div
                        key={format(d, "dd.MM")}
                        css={[
                          tw`flex-1 relative`,
                          index !== all.length - 1 &&
                            tw`border-r-1 border-r-gray-100`,
                          i !== 0 &&
                            i % 4 === 0 &&
                            tw`border-t-1 border-t-gray-100`,
                          doesWork
                            ? tw`bg-white`
                            : tw`bg-gray-100 border-t-gray-300 border-r-gray-300`,
                        ]}
                      >
                        {firstBehindStart && (
                          <div
                            css={[
                              tw`flex flex-1 items-end justify-end h-full -mt-10 border-b-1`,
                            ]}
                          >
                            <Typography.BodyXSmall
                              containerCss={[tw`text-end mr-1 mb-1`]}
                            >
                              {format(currentDate, "HH:mm")}
                            </Typography.BodyXSmall>
                          </div>
                        )}
                        {schedule
                          .filter(
                            (sc) =>
                              isSameHour(currentDate, sc.startDate) &&
                              getMinutes(sc.startDate) >=
                                getMinutes(currentDate) &&
                              getMinutes(sc.startDate) <
                                getMinutes(currentDate) + 15
                          )
                          .map((sc) => (
                            <TimeItem
                              key={sc.startDate.toISOString()}
                              vertical
                              currentTimeInMinutes={hours * 60 + minutes}
                              slot={sc}
                              offset={differenceInMinutes(
                                sc.startDate,
                                currentDate
                              )}
                            />
                          ))}
                        {firstAfterEnd && (
                          <div
                            css={[
                              tw`flex flex-1 items-start pt-1 justify-end h-full border-t-1`,
                            ]}
                          >
                            <Typography.BodyXSmall
                              containerCss={[tw`text-end mr-1`]}
                            >
                              {format(currentDate, "HH:mm")}
                            </Typography.BodyXSmall>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
